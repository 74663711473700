import { convert, LocalDate, LocalDateTime, Month, nativeJs } from "@js-joda/core";
import Popper from "@popperjs/core";
import React from "react";
import { default as ReactDatePicker } from "react-datepicker";
import { fmap } from "../../utils/general.utils";

export interface CustomHeaderParams {
  monthDate: LocalDate;
  date: LocalDate;
  changeYear(year: number): void;
  changeMonth(month: Month): void;
  customHeaderCount: number;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
}

export interface DatePickerJsJodaAdapterProps {
  pickerYears?: [number, number];
  adjustDateOnChange?: boolean | undefined;
  allowSameDay?: boolean | undefined;
  ariaDescribedBy?: string | undefined;
  ariaLabelClose?: string | undefined;
  ariaLabelledBy?: string | undefined;
  ariaRequired?: string | undefined;
  autoComplete?: string | undefined;
  autoFocus?: boolean | undefined;
  calendarClassName?: string | undefined;
  calendarContainer?(props: { children: React.ReactNode[] }): React.ReactNode;
  calendarStartDay?: number | undefined;
  children?: React.ReactNode | undefined;
  chooseDayAriaLabelPrefix?: string | undefined;
  className?: string | undefined;
  clearButtonTitle?: string | undefined;
  closeOnScroll?: boolean | ((e: Event) => boolean) | undefined;
  customInput?: React.ReactNode | undefined;
  customInputRef?: string | undefined;
  customTimeInput?: React.ReactNode | undefined;
  dateFormat?: string | string[] | undefined;
  dateFormatCalendar?: string | undefined;
  dayClassName?(date: LocalDateTime): string | null;
  weekDayClassName?(date: LocalDateTime): string | null;
  monthClassName?(date: LocalDateTime): string | null;
  timeClassName?(date: LocalDateTime): string | null;
  disabledDayAriaLabelPrefix?: string | undefined;
  disabled?: boolean | undefined;
  disabledKeyboardNavigation?: boolean | undefined;
  dropdownMode?: "scroll" | "select" | undefined;
  endDate?: LocalDate | null | undefined;
  excludeDates?: LocalDateTime[] | undefined;
  excludeTimes?: LocalDateTime[] | undefined;
  filterDate?(date: LocalDateTime): boolean;
  filterTime?(date: LocalDateTime): boolean;
  fixedHeight?: boolean | undefined;
  forceShowMonthNavigation?: boolean | undefined;
  formatWeekDay?(formattedDate: string): React.ReactNode;
  formatWeekNumber?(date: LocalDateTime): string | number;
  highlightDates?: Array<HighlightDates | LocalDateTime> | undefined;
  id?: string | undefined;
  includeDates?: LocalDateTime[] | undefined;
  includeTimes?: LocalDateTime[] | undefined;
  injectTimes?: LocalDateTime[] | undefined;
  inline?: boolean | undefined;
  focusSelectedMonth?: boolean | undefined;
  isClearable?: boolean | undefined;
  maxDate?: LocalDateTime | null | undefined;
  maxTime?: LocalDateTime | undefined;
  minDate?: LocalDateTime | null | undefined;
  minTime?: LocalDateTime | undefined;
  monthsShown?: number | undefined;
  name?: string | undefined;
  nextMonthButtonLabel?: string | React.ReactNode | undefined;
  nextYearButtonLabel?: string | undefined;
  onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
  onCalendarClose?(): void;
  onCalendarOpen?(): void;
  onChange(
    date: LocalDate | [LocalDate, LocalDate | null] | /* for selectsRange */ null | LocalDateTime,
    event: React.SyntheticEvent<unknown> | undefined,
  ): void;
  onChangeRaw?(event: React.FocusEvent<HTMLInputElement>): void;
  onClickOutside?(event: React.MouseEvent<HTMLDivElement>): void;
  onDayMouseEnter?: ((date: LocalDateTime) => void) | undefined;
  onFocus?(event: React.FocusEvent<HTMLInputElement>): void;
  onInputClick?(): void;
  onInputError?(err: { code: number; msg: string }): void;
  onKeyDown?(event: React.KeyboardEvent<HTMLDivElement>): void;
  onMonthChange?(date: LocalDateTime): void;
  onMonthMouseLeave?: (() => void) | undefined;
  onSelect?(
    date: LocalDate | LocalDateTime,
    event: React.SyntheticEvent<unknown> | undefined,
  ): void;
  onWeekSelect?(
    firstDayOfWeek: LocalDateTime,
    weekNumber: string | number,
    event: React.SyntheticEvent<unknown> | undefined,
  ): void;
  onYearChange?(date: LocalDateTime): void;
  open?: boolean | undefined;
  openToDate?: LocalDateTime | undefined;
  peekNextMonth?: boolean | undefined;
  placeholderText?: string | undefined;
  popperClassName?: string | undefined;
  popperContainer?(props: { children: React.ReactNode[] }): React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  popperModifiers?: any | undefined; // use the actual value when this is merged - https://github.com/DefinitelyTyped/DefinitelyTyped/pull/54037
  popperPlacement?: Popper.Placement | undefined;
  popperProps?: Record<string, unknown> | undefined;
  preventOpenOnFocus?: boolean | undefined;
  previousMonthButtonLabel?: string | React.ReactNode | undefined;
  previousYearButtonLabel?: string | undefined;
  readOnly?: boolean | undefined;
  renderCustomHeader?(params: CustomHeaderParams): React.ReactNode;
  renderDayContents?(dayOfMonth: number, date?: LocalDateTime): React.ReactNode;
  required?: boolean | undefined;
  scrollableMonthYearDropdown?: boolean | undefined;
  scrollableYearDropdown?: boolean | undefined;
  selected?: LocalDate | LocalDateTime | null | undefined;
  selectsEnd?: boolean | undefined;
  selectsStart?: boolean | undefined;
  selectsRange?: boolean | undefined;
  shouldCloseOnSelect?: boolean | undefined;
  showDisabledMonthNavigation?: boolean | undefined;
  showFullMonthYearPicker?: boolean | undefined;
  showMonthDropdown?: boolean | undefined;
  showMonthYearDropdown?: boolean | undefined;
  showMonthYearPicker?: boolean | undefined;
  showPopperArrow?: boolean | undefined;
  showPreviousMonths?: boolean | undefined;
  showQuarterYearPicker?: boolean | undefined;
  showTimeInput?: boolean | undefined;
  showTimeSelect?: boolean | undefined;
  showTimeSelectOnly?: boolean | undefined;
  showTwoColumnMonthYearPicker?: boolean | undefined;
  showWeekNumbers?: boolean | undefined;
  showYearDropdown?: boolean | undefined;
  showYearPicker?: boolean | undefined;
  startDate?: LocalDate | null | undefined;
  startOpen?: boolean | undefined;
  strictParsing?: boolean | undefined;
  tabIndex?: number | undefined;
  timeCaption?: string | undefined;
  timeFormat?: string | undefined;
  timeInputLabel?: string | undefined;
  timeIntervals?: number | undefined;
  title?: string | undefined;
  todayButton?: React.ReactNode | undefined;
  useShortMonthInDropdown?: boolean | undefined;
  useWeekdaysShort?: boolean | undefined;
  weekAriaLabelPrefix?: string | undefined;
  value?: string | undefined;
  weekLabel?: string | undefined;
  withPortal?: boolean | undefined;
  portalId?: string | undefined;
  wrapperClassName?: string | undefined;
  yearDropdownItemNumber?: number | undefined;
  excludeScrollbar?: boolean | undefined;
  enableTabLoop?: boolean | undefined;
  yearItemNumber?: number | undefined;
}

const DatePickerJsJodaAdapter = (props: DatePickerJsJodaAdapterProps) => {
  return (
    <ReactDatePicker
      {...props}
      calendarClassName={props.className}
      dayClassName={convFactoryFromLocalDate(props.dayClassName?.bind(this))}
      endDate={convFromLocalDate(props.endDate)}
      excludeDates={convFromLocalDateArr(props.excludeDates)}
      excludeTimes={convFromLocalDateArr(props.excludeTimes)}
      filterDate={convFactoryFromLocalDate(props.filterDate?.bind(this))}
      filterTime={convFactoryFromLocalDate(props.filterTime?.bind(this))}
      formatWeekNumber={convFactoryFromLocalDate(props.formatWeekNumber?.bind(this))}
      highlightDates={convHighlightDates(props.highlightDates)}
      includeDates={convFromLocalDateArr(props.includeDates)}
      includeTimes={convFromLocalDateArr(props.includeTimes)}
      injectTimes={convFromLocalDateArr(props.injectTimes)}
      maxDate={convFromLocalDate(props.maxDate)}
      maxTime={convFromLocalDate(props.maxTime) ?? undefined}
      minDate={convFromLocalDate(props.minDate)}
      minTime={convFromLocalDate(props.minTime) ?? undefined}
      monthClassName={convFactoryFromLocalDate(props.dayClassName?.bind(this))}
      openToDate={convFromLocalDate(props.openToDate) ?? undefined}
      renderCustomHeader={convRenderCustomHeader(props.renderCustomHeader?.bind(this))}
      renderDayContents={convRenderDayContents(props.renderDayContents?.bind(this))}
      selected={convFromLocalDate(props.selected)}
      startDate={convFromLocalDate(props.startDate)}
      timeClassName={convFactoryFromLocalDate(props.weekDayClassName?.bind(this))}
      weekDayClassName={convFactoryFromLocalDate(props.weekDayClassName?.bind(this))}
      onChange={convOnChange(props.onChange.bind(this))}
      onDayMouseEnter={convFactoryFromLocalDate(props.onDayMouseEnter?.bind(this))}
      onMonthChange={convFactoryFromLocalDate(props.onMonthChange?.bind(this))}
      onSelect={convOnSelect(props.onSelect?.bind(this))}
      onWeekSelect={convOnWeekSelect(props.onWeekSelect?.bind(this))}
      onYearChange={convFactoryFromLocalDate(props.onYearChange?.bind(this))}
    />
  );
};

export default DatePickerJsJodaAdapter;

interface ReactDatePickerHighlightDates {
  [className: string]: Date[];
}

interface HighlightDates {
  [className: string]: LocalDate[];
}

function convFactoryFromLocalDate<T>(
  fn?: (date: LocalDateTime) => T,
): ((date: Date) => T) | undefined {
  if (fn === undefined) {
    return undefined;
  }
  return (date) => fn(LocalDateTime.from(nativeJs(date)));
}

function convOnChange(
  onChange: (
    date: LocalDate | LocalDateTime | [LocalDate, LocalDate | null] | /* for selectsRange */ null,
    event: React.SyntheticEvent<unknown> | undefined,
  ) => void,
): (
  date: Date | [null, null] | [Date, Date | null] | /* for selectsRange */ null,
  event: React.SyntheticEvent<unknown> | undefined,
) => void {
  return (date, event) => {
    if (date === null) {
      return onChange(null, event);
    }

    if (Array.isArray(date)) {
      const [start, end] = date;

      if (start === null) {
        return onChange(null, event);
      }

      return onChange(
        [LocalDate.from(nativeJs(start)), fmap(end, (x) => LocalDate.from(nativeJs(x))) ?? null],
        event,
      );
    }

    return onChange(LocalDateTime.from(nativeJs(date)), event);
  };
}

function convOnSelect(
  onSelect?: (
    date: LocalDate | LocalDateTime,
    event: React.SyntheticEvent<unknown> | undefined,
  ) => void,
): ((date: Date, event: React.SyntheticEvent<unknown> | undefined) => void) | undefined {
  if (onSelect === undefined) {
    return undefined;
  }

  return (date, event) => onSelect(LocalDateTime.from(nativeJs(date)), event);
}

function convRenderDayContents(
  renderDayContents?: (dayOfMonth: number, date?: LocalDateTime) => React.ReactNode,
): ((dayOfMonth: number, date?: Date) => React.ReactNode) | undefined {
  if (renderDayContents === undefined) {
    return undefined;
  }

  return (dayOfMonth, date) => renderDayContents(dayOfMonth, LocalDateTime.from(nativeJs(date)));
}

function convFromLocalDate(date?: LocalDate | LocalDateTime | null) {
  if (date instanceof LocalDate || date instanceof LocalDateTime) {
    return convert(date).toDate();
  }

  if (date === null) {
    return null;
  }

  return undefined;
}

function convRenderCustomHeader(
  fn?: (params: CustomHeaderParams) => React.ReactNode,
):
  | ((params: {
      monthDate: Date;
      date: Date;
      changeYear(year: number): void;
      changeMonth(month: number): void;
      customHeaderCount: number;
      decreaseMonth(): void;
      increaseMonth(): void;
      prevMonthButtonDisabled: boolean;
      nextMonthButtonDisabled: boolean;
      decreaseYear(): void;
      increaseYear(): void;
      prevYearButtonDisabled: boolean;
      nextYearButtonDisabled: boolean;
    }) => React.ReactNode)
  | undefined {
  if (fn === undefined) {
    return undefined;
  }

  return (params) =>
    fn({
      ...params,
      monthDate: LocalDate.from(nativeJs(params.monthDate)),
      date: LocalDate.from(nativeJs(params.date)),
      changeMonth: (month) => params.changeMonth(month.ordinal()),
    });
}

function convFromLocalDateArr<T extends LocalDateTime[] | undefined>(dates: T) {
  return dates?.map((date) => convert(date).toDate());
}

function convOnWeekSelect(
  fn?: (
    firstDayOfWeek: LocalDateTime,
    weekNumber: string | number,
    event: React.SyntheticEvent<unknown> | undefined,
  ) => void,
):
  | ((
      firstDayOfWeek: Date,
      weekNumber: string | number,
      event: React.SyntheticEvent<unknown> | undefined,
    ) => void)
  | undefined {
  if (fn === undefined) {
    return undefined;
  }

  return (firstDayOfWeek, weekNumber, event) =>
    fn(LocalDateTime.from(nativeJs(firstDayOfWeek)), weekNumber, event);
}

function convHighlightDates(
  highlightDates: Array<HighlightDates | LocalDateTime> | undefined,
): Array<ReactDatePickerHighlightDates | Date> | undefined {
  if (highlightDates === undefined) {
    return undefined;
  }

  return highlightDates.map((x): ReactDatePickerHighlightDates | Date => {
    if (x instanceof LocalDateTime) {
      return convert(x).toDate();
    }

    const a = Object.entries(x).reduce((acc, [key, dates]) => {
      acc[key] = dates.map((date) => convert(date).toDate());
      return acc;
    }, {} as ReactDatePickerHighlightDates);

    return a;
  });
}
