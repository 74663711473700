import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import InternalErrorBox from "../../shared/components/InternalErrorBox";
import LoadingPage from "../../shared/components/LoadingPage";
import useZodSearchParamFilters from "../../shared/hooks/useZodSearchParamFilters";
import { AgencyMemberId } from "../../shared/schema/schema";
import { useApi } from "../../shared/utils/use-api";
import WorkflowsPage from "./WorkflowsPage";

export const zListedWorkflowFilters = z.object({
  name: z.string(),
  owners: z.array(AgencyMemberId),
  publicationStatus: z.union([z.literal("PUBLISHED"), z.literal("UNPUBLISHED")]).nullable(),
});
export type ListedWorkflowFilters = z.infer<typeof zListedWorkflowFilters>;

export const workflowDefinitionsDefaultFilters: ListedWorkflowFilters = {
  name: "",
  owners: [],
  publicationStatus: "PUBLISHED",
};

const WorkflowsRoute = () => {
  const { queries } = useApi();

  const [filters, setFilters] = useZodSearchParamFilters({
    schema: zListedWorkflowFilters,
    default: workflowDefinitionsDefaultFilters,
    key: "filters",
  });
  const query = useQuery(queries.workflowDefinitions({ filters }));

  const handleChangeFilters = (changes: Partial<ListedWorkflowFilters>) => {
    setFilters({ ...filters, ...changes });
  };

  if (query.isLoading) {
    return <LoadingPage />;
  }

  if (query.isError) {
    return <InternalErrorBox error={query.error} onRetry={query.refetch} />;
  }

  return (
    <WorkflowsPage
      filters={filters}
      owners={query.data.owners}
      workflows={query.data.workflows}
      onChangeFilters={handleChangeFilters}
    />
  );
};

export default WorkflowsRoute;
