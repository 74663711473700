import { useParams } from "react-router-dom";
import { z } from "zod";

export default function usePathParams<$PathParamSchema extends z.AnyZodObject>(
  schema: $PathParamSchema,
): z.infer<$PathParamSchema> {
  const params = useParams();

  return schema.parse(params);
}
