import { Flex } from "@chakra-ui/react";
import QueryResolver from "../../../shared/components/QueryResolver";
import { ResolvedInput } from "../../../shared/schemas/workflows";
import { toArray } from "../../../shared/utils/general.utils";
import { useApi } from "../../../shared/utils/use-api";

export default function ResolvedInputFormatter(props: ResolvedInput): JSX.Element {
  if (props.type === "entity") {
    return <EntityResolvedInputFormatter {...props} />;
  }

  if (props.array) {
    return <>{props.value.map((x) => String(x)).join(", ")}</>;
  }

  return <>{props.value}</>;
}

function EntityResolvedInputFormatter(props: ResolvedInput & { type: "entity" }) {
  const { queries } = useApi();
  return (
    <Flex gap={2} sx={{ "> *:not(:last-child):after": { content: '", "' } }}>
      {toArray(props.value).map((value) => (
        <QueryResolver
          key={value}
          options={queries.entityName({ entity: props.entity, id: value })}
          onError={`Error (${props.value})`}
          onLoading={`... (${props.value})`}
          onSuccess={(x) => (
            <span>
              {String(x.data)} ({props.value})
            </span>
          )}
        />
      ))}
    </Flex>
  );
}
