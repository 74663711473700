import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { WorkflowDefinitionCorrelationKey } from "../../../shared/schema/schema";
import { WorkflowDefinition } from "../../../shared/schemas/workflows";
import { generateId } from "../../../shared/utils/string.utils";
import { useApi } from "../../../shared/utils/use-api";
import { workflowDefinitionsDefaultFilters } from "../../Workflows/WorkflowsRoute";

export default function useEditor(props: {
  workflow: WorkflowDefinition;
  onError: (error: unknown) => void;
}) {
  const { queries, mutations } = useApi();
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const createWorkflowMutation = useMutation(mutations.createWorkflow());
  const updateWorkflowMutation = useMutation(mutations.updateWorkflow());
  const publishWorkflowMutation = useMutation(mutations.publishWorkflow());
  const cloneWorkflowMutation = useMutation(mutations.cloneWorkflow());

  const handleSaveWorkflow = (workflow: WorkflowDefinition) => {
    const isEditing = workflow.id > 0;

    if (isEditing) {
      updateWorkflowMutation.mutate(workflow, {
        onSuccess: (data) => {
          if (workflow.id !== data.id) {
            navigate({ pathname: `/editor/${data.id}` });
            toast({ position: "top", description: "New draft created", status: "success" });
          } else {
            toast({ position: "top", description: "Workflow updated", status: "success" });
          }
        },
        onError: props.onError,
      });
    } else {
      createWorkflowMutation.mutate(workflow, {
        onSuccess: (data) => {
          navigate({ pathname: `/editor/${data.workflow.id}` });
          toast({ position: "top", description: "Workflow created", status: "success" });
        },
        onError: props.onError,
      });
    }

    queryClient.invalidateQueries(
      queries.workflowDefinitions({ filters: workflowDefinitionsDefaultFilters }).queryKey,
    );
  };

  const handleChangePublicationState = (params: {
    published: boolean;
    deleteScheduledInstances: boolean;
  }) => {
    publishWorkflowMutation.mutate(
      {
        ...params,
        id: props.workflow.id,
      },
      {
        onSuccess: () => {
          toast({
            position: "top",
            description: params.published ? "Workflow published" : "Workflow unpublished",
            status: "success",
          });
          queryClient.invalidateQueries(
            queries.workflowDefinitions({ filters: workflowDefinitionsDefaultFilters }).queryKey,
          );
        },
        onError: props.onError,
      },
    );
  };

  const handleCloneWorkflow = () => {
    cloneWorkflowMutation.mutate(
      {
        id: props.workflow.id,
        correlationKey: WorkflowDefinitionCorrelationKey.parse(generateId("correlationkey")),
      },
      {
        onSuccess: (data) => {
          navigate({ pathname: `/editor/${data.id}` });
          toast({ position: "top", description: "Workflow cloned", status: "success" });
          queryClient.invalidateQueries(
            queries.workflowDefinitions({ filters: workflowDefinitionsDefaultFilters }).queryKey,
          );
        },
        onError: props.onError,
      },
    );
  };

  return {
    isSaving: createWorkflowMutation.isLoading || updateWorkflowMutation.isLoading,
    isPublishing: publishWorkflowMutation.isLoading,
    isCloning: cloneWorkflowMutation.isLoading,
    saveWorkflow: handleSaveWorkflow,
    changePublicationState: handleChangePublicationState,
    cloneWorkflow: handleCloneWorkflow,
    showError: props.onError,
  };
}
