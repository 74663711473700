import { ButtonProps, FormControl, FormLabel } from "@chakra-ui/react";
import { RequiredAst } from "../../../shared/components/RequiredAst";
import { Messages } from "../../../shared/api";
import WorkflowEntityFormControl, { WorkflowEntitySelect } from "./WorkflowEntityFormControl";
import { InferWorkflowEntityFieldType, WorkflowEntityFieldLiteral } from "../workflow.types";

type EntityInput = Messages["WorkflowEntityField"] & {
  entity: keyof WorkflowEntityFieldLiteral;
};

type ArrayInputProps<TInput extends EntityInput> = {
  multiple: true;
  value: InferWorkflowEntityFieldType<TInput>[] | null;
  onChange: (value: InferWorkflowEntityFieldType<TInput>[] | null) => void;
};

type NonArrayInputProps<TInput extends EntityInput> = {
  multiple: false;
  value: InferWorkflowEntityFieldType<TInput> | null;
  onChange: (value: InferWorkflowEntityFieldType<TInput> | null) => void;
};

type Props<TInput extends EntityInput> = {
  input: TInput;
  errors?: string[];
  isRequired?: boolean;
  multiple: boolean;
  label: string;
  renderUnselected?: string;
  defaultFilters?: Record<PropertyKey, unknown>;
  hardFilters?: Record<PropertyKey, unknown>;
  renderHint?: boolean;
  size?: ButtonProps["size"];
  allowUnselect?: boolean;
} & (ArrayInputProps<TInput> | NonArrayInputProps<TInput>);

export default function EntityFormControl<TInput extends EntityInput>(
  props: Props<TInput> & { showLabel?: boolean },
) {
  return (
    <FormControl isInvalid={(props.errors ?? []).length > 0}>
      {props.showLabel !== false && (
        <FormLabel>
          {props.label}
          {props.isRequired && <RequiredAst />}:
        </FormLabel>
      )}
      <EntitySelect renderHint={true} {...props} />
    </FormControl>
  );
}

export function EntitySelect<TInput extends EntityInput>(props: Props<TInput>) {
  const handleChange: React.ComponentProps<typeof WorkflowEntityFormControl>["onChange"] = (
    value,
  ) => {
    const definedValues = value.filter(Boolean) as InferWorkflowEntityFieldType<TInput>[];

    return props.multiple
      ? props.onChange(definedValues.length > 0 ? definedValues : null)
      : props.onChange(definedValues.length > 0 ? definedValues.at(0) ?? null : null);
  };

  const handleChangeField: React.ComponentProps<
    typeof WorkflowEntityFormControl
  >["onChangeField"] = (e, index) => {
    const value = e.target.value;
    const arrValue = Array.isArray(props.value) ? props.value : [props.value];

    return handleChange(arrValue.map((x, i) => (i === index ? (value === "" ? null : value) : x)));
  };

  return (
    <WorkflowEntitySelect
      allowUnselect={props.allowUnselect}
      defaultFilters={props.defaultFilters}
      errors={props.errors ?? []}
      hardFilters={props.hardFilters}
      hint={null}
      input={{ ...props.input, array: props.multiple, name: props.label }}
      isRequired={props.isRequired ?? false}
      renderHint={props.renderHint ?? false}
      renderUnselected={props.renderUnselected}
      size={props.size}
      value={Array.isArray(props.value) ? props.value : [props.value]}
      onChange={handleChange}
      onChangeField={handleChangeField}
    />
  );
}
