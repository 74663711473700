import { createIcon } from "@chakra-ui/react";

const EditRoundedIcon = createIcon({
  displayName: "EditRoundedIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="m19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM4 21q-.425 0-.713-.288T3 20v-2.825q0-.2.075-.388t.225-.337l10.3-10.3l4.25 4.25l-10.3 10.3q-.15.15-.337.225T6.825 21H4Z"
      fill="currentColor"
    />
  ),
});

export default EditRoundedIcon;
