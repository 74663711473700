import React from "react";

export default function useSelectedNode() {
  const [selectedNodeId, setSelectedNodeId] = React.useState<string | null>(null);

  const select = (nodeId: string) => {
    setSelectedNodeId(nodeId);
  };

  const unselect = (nodeId: string) => {
    if (selectedNodeId === nodeId) {
      setSelectedNodeId(null);
    }
  };

  return { selectedNodeId, select, unselect };
}
