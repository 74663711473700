import React from "react";

export default function useEditorHistory(params: {
  onUndo: () => void;
  onRedo: () => void;
  onSave: () => void;
}) {
  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.code === "KeyZ") {
        if (isFocusedOnAnyInput()) {
          return;
        }

        e.preventDefault();

        if (e.shiftKey) {
          params.onRedo();
        } else {
          params.onUndo();
        }
      }

      if ((e.metaKey || e.ctrlKey) && e.code === "KeyY") {
        if (isFocusedOnAnyInput()) {
          return;
        }

        e.preventDefault();
        params.onRedo();
      }

      if ((e.metaKey || e.ctrlKey) && e.code === "KeyS") {
        e.preventDefault();
        params.onSave();
      }
    };

    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  });
}

function isFocusedOnAnyInput(): boolean {
  return document.activeElement instanceof HTMLInputElement;
}
