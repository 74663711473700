import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { DefinitionNode, OperationDefinitionNode, TypeNode } from "graphql";
import { fmap } from "./general.utils";

export const nonFilterableVariables = ["order", "limit", "offset"] as const;
type NonFilterableVariables = (typeof nonFilterableVariables)[number];

export type GraphQLDocumentVariables = Record<NonFilterableVariables, unknown> &
  Record<string, unknown>;

export type GraphQLDocumentFilters<TDocumentVariables extends GraphQLDocumentVariables> = Omit<
  TDocumentVariables,
  NonFilterableVariables
>;

export function fmapIn<T>(value: T[] | null | undefined): { in: T[] } | undefined {
  return fmap(value, (x) => ({ in: x }));
}

export function fmapEq<T>(value: T | null | undefined): { eq: T } | undefined {
  return fmap(value, (x) => ({ eq: x }));
}

export function isOperationDefinition(node: DefinitionNode): node is OperationDefinitionNode {
  return node.kind === "OperationDefinition";
}

export function getGraphQLFilterVariableEntries<
  TDocumentVariables extends GraphQLDocumentVariables,
>(
  document: TypedDocumentNode<unknown, TDocumentVariables>,
): [Exclude<keyof TDocumentVariables, NonFilterableVariables>, string][] {
  return getDocumentNodeVariables(document).filter(
    (entry): entry is [Exclude<keyof TDocumentVariables, NonFilterableVariables>, string] => {
      return !nonFilterableVariables.includes(entry[0] as NonFilterableVariables);
    },
  );
}

export function getDocumentNodeVariables<TDocumentVariables>(
  document: TypedDocumentNode<unknown, TDocumentVariables>,
): [keyof TDocumentVariables, string][] {
  const definiton = document.definitions.find(isOperationDefinition);

  if (definiton === undefined) {
    throw new Error("Invalid document. Expected query operation");
  }

  if (definiton.variableDefinitions === undefined) {
    return [];
  }

  return definiton.variableDefinitions.map((x) => [
    x.variable.name.value as keyof TDocumentVariables,
    getTypeNodeNamedTypeValue(x.type),
  ]);
}

function getTypeNodeNamedTypeValue(node: TypeNode): string {
  if (node.kind === "NamedType") {
    return node.name.value;
  }

  if (node.kind === "NonNullType") {
    return getTypeNodeNamedTypeValue(node.type);
  }

  return getTypeNodeNamedTypeValue(node.type);
}
