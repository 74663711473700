import { Wrap } from "@chakra-ui/react";
import Sidebar from "../../../Sidebar";

export default function DisplayResolvedValue(props: { value: string | string[] | null }) {
  if (props.value === null) {
    return <Sidebar.Text>None</Sidebar.Text>;
  }

  if (Array.isArray(props.value)) {
    return (
      <Wrap>
        {props.value.map((value, index) => (
          <Sidebar.Text key={index}>{value}</Sidebar.Text>
        ))}
      </Wrap>
    );
  }

  return <Sidebar.Text>{props.value}</Sidebar.Text>;
}
