import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Skeleton,
  Switch,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { XYPosition } from "reactflow";
import { AddFieldSelection } from "../../../../../shared/components/AddFieldSelection";
import InternalErrorBox from "../../../../../shared/components/InternalErrorBox";
import NodeIcon from "../../../../../shared/components/NodeIcon";
import Select from "../../../../../shared/components/Select";
import WorkflowTaskClusterSelect from "../../../../../shared/components/WorkflowTaskClusterSelect";
import AddIcon from "../../../../../shared/icons/AddIcon";
import BaselineMinusIcon from "../../../../../shared/icons/BaselineMinusIcon";
import {
  AgencyMemberId,
  WorkflowSkillId,
  WorkflowTagId,
} from "../../../../../shared/schema/schema";
import {
  ConcurrencyDomain,
  ConcurrencyRule,
  ConcurrencyRuleOption,
  HumanTaskAssignmentStrategy,
  HumanTaskAssignmentStrategyOption,
  HumanTaskTemplateDefinition,
  HumanTaskTemplateReference,
  NamedField,
  NamedOutput,
  NodeReferenceQuery,
  SchedulingType,
  WorkflowChildChoiceTask,
  WorkflowChildGroup,
  WorkflowChildHumanTaskReference,
  WorkflowChildReferenceBinding,
  WorkflowChildResetToTask,
  WorkflowChildScheduling,
  WorkflowChildSystemTaskReference,
  WorkflowChoice,
  WorkflowDataField,
  WorkflowEntity,
  WorkflowError,
  WorkflowErrorEvent,
  WorkflowErrorHandlerType,
  WorkflowEventTriggerOption,
  WorkflowNode,
  WorkflowRetry,
  WorkflowTag,
  WorkflowTagReference,
  WorkflowTaskClusterReference,
  WorkflowTaskQuery,
  WorkflowTaskTrigger,
  schedulingTypes,
  workflowErrorEvents,
} from "../../../../../shared/schemas/workflows";
import { emptyObject } from "../../../../../shared/utils/empty-object";
import { fmap } from "../../../../../shared/utils/general.utils";
import { generateId, sanitizeTestId } from "../../../../../shared/utils/string.utils";
import { useApi } from "../../../../../shared/utils/use-api";
import CreateTagModal from "../../../../Tags/components/CreateTagModal";
import { EntitySelect } from "../../../../Workflows/components/EntityFormControl";
import {
  InputPath,
  bindCanHavePlaceholders,
  bindPlaceholdersAreNotEmpty,
  emptyRef,
  getAvailableNodesForReset,
  getNodeInput,
  getPlaceholdersFromBind,
  isValidScheduleBind,
  transformInputFieldsToOptional,
} from "../../../utils/workflow-node.utils";
import Sidebar from "../../Sidebar";
import ChoiceComponent from "../ChoiceTask/components/ChoiceComponent";
import ChoiceDefault from "../ChoiceTask/components/ChoiceDefault";
import BindButton from "../Node/components/BindButton";
import FancySelection from "../Node/components/FancySelection";
import InputBinding from "../Node/components/InputBinding";
import QueryComponent from "../Node/components/QueryComponent";
import ConcurrencyDomainSelect from "./components/ConcurrencyDomainSelect";
import ConcurrencyRuleSelect from "./components/ConcurrencyRuleSelect";
import DefineFieldTypeComponent from "./components/DefineFieldTypeComponent";
import IntervalInput from "./components/IntervalInput";
import PrioritySlider from "./components/PrioritySlider";
import SeverityMenu from "./components/SeverityMenu";
import TaskOutputComponent from "./components/TaskOutputComponent";
import TaskTriggerComponent from "./components/TaskTriggerComponent";

export type TaskMeta =
  | WorkflowChildHumanTaskReference["meta"]
  | WorkflowChildSystemTaskReference["meta"];

export type GroupMeta = WorkflowChildGroup["meta"];

export type ChoiceMeta = WorkflowChildChoiceTask["meta"];

export type BasicMeta = {
  name: string;
  position?: XYPosition;
  description?: string;
};

export type NodeMeta = TaskMeta | GroupMeta;

export function DescriptionComponentSection(props: {
  description: string | undefined;
  placeholder?: string;
  onChange: (description: string) => void;
}) {
  return (
    <>
      <Sidebar.Section>
        <Sidebar.Description
          data-testid="task-description-input"
          placeholder={props.placeholder ?? "Describe your task..."}
          value={props.description}
          onChange={(e) => props.onChange(e.target.value)}
        />
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function InputBindComponentSection(props: {
  input: Record<string, WorkflowDataField>;
  bind: Record<string, WorkflowChildReferenceBinding>;
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  sectionTitle?: React.ReactNode;
  onUpdateBind: (name: string, bind: WorkflowChildReferenceBinding | null) => void;
}) {
  if (Object.keys(props.input).length === 0) {
    return null;
  }

  const handleUpdatePlaceholder = (
    name: string,
    placeholderName: string,
    placeholderBind: WorkflowChildReferenceBinding | null,
    bind: WorkflowChildReferenceBinding,
  ) => {
    if (bind.type !== "text" && bind.type !== "textarea") {
      return;
    }

    props.onUpdateBind(name, {
      ...bind,
      placeholders:
        placeholderBind === null
          ? Object.fromEntries(
              Object.entries(bind.placeholders ?? {}).filter(([key]) => key !== placeholderName),
            )
          : {
              ...bind.placeholders,
              [placeholderName]: placeholderBind,
            },
    });
  };

  return (
    <>
      <Sidebar.Section data-testid="input-bind-section">
        <Sidebar.Row>
          <Sidebar.SectionTitle>{props.sectionTitle ?? "Input"}</Sidebar.SectionTitle>
        </Sidebar.Row>
        {Object.entries(props.input).map(([name, input]) => {
          const bind: WorkflowChildReferenceBinding = props.bind[name] ?? emptyRef.bind({ input });

          return (
            <React.Fragment key={name}>
              <InputBinding
                availablePaths={props.availablePaths}
                availableQueries={null}
                bind={bind}
                entityOptions={props.entityOptions}
                input={input}
                name={name}
                onUpdate={props.onUpdateBind}
              />
              {bindPlaceholdersAreNotEmpty(bind) && (
                <Container mb={4} mt={2} paddingEnd={0}>
                  {Object.entries(bind.placeholders ?? {}).map(
                    ([placeholderName, placeholderValue]) => {
                      return (
                        <InputBinding
                          key={placeholderName}
                          availablePaths={props.availablePaths}
                          availableQueries={null}
                          bind={placeholderValue}
                          entityOptions={props.entityOptions}
                          input={{ type: "text" }}
                          name={placeholderName}
                          onUpdate={(_, placeholderBind) =>
                            handleUpdatePlaceholder(name, placeholderName, placeholderBind, bind)
                          }
                        />
                      );
                    },
                  )}
                </Container>
              )}
            </React.Fragment>
          );
        })}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function OutputStaticComponentSection(props: { output: Record<string, NamedOutput> }) {
  if (Object.keys(props.output).length === 0) {
    return null;
  }

  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Output</Sidebar.SectionTitle>
        </Sidebar.Row>
        {Object.entries(props.output).map(([resultId, outputRow]) => (
          <TaskOutputComponent key={resultId} name={resultId} outputRow={outputRow} />
        ))}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function QueriesComponentSection(props: {
  id: string;
  queries: Map<string, NodeReferenceQuery>;
  queryOptions: WorkflowTaskQuery[];
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  onAddQuery: (nodeRefId: string) => void;
  onUpdateQuery: (nodeRefId: string, queryRefId: string, query: NodeReferenceQuery) => void;
  onRemoveQuery: (nodeRefId: string, queryRefId: string) => void;
}) {
  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Queries</Sidebar.SectionTitle>
          <Sidebar.SquareButton
            aria-label="add query"
            data-testid="add-query-button"
            onClick={() => props.onAddQuery(props.id)}
          >
            <AddIcon />
          </Sidebar.SquareButton>
        </Sidebar.Row>
        {[...props.queries].map(([id, query], i) => {
          const availableQueries = new Map([...props.queries].slice(0, i));
          return (
            <QueryComponent
              key={i}
              availablePaths={props.availablePaths}
              availableQueries={availableQueries}
              entityOptions={props.entityOptions}
              id={id}
              queryOptions={props.queryOptions}
              queryRef={query}
              onClickRemove={() => props.onRemoveQuery(props.id, id)}
              onUpdate={(id, query) => props.onUpdateQuery(props.id, id, query)}
            />
          );
        })}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function ChoiceTaskConditionsComponentSection(props: {
  id: string;
  node: WorkflowChildChoiceTask;
  queryOptions: WorkflowTaskQuery[];
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  onAddChoice: () => void;
  onRemoveChoice: (index: number) => void;
  onUpdateChoice: (index: number, choice: WorkflowChoice) => void;
  onUpdateNode: (node: WorkflowChildChoiceTask) => void;
}) {
  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Choices</Sidebar.SectionTitle>
          <Sidebar.SquareButton aria-label="add choice" onClick={props.onAddChoice}>
            <AddIcon />
          </Sidebar.SquareButton>
        </Sidebar.Row>
        {props.node.choices.map((choice, i) => (
          <ChoiceComponent
            key={i}
            availablePaths={props.availablePaths}
            choice={choice}
            entityOptions={props.entityOptions}
            isFirst={i === 0}
            queryOptions={props.queryOptions}
            onRemove={() => props.onRemoveChoice(i)}
            onUpdate={(choice) => props.onUpdateChoice(i, choice)}
          />
        ))}
        {props.node.choices.length > 0 && (
          <ChoiceDefault node={props.node} onUpdate={props.onUpdateNode} />
        )}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function TaskTriggersComponentSection(props: {
  node: WorkflowNode;
  triggerEventOptions: WorkflowEventTriggerOption[];
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  onAdd: () => void;
  onUpdate: (trigger: WorkflowTaskTrigger) => void;
  onRemove: () => void;
}) {
  return (
    <>
      <Sidebar.Section data-testid="task-trigger-section">
        <Sidebar.Row>
          <Sidebar.SectionTitle>Trigger</Sidebar.SectionTitle>
          <Sidebar.SquareButton aria-label="add trigger" onClick={props.onAdd}>
            <AddIcon />
          </Sidebar.SquareButton>
        </Sidebar.Row>
        {props.node.trigger && (
          <TaskTriggerComponent
            availablePaths={props.availablePaths}
            entityOptions={props.entityOptions}
            trigger={props.node.trigger}
            triggerEventOptions={props.triggerEventOptions}
            onRemove={props.onRemove}
            onUpdate={props.onUpdate}
          />
        )}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function MandatoryComponentSection(props: {
  mandatory: boolean;
  onUpdateMeta: <T extends keyof TaskMeta>(name: T, value: TaskMeta[T]) => void;
}) {
  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Mandatory</Sidebar.SectionTitle>
          <Switch
            isChecked={props.mandatory}
            onChange={(e) => props.onUpdateMeta("mandatory", e.target.checked)}
          />
        </Sidebar.Row>
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function PriorityComponentSection(props: {
  priority: number;
  onUpdate: (priority: number) => void;
}) {
  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Priority</Sidebar.SectionTitle>
          <Container paddingX={8}>
            <PrioritySlider value={props.priority} onChange={props.onUpdate} />
          </Container>
        </Sidebar.Row>
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function SeverityComponentSection(props: {
  severity: string;
  onUpdate: (severity: string) => void;
}) {
  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Severity</Sidebar.SectionTitle>
          <SeverityMenu value={props.severity} onChange={props.onUpdate} />
        </Sidebar.Row>
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function ConcurrencyComponentSection(props: {
  rule: ConcurrencyRuleOption;
  onUpdate: (rule: ConcurrencyRuleOption) => void;
}) {
  const handleChangeRule = (rule: ConcurrencyRule) => {
    props.onUpdate({ ...props.rule, rule });
  };

  const handleChangeDomain = (domain: ConcurrencyDomain) => {
    props.onUpdate({ ...props.rule, domain });
  };

  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Concurrency</Sidebar.SectionTitle>
          <ConcurrencyRuleSelect value={props.rule.rule} onChange={handleChangeRule} />
        </Sidebar.Row>

        {props.rule.rule !== "RUN_ALWAYS" && (
          <Sidebar.Row>
            <Sidebar.Label>Domain</Sidebar.Label>
            <ConcurrencyDomainSelect value={props.rule.domain} onChange={handleChangeDomain} />
          </Sidebar.Row>
        )}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function SchedulingComponentSection(props: {
  timeout: number | undefined;
  createAfter?: WorkflowChildScheduling["createAfter"];
  createBefore?: WorkflowChildScheduling["createBefore"];
  finishBefore: WorkflowChildScheduling["finishBefore"] | undefined;
  availablePaths: InputPath[];
  entityOptions: WorkflowEntity[];
  isErrorHandling?: boolean;
  onUpdateScheduling: <T extends keyof WorkflowChildScheduling>(
    name: T,
    value: WorkflowChildScheduling[T],
  ) => void;
}) {
  const hasTimeout = props.timeout !== undefined;

  const handleToggleTimeout = () => {
    props.onUpdateScheduling("timeout", hasTimeout ? undefined : 0);
  };

  const scheduleInfo: Record<SchedulingType, { name: string; description: string }> = {
    createAfter: {
      name: "Create after",
      description: "The task will only be created after the specified time",
    },
    createBefore: {
      name: "Create before",
      description: "The task will fail if not created before the specified time",
    },
    finishBefore: {
      name: "Finish before",
      description: "The task will fail if not finished before the specified time",
    },
  };

  const schedules = schedulingTypes.map((key) => ({
    key: key,
    has: props[key] !== undefined,
    toggle: () => {
      props.onUpdateScheduling(key, props[key] === undefined ? null : undefined);
    },
    ...scheduleInfo[key],
  }));

  const filterSchedule = (schedule: (typeof schedules)[0]) =>
    !props.isErrorHandling || schedule.key === "finishBefore";

  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Scheduling</Sidebar.SectionTitle>
          <Menu isLazy placement="bottom-end">
            <MenuButton
              as={Sidebar.SquareButton}
              data-testid="add-scheduling-button"
              icon={<AddIcon />}
            />
            <Portal>
              <MenuList minW={24}>
                <MenuItem data-testid="scheduling-timeout-button" onClick={handleToggleTimeout}>
                  Timeout
                </MenuItem>
                {schedules
                  .filter((schedule) => !schedule.has)
                  .filter(filterSchedule)
                  .map((schedule) => (
                    <MenuItem
                      key={schedule.key}
                      data-testid={`scheduling-${schedule.key}-button`}
                      onClick={schedule.toggle}
                    >
                      {schedule.name}
                    </MenuItem>
                  ))}
              </MenuList>
            </Portal>
          </Menu>
        </Sidebar.Row>
        {hasTimeout && (
          <Sidebar.Row>
            <Sidebar.Label>Timeout</Sidebar.Label>
            <Flex alignItems="center">
              <IntervalInput
                value={props.timeout ?? 1}
                onChange={(v) => props.onUpdateScheduling("timeout", v)}
              />
              <Sidebar.SquareButton aria-label="remove" onClick={handleToggleTimeout}>
                <BaselineMinusIcon />
              </Sidebar.SquareButton>
            </Flex>
          </Sidebar.Row>
        )}
        {schedules
          .filter((schedule) => schedule.has)
          .filter(filterSchedule)
          .map((schedule) => {
            const property = props[schedule.key];
            return (
              <Sidebar.Row key={schedule.key}>
                <Sidebar.Label hint={schedule.description}>{schedule.name}</Sidebar.Label>
                <Flex alignItems="center">
                  <BindButton
                    availablePaths={props.availablePaths}
                    availableQueries={null}
                    bind={property ?? null}
                    entityOptions={props.entityOptions}
                    input={
                      property?.type === "path"
                        ? property.path?.output ?? { type: "datetime" }
                        : { type: "datetime" }
                    }
                    name={schedule.name}
                    onUpdate={(_, bind) => {
                      props.onUpdateScheduling(
                        schedule.key,
                        bind !== null && !isValidScheduleBind(bind) ? undefined : bind,
                      );
                    }}
                  />
                  <Sidebar.SquareButton aria-label="remove" onClick={schedule.toggle}>
                    <BaselineMinusIcon />
                  </Sidebar.SquareButton>
                </Flex>
              </Sidebar.Row>
            );
          })}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function ErrorHandlingComponentSection(props: {
  errors: WorkflowError[] | undefined;
  scheduling: WorkflowChildScheduling | undefined;
  output: Record<string, NamedOutput>;
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  queryOptions: WorkflowTaskQuery[];
  availableQueries: Map<string, NodeReferenceQuery>;
  templates: HumanTaskTemplateDefinition[];
  skillOptions: WorkflowSkillId[];
  hasRetry: boolean;
  onAdd: (type: WorkflowErrorHandlerType) => void;
  onUpdate: (error: WorkflowError) => void;
  onRemove: (error: WorkflowError) => void;
}) {
  const selectedEvents = new Set(
    (props.errors?.flatMap((error) => error.on) ?? []).filter(
      (event) =>
        event === "unexpected" || event === "retryLimit" || props.scheduling?.[event] !== undefined,
    ),
  );
  const availableEvents = workflowErrorEvents.filter((event) => {
    if (selectedEvents.has(event)) {
      return false;
    }

    if (event === "retryLimit") {
      return props.hasRetry;
    }

    return event === "unexpected" || props.scheduling?.[event] !== undefined;
  });

  const handlingTypes = [
    {
      id: "new-task",
      label: "Create new task",
      description: "Create a new task to handle the error. Only context above is available.",
    },
    {
      id: "fallback-to-manual",
      label: "Fallback to manual",
      description:
        "Create a human task to handle the error. The user will choose the path and enter the context.",
    },
    {
      id: "fallback-to-default",
      label: "Fallback to default",
      description: "Choose a default path to handle the error. Define the context to be used.",
    },
  ] as const;

  return (
    <>
      <Sidebar.Section data-section="error-handling">
        <Sidebar.Row>
          <Sidebar.SectionTitle>Error handling</Sidebar.SectionTitle>
          <Menu placement="bottom-end">
            <MenuButton
              as={Sidebar.SquareButton}
              data-testid="add-error-handling-button"
              icon={<AddIcon />}
            />
            <Portal>
              <MenuList maxW="sm">
                {handlingTypes.map((handlingType) => (
                  <MenuItem
                    key={handlingType.id}
                    data-testid={`error-handling-${sanitizeTestId(handlingType.id)}-button`}
                    onClick={() => props.onAdd(handlingType.id)}
                  >
                    <VStack alignItems="start" gap="0" spacing="0">
                      <Text>{handlingType.label}</Text>
                      <Text color="gray.400" fontSize="sm">
                        {handlingType.description}
                      </Text>
                    </VStack>
                  </MenuItem>
                ))}
              </MenuList>
            </Portal>
          </Menu>
        </Sidebar.Row>
        {props.errors?.flatMap((error) => {
          const events = [...error.on, ...availableEvents].sort((a, b) => a.localeCompare(b));

          if (events.length === 0) {
            return [];
          }

          return (
            <ErrorHandlingComponent
              key={error.id}
              availablePaths={props.availablePaths}
              availableQueries={props.availableQueries}
              entityOptions={props.entityOptions}
              error={error}
              events={events}
              output={props.output}
              queryOptions={props.queryOptions}
              skillOptions={props.skillOptions}
              templates={props.templates}
              title={handlingTypes.find((type) => type.id === error.handler.type)?.label ?? ""}
              onRemove={() => props.onRemove(error)}
              onUpdate={props.onUpdate}
            />
          );
        })}
        {availableEvents.length > 0 && (
          <Alert status="info">
            <AlertIcon />
            The following errors will cause the task to fail: {availableEvents.join(", ")}
          </Alert>
        )}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

function ErrorHandlingComponent(props: {
  title: string;
  error: WorkflowError;
  events: WorkflowErrorEvent[];
  output: Record<string, NamedOutput>;
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  queryOptions: WorkflowTaskQuery[];
  availableQueries: Map<string, NodeReferenceQuery>;
  templates: HumanTaskTemplateDefinition[];
  skillOptions: WorkflowSkillId[];
  onUpdate: (error: WorkflowError) => void;
  onRemove: () => void;
}) {
  const handler = props.error.handler;

  return (
    <Flex alignItems="center" direction="row" gap="2">
      <Container border="1px" borderColor="gray.100" mb="2" rounded="md">
        <Sidebar.Row>
          <Flex alignItems="center" gap="2">
            <Sidebar.Label>On</Sidebar.Label>
            <Select
              multiple
              data-testid="error-handling-events"
              hideCheckAll={true}
              hideClearSelection={true}
              label="Events"
              options={props.events.map((event) => ({ label: event, value: event }))}
              size="xs"
              value={props.error.on}
              onChange={(on) => props.onUpdate({ ...props.error, on: on ?? [] })}
            />
          </Flex>
          <Sidebar.Row>{props.title}</Sidebar.Row>
        </Sidebar.Row>
        {handler.type === "fallback-to-manual" && (
          <Sidebar.Row>
            <Sidebar.Input
              value={handler.meta.name}
              onChange={(e) =>
                props.onUpdate({
                  ...props.error,
                  handler: {
                    ...handler,
                    meta: { ...handler.meta, name: e.target.value },
                  },
                })
              }
            />
          </Sidebar.Row>
        )}
        <ErrorHandlingSettings
          availablePaths={props.availablePaths}
          availableQueries={props.availableQueries}
          entityOptions={props.entityOptions}
          error={props.error}
          output={props.output}
          queryOptions={props.queryOptions}
          skillOptions={props.skillOptions}
          templates={props.templates}
          onUpdate={props.onUpdate}
        />
      </Container>
      <Sidebar.SquareButton aria-label="remove" onClick={() => props.onRemove()}>
        <BaselineMinusIcon />
      </Sidebar.SquareButton>
    </Flex>
  );
}

function ErrorHandlingSettings(props: {
  error: WorkflowError;
  output: Record<string, NamedOutput>;
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  availableQueries: Map<string, NodeReferenceQuery>;
  queryOptions: WorkflowTaskQuery[];
  templates: HumanTaskTemplateDefinition[];
  skillOptions: WorkflowSkillId[];
  onUpdate: (error: WorkflowError) => void;
}) {
  const handler = props.error.handler;
  const statuses = Object.values(props.output).map((output) => output.name);

  switch (handler.type) {
    case "fallback-to-default": {
      const output = handler.status === null ? undefined : props.output[handler.status];

      const handleUpdateBind = (fieldId: string, bind: WorkflowChildReferenceBinding | null) => {
        props.onUpdate({
          ...props.error,
          handler: {
            ...handler,
            output:
              bind === null
                ? {}
                : {
                    ...handler.output,
                    [fieldId]: bind,
                  },
          },
        });
      };

      return (
        <>
          <Sidebar.Row>
            <Sidebar.Label>Status</Sidebar.Label>
            <Menu isLazy>
              <MenuButton
                as={Button}
                isDisabled={Object.keys(props.output).length === 0}
                rightIcon={<ChevronDownIcon />}
                size="sm"
              >
                {handler.status ?? "Select status"}
              </MenuButton>
              <MenuList>
                {statuses.map((status) => (
                  <MenuItem
                    key={status}
                    onClick={() =>
                      props.onUpdate({
                        ...props.error,
                        handler: {
                          ...handler,
                          status: status,
                        },
                      })
                    }
                  >
                    {status}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Sidebar.Row>
          {output && (
            <InputBindComponentSection
              availablePaths={props.availablePaths}
              bind={handler.output}
              entityOptions={props.entityOptions}
              input={output.output}
              onUpdateBind={handleUpdateBind}
            />
          )}
        </>
      );
    }

    case "fallback-to-manual": {
      const handleUpdateQuery = (_: string, queryRefId: string, query: NodeReferenceQuery) => {
        props.onUpdate({
          ...props.error,
          handler: {
            ...handler,
            queries: new Map(handler.queries).set(queryRefId, query),
          },
        });
      };

      const handleRemoveQuery = (_: string, queryRefId: string) => {
        props.onUpdate({
          ...props.error,
          handler: {
            ...handler,
            queries: new Map(Array.from(handler.queries).filter(([id]) => id !== queryRefId)),
          },
        });
      };

      const handleAddQuery = () => {
        props.onUpdate({
          ...props.error,
          handler: {
            ...handler,
            queries: new Map(handler.queries).set(generateId("query"), emptyObject.query()),
          },
        });
      };

      const handleUpdateTemplate = (templateRef: HumanTaskTemplateReference) => {
        props.onUpdate({
          ...props.error,
          handler: {
            ...handler,
            template: templateRef,
          },
        });
      };

      const handleUpdateTaskMeta = <T extends keyof TaskMeta>(name: T, value: TaskMeta[T]) => {
        props.onUpdate({
          ...props.error,
          handler: {
            ...handler,
            meta: {
              ...handler.meta,
              [name]: value,
            },
          },
        });
      };

      const handleUpdateScheduling = <T extends keyof WorkflowChildScheduling>(
        name: T,
        value: WorkflowChildScheduling[T],
      ) => {
        props.onUpdate({
          ...props.error,
          handler: {
            ...handler,
            scheduling: {
              ...handler.scheduling,
              [name]: value,
            },
          },
        });
      };

      const handleUpdateTimeout = (timeout: number | null) => {
        props.onUpdate({
          ...props.error,
          handler: {
            ...handler,
            assignmentTimeoutSeconds: timeout,
          },
        });
      };

      const handleUpdateCluster = (cluster: WorkflowTaskClusterReference | undefined) => {
        props.onUpdate({
          ...props.error,
          handler: {
            ...handler,
            cluster: cluster,
          },
        });
      };

      const handleUpdateSkills = (skills: WorkflowSkillId[]) => {
        props.onUpdate({
          ...props.error,
          handler: {
            ...handler,
            skills,
          },
        });
      };

      return (
        <>
          <QueriesComponentSection
            availablePaths={props.availablePaths}
            entityOptions={props.entityOptions}
            id={props.error.id}
            queries={handler.queries}
            queryOptions={props.queryOptions}
            onAddQuery={handleAddQuery}
            onRemoveQuery={handleRemoveQuery}
            onUpdateQuery={handleUpdateQuery}
          />

          <HumanTaskTemplateSection
            availablePaths={props.availablePaths}
            entityOptions={props.entityOptions}
            templateRef={handler.template}
            templates={props.templates}
            onUpdate={handleUpdateTemplate}
          />

          <TimeoutComponentSection
            data-testid="assigment-timeout"
            timeout={handler.assignmentTimeoutSeconds}
            title="Assignment timeout"
            onChange={handleUpdateTimeout}
          />

          <ClusterComponentSection
            availablePaths={props.availablePaths}
            availableQueries={props.availableQueries}
            cluster={handler.cluster}
            entityOptions={props.entityOptions}
            onChange={handleUpdateCluster}
          />

          <SkillsComponentSection
            skillOptions={props.skillOptions}
            skills={handler.skills}
            onChange={handleUpdateSkills}
          />

          <MandatoryComponentSection
            mandatory={handler.meta.mandatory}
            onUpdateMeta={handleUpdateTaskMeta}
          />

          <PriorityComponentSection
            priority={handler.meta.priority}
            onUpdate={(v) => handleUpdateTaskMeta("priority", v)}
          />

          <SeverityComponentSection
            severity={handler.meta.severity}
            onUpdate={(v) => handleUpdateTaskMeta("severity", v)}
          />

          <SchedulingComponentSection
            availablePaths={props.availablePaths}
            entityOptions={props.entityOptions}
            finishBefore={handler.scheduling?.finishBefore}
            isErrorHandling={true}
            timeout={handler.scheduling?.timeout}
            onUpdateScheduling={handleUpdateScheduling}
          />
        </>
      );
    }

    case "new-task":
      return null;
  }
}

export function HumanTaskTemplateSection(props: {
  templates: HumanTaskTemplateDefinition[];
  templateRef: HumanTaskTemplateReference;
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  onUpdate: (templateRef: HumanTaskTemplateReference) => void;
}) {
  const handleUpdateLayout = (template: HumanTaskTemplateDefinition) => {
    props.onUpdate({
      ...props.templateRef,
      template,
    });
  };

  const handleUpdateBind = (name: string, bind: WorkflowChildReferenceBinding | null) => {
    props.onUpdate({
      ...props.templateRef,
      bind:
        bind === null
          ? {}
          : {
              ...props.templateRef.bind,
              [name]: bindCanHavePlaceholders(bind)
                ? { ...bind, placeholders: getPlaceholdersFromBind(bind) }
                : bind,
            },
    });
  };

  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Template</Sidebar.SectionTitle>
          <Menu isLazy>
            <MenuButton
              as={Button}
              data-testid="select-template-dropdown"
              rightIcon={<ChevronDownIcon />}
              size="sm"
            >
              {props.templateRef.template?.layout ?? "Select template"}
            </MenuButton>
            <MenuList>
              {props.templates.map((template) => (
                <MenuItem
                  key={template.layout}
                  data-testid={`select-template-${sanitizeTestId(template.layout)}`}
                  onClick={() => handleUpdateLayout(template)}
                >
                  {template.layout}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Sidebar.Row>
      </Sidebar.Section>
      <Divider />
      <InputBindComponentSection
        availablePaths={props.availablePaths}
        bind={props.templateRef.bind}
        entityOptions={props.entityOptions}
        input={props.templateRef.template?.input ?? {}}
        onUpdateBind={handleUpdateBind}
      />
      <Divider />
    </>
  );
}

export function DefineFieldTypesSection(props: {
  title: string;
  fieldTypes: Map<string, NamedField>;
  entityOptions: WorkflowEntity[];
  onUpdate: (id: string, field: NamedField) => void;
  onRemove: (id: string) => void;
  onAdd: (field: WorkflowDataField) => void;
}) {
  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>{props.title}</Sidebar.SectionTitle>
          <AddFieldSelection
            data-testid="add-field-button"
            entityOptions={props.entityOptions}
            onAdd={props.onAdd}
          />
        </Sidebar.Row>
        {[...props.fieldTypes.entries()].map(([id, fieldType], i) => (
          <DefineFieldTypeComponent
            key={i}
            data-testid={`field-type-${i}`}
            entityOptions={props.entityOptions}
            fieldType={fieldType}
            id={id}
            onClickRemove={() => props.onRemove(id)}
            onUpdate={(fieldType) => props.onUpdate(id, fieldType)}
          />
        ))}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function DefineOutputSection(props: {
  output: Record<string, NamedOutput>;
  entityOptions: WorkflowEntity[];
  onUpdate: (id: string, name: string) => void;
  onRemove: (id: string) => void;
  onAdd: () => void;
  onUpdateField: (outputId: string, fieldId: string, fieldType: NamedField) => void;
  onRemoveField: (outputId: string, fieldId: string) => void;
  onAddField: (outputId: string, field: WorkflowDataField) => void;
}) {
  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Output</Sidebar.SectionTitle>
          <Sidebar.SquareButton
            aria-label="add"
            data-testid="add-output-button"
            icon={<AddIcon />}
            onClick={props.onAdd}
          />
        </Sidebar.Row>
        {Object.entries(props.output).map(([outputId, outputRow]) => (
          <React.Fragment key={outputId}>
            <Sidebar.Row>
              <Sidebar.Input
                data-testid={`output-name-${outputId}`}
                placeholder="Output option"
                value={outputRow.name}
                onChange={(e) => props.onUpdate(outputId, e.target.value)}
              />
              <Flex alignItems="center" direction="row">
                <Sidebar.SquareButton aria-label="remove" onClick={() => props.onRemove(outputId)}>
                  <BaselineMinusIcon />
                </Sidebar.SquareButton>
                <AddFieldSelection
                  data-testid={`output-${sanitizeTestId(outputId)}-add-field-button`}
                  entityOptions={props.entityOptions}
                  onAdd={(field) => props.onAddField(outputId, field)}
                />
              </Flex>
            </Sidebar.Row>
            {Object.entries(outputRow.output).map(([fieldId, fieldType], i) => (
              <Container key={i} marginStart="2">
                <DefineFieldTypeComponent
                  data-testid={`output-${sanitizeTestId(outputId)}-field-type-${i}`}
                  entityOptions={props.entityOptions}
                  fieldType={fieldType}
                  id={fieldId}
                  onClickRemove={() => props.onRemoveField(outputId, fieldId)}
                  onUpdate={(fieldType) => props.onUpdateField(outputId, fieldId, fieldType)}
                />
              </Container>
            ))}
          </React.Fragment>
        ))}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function RootNodesComponentSection(props: {
  id: string;
  nodes: WorkflowNode[];
  onAddNode: () => void;
  onRemoveNode: (id: string) => void;
}) {
  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Root Nodes</Sidebar.SectionTitle>
          <Sidebar.SquareButton aria-label="add node" onClick={props.onAddNode}>
            <AddIcon />
          </Sidebar.SquareButton>
        </Sidebar.Row>
        {props.nodes.map((node) => (
          <Sidebar.Row key={node.logicalId}>
            <Sidebar.Label>{node.meta.name}</Sidebar.Label>
            <Sidebar.SquareButton
              aria-label="remove node"
              onClick={() => props.onRemoveNode(node.logicalId)}
            >
              <BaselineMinusIcon />
            </Sidebar.SquareButton>
          </Sidebar.Row>
        ))}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function ClusterComponentSection(props: {
  cluster: WorkflowTaskClusterReference | undefined;
  availablePaths: InputPath[];
  availableQueries: Map<string, NodeReferenceQuery>;
  entityOptions: WorkflowEntity[];
  onChange: (cluster: WorkflowTaskClusterReference | undefined) => void;
}) {
  return (
    <>
      <Sidebar.Section data-testid="cluster-section">
        <Sidebar.Row>
          <Sidebar.SectionTitle>Cluster</Sidebar.SectionTitle>
          <WorkflowTaskClusterSelect
            data-testid="cluster-dropdown"
            size="sm"
            value={props.cluster?.id ?? null}
            onChange={(x) => props.onChange(fmap(x, (id) => ({ id, bind: {} })))}
          />
        </Sidebar.Row>
        {props.cluster && (
          <>
            <ClusterInputComponentSection
              availablePaths={props.availablePaths}
              availableQueries={props.availableQueries}
              cluster={props.cluster}
              entityOptions={props.entityOptions}
              onChange={props.onChange}
            />
            <Divider />
          </>
        )}
      </Sidebar.Section>
      <Divider />
    </>
  );
}
export function ClusterInputComponentSection(props: {
  cluster: WorkflowTaskClusterReference;
  availablePaths: InputPath[];
  availableQueries: Map<string, NodeReferenceQuery>;
  entityOptions: WorkflowEntity[];
  onChange: (cluster: WorkflowTaskClusterReference) => void;
}) {
  const { queries } = useApi();
  const query = useQuery(queries.cluster(props.cluster.id));

  const handleChangeInputBinding = (name: string, bind: WorkflowChildReferenceBinding | null) => {
    const newBind = { ...props.cluster.bind };

    bind !== null ? (newBind[name] = bind) : delete newBind[name];

    props.onChange({ id: props.cluster.id, bind: newBind });
  };

  switch (query.status) {
    case "loading":
      return (
        <Sidebar.Section data-testid="cluster-input-section">
          <Sidebar.Row>
            <Skeleton h={4} w={16} />
            <Skeleton h={8} rounded="md" w={24} />
          </Sidebar.Row>
          <Sidebar.Row>
            <Skeleton h={4} w={16} />
            <Skeleton h={8} rounded="md" w={24} />
          </Sidebar.Row>
        </Sidebar.Section>
      );

    case "error":
      return <InternalErrorBox error={query.error} />;

    case "success":
      return (
        <Sidebar.Section data-testid="cluster-input-section">
          {query.data.input.map(([key, field], i) => (
            <InputBinding
              key={i}
              availablePaths={props.availablePaths}
              availableQueries={props.availableQueries}
              bind={props.cluster.bind[key] ?? null}
              data-testid={`cluster-input-${key}`}
              entityOptions={props.entityOptions}
              input={field}
              name={key}
              onUpdate={handleChangeInputBinding}
            />
          ))}
        </Sidebar.Section>
      );
  }
}

export function SkillsComponentSection(props: {
  skillOptions: WorkflowSkillId[];
  skills: WorkflowSkillId[];
  onChange: (skills: WorkflowSkillId[]) => void;
}) {
  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Assignment skills</Sidebar.SectionTitle>
          <Select
            multiple
            data-testid="skills-dropdown"
            hideCheckAll={true}
            hideClearSelection={true}
            label="Skills"
            options={props.skillOptions.map((skill) => ({ label: skill, value: skill }))}
            size="sm"
            value={props.skills}
            onChange={(selected) => props.onChange(selected ?? [])}
          />
        </Sidebar.Row>
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function AssignmentStrategyComponentSection(props: {
  strategies: HumanTaskAssignmentStrategyOption[];
  strategy: HumanTaskAssignmentStrategy | null;
  availablePaths: InputPath[];
  entityOptions: WorkflowEntity[];
  onChange: (strategy: HumanTaskAssignmentStrategy | null) => void;
}) {
  const { strategy } = props;

  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Assignment strategy</Sidebar.SectionTitle>
          <Select
            allowUnselect
            data-testid="strategy-dropdown"
            label="Strategy"
            options={props.strategies.map((strategy) => ({
              label: strategy.type,
              value: strategy,
            }))}
            size="sm"
            value={strategy?.option}
            onChange={(selected) =>
              props.onChange(fmap(selected, (option) => ({ option })) ?? null)
            }
          />
        </Sidebar.Row>
        {strategy?.option.input && (
          <InputBindComponentSection
            availablePaths={props.availablePaths}
            bind={strategy.bind ?? {}}
            entityOptions={props.entityOptions}
            input={strategy.option.input}
            onUpdateBind={(name, bind) =>
              bind === null
                ? {}
                : props.onChange({
                    ...strategy,
                    bind: {
                      ...strategy.bind,
                      [name]: bind,
                    },
                  })
            }
          />
        )}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function TimeoutComponentSection(props: {
  title: string;
  timeout: number | null;
  "data-testid": string;
  onChange: (timeout: number | null) => void;
}) {
  const handleRemoveTimeout = () => props.onChange(null);

  const handleAddTimeout = () => props.onChange(0);

  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>{props.title}</Sidebar.SectionTitle>

          {props.timeout !== null && (
            <IntervalInput
              data-testid={`${props["data-testid"]}-input`}
              value={props.timeout}
              onChange={props.onChange}
            />
          )}

          {props.timeout !== null ? (
            <Sidebar.SquareButton
              aria-label="remove timeout"
              data-testid={`${props["data-testid"]}-remove-button`}
              onClick={handleRemoveTimeout}
            >
              <BaselineMinusIcon />
            </Sidebar.SquareButton>
          ) : (
            <Sidebar.SquareButton
              aria-label="add timeout"
              data-testid={`${props["data-testid"]}-add-button`}
              onClick={handleAddTimeout}
            >
              <AddIcon />
            </Sidebar.SquareButton>
          )}
        </Sidebar.Row>
      </Sidebar.Section>
      <Divider />
    </>
  );
}

export function RetryComponentSection(props: {
  retry: WorkflowRetry | undefined;
  output: Record<string, NamedOutput>;
  hasTaskTrigger: boolean;
  onUpdate: (retry: WorkflowRetry | undefined) => void;
}) {
  const retryOptions = [
    {
      id: "trigger",
      label: "Trigger",
      description: "Retry this task when this task failed via trigger",
      isActive: props.hasTaskTrigger,
    },
    {
      id: "interval",
      label: "Interval",
      description: "Retry this task after a specified interval",
      isActive: true,
    },
  ] as const;

  const handleAddRetry = (type: "trigger" | "interval") => {
    const newRetry: WorkflowRetry = (() => {
      switch (type) {
        case "trigger":
          return {
            type: "trigger",
            maxRetries: 3,
            retryPaths: [],
          };

        case "interval":
          return {
            type: "interval",
            interval: 60 * 30,
            maxRetries: 3,
            retryPaths: [],
          };
      }
    })();

    props.onUpdate(newRetry);
  };

  const handleRemoveRetry = () => {
    props.onUpdate(undefined);
  };

  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>
            Retry{props.retry && ` (${props.retry.type})`}
          </Sidebar.SectionTitle>
          {props.retry ? (
            <Sidebar.SquareButton aria-label="remove" onClick={handleRemoveRetry}>
              <BaselineMinusIcon />
            </Sidebar.SquareButton>
          ) : (
            <Menu placement="bottom-end">
              <MenuButton
                as={Sidebar.SquareButton}
                data-testid="add-retry-button"
                icon={<AddIcon />}
              />
              <Portal>
                <MenuList maxW="sm">
                  {retryOptions
                    .filter((option) => option.isActive)
                    .map((option) => (
                      <MenuItem
                        key={option.id}
                        data-testid={`retry-${sanitizeTestId(option.id)}-button`}
                        onClick={() => handleAddRetry(option.id)}
                      >
                        <VStack alignItems="start" gap="0" spacing="0">
                          <Text>{option.label}</Text>
                          <Text color="gray.400" fontSize="sm">
                            {option.description}
                          </Text>
                        </VStack>
                      </MenuItem>
                    ))}
                </MenuList>
              </Portal>
            </Menu>
          )}
        </Sidebar.Row>
        {props.retry && (
          <RetryComponent output={props.output} retry={props.retry} onUpdate={props.onUpdate} />
        )}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

function RetryComponent(props: {
  retry: WorkflowRetry;
  output: Record<string, NamedOutput>;
  onUpdate: (retry: WorkflowRetry) => void;
}) {
  const handleChangeMaxRetries = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxRetries = (() => {
      try {
        const value = parseInt(e.target.value);
        return isNaN(value) ? 0 : value;
      } catch (e) {
        return 0;
      }
    })();

    props.onUpdate({
      ...props.retry,
      maxRetries,
    });
  };

  const outputOptions = Object.values(props.output).map((output) => ({
    label: output.name,
    value: output.name,
  }));

  const handleChangeOutputs = (outputs: string[] | undefined) => {
    props.onUpdate({
      ...props.retry,
      retryPaths: outputs ?? [],
    });
  };

  const handleChangeInterval = (interval: number) => {
    if (props.retry.type !== "interval") {
      return;
    }
    props.onUpdate({
      ...props.retry,
      interval,
    });
  };

  return (
    <>
      <Sidebar.Row>
        <Sidebar.Label>Max retries</Sidebar.Label>
        <Sidebar.Input value={props.retry.maxRetries} w="10" onChange={handleChangeMaxRetries} />
      </Sidebar.Row>

      {props.retry.type === "interval" && (
        <Sidebar.Row>
          <Sidebar.Label>Interval</Sidebar.Label>
          <IntervalInput value={props.retry.interval} onChange={handleChangeInterval} />
        </Sidebar.Row>
      )}

      <Sidebar.Row>
        <Sidebar.Label>For outputs</Sidebar.Label>
        <Select
          hideCheckAll
          hideClearSelection
          multiple
          buttonProps={{ size: "xs" }}
          label="Outputs"
          options={outputOptions}
          value={props.retry.retryPaths}
          onChange={handleChangeOutputs}
        />
      </Sidebar.Row>
    </>
  );
}

export function ResetToNodeSection(props: {
  sourceNode: WorkflowChildResetToTask;
  workflowChildren: Record<string, WorkflowNode>;
  availablePaths: InputPath[];
  entityOptions: WorkflowEntity[];
  onSelect: (node: WorkflowNode | null) => void;
  onUpdateBind: (name: string, bind: WorkflowChildReferenceBinding | null) => void;
}) {
  const selectedNode = props.sourceNode.to?.node ?? null;
  const nodeInput = fmap(selectedNode, getNodeInput);
  const nodeOptions = getAvailableNodesForReset({
    sourceNode: props.sourceNode,
    workflowChildren: props.workflowChildren,
  });

  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Node</Sidebar.SectionTitle>

          <FancySelection<WorkflowNode>
            options={[
              {
                title: "Select node",
                items: nodeOptions.map((node) => ({
                  value: node,
                  title: node.meta.name,
                  description: "description" in node.meta ? node.meta.description : "",
                  icon: <NodeIcon fontSize="2xl" type={node.type} />,
                })),
              },
            ]}
            selected={selectedNode}
            title="Select node"
            trigger={
              <Button
                data-testid="select-target-node-button"
                rightIcon={<ChevronRightIcon />}
                size="sm"
              >
                {selectedNode?.meta.name ?? "Select node"}
              </Button>
            }
            onSelect={props.onSelect}
          />
        </Sidebar.Row>
      </Sidebar.Section>
      <Divider />
      {selectedNode !== null && nodeInput !== undefined && (
        <InputBindComponentSection
          availablePaths={props.availablePaths}
          bind={props.sourceNode.to?.bind ?? {}}
          entityOptions={props.entityOptions}
          input={transformInputFieldsToOptional(nodeInput.input)}
          sectionTitle="Input overrides (optional)"
          onUpdateBind={props.onUpdateBind}
        />
      )}
      <Divider />
    </>
  );
}

export function OwnerSection(props: {
  owner: AgencyMemberId;
  onChange: (owner: AgencyMemberId) => void;
}) {
  return (
    <Sidebar.Section>
      <Sidebar.Row>
        <Sidebar.SectionTitle>Owner</Sidebar.SectionTitle>
        <EntitySelect
          allowUnselect={false}
          errors={[]}
          input={{
            type: "entity",
            entity: "Agency Member",
          }}
          label="Owner"
          multiple={false}
          size="sm"
          value={props.owner}
          onChange={(selected) => selected && props.onChange(selected)}
        />
      </Sidebar.Row>
    </Sidebar.Section>
  );
}

export function TagsComponentSection(props: {
  tags: WorkflowTagReference[];
  availablePaths: InputPath[];
  availableQueries: Map<string, NodeReferenceQuery>;
  entityOptions: WorkflowEntity[];
  onChange: (tags: WorkflowTagReference[]) => void;
}) {
  const handleAddTag = (tag: WorkflowTag) => {
    props.onChange([...props.tags, { ...tag, bind: {} }]);
  };

  const handleChangeTag = (tag: WorkflowTagReference) => {
    const tags = props.tags.map((t) => (t.id === tag.id ? tag : t));
    props.onChange(tags);
  };

  const handleRemove = (tagId: WorkflowTagId) => {
    const tags = props.tags.filter((tag) => tag.id !== tagId);
    props.onChange(tags);
  };

  return (
    <>
      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Tags</Sidebar.SectionTitle>
          <AddTagMenu
            current={props.tags.map((tag) => tag.id)}
            onAdd={handleAddTag}
            onSelect={handleAddTag}
          />
        </Sidebar.Row>
        {props.tags.map((tag) => (
          <TagComponent
            key={tag.id}
            availablePaths={props.availablePaths}
            availableQueries={props.availableQueries}
            entityOptions={props.entityOptions}
            tag={tag}
            onChange={handleChangeTag}
            onRemove={() => handleRemove(tag.id)}
          />
        ))}
      </Sidebar.Section>
      <Divider />
    </>
  );
}

function AddTagMenu(props: {
  current: WorkflowTagId[];
  onSelect: (tag: WorkflowTag) => void;
  onAdd: (tag: WorkflowTag) => void;
}) {
  const { queries } = useApi();
  const query = useQuery(queries.tags());

  const tags = (query.isSuccess ? query.data.tags : []).filter(
    (tag) => !props.current.includes(tag.id),
  );

  return (
    <Select
      label="Select tag"
      multiple={false}
      options={tags.map((tag) => ({ value: tag, label: tag.name, description: tag.id }))}
      renderAfter={() => <CreateTagModalMenuItem onAdd={props.onAdd} />}
      value={null}
      width="fit-content"
      onChange={(selected) => selected && props.onSelect(selected)}
    >
      <Sidebar.SquareButton aria-label="add" data-testid="add-tag-button">
        <AddIcon />
      </Sidebar.SquareButton>
    </Select>
  );
}

export function CreateTagModalMenuItem(props: { onAdd: (tag: WorkflowTag) => void }) {
  const disclosure = useDisclosure();
  return (
    <>
      <Divider />
      <Select.MenuItem data-testid="add-new-tag" onClick={disclosure.onOpen}>
        <Text color="blue.500">Add new</Text>
      </Select.MenuItem>
      <CreateTagModal disclosure={disclosure} onSuccess={props.onAdd} />
    </>
  );
}

function TagComponent(props: {
  tag: WorkflowTagReference;
  availablePaths: InputPath[];
  availableQueries: Map<string, NodeReferenceQuery>;
  entityOptions: WorkflowEntity[];
  onChange: (tag: WorkflowTagReference) => void;
  onRemove: () => void;
}) {
  return (
    <>
      <Sidebar.Row>
        <Sidebar.Label>{props.tag.name}</Sidebar.Label>
        <Sidebar.SquareButton aria-label="remove" onClick={props.onRemove}>
          <BaselineMinusIcon />
        </Sidebar.SquareButton>
      </Sidebar.Row>
      <TagComponentInput
        availablePaths={props.availablePaths}
        availableQueries={props.availableQueries}
        entityOptions={props.entityOptions}
        tag={props.tag}
        onChange={props.onChange}
      />
    </>
  );
}
function TagComponentInput(props: {
  tag: WorkflowTagReference;
  availablePaths: InputPath[];
  availableQueries: Map<string, NodeReferenceQuery>;
  entityOptions: WorkflowEntity[];
  onChange: (tag: WorkflowTagReference) => void;
}) {
  const handleChangeInputBinding = (name: string, bind: WorkflowChildReferenceBinding | null) => {
    const newBind = { ...props.tag.bind };

    bind !== null ? (newBind[name] = bind) : delete newBind[name];

    props.onChange({ ...props.tag, bind: newBind });
  };

  return (
    <Box data-testid="tag-input-section" marginStart={4}>
      {props.tag.input.map(([key, field], i) => (
        <InputBinding
          key={i}
          availablePaths={props.availablePaths}
          availableQueries={props.availableQueries}
          bind={props.tag.bind[key] ?? null}
          data-testid={`tag-input-${key}`}
          entityOptions={props.entityOptions}
          input={field}
          name={key}
          onUpdate={handleChangeInputBinding}
        />
      ))}
    </Box>
  );
}
