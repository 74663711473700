import * as Ajv from "ajv";
import { createAjvDraft07Compat } from "./ajv-compat";
import { Reviver, revivers } from "./schema-revivers";
import { UnsafeAny } from "../utils/types";

const validators = new Map<string, Ajv.ValidateFunction>();

const ajv = createAjvDraft07Compat({
  removeAdditional: true,
});

/**
 * See this for an explanation:
 * <https://github.com/YousefED/typescript-json-schema/issues/98#issuecomment-349733289>
 */
const validateFn = (
  keywordValue: string,
  data: UnsafeAny,
  _parentSchema?: object,
  dataPath?: string,
  parentData?: object | UnsafeAny[],
  parentDataProperty?: string | number,
  _rootData?: object | UnsafeAny[],
): boolean => {
  const setErrorMessage = (msg: string): void => {
    (<UnsafeAny>validateFn).errors = [
      {
        keyword: "xreviver",
        dataPath: "" + dataPath,
        schemaPath: "", // This field appears to be ignored
        params: {
          keyword: "xreviver",
        },
        message: msg,
        data: data,
      },
    ];
  };

  if (typeof data === "string") {
    const reviverFn: Reviver | undefined = <UnsafeAny>revivers[keywordValue];
    if (reviverFn === undefined) {
      setErrorMessage(`Unknown xreviver function: "${keywordValue}"`);
      return false;
    }

    let parsed: UnsafeAny;
    try {
      parsed = reviverFn(data);
    } catch (e: UnsafeAny) {
      // Take only the first line, because the rest may contain junk (a stack trace)
      const parseError = e.message.split("\n")[0];

      setErrorMessage(`${keywordValue}: ${parseError}`);
      return false;
    }
    if (parentData !== undefined && parentDataProperty !== undefined) {
      (<UnsafeAny>parentData)[parentDataProperty] = parsed;
    }
  }
  return true;
};

ajv.addKeyword("xreviver", {
  modifying: true,
  validate: validateFn,
});

export type Path = string;

async function getMessages() {
  const { messages, pathsToMessage } = await import("./messages.json");
  return { messages, pathsToMessage };
}

async function getValidator(method: string, path: Path) {
  const validatorKey = `${method} ${path}`;

  // Attempt to get from cache
  let validator = validators.get(validatorKey);
  if (validator) return validator;

  const { messages, pathsToMessage } = await getMessages();

  const messageKey = (pathsToMessage as UnsafeAny)[validatorKey];
  const message = (messages as UnsafeAny)[messageKey];

  if (message) {
    validator = ajv.compile(message);
    validators.set(validatorKey, validator); // Cache the compiled validator
  }

  return validator;
}

export async function getPathValidator(
  method: string,
  path: Path,
): Promise<Ajv.ValidateFunction | undefined> {
  const validator = await getValidator(method, path);

  return validator;
}

class InvalidResponseError extends Error {
  readonly detail: unknown;
  readonly responseJSON: unknown;
  readonly errorJSON: unknown;

  constructor(params: {
    message: string;
    detail: string;
    responseJSON: unknown;
    errorJSON: unknown;
  }) {
    super(params.message);
    this.detail = params.detail;
    this.responseJSON = params.responseJSON;
    this.errorJSON = params.errorJSON;
  }

  toJSON() {
    return {
      detail: this.detail,
      responseJSON: this.responseJSON,
      errorJSON: this.errorJSON,
    };
  }
}

export async function withValidator<T>(params: {
  data: T;
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  url: string;
}): Promise<T> {
  const validate = await getPathValidator(params.method, params.url);

  if (validate !== undefined && validate(params.data) !== true) {
    throw new InvalidResponseError({
      message: `JSON schema validation error ${JSON.stringify(validate.errors)}`,
      detail: "",
      responseJSON: params.data,
      errorJSON: validate.errors,
    });
  }

  return params.data;
}
