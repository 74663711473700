import Select from "../../../../../../shared/components/Select";
import { ConcurrencyDomain } from "../../../../../../shared/schemas/workflows";
import { objectEntries } from "../../../../../../shared/utils/general.utils";

interface Props {
  value: ConcurrencyDomain;
  onChange: (value: ConcurrencyDomain) => void;
}

const items: Record<
  ConcurrencyDomain,
  {
    label: string;
    description: string;
  }
> = {
  GLOBAL: {
    label: "Global",
    description: "Matches all workflows. (Will run the least number of times)",
  },
  CORRELATION: {
    label: "Correlation",
    description: "Matches the same workflow across all versions. (Will run more times)",
  },
  DEFINITION: {
    label: "Definition",
    description: "Matches the same workflow and version. (Will run the most number of times)",
  },
};

export default function ConcurrencyDomainSelect(props: Props) {
  const handleChange = (value: ConcurrencyDomain | undefined) => {
    if (value !== undefined) {
      props.onChange(value);
    }
  };

  return (
    <Select
      label="Domain"
      multiple={false}
      options={objectEntries(items).map(([rule, value]) => ({
        label: value.label,
        value: rule,
        description: value.description,
      }))}
      size="sm"
      value={props.value}
      onChange={handleChange}
    />
  );
}
