import { useContext } from "react";
import { useStore } from "zustand";
import { WorkflowContext } from "../components/WorkflowProvider";
import assert from "../utils/assert";
import { WorkflowState } from "../utils/create-workflow-store";

export default function useWorkflowStore<T>(selector: (state: WorkflowState) => T): T {
  const store = useContext(WorkflowContext);

  assert(store, "Missing WorkflowContext.Provider in the tree");

  return useStore(store, selector);
}
