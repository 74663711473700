export const basicFieldTypes = [
  { name: "text", label: "Text" },
  { name: "textarea", label: "Textarea" },
  { name: "number", label: "Number" },
  { name: "boolean", label: "Boolean" },
  { name: "date", label: "Date" },
  { name: "time", label: "Time" },
  { name: "datetime", label: "DateTime" },
  { name: "file", label: "File" },
] as const;
