import { useQuery } from "@tanstack/react-query";
import {
  WorkflowDefinitionCorrelationKey,
  WorkflowDefinitionId,
} from "../../../shared/schema/schema";
import { WorkflowVersion } from "../../../shared/schemas/workflows";
import assert from "../../../shared/utils/assert";
import { fmapEq } from "../../../shared/utils/graphql.utils";
import { useApi } from "../../../shared/utils/use-api";

export type WorkflowDashboardQuery = ReturnType<typeof useWorkflowDashboardQuery>;

export default function useWorkflowDashboardQuery(params: {
  id?: WorkflowDefinitionId;
  correlationKey: WorkflowDefinitionCorrelationKey;
}) {
  const { queries } = useApi();

  return useQuery({
    ...queries.workflowDashboardDefinitions({
      corrletionKey: { eq: params.correlationKey },
      workflowDefinitionId: fmapEq(params.id) ?? null,
    }),
    keepPreviousData: true,
    select: ({ workflowDefinitions }) => {
      const definition = workflowDefinitions.nodes.at(0);

      assert(definition !== undefined, "Definition not found");

      const versions = definition.versions.nodes.map(
        (version): WorkflowVersion => ({
          id: version.id,
          createdAt: version.createdAt,
          input: new Map(version.input.value),
          name: version.name,
          number: version.version,
          owner: {
            id: version.owner.id,
            name: version.owner.full_name,
          },
          published: version.published,
        }),
      );

      const latestVersion = [...versions].sort((a, b) => b.number - a.number).at(0);
      const selectedVersion = versions.find((v) => v.id === params.id);
      const activeVersion = selectedVersion ?? latestVersion;

      assert(activeVersion !== undefined, "Definition has no versions");

      return { activeVersion, versions };
    },
  });
}
