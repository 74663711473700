import { produce } from "immer";
import { temporal } from "zundo";
import { createStore } from "zustand";
import {
  connectNodes,
  copyNode,
  findNode,
  findNodeSiblings,
  generatePlaceholdersForNode,
  getNodeIdsToRemoveByConnectedNodeOutput,
  getNodeOutput,
} from "../../pages/Editor/utils/workflow-node.utils";
import {
  NodeReferenceQuery,
  WorkflowDataField,
  WorkflowDefinition,
  WorkflowError,
  WorkflowErrorHandlerType,
  WorkflowInput,
  WorkflowNode,
  WorkflowTaskTrigger,
  WorkflowTrigger,
} from "../schemas/workflows";
import { emptyObject } from "./empty-object";
import { generateId } from "./string.utils";

export interface WorkflowState extends WorkflowDefinition {
  updateWorkflowMeta: (meta: WorkflowDefinition["meta"]) => void;
  setName: (name: string) => void;
  setDescription: (description: string) => void;
  setConcurrencyRule: (rule: WorkflowDefinition["concurrencyRule"]) => void;
  addInput: (field: WorkflowDataField) => void;
  removeInput: (id: string) => void;
  updateInput: (id: string, input: WorkflowInput) => void;
  addTrigger: (type: WorkflowTrigger["type"]) => void;
  removeTrigger: (id: string) => void;
  updateTrigger: (trigger: WorkflowTrigger) => void;
  addNode: (id: string, node: WorkflowNode) => void;
  updateNode: (id: string, node: WorkflowNode) => void;
  setNodeName: (id: string, name: string) => void;
  removeNode: (id: string) => void;
  connectNodes: (sourceId: string, targetId: string) => void;
  copyNode: (sourceId: string, targetId: string) => void;
  addQuery: (nodeRefId: string) => void;
  updateQuery: (nodeRefId: string, queryRefId: string, query: NodeReferenceQuery) => void;
  removeQuery: (nodeRefId: string, queryRefId: string) => void;
  addTaskTrigger: (nodeRefId: string) => void;
  updateTaskTrigger: (nodeRefId: string, trigger: WorkflowTaskTrigger) => void;
  removeTaskTrigger: (nodeRefId: string) => void;
  addErrorHandling: (nodeRefId: string, type: WorkflowErrorHandlerType) => void;
  updateErrorHandling: (nodeRefId: string, error: WorkflowError) => void;
  removeErrorHandling: (nodeRefId: string, error: WorkflowError) => void;
}

export type WorkflowStore = ReturnType<typeof createWorkflowStore>;

interface Params {
  workflow: WorkflowDefinition;
  onError: (message: string) => void;
}

export const createWorkflowStore = ({ workflow, onError }: Params) => {
  return createStore<WorkflowState>()(
    temporal<WorkflowState>((set) => ({
      ...workflow,
      updateWorkflowMeta: (meta) => set(() => ({ meta })),
      setName: (name) => set(() => ({ name })),
      setDescription: (description) => set(() => ({ description })),
      setConcurrencyRule: (concurrencyRule) => set(() => ({ concurrencyRule })),
      addInput: (field) => {
        set(
          produce((state: WorkflowState) => {
            state.input.set(generateId("workflowInput"), emptyObject.input(field));
          }),
        );
      },
      removeInput: (id) => {
        set(
          produce((state: WorkflowState) => {
            state.input.delete(id);
          }),
        );
      },
      updateInput: (id, input) => {
        set(
          produce((state: WorkflowState) => {
            state.input.set(id, input);
          }),
        );
      },
      addTrigger: (type) => {
        set(
          produce((state: WorkflowState) => {
            const trigger =
              type === "event" ? emptyObject.triggers.event() : emptyObject.triggers.job();
            state.triggers.push(trigger);
          }),
        );
      },
      removeTrigger: (id) => {
        set(
          produce((state: WorkflowState) => {
            state.triggers = state.triggers.filter((trigger) => trigger.id !== id);
          }),
        );
      },
      updateTrigger: (trigger) => {
        set(
          produce((state: WorkflowState) => {
            state.triggers = state.triggers.map((t) => (t.id === trigger.id ? trigger : t));
          }),
        );
      },
      addNode: (id, node) => {
        set(
          produce((state: WorkflowState) => {
            const siblings = (() => {
              if (node.parent === null) {
                return state.children;
              }

              const parent = findNode(node.parent, state.children);

              if (parent === null || parent.type !== "group") {
                return null;
              }

              return parent.children;
            })();

            if (siblings !== null) {
              siblings[id] = node;
            }
          }),
        );
      },
      updateNode: (id, node) => {
        set(
          produce((state: WorkflowState) => {
            const siblings = findNodeSiblings(id, state.children);

            if (siblings !== null) {
              siblings[id] = node;
            }
          }),
        );
      },
      setNodeName: (id, name) => {
        set(
          produce((state: WorkflowState) => {
            const siblings = findNodeSiblings(id, state.children);

            if (siblings === null) {
              return;
            }

            const node = siblings[id];

            if (node !== undefined) {
              node.meta.name = name;
            }
          }),
        );
      },
      removeNode: (id) => {
        set(
          produce((state: WorkflowState) => {
            const siblings = findNodeSiblings(id, state.children);

            if (siblings !== null) {
              delete siblings[id];
            }
          }),
        );
      },
      connectNodes: (sourceId, targetId) => {
        set(
          produce((state: WorkflowState) => {
            const source = findNode(sourceId, state.children);
            const target = findNode(targetId, state.children);

            if (source === null || target === null) {
              return;
            }

            const newChildren = connectNodes({
              nodes: state.children,
              source: source,
              target: target,
              workflowInput: state.input,
            });

            if (newChildren === undefined) {
              return;
            }

            state.children = newChildren;
          }),
        );
      },
      copyNode: (sourceId, targetId) => {
        set(
          produce((state: WorkflowState) => {
            const sourceNode = findNode(sourceId, state.children);

            if (sourceNode === null) {
              return;
            }

            const targetNode = findNode(targetId, state.children);

            if (targetNode === null || targetNode.type === "group") {
              return;
            }

            try {
              const newWorkflowChildren = copyNode({
                sourceNode,
                targetNode,
                workflowDefinition: state,
              });

              state.children = newWorkflowChildren;
            } catch (e) {
              onError(`${e}`);
              return;
            }
          }),
        );
      },
      addQuery: (nodeRefId) => {
        set(
          produce((state: WorkflowState) => {
            const siblings = findNodeSiblings(nodeRefId, state.children);

            if (siblings === null) {
              return;
            }

            const node = siblings[nodeRefId];

            if (node === undefined) {
              return;
            }

            if ("queries" in node) {
              const query = emptyObject.query();

              node.queries.set(generateId("query"), query);
            }
          }),
        );
      },
      updateQuery: (nodeRefId, queryRefId, query) => {
        set(
          produce((state: WorkflowState) => {
            const siblings = findNodeSiblings(nodeRefId, state.children);

            if (siblings === null) {
              return;
            }

            const node = siblings[nodeRefId];

            if (node === undefined) {
              return;
            }

            if ("queries" in node) {
              node.queries.set(queryRefId, query);
            }
          }),
        );
      },
      removeQuery: (nodeRefId, queryRefId) => {
        set(
          produce((state: WorkflowState) => {
            const siblings = findNodeSiblings(nodeRefId, state.children);

            if (siblings === null) {
              return;
            }

            const node = siblings[nodeRefId];

            if (node === undefined) {
              return;
            }

            if ("queries" in node) {
              node.queries.delete(queryRefId);
            }
          }),
        );
      },
      addTaskTrigger: (nodeRefId) => {
        set(
          produce((state: WorkflowState) => {
            const siblings = findNodeSiblings(nodeRefId, state.children);

            if (siblings === null) {
              return;
            }

            const node = siblings[nodeRefId];

            if (node === undefined) {
              return;
            }

            node.trigger = emptyObject.taskTrigger();
          }),
        );
      },
      updateTaskTrigger: (nodeRefId, trigger) => {
        set(
          produce((state: WorkflowState) => {
            const siblings = findNodeSiblings(nodeRefId, state.children);

            if (siblings === null) {
              return;
            }

            const node = siblings[nodeRefId];

            if (node === undefined) {
              return;
            }

            node.trigger = trigger;
          }),
        );
      },
      removeTaskTrigger: (nodeRefId) => {
        set(
          produce((state: WorkflowState) => {
            const siblings = findNodeSiblings(nodeRefId, state.children);

            if (siblings === null) {
              return;
            }

            const node = siblings[nodeRefId];

            if (node === undefined) {
              return;
            }

            node.trigger = undefined;
          }),
        );
      },
      addErrorHandling: (nodeRefId, type) => {
        set(
          produce((state: WorkflowState) => {
            const siblings = findNodeSiblings(nodeRefId, state.children);

            if (siblings === null) {
              return;
            }

            const node = siblings[nodeRefId];

            if (node === undefined) {
              return;
            }

            if (node.errors === undefined) {
              node.errors = [];
            }

            node.errors.push(emptyObject.errorHandling({ type, node }));
          }),
        );
      },
      updateErrorHandling: (nodeRefId, error) => {
        set(
          produce((state: WorkflowState) => {
            const siblings = findNodeSiblings(nodeRefId, state.children);

            if (siblings === null) {
              return;
            }

            const node = siblings[nodeRefId];

            if (node === undefined) {
              return;
            }

            if (node.errors === undefined) {
              return;
            }

            const errorHandlingIndex = node.errors.findIndex(
              (errorHandling) => errorHandling.id === error.id,
            );

            if (errorHandlingIndex === -1) {
              return;
            }

            node.errors[errorHandlingIndex] = error;

            generatePlaceholdersForNode({
              node,
              children: state.children,
            }).forEach((n) => {
              siblings[n.logicalId] = n;
            });

            getNodeIdsToRemoveByConnectedNodeOutput({
              workflow: state,
              parent: node.parent,
              nodeRefId: nodeRefId,
              nodeOutput: getNodeOutput(node),
            }).forEach((nodeId) => {
              delete siblings[nodeId];
            });
          }),
        );
      },
      removeErrorHandling: (nodeRefId, error) => {
        set(
          produce((state: WorkflowState) => {
            const siblings = findNodeSiblings(nodeRefId, state.children);

            if (siblings === null) {
              return;
            }

            const node = siblings[nodeRefId];

            if (node === undefined) {
              return;
            }

            if (node.errors === undefined) {
              return;
            }

            const errorHandlingIndex = node.errors.findIndex(
              (errorHandling) => errorHandling.id === error.id,
            );

            if (errorHandlingIndex === -1) {
              return;
            }

            node.errors.splice(errorHandlingIndex, 1);

            getNodeIdsToRemoveByConnectedNodeOutput({
              workflow: state,
              parent: node.parent,
              nodeRefId: nodeRefId,
              nodeOutput: getNodeOutput(node),
            }).forEach((nodeId) => {
              delete siblings[nodeId];
            });
          }),
        );
      },
    })),
  );
};
