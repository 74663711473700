import { nanoid } from "nanoid";
import { objectKeys } from "react-querybuilder";

const _idsMap = {
  node: 1,
  correlationkey: 1,
  nodeOutput: 1,
  nodeOutputField: 1,
  query: 1,
  jobTrigger: 1,
  eventTrigger: 1,
  errorHandling: 1,
  workflowInput: 1,
  taskCluster: 1,
};

export function generateId(type: keyof typeof _idsMap) {
  if (import.meta.env.MODE === "test") {
    return `id-${type}-${_idsMap[type]++}`;
  }

  return nanoid();
}

export function sanitizeTestId(str: string) {
  return str
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9-]/g, "-")
    .replace(/-+/g, "-");
}

export function resetTestIds() {
  for (const key of objectKeys(_idsMap)) {
    _idsMap[key] = 1;
  }
}
