import { createIcon } from "@chakra-ui/react";

const DatabaseIcon = createIcon({
  displayName: "DatabaseIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M12 11q3.75 0 6.375-1.175T21 7q0-1.65-2.625-2.825T12 3Q8.25 3 5.625 4.175T3 7q0 1.65 2.625 2.825T12 11Zm0 2.5q1.025 0 2.563-.213t2.962-.687q1.425-.475 2.45-1.237T21 9.5V12q0 1.1-1.025 1.863t-2.45 1.237q-1.425.475-2.962.688T12 16q-1.025 0-2.563-.213T6.476 15.1q-1.425-.475-2.45-1.237T3 12V9.5q0 1.1 1.025 1.863t2.45 1.237q1.425.475 2.963.688T12 13.5Zm0 5q1.025 0 2.563-.213t2.962-.687q1.425-.475 2.45-1.238T21 14.5V17q0 1.1-1.025 1.863t-2.45 1.237q-1.425.475-2.962.688T12 21q-1.025 0-2.563-.213T6.476 20.1q-1.425-.475-2.45-1.238T3 17v-2.5q0 1.1 1.025 1.863t2.45 1.237q1.425.475 2.963.688T12 18.5Z"
      fill="currentColor"
    />
  ),
});

export default DatabaseIcon;
