import React from "react";

export default function useNodeClipboard({
  selectedNodeId,
  onCopyNode,
}: {
  selectedNodeId: string | null;
  onCopyNode: (sourceNodeId: string, targetNodeId: string) => void;
}) {
  const [clipboard, setClipboard] = React.useState<string | null>(null);

  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.code === "KeyC") {
        const selection = window.getSelection();

        if (
          (selection?.type !== "None" && selection?.type !== "Caret") ||
          selectedNodeId === null ||
          !isActiveElementNode()
        ) {
          return;
        }

        e.preventDefault();

        setClipboard(selectedNodeId);
      }

      if ((e.metaKey || e.ctrlKey) && e.code === "KeyV") {
        if (selectedNodeId === null || clipboard === null || !isActiveElementNode()) {
          return;
        }

        e.preventDefault();

        onCopyNode(clipboard, selectedNodeId);
      }
    };

    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  });
}

function isActiveElementNode() {
  return document.activeElement?.classList.contains("react-flow__node") === true;
}
