import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { operators } from "../../../../../../shared/constants/operators.const";
import BaselineMinusIcon from "../../../../../../shared/icons/BaselineMinusIcon";
import {
  WorkflowEntity,
  WorkflowEventTriggerOption,
  WorkflowTaskTriggerCondition,
  WorkflowTaskTriggerConditions,
} from "../../../../../../shared/schemas/workflows";
import { getEventTriggerInputPath } from "../../../../utils/workflow-triggers.utils";
import { formatOperatorToHuman, InputPath } from "../../../../utils/workflow-node.utils";
import Sidebar from "../../../Sidebar";
import BindButton from "../../Node/components/BindButton";

interface Props {
  conditions: WorkflowTaskTriggerConditions;
  availablePaths: InputPath[];
  entityOptions: WorkflowEntity[];
  eventTriggerOption: WorkflowEventTriggerOption | null;
  onUpdateConditions: (conditions: WorkflowTaskTriggerConditions) => void;
}

function TaskTriggerConditions(props: Props) {
  const handleAddCondition = () => {
    const emptyCondition: WorkflowTaskTriggerCondition = {
      property: null,
      operator: "=",
      bind: null,
    };

    props.onUpdateConditions({
      ...props.conditions,
      conditions: [...props.conditions.conditions, emptyCondition],
    });
  };

  const handleRemoveCondition = (index: number) => {
    props.onUpdateConditions({
      ...props.conditions,
      conditions: props.conditions.conditions.filter(
        (_, conditionIndex) => conditionIndex !== index,
      ),
    });
  };

  const handleUpdateCondition = (index: number, condition: WorkflowTaskTriggerCondition) => {
    props.onUpdateConditions({
      ...props.conditions,
      conditions: props.conditions.conditions.map((c, conditionIndex) =>
        conditionIndex === index ? condition : c,
      ),
    });
  };

  const handleAddGroup = () => {
    const emptyGroup: WorkflowTaskTriggerConditions = {
      operator: "and",
      conditions: [],
    };

    props.onUpdateConditions({
      ...props.conditions,
      conditions: [...props.conditions.conditions, emptyGroup],
    });
  };

  const handleUpdateConditions = (index: number, conditions: WorkflowTaskTriggerConditions) => {
    props.onUpdateConditions({
      ...props.conditions,
      conditions: props.conditions.conditions.map((c, conditionIndex) =>
        conditionIndex === index ? conditions : c,
      ),
    });
  };

  return (
    <Flex
      border="1px"
      borderColor="gray.200"
      borderRadius="md"
      direction="column"
      marginStart="2"
      marginTop="2"
      padding="2"
    >
      <Flex gap="2" justifyContent="space-between">
        <GroupTypeRadio
          groupType={props.conditions.operator}
          onChange={(groupType) =>
            props.onUpdateConditions({
              ...props.conditions,
              operator: groupType,
            })
          }
        />
        <Flex gap="1">
          <Button size="xs" onClick={handleAddCondition}>
            + Rule
          </Button>
          <Button size="xs" onClick={handleAddGroup}>
            + Group
          </Button>
        </Flex>
      </Flex>
      {props.conditions.conditions.map((condition, index) => (
        <Flex key={index} justifyContent="space-between" marginTop="1">
          {"conditions" in condition ? (
            <TaskTriggerConditions
              availablePaths={props.availablePaths}
              conditions={condition}
              entityOptions={props.entityOptions}
              eventTriggerOption={props.eventTriggerOption}
              onUpdateConditions={(conditions) => handleUpdateConditions(index, conditions)}
            />
          ) : (
            <WorkflowTaskTriggerConditionBuilder
              availablePaths={props.availablePaths}
              condition={condition}
              entityOptions={props.entityOptions}
              eventTriggerOption={props.eventTriggerOption}
              onUpdate={(condition) => handleUpdateCondition(index, condition)}
            />
          )}
          <Sidebar.SquareButton
            aria-label="remove"
            size="xs"
            onClick={() => handleRemoveCondition(index)}
          >
            <BaselineMinusIcon />
          </Sidebar.SquareButton>
        </Flex>
      ))}
    </Flex>
  );
}

function WorkflowTaskTriggerConditionBuilder(props: {
  condition: WorkflowTaskTriggerCondition;
  availablePaths: InputPath[];
  entityOptions: WorkflowEntity[];
  eventTriggerOption: WorkflowEventTriggerOption | null;
  onUpdate: (condition: WorkflowTaskTriggerCondition) => void;
}) {
  const fieldsPaths =
    props.eventTriggerOption === null ? [] : getEventTriggerInputPath(props.eventTriggerOption);

  const selectedPath = fieldsPaths.find((path) => path.key === props.condition.property);

  const input =
    props.eventTriggerOption?.fields.find(
      (field) => field.name === props.condition.property?.split(".").pop(),
    ) ?? null;

  return (
    <Flex gap="1">
      <BindButton
        availablePaths={fieldsPaths}
        availableQueries={null}
        bind={{ type: "path", path: selectedPath ?? null }}
        entityOptions={props.entityOptions}
        input={input}
        name="Field"
        size="xs"
        onUpdate={(_, bind) => {
          if (bind === null) {
            return props.onUpdate({
              ...props.condition,
              property: null,
            });
          }

          if (bind.type !== "path") {
            return;
          }

          props.onUpdate({
            ...props.condition,
            property: bind.path?.key ?? null,
          });
        }}
      />
      <OperatorSelect
        operator={props.condition.operator}
        onChange={(operator) =>
          props.onUpdate({
            ...props.condition,
            operator,
          })
        }
      />
      {props.condition.operator !== "is empty" &&
        props.condition.operator !== "is not empty" &&
        input !== null && (
          <BindButton
            availablePaths={props.availablePaths}
            availableQueries={null}
            bind={props.condition.bind}
            entityOptions={props.entityOptions}
            input={input}
            name="Operand"
            size="xs"
            onUpdate={(_, bind) => {
              if (bind === null) {
                return props.onUpdate({
                  ...props.condition,
                  bind: null,
                });
              }

              props.onUpdate({
                ...props.condition,
                bind: bind,
              });
            }}
          />
        )}
    </Flex>
  );
}

function OperatorSelect(props: {
  operator: WorkflowTaskTriggerCondition["operator"];
  onChange: (operator: WorkflowTaskTriggerCondition["operator"]) => void;
}) {
  return (
    <Menu isLazy>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="xs">
        {props.operator}
      </MenuButton>
      <MenuList>
        {operators.map((operator) => (
          <MenuItem key={operator} onClick={() => props.onChange(operator)}>
            {formatOperatorToHuman(operator)}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

function GroupTypeRadio(props: {
  groupType: WorkflowTaskTriggerConditions["operator"];
  onChange: (groupType: WorkflowTaskTriggerConditions["operator"]) => void;
}) {
  const handleChange = (value: string) => {
    props.onChange(value === "and" ? "and" : "or");
  };

  return (
    <RadioGroup size="sm" value={props.groupType} onChange={handleChange}>
      <Stack direction="row">
        <Radio value="and">And</Radio>
        <Radio value="or">Or</Radio>
      </Stack>
    </RadioGroup>
  );
}

export default TaskTriggerConditions;
