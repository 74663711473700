import { InputPath } from "../../pages/Editor/utils/workflow-node.utils";

export const todayPath = {
  availability: { isAvailable: true },
  type: "dates",
  output: { type: "date" },
  path: [
    {
      id: "today",
      label: "Today",
    },
  ],
  key: "dates.today",
} satisfies InputPath;

export const nowPath = {
  availability: { isAvailable: true },
  type: "dates",
  output: { type: "datetime" },
  path: [
    {
      id: "now",
      label: "Now",
    },
  ],
  key: "dates.now",
} satisfies InputPath;

export const nowTimePath = {
  availability: { isAvailable: true },
  type: "dates",
  output: { type: "time" },
  path: [
    {
      id: "now-time",
      label: "Now (time)",
    },
  ],
  key: "dates.now-time",
} satisfies InputPath;
