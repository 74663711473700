import {
  NodeReferenceQuery,
  WorkflowChildShortCircuitTask,
  WorkflowDefinition,
  WorkflowEntity,
  WorkflowNode,
  WorkflowRetry,
  WorkflowTagReference,
  WorkflowTaskQuery,
} from "../../../../../shared/schemas/workflows";
import { InputPath, getNodeOutput } from "../../../utils/workflow-node.utils";
import {
  BasicMeta,
  DescriptionComponentSection,
  QueriesComponentSection,
  RetryComponentSection,
  TagsComponentSection,
} from "../shared/sections";

interface Props {
  id: string;
  workflow: WorkflowDefinition;
  taskRef: WorkflowChildShortCircuitTask;
  queryOptions: WorkflowTaskQuery[];
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  taskTriggersComponentSection: JSX.Element;
  tags: WorkflowTagReference[];
  onChangeTags: (tags: WorkflowTagReference[]) => void;
  onUpdateMeta: <T extends keyof BasicMeta>(name: T, value: BasicMeta[T]) => void;
  onUpdateTask: (id: string, task: WorkflowChildShortCircuitTask) => void;
  onAddQuery: (nodeRefId: string) => void;
  onUpdateQuery: (nodeRefId: string, queryRefId: string, query: NodeReferenceQuery) => void;
  onRemoveQuery: (nodeRefId: string, queryRefId: string) => void;
  onAddNode: (id: string, node: WorkflowNode) => void;
  onRemoveNode: (id: string) => void;
}

function ShortCircuitTaskSidebar(props: Props) {
  const handleChangeDescription = (description: string) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      meta: {
        ...props.taskRef.meta,
        description,
      },
    });
  };

  const handleUpdateRetry = (retry: WorkflowRetry | undefined) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      retry,
    });
  };

  const output = getNodeOutput(props.taskRef);

  return (
    <>
      <DescriptionComponentSection
        description={props.taskRef.meta.description}
        onChange={handleChangeDescription}
      />

      {props.taskTriggersComponentSection}

      <RetryComponentSection
        hasTaskTrigger={props.taskRef.trigger !== undefined}
        output={output.success}
        retry={props.taskRef.retry}
        onUpdate={handleUpdateRetry}
      />

      <QueriesComponentSection
        availablePaths={props.availablePaths}
        entityOptions={props.entityOptions}
        id={props.id}
        queries={props.taskRef.queries}
        queryOptions={props.queryOptions}
        onAddQuery={props.onAddQuery}
        onRemoveQuery={props.onRemoveQuery}
        onUpdateQuery={props.onUpdateQuery}
      />

      <TagsComponentSection
        availablePaths={props.availablePaths}
        availableQueries={props.taskRef.queries}
        entityOptions={props.entityOptions}
        tags={props.tags}
        onChange={props.onChangeTags}
      />
    </>
  );
}

export default ShortCircuitTaskSidebar;
