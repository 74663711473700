import { LocalDate, LocalDateTime, LocalTime } from "@js-joda/core";
import { produce } from "immer";
import { CSSProperties } from "react";
import { Edge, Node, XYPosition } from "reactflow";
import { nowPath, nowTimePath, todayPath } from "../../../shared/constants/date-paths.const";
import { operatorToHumanString } from "../../../shared/constants/operators.const";
import { mappings } from "../../../shared/schemas/mapping";
import {
  FormatterFieldType,
  HumanTaskTemplateDefinition,
  NodeOutput,
  NodeOutputKey,
  NodeReferenceQuery,
  NodeResult,
  Task,
  ValueOperator,
  WorkflowChildChoiceTask,
  WorkflowChildDependsOn,
  WorkflowChildGroup,
  WorkflowChildHumanTaskReference,
  WorkflowChildReferenceBinding,
  WorkflowChildReferenceBindings,
  WorkflowChildResetToTask,
  WorkflowChildShortCircuitTask,
  WorkflowChildSystemTaskReference,
  WorkflowChildWorkflowReference,
  WorkflowChoice,
  WorkflowDataField,
  WorkflowDefinition,
  WorkflowError,
  WorkflowInput,
  WorkflowInstanceExecutionResult,
  WorkflowNode,
} from "../../../shared/schemas/workflows";
import {
  assertDefined,
  fmap,
  isDefined,
  validateOneOf,
  writeable,
} from "../../../shared/utils/general.utils";
import { isPlainObject } from "../../../shared/utils/object.utils";
import { generateId } from "../../../shared/utils/string.utils";
import { workflowConfig } from "../workflow-config.const";
import { getEventTriggerInputPath } from "./workflow-triggers.utils";

export function getConnectedNodesMap(children: Record<string, WorkflowNode>) {
  const connectedNodesMap = new Map<string, string[]>();
  const previousNodeMap = new Map<string, string>();

  for (const [id, child] of Object.entries(children)) {
    if (child.dependsOn === undefined) {
      continue;
    }

    const { id: sourceId } = parseDependsOn(child.dependsOn);

    previousNodeMap.set(id, sourceId);

    if (!connectedNodesMap.has(sourceId)) {
      connectedNodesMap.set(sourceId, []);
    }

    connectedNodesMap.get(sourceId)?.push(id);
  }

  return { previousNodeMap, connectedNodesMap };
}

export function getIsLeafNode(params: {
  nodeRefId: string;
  connectedNodesMap: Map<string, string[]>;
}): boolean {
  return (params.connectedNodesMap.get(params.nodeRefId)?.length ?? 0) === 0;
}

function addGroupNodesExecutionResult(params: {
  children: Record<string, WorkflowNode>;
  executionResult: WorkflowInstanceExecutionResult;
}) {
  const nodeResults = new Map(params.executionResult.nodeResults);

  const groupNodes = Object.values(params.children).filter(
    (child): child is WorkflowChildGroup => child.type === "group",
  );

  for (const groupNode of groupNodes) {
    if (nodeResults.has(groupNode.logicalId)) {
      continue;
    }

    const executedChildren = Object.keys(groupNode.children).flatMap((childId) => {
      const executedChild = nodeResults.get(childId);
      return executedChild === undefined ? [] : [executedChild];
    });

    if (executedChildren.length !== Object.values(groupNode.children).filter(isRealNode).length) {
      continue;
    }

    executedChildren.sort((a, b) =>
      a.startedAt === null && b.startedAt === null
        ? 0
        : a.startedAt === null
        ? 1
        : b.startedAt === null
        ? -1
        : a.startedAt.compareTo(b.startedAt),
    );

    const firstExecutedChild = executedChildren[0];

    executedChildren.sort((a, b) =>
      a.finishedAt === null && b.finishedAt === null
        ? 0
        : a.finishedAt === null
        ? -1
        : b.finishedAt === null
        ? 1
        : b.finishedAt.compareTo(a.finishedAt),
    );

    const lastFinishedChild = executedChildren[0];

    // TODO @HTMHell: this should be changed once the server changes group logic.
    // currently, the server always returns "OK" as the group result
    // perhaps the server should return the execution result of groups?

    nodeResults.set(groupNode.logicalId, {
      id: null,
      executedBy: firstExecutedChild?.executedBy ?? null,
      finishedAt: lastFinishedChild?.finishedAt ?? null,
      startedAt: firstExecutedChild?.startedAt ?? null,
      input: [],
      status: { name: "SUCCESS" },
      output: {
        key: "OK",
        fields: [],
      },
    });
  }

  return {
    ...params.executionResult,
    nodeResults,
  };
}

function getReactFlowNodeType(params: { isDoneNode: boolean; node: WorkflowNode }) {
  if (params.isDoneNode) {
    return "output";
  }

  if (params.node.logicalId === dummyTaskRefIds.trigger) {
    return "input";
  }

  if (params.node.type === "short-circuit-task" || params.node.type === "reset-to-task") {
    return "output";
  }

  return "default";
}

export function generateReactflowNodes(params: {
  children: Record<string, WorkflowNode>;
  selectedNodeId: string | null;
  parentNodeId?: string;
  executionResult?: WorkflowInstanceExecutionResult;
}): Node[] {
  const { connectedNodesMap, previousNodeMap } = getConnectedNodesMap(params.children);

  return Object.entries(params.children)
    .sort(([, child]) => (child.type === "group" ? 1 : 0))
    .flatMap(([id, child]): Node[] => {
      const isLeafNode = getIsLeafNode({ nodeRefId: id, connectedNodesMap });
      const isDoneNode = isLeafNode && isPlaceholder(child);
      const previousNodeId = previousNodeMap.get(id) ?? null;
      const isExecutedDoneNode =
        params.executionResult === undefined
          ? undefined
          : getIsExecutedDoneNode({
              isDoneNode,
              executionResult: addGroupNodesExecutionResult({
                children: params.children,
                executionResult: params.executionResult,
              }),
              previousNodeId,
            });

      const reactFlowNode: Node = {
        id,
        type: getReactFlowNodeType({ node: child, isDoneNode }),
        data: {
          label: getNodeLabel({
            node: child,
            isDoneNode,
            executionResult: params.executionResult,
            isExecutedDoneNode,
          }),
        },
        selected: id === params.selectedNodeId,
        position: child.meta.position ?? { x: 0, y: 0 },
        parentNode: params.parentNodeId,
        extent: params.parentNodeId === undefined ? undefined : "parent",
        style:
          params.executionResult === undefined
            ? getReactFlowNodeStyle({ node: child, isDoneNode })
            : getReactFlowNodeStyleForReadOnly({
                node: child,
                executionResult: params.executionResult,
                isDoneNode,
                isExecutedDoneNode,
              }),
      };

      if (child.type === "group") {
        return [
          {
            ...reactFlowNode,
            data: {
              ...reactFlowNode.data,
              label: undefined,
            },
          },
          ...generateReactflowNodes({
            children: child.children,
            selectedNodeId: params.selectedNodeId,
            parentNodeId: id,
            executionResult: params.executionResult,
          }),
        ];
      }

      return [reactFlowNode];
    });
}

function getNodeLabel(params: {
  node: WorkflowNode;
  isDoneNode: boolean;
  isExecutedDoneNode: boolean | undefined;
  executionResult: WorkflowInstanceExecutionResult | undefined;
}) {
  const debug = false as boolean;

  const suffix = debug ? ` (${params.node.logicalId})` : "";

  const name = getNodeLabelForReadOnly(params);

  return name + suffix;
}

function getNodeLabelForReadOnly(params: {
  node: WorkflowNode;
  isDoneNode: boolean;
  isExecutedDoneNode: boolean | undefined;
  executionResult: WorkflowInstanceExecutionResult | undefined;
}) {
  if (params.isDoneNode) {
    return getIsExecutedDoneNodeWithExecutionResult(params)
      ? params.executionResult.status.name
      : params.executionResult === undefined
      ? "Done"
      : "N/A";
  }

  return params.node.meta.name;
}

function getIsExecutedDoneNodeWithExecutionResult(params: {
  isDoneNode: boolean;
  executionResult: WorkflowInstanceExecutionResult | undefined;
  isExecutedDoneNode: boolean | undefined;
}): params is {
  isDoneNode: true;
  executionResult: WorkflowInstanceExecutionResult;
  isExecutedDoneNode: true;
} {
  if (params.isDoneNode) {
    return params.isExecutedDoneNode === true && params.executionResult !== undefined;
  }

  return false;
}

function getIsExecutedDoneNode(params: {
  isDoneNode: boolean;
  executionResult: WorkflowInstanceExecutionResult;
  previousNodeId: string | null;
}) {
  if (params.isDoneNode && params.previousNodeId !== null) {
    const previousNodeResult = params.executionResult.nodeResults.get(params.previousNodeId);

    return (
      previousNodeResult !== undefined &&
      (previousNodeResult.status.name === "ERROR" || previousNodeResult.status.name === "SUCCESS")
    );
  }

  return false;
}

function getReactFlowNodeStyleForReadOnly(params: {
  node: WorkflowNode;
  isDoneNode: boolean;
  executionResult: WorkflowInstanceExecutionResult;
  isExecutedDoneNode: boolean | undefined;
}): CSSProperties {
  const nodeResult = params.executionResult.nodeResults.get(params.node.logicalId);
  const status = params.isExecutedDoneNode ? params.executionResult.status : nodeResult?.status;

  const overrides =
    params.node.type === "group" ? {} : getReactFlowNodeStyleForReadOnlyOverrides(status);

  return {
    ...getReactFlowNodeStyle({ node: params.node, isDoneNode: false }),
    ...overrides,
  };
}

function getReactFlowNodeStyleForReadOnlyOverrides(
  status: NodeResult["status"] | WorkflowInstanceExecutionResult["status"] | undefined,
): CSSProperties {
  switch (status?.name) {
    case undefined:
      return {
        backgroundColor: undefined,
        borderColor: undefined,
      };

    case "SUCCESS":
      return {
        backgroundColor: "var(--chakra-colors-green-100)",
        borderColor: "var(--chakra-colors-green-500)",
      };

    case "ERROR":
      return {
        backgroundColor: "var(--chakra-colors-red-100)",
        borderColor: "var(--chakra-colors-red-500)",
      };

    case "IN_PROGRESS":
    case "WAITING":
    case "CREATED":
      return {
        backgroundColor: "var(--chakra-colors-blue-100)",
        borderColor: "var(--chakra-colors-blue-500)",
      };

    case "CANCELED":
      return {
        backgroundColor: "var(--chakra-colors-orange-100)",
        borderColor: "var(--chakra-colors-orange-500)",
      };
  }
}

function getReactFlowNodeStyle(params: { node: WorkflowNode; isDoneNode: boolean }): CSSProperties {
  if (params.isDoneNode) {
    return {
      width: workflowConfig.nodeWidth,
      backgroundColor: "#C6F6D5",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
  }

  if (params.node.type === "group") {
    return {
      backgroundColor: "rgba(255, 0, 0, 0.2)",
      ...getReactFlowGroupDimentions(params.node),
    };
  }

  const borderColor = getReactFlowNodeBorderColor({ node: params.node, isDoneNode: false });

  return {
    borderColor,
    width: workflowConfig.nodeWidth,
  };
}

function getReactFlowNodeBorderColor(params: {
  node: Exclude<WorkflowNode, { type: "group" }>;
  isDoneNode: boolean;
}) {
  switch (params.node.type) {
    case "system-task":
      return isPlaceholder(params.node) || isTrigger(params.node)
        ? "var(--chakra-colors-black)"
        : "var(--chakra-colors-blue-500)";

    case "human-task":
      return "var(--chakra-colors-orange-500)";

    case "choice":
      return "var(--chakra-colors-purple-500)";

    case "workflow":
      return "var(--chakra-colors-teal-500)";

    case "short-circuit-task":
      return "var(--chakra-colors-red-500)";

    case "reset-to-task":
      return "var(--chakra-colors-yellow-500)";
  }
}

export function getReactFlowGroupDimentions(node: WorkflowChildGroup): {
  width: number;
  height: number;
} {
  const childrenPositions = Object.values(node.children).map((child) => child.meta.position);

  const x = childrenPositions.map((p) => p?.x ?? 0);
  const y = childrenPositions.map((p) => p?.y ?? 0);

  const numNestedGroups = getGroupsDepth(node) * 2;

  return {
    width:
      Math.max(...x) +
      workflowConfig.nodeWidth +
      workflowConfig.groupPadding +
      workflowConfig.groupPadding * numNestedGroups,
    height:
      Math.max(...y) +
      workflowConfig.nodeHeight +
      workflowConfig.groupPadding +
      workflowConfig.groupPadding * numNestedGroups,
  };
}

function getGroupsDepth(node: WorkflowChildGroup, depth = 0): number {
  const children = Object.values(node.children);

  if (children.length === 0) {
    return depth;
  }

  return Math.max(
    ...children.map((child) => {
      if (child.type === "group") {
        return getGroupsDepth(child, depth + 1);
      }

      return depth;
    }),
  );
}

export function generateReactflowEdges(params: {
  children: Record<string, WorkflowNode>;
  selectedNodeId: string | null;
  isNested?: boolean;
}): Edge[] {
  return Object.entries(params.children).flatMap(([id, child]): Edge[] => {
    const a =
      child.type === "group"
        ? generateReactflowEdges({
            children: child.children,
            selectedNodeId: params.selectedNodeId,
            isNested: true,
          })
        : [];

    const b = ((): Edge[] => {
      if (child.dependsOn === undefined) {
        return [];
      }

      const {
        id: sourceId,
        type: sourceType,
        output: sourceResult,
      } = parseDependsOn(child.dependsOn);

      const edgeId = `${sourceId}:${sourceType}:${sourceResult} -> ${id}`;

      return [
        {
          id: edgeId,
          source: sourceId,
          target: id,
          label: getEdgeName({ sourceId, sourceResult, sourceType, children: params.children }),
          selected: edgeId === params.selectedNodeId,
          zIndex: params.isNested === true ? 1 : undefined,
        },
      ];
    })();

    return [...a, ...b];
  });
}

function getEdgeName(params: {
  sourceId: string;
  sourceResult: string;
  sourceType: "error" | "output";
  children: Record<string, WorkflowNode>;
}) {
  const node = findNode(params.sourceId, params.children);

  if (node === null) {
    return params.sourceResult;
  }

  const parent = node.parent === null ? null : findGroupParentNode(node.parent, params.children);
  const siblings = parent?.children ?? params.children;
  const nodeWithOutput = siblings[params.sourceId];

  if (params.sourceType === "error") {
    const error = nodeWithOutput?.errors?.find((e) => e.id === params.sourceResult);

    if (error !== undefined) {
      return error.on.join(", ");
    }
  }

  if (nodeWithOutput === undefined || nodeWithOutput.type !== "human-task") {
    return params.sourceResult;
  }

  return nodeWithOutput.output[params.sourceResult]?.name ?? params.sourceResult;
}

export function getNodeIdsToRemoveByConnectedNodeOutput(params: {
  workflow: WorkflowDefinition;
  parent: string | null;
  nodeRefId: string;
  nodeOutput: NodeOutput;
}) {
  const parentNode =
    params.parent === null ? null : findGroupParentNode(params.parent, params.workflow.children);

  const siblings = parentNode?.children ?? params.workflow.children;

  return Object.entries(siblings)
    .filter(([, child]) => {
      if (child.dependsOn === undefined) {
        return false;
      }

      const dependsOn = parseDependsOn(child.dependsOn);

      return (
        dependsOn.id === params.nodeRefId &&
        !Object.keys(
          params.nodeOutput[dependsOn.type === "output" ? "success" : "error"] ?? {},
        ).includes(dependsOn.output)
      );
    })
    .map(([id]) => id);
}

export function calculateNewNodePositionFromEdges(params: {
  minX: number;
  maxX: number;
  maxY: number;
  nodeWidth: number;
  hasAttachedNodes: boolean;
}) {
  return {
    x: getNewNodeXPosition({
      xEdges: { min: params.minX, max: params.maxX },
      nodeWidth: params.nodeWidth,
      hasAttachedNodes: params.hasAttachedNodes,
    }),
    y: params.maxY + workflowConfig.defaultNodesYMargin,
  };
}

export function getNodeEdgePositions(params: {
  node: WorkflowNode;
  children: Record<string, WorkflowNode>;
}) {
  const { y: top, x: left } = params.node.meta.position ?? {
    y: 0,
    x: 0,
  };

  const parentNode =
    params.node.parent === null ? null : findGroupParentNode(params.node.parent, params.children);

  const siblings = parentNode?.children ?? params.children;

  const attachedNodes = Object.values(siblings).filter((child) => {
    const { id } = child.dependsOn === undefined ? { id: null } : parseDependsOn(child.dependsOn);

    return id === params.node.logicalId;
  });

  const nodeHeight = getNodeHeight(params.node);

  return {
    minX:
      attachedNodes.length > 0
        ? Math.min(...attachedNodes.map((n) => n.meta.position?.x ?? left))
        : left,
    maxX:
      attachedNodes.length > 0
        ? Math.max(...attachedNodes.map((n) => n.meta.position?.x ?? left))
        : left,
    minY: top,
    maxY: top + nodeHeight,
    siblings,
    attachedNodes,
  };
}

export function getNodeWidth(node: WorkflowNode | undefined) {
  return node?.type === "group"
    ? getReactFlowGroupDimentions(node).width
    : workflowConfig.nodeWidth;
}

export function getNodeHeight(node: WorkflowNode | undefined) {
  return node?.type === "group"
    ? getReactFlowGroupDimentions(node).height
    : workflowConfig.nodeHeight;
}

function getNewNodeXPosition(params: {
  nodeWidth: number;
  xEdges: { min: number; max: number };
  hasAttachedNodes: boolean;
}) {
  const addition = params.nodeWidth + workflowConfig.defaultNodesXMargin;

  return params.xEdges.max + params.xEdges.min < 0
    ? params.xEdges.max + (params.hasAttachedNodes ? addition : 0)
    : params.xEdges.min - (params.hasAttachedNodes ? addition : 0);
}

export function generatePlaceholdersForNode(params: {
  node: WorkflowNode;
  children: Record<string, WorkflowNode>;
}) {
  const { minX, maxX, maxY, siblings, attachedNodes } = getNodeEdgePositions({
    node: params.node,
    children: params.children,
  });

  const output = getNodeOutput(params.node);

  return (["success", "error"] as const).flatMap((type) => {
    const unAttachedOutputs = Object.keys(output[type] ?? {}).filter(
      (resultKey) =>
        !Object.values(siblings).some((s) => {
          if (s.dependsOn === undefined) {
            return false;
          }

          const dependsOn = parseDependsOn(s.dependsOn);

          return (
            dependsOn.id === params.node.logicalId &&
            dependsOn.type === (type === "error" ? "error" : "output") &&
            dependsOn.output === resultKey
          );
        }),
    );

    const nodeWidth = getNodeWidth(params.node);

    return unAttachedOutputs.map((resultKey) => {
      return emptyRef.placeholder({
        parent: params.node.parent,
        logicalId: generateId("node"),
        dependsOn: mapDependsOn({
          id: params.node.logicalId,
          type: type === "error" ? "error" : "output",
          output: resultKey,
        }),
        position: calculateNewNodePositionFromEdges({
          hasAttachedNodes: attachedNodes.length > 0,
          minX,
          maxX,
          maxY,
          nodeWidth,
        }),
      });
    });
  });
}

function getNodeConnectedOutputs(params: {
  node: WorkflowNode;
  children: Record<string, WorkflowNode>;
}) {
  return Object.values(params.children).flatMap((node) => {
    const returnOutputs: ReturnType<typeof parseDependsOn>[] = [];

    if (node.type === "group") {
      returnOutputs.push(
        ...getNodeConnectedOutputs({ node: params.node, children: node.children }),
      );
    }

    if (node.dependsOn === undefined) {
      return returnOutputs;
    }

    const dependsOn = parseDependsOn(node.dependsOn);

    if (dependsOn.id !== params.node.logicalId) {
      return returnOutputs;
    }

    returnOutputs.push(dependsOn);

    return returnOutputs;
  });
}

export function getAllNodeConnections(params: {
  node: WorkflowNode;
  children: Record<string, WorkflowNode>;
}) {
  const outputs = getNodeConnectedOutputs(params);

  return {
    error: new Set(outputs.filter((output) => output.type === "error").map(({ output }) => output)),
    output: new Set(
      outputs.filter((output) => output.type === "output").map(({ output }) => output),
    ),
  };
}

export function getNodeOutput(node: WorkflowNode | WorkflowNodeTree): NodeOutput {
  switch (node.type) {
    case "workflow": {
      throw new Error("Not implemented");
    }

    case "system-task": {
      if (node.task.id === dummyTasks.placeholder.id) {
        return {
          success: { OK: { name: "OK", output: {} } },
        };
      }

      if (node.task.id === dummyTasks.trigger.id) {
        return {
          success: { Triggered: { name: "Triggered", output: {} } },
        };
      }

      return {
        error: getErrorsOutput(node.errors),
        success: node.task.output,
      };
    }

    case "human-task": {
      return {
        error: getErrorsOutput(node.errors),
        success: node.output,
      };
    }

    case "group": {
      // TODO @HTMHell change it once the server supports something more robust
      return {
        success: { OK: { name: "OK", output: {} } },
      };
    }

    case "choice": {
      return {
        error: getErrorsOutput(node.errors),
        success: Object.fromEntries([
          ...node.choices.map((choice) => [
            choice.output,
            {
              name: choice.output,
              output: {},
            },
          ]),
          ...(node.default !== undefined
            ? [[node.default.output, { name: node.default.output, output: {} }]]
            : []),
        ]),
      };
    }

    case "short-circuit-task": {
      return {
        error: getErrorsOutput(node.errors),
        success: {},
      };
    }

    case "reset-to-task": {
      return {
        error: getErrorsOutput(node.errors),
        success: {},
      };
    }
  }
}

export function getGroupRefOutput(
  groupRef: WorkflowChildGroup | WorkflowNodeTreeGroup,
): NodeOutput {
  const leaves = getLeaves(Object.values(groupRef.children));

  // assuming all leaves have the same output
  // TODO: validate this assumption
  const firstLeaf = leaves.at(0);

  if (firstLeaf === undefined) {
    return {
      success: {},
    };
  }

  return getNodeOutput(firstLeaf);
}

function getErrorsOutput(errors?: WorkflowError[]) {
  if (errors === undefined) {
    return undefined;
  }

  const entries = errors
    .filter((error) => error.handler.type === "new-task")
    .map((error) => [
      error.id,
      {
        name: error.on.join(", "),
        output: {},
      },
    ]);

  if (entries.length === 0) {
    return undefined;
  }

  return Object.fromEntries(entries);
}

export function getNodeInput(node: WorkflowNode): {
  bind: Record<string, WorkflowChildReferenceBinding>;
  input: Record<string, WorkflowDataField>;
} {
  switch (node.type) {
    case "system-task": {
      if (Object.keys(dummyTasks).includes(node.task.id)) {
        return {
          bind: {},
          input: {},
        };
      }

      return {
        bind: node.bind,
        input: node.task.input,
      };
    }

    case "human-task": {
      return {
        bind: node.template.bind,
        input: node.template.template?.input ?? {},
      };
    }

    case "choice":
    case "group":
    case "reset-to-task":
    case "short-circuit-task":
      return {
        bind: {},
        input: {},
      };

    case "workflow":
      throw new Error("Not implemented");
  }
}

export function getAvailableNodeResult(params: {
  node: WorkflowNode;
  workflowChildren: Record<string, WorkflowNode>;
}): {
  type: "output" | "error";
  key: string;
} | null {
  switch (params.node.type) {
    case "workflow":
      return null;

    case "system-task":
    case "human-task":
    case "choice":
    case "group":
    case "short-circuit-task":
    case "reset-to-task": {
      const allConnections = getAllNodeConnections({
        node: params.node,
        children: params.workflowChildren,
      });

      const output = getNodeOutput(params.node);
      const success = Object.keys(output.success).find((key) => !allConnections.output.has(key));
      const error = Object.keys(output.error ?? {}).find((key) => !allConnections.error.has(key));

      return (
        fmap(success, (key) => ({
          type: "output" as const,
          key,
        })) ??
        fmap(error, (key) => ({
          type: "error" as const,
          key,
        })) ??
        null
      );
    }
  }
}

export function getConnectedNodeByOutput(
  children: Record<string, WorkflowNode>,
  sourceId: string,
  outputKey: NodeOutputKey,
): WorkflowNode | undefined {
  const siblings = findNodeSiblings(sourceId, children) ?? children;
  const connectedNodeIds = getConnectedNodeIds(siblings, sourceId);

  const connectedNode = connectedNodeIds.find((id) => {
    const node = siblings[id];

    if (node?.dependsOn === undefined) {
      return false;
    }

    const dependsOn = parseDependsOn(node.dependsOn);

    return dependsOn.output === outputKey.key && dependsOn.type === outputKey.type;
  });

  return connectedNode === undefined ? undefined : siblings[connectedNode];
}

export function getConnectedNodeIds(
  siblings: Record<string, WorkflowNode>,
  sourceId: string,
): string[] {
  return Object.entries(siblings)
    .filter(([, child]) => {
      if (child.dependsOn === undefined) {
        return false;
      }

      const { id } = parseDependsOn(child.dependsOn);

      return id === sourceId;
    })
    .map(([id]) => id);
}

type TreeProps = {
  outputKey: NodeOutputKey | null;
  resultChildren: WorkflowNodeTree[];
};

export type WorkflowNodeTreeGroup = Omit<WorkflowChildGroup, "children"> &
  TreeProps & {
    children: WorkflowNodeTree[];
  };

export type WorkflowNodeTree =
  | (WorkflowChildSystemTaskReference & TreeProps)
  | (WorkflowChildHumanTaskReference & TreeProps)
  | (WorkflowChildWorkflowReference & TreeProps)
  | (WorkflowChildChoiceTask & TreeProps)
  | (WorkflowChildShortCircuitTask & TreeProps)
  | (WorkflowChildResetToTask & TreeProps)
  | WorkflowNodeTreeGroup;

export type WorkflowNodeReversedTree = {
  node: WorkflowNode;
  resultParent: WorkflowNodeReversedTree | null;
};

export interface InputPath {
  type: "input" | "tasks" | "queries" | "triggers" | "dates";
  path: {
    id: string;
    label: string;
  }[];
  output: WorkflowDataField;
  key: string;
  availability:
    | {
        isAvailable: true;
      }
    | { isAvailable: false; reason: string | null };
}

function getOutputNodes(params: {
  node: WorkflowNode;
  children: Record<string, WorkflowNode>;
}): WorkflowNode[] {
  if (isPlaceholder(params.node)) {
    return [];
  }

  const placeholders = generatePlaceholdersForNode({
    node: params.node,
    children: params.children,
  });

  const output = getNodeOutput(params.node);

  return (["success", "error"] as const).flatMap((type) =>
    Object.keys(output[type] ?? {}).map(
      (outputKey) =>
        getConnectedNodeByOutput(params.children, params.node.logicalId, {
          key: outputKey,
          type: type === "success" ? "output" : "error",
        }) ??
        assertDefined(
          placeholders.find((p) => {
            if (p.dependsOn === undefined) {
              return false;
            }

            const dependsOn = parseDependsOn(p.dependsOn);

            return (
              dependsOn.output === outputKey &&
              dependsOn.type === (type === "success" ? "output" : "error")
            );
          }),
          `placeholder not found for ${
            params.node.logicalId
          }/${type}/${outputKey}. Available: ${placeholders.map((p) => p.logicalId)}`,
        ),
    ),
  );
}

function getWorkflowNodeTree(
  children: Record<string, WorkflowNode>,
  node: WorkflowNode,
  outputKey: NodeOutputKey | null,
): WorkflowNodeTree {
  const resultChildren = getOutputNodes({
    node,
    children,
  });

  const temp = {
    ...node,
    outputKey,
    resultChildren: [],
  };

  const temp2 =
    temp.type === "group"
      ? {
          ...temp,
          children: generateWorkflowTrees(temp.children),
        }
      : temp;

  return {
    ...temp2,
    resultChildren: resultChildren.map((child) => {
      if (child.dependsOn === undefined) {
        throw new Error("child.dependsOn === undefined");
      }

      const dependsOn = parseDependsOn(child.dependsOn);

      return getWorkflowNodeTree(
        children,
        child.type === "group"
          ? {
              ...child,
              children: addPlaceholdersToLooseEnds(child.children),
            }
          : child,
        {
          type: dependsOn.type,
          key: dependsOn.output,
        },
      );
    }),
  };
}

export function findNodeInTree(
  nodeId: string,
  tree: WorkflowNodeTree,
): WorkflowNodeTree | undefined {
  if (tree.logicalId === nodeId) {
    return tree;
  }

  const stack = [tree];

  while (stack.length > 0) {
    const node = stack.pop();

    if (node === undefined) {
      continue;
    }

    if (node.logicalId === nodeId) {
      return node;
    }

    if (node.type === "group") {
      stack.push(...Object.values(node.children));
    }

    stack.push(...node.resultChildren);
  }

  return undefined;
}

export function addPlaceholdersToLooseEnds(
  children: Record<string, WorkflowNode>,
): Record<string, WorkflowNode> {
  const trees = generateWorkflowTrees(children);

  return trees
    .map((tree) => flattenWorkflowNodeTree(tree))
    .reduce((acc, val) => ({ ...acc, ...val }), {});
}

function mapTreeNode(tree: WorkflowNodeTree): WorkflowNode {
  const { outputKey: _, resultChildren: __, ...node } = tree;

  switch (node.type) {
    case "system-task":
    case "human-task":
    case "workflow":
    case "choice":
    case "short-circuit-task":
    case "reset-to-task":
      return node;

    case "group":
      return {
        ...node,
        children: node.children
          .map((child) => flattenWorkflowNodeTree(child))
          .reduce((acc, val) => ({ ...acc, ...val }), {}),
      };
  }
}

function flattenWorkflowNodeTree(
  tree: WorkflowNodeTree,
  children?: Record<string, WorkflowNode>,
): Record<string, WorkflowNode> {
  children = children ?? {};

  children[tree.logicalId] = mapTreeNode(tree);

  return tree.resultChildren.reduce((acc, child) => flattenWorkflowNodeTree(child, acc), children);
}

export function getRootNodes(children: Record<string, WorkflowNode>): WorkflowNode[] {
  return Object.values(children).filter((node) => node.dependsOn === undefined);
}

export function generateWorkflowTrees(children: Record<string, WorkflowNode>) {
  const rootNodes = getRootNodes(children);

  return rootNodes.flatMap((node) => {
    const output = getNodeOutput(node);

    return (["success", "error"] as const).flatMap((type) => {
      const outputs = Object.keys(output[type] ?? {});

      return (outputs.length === 0 && type === "success" ? [null] : outputs).map((key) =>
        getWorkflowNodeTree(
          children,
          node,
          key === null
            ? null
            : {
                type: type === "success" ? "output" : "error",
                key,
              },
        ),
      );
    });
  });
}

export function generateWorkflowTreeUp(
  node: WorkflowNode,
  nodes: Record<string, WorkflowNode>,
  traversed: Set<string> = new Set(),
): WorkflowNodeReversedTree | null {
  const dependsOn = node.dependsOn === undefined ? undefined : parseDependsOn(node.dependsOn);

  const parentNode =
    dependsOn === undefined
      ? fmap(node.parent, (parentId) => nodes[parentId])
      : nodes[dependsOn.id];

  const resultParent =
    parentNode === undefined || traversed.has(node.logicalId)
      ? null
      : generateWorkflowTreeUp(parentNode, nodes, traversed);

  traversed.add(node.logicalId);

  return {
    node,
    resultParent,
  };
}

export function getNodeTreeById(id: string, trees: WorkflowNodeTree[]) {
  return trees
    .flatMap((tree) => {
      const t = findNodeInTree(id, tree);

      return t === undefined ? [] : [t];
    })
    .at(0);
}

export function flattenNodes(nodes: Record<string, WorkflowNode>): Record<string, WorkflowNode> {
  return Object.values(nodes).reduce((acc, node) => {
    if (node.type === "group") {
      return {
        ...acc,
        ...flattenNodes(node.children),
        [node.logicalId]: node,
      };
    }

    return {
      ...acc,
      [node.logicalId]: node,
    };
  }, {});
}

export function getAvailablePathsForWorkflow(params: {
  workflowInput: Map<string, WorkflowInput>;
}) {
  return [...params.workflowInput.entries()].map(
    ([field, value]): InputPath => ({
      availability: { isAvailable: true },
      type: "input",
      path: [
        {
          id: field,
          label: value.name,
        },
      ],
      output: value,
      key: `input.${field}`,
    }),
  );
}

export function getAvailablePathsForNode(params: {
  nodeRefId: string;
  flattenedNodes: Record<string, WorkflowNode>;
  workflowInput: Map<string, WorkflowInput>;
  overrideNode?: WorkflowNode;
}): InputPath[] {
  const node = assertDefined(
    params.flattenedNodes[params.nodeRefId],
    `Cannot find node "${params.nodeRefId}"`,
  );
  const nodeTree = generateWorkflowTreeUp(node, params.flattenedNodes);

  return [
    ...getAvailablePathsForWorkflow({ workflowInput: params.workflowInput }),
    ...getQueryPaths(),
    ...getNodeOutputPaths(),
    ...getNodeInputPaths(),
    ...getTaskTriggerPaths(),
    ...getDatePaths(),
  ];

  function getDatePaths(): InputPath[] {
    return [todayPath, nowPath, nowTimePath];
  }

  function getQueryPaths() {
    if (!("queries" in node)) {
      return [];
    }

    return [...node.queries].flatMap(([id, queryRef]) => {
      const query = queryRef.queryDefinition;

      if (query === null) {
        return [];
      }

      return Object.entries(query.output).map(
        ([k, output]): InputPath => ({
          availability: { isAvailable: true },
          type: "queries",
          path: [
            {
              id: id,
              label: query.name,
            },
            {
              id: k,
              label: k,
            },
          ],
          output: output,
          key: `queries.${id}.${k}`,
        }),
      );
    });
  }

  function getTaskTriggerPaths() {
    if (node.trigger === undefined) {
      return [];
    }

    const event = node.trigger.event;

    if (event === null) {
      return [];
    }

    return getEventTriggerInputPath(event);
  }

  function getNodeOutputPaths() {
    if (!isDefined(nodeTree)) {
      return [];
    }

    return traverse(nodeTree, (node, outputParent) => {
      if (outputParent === undefined || node.dependsOn === undefined) {
        return [];
      }

      const dependsOn = parseDependsOn(node.dependsOn);

      switch (outputParent.type) {
        case "system-task":
        case "human-task":
        case "choice":
        case "group": {
          if (Object.values(dummyTaskRefIds).includes(node.logicalId)) {
            return [];
          }

          const output = getNodeOutput(outputParent);

          const dependsOnOutput =
            output[dependsOn.type === "error" ? "error" : "success"]?.[dependsOn.output];

          if (dependsOnOutput === undefined) {
            return [];
          }

          return Object.entries(dependsOnOutput.output).map(
            ([fieldId, value]): InputPath => ({
              availability: { isAvailable: true },
              type: "tasks",
              path: [
                {
                  id: outputParent.logicalId,
                  label: outputParent.meta.name,
                },
                {
                  id: "output",
                  label: "Output",
                },
                {
                  id: dependsOnOutput.name,
                  label: dependsOnOutput.name,
                },
                {
                  id: value.name,
                  label: value.name,
                },
              ],
              output: value,
              key: `tasks.${outputParent.logicalId}.output.${dependsOn.output}.${fieldId}`,
            }),
          );
        }

        case "workflow":
          // TODO @HTMHell once the server supports it
          return [];

        case "short-circuit-task":
        case "reset-to-task":
          return [];
      }
    });
  }

  function getNodeInputPaths() {
    const parentNode = nodeTree?.resultParent;

    if (!isDefined(parentNode)) {
      return [];
    }

    return traverse(parentNode, (node) => {
      switch (node.type) {
        case "system-task":
        case "human-task": {
          const input = getNodeInput(node);

          return Object.keys(input.bind).flatMap((fieldId): InputPath[] => {
            const fieldType = input.input[fieldId];

            if (fieldType === undefined) {
              return [];
            }

            return [
              {
                availability: { isAvailable: true },
                type: "tasks",
                path: [
                  {
                    id: node.logicalId,
                    label: node.meta.name,
                  },
                  {
                    id: "input",
                    label: "Input",
                  },
                  {
                    id: fieldId,
                    label: fieldId,
                  },
                ],
                output: fieldType,
                key: `tasks.${node.logicalId}.input.${fieldId}`,
              },
            ];
          });
        }

        case "choice":
        case "short-circuit-task":
        case "reset-to-task":
        case "group":
        case "workflow":
          return [];
      }
    });
  }

  function traverse(
    node: WorkflowNodeReversedTree,
    iterator: (node: WorkflowNode, outputParent: WorkflowNode | undefined) => InputPath[],
  ): InputPath[] {
    const actualNode =
      params.overrideNode?.logicalId === node.node.logicalId ? params.overrideNode : node.node;

    const nodePaths = iterator(actualNode, node.resultParent?.node);

    if (node.resultParent === null) {
      return nodePaths;
    }

    const parentNodePath = traverse(node.resultParent, iterator);

    return [...nodePaths, ...parentNodePath];
  }
}

export const emptyRef = {
  workflow: (params: {
    parent: string | null;
    logicalId: string;
    id: string;
    dependsOn?: WorkflowChildDependsOn;
    position?: XYPosition;
  }): WorkflowChildWorkflowReference => ({
    parent: params.parent,
    logicalId: params.logicalId,
    dependsOn: params.dependsOn,
    type: "workflow",
    workflow: {
      id: params.id,
      version: 1,
    },
    bind: {},
    meta: {
      name: "",
      position: params.position,
    },
    tags: [],
  }),
  group: (params: {
    parent: string | null;
    logicalId: string;
    dependsOn?: WorkflowChildDependsOn;
    position?: XYPosition;
  }): WorkflowChildGroup => {
    const childId = generateId("node");
    return {
      parent: params.parent,
      logicalId: params.logicalId,
      dependsOn: params.dependsOn,
      type: "group",
      meta: {
        name: "Untitled Group",
        position: params.position,
      },
      children: {
        [childId]: emptyRef.placeholder({
          parent: params.logicalId,
          logicalId: childId,
          position: { x: workflowConfig.groupPadding, y: workflowConfig.groupPadding },
        }),
      },
      tags: [],
    };
  },
  humanTask: (params: {
    parent: string | null;
    logicalId: string;
    dependsOn?: WorkflowChildDependsOn;
    position?: XYPosition;
    taskTemplateOptions: HumanTaskTemplateDefinition[];
  }): WorkflowChildHumanTaskReference => {
    const layout = params.taskTemplateOptions.at(0)?.layout;

    if (layout === undefined) {
      throw new Error("Layout not found");
    }

    return {
      parent: params.parent,
      logicalId: params.logicalId,
      dependsOn: params.dependsOn,
      type: "human-task",
      meta: {
        name: "Untitled Human Task",
        position: params.position,
        priority: 1,
        severity: "LOW",
        mandatory: true,
      },
      queries: new Map(),
      output: {},
      skills: [],
      assignmentTimeoutSeconds: 60 * 20,
      assignmentStrategy: null,
      template: {
        template: null,
        bind: {},
      },
      tags: [],
    };
  },
  task: (params: {
    parent: string | null;
    logicalId: string;
    dependsOn?: WorkflowChildDependsOn;
    position?: XYPosition;
    task: Task;
  }): WorkflowChildSystemTaskReference => ({
    parent: params.parent,
    logicalId: params.logicalId,
    dependsOn: params.dependsOn,
    type: "system-task",
    task: params.task,
    queries: new Map(),
    bind: {},
    meta: {
      name: params.task.meta.name,
      position: params.position,
      mandatory: true,
      priority: 1,
      severity: "LOW",
    },
    tags: [],
  }),
  placeholder: (params: {
    parent: string | null;
    logicalId: string;
    dependsOn?: WorkflowChildDependsOn;
    position?: XYPosition;
  }): WorkflowChildSystemTaskReference => ({
    parent: params.parent,
    logicalId: params.logicalId,
    dependsOn: params.dependsOn,
    type: "system-task",
    task: dummyTasks.placeholder,
    queries: new Map(),
    bind: {},
    meta: {
      name: "Placeholder",
      mandatory: true,
      priority: 1,
      severity: "LOW",
      position: params.position,
    },
    tags: [],
  }),
  trigger: (params: {
    parent: string | null;
    logicalId: string;
    position?: XYPosition;
  }): WorkflowChildSystemTaskReference => ({
    parent: params.parent,
    logicalId: params.logicalId,
    type: "system-task",
    task: dummyTasks.trigger,
    queries: new Map(),
    bind: {},
    meta: {
      name: "Trigger",
      mandatory: true,
      priority: 1,
      severity: "LOW",
      position: params.position,
    },
    tags: [],
  }),
  bind: (params: { input: WorkflowDataField }): WorkflowChildReferenceBinding => {
    switch (params.input.type) {
      case "entity":
        return { type: "path", path: null };

      case "option":
        return emptyRef.bind({ input: params.input.optionType });

      case "union":
        return emptyRef.bind({ input: params.input.union[0] });

      case "file":
      case "boolean":
      case "date":
      case "time":
      case "datetime":
      case "number":
      case "text":
      case "textarea": {
        const isArray = params.input.array ?? false;

        return isArray
          ? {
              array: true,
              type: params.input.type,
              value: [],
            }
          : {
              array: false,
              type: params.input.type,
              value: null,
            };
      }
      case "unknown": {
        throw new Error("Unknown should never appear in bind");
      }
    }
  },
  choiceNode: (params: {
    parent: string | null;
    logicalId: string;
    dependsOn?: WorkflowChildDependsOn;
    position?: XYPosition;
  }): WorkflowChildChoiceTask => ({
    parent: params.parent,
    logicalId: params.logicalId,
    dependsOn: params.dependsOn,
    type: "choice",
    meta: {
      name: "Untitled Choice",
      position: params.position,
    },
    choices: [],
    queries: new Map(),
    tags: [],
  }),
  shortCircuit: (params: {
    parent: string | null;
    logicalId: string;
    dependsOn?: WorkflowChildDependsOn;
    position?: XYPosition;
  }): WorkflowChildShortCircuitTask => ({
    parent: params.parent,
    logicalId: params.logicalId,
    dependsOn: params.dependsOn,
    type: "short-circuit-task",
    meta: {
      name: "Untitled Short Circuit",
      position: params.position,
      mandatory: true,
      priority: 1,
      severity: "LOW",
    },
    queries: new Map(),
    tags: [],
  }),
  resetToTask: (params: {
    parent: string | null;
    logicalId: string;
    dependsOn?: WorkflowChildDependsOn;
    position?: XYPosition;
  }): WorkflowChildResetToTask => ({
    parent: params.parent,
    logicalId: params.logicalId,
    dependsOn: params.dependsOn,
    type: "reset-to-task",
    meta: {
      name: "Untitled Reset To Task",
      position: params.position,
      mandatory: true,
      priority: 1,
      severity: "LOW",
    },
    queries: new Map(),
    to: null,
    tags: [],
  }),
  choice: (): WorkflowChoice => ({
    conditions: {
      operator: "and",
      conditions: [],
    },
    output: "Output",
  }),
};

function areFieldTypesEqual(a: WorkflowDataField, b: WorkflowDataField): boolean {
  if (a.type === "entity" && b.type === "entity") {
    return a.entity === b.entity;
  }

  if (a.type === "union" && b.type === "union") {
    return b.union.every((ub) => a.union.some((ua) => areFieldTypesEqual(ub, ua)));
  }

  if (a.type === "union") {
    return a.union.some((ua) => areFieldTypesEqual(ua, b));
  }

  if (a.type === "text" || a.type === "textarea") {
    return b.type === "text" || b.type === "textarea";
  }

  return a.type === b.type;
}

function isUnionTypeOfArrays(field: WorkflowDataField): boolean {
  return field.type === "union" && field.union.every((f) => f.array === true);
}

export function isPathAvailable(params: {
  input: WorkflowDataField;
  path: InputPath;
  availableQueries: Map<string, NodeReferenceQuery> | null;
}): InputPath["availability"] {
  if (params.input.type === "text" || params.input.type === "textarea") {
    return { isAvailable: true };
  }

  if (!areFieldTypesEqual(params.input, params.path.output)) {
    return {
      isAvailable: false,
      reason: "Cannot bind different types",
    };
  }

  if (
    params.availableQueries !== null &&
    params.path.type === "queries" &&
    !params.availableQueries.has(params.path.path[0]?.id ?? "")
  ) {
    return {
      isAvailable: false,
      reason: "Can only bind to previous queries",
    };
  }

  // TODO: Discuss this
  // if (!params.input.optional && params.path.output.optional) {
  //   return {
  //     isAvailable: false,
  //     reason: "Cannot bind optional to non-optional",
  //   };
  // }

  if (!params.input.array && params.path.output.array && !isUnionTypeOfArrays(params.input)) {
    return {
      isAvailable: false,
      reason: "Cannot bind array to non-array",
    };
  }

  return { isAvailable: true };
}

export function hasAvailablePath(availablePaths: InputPath[]) {
  return availablePaths.some((path) => path.availability.isAvailable);
}

export const dummyTasks: Record<"trigger" | "placeholder", Task> = {
  trigger: {
    id: "_trigger",
    input: {},
    output: {},
    meta: {
      name: "Trigger",
      description: "Trigger",
      categories: [],
    },
    version: 1,
  },
  placeholder: {
    id: "placeholder",
    version: 1,
    meta: {
      name: "Placeholder",
      description: "",
      categories: [],
    },
    input: {},
    output: {},
  },
};

export const dummyTaskRefIds = {
  trigger: "_trigger",
};

export function canAddTask(params: {
  task: {
    id: string;
    version?: number | undefined;
  };
  workflow: WorkflowDefinition;
}) {
  const dummyTask = Object.values(dummyTasks).find(
    ({ id, version }) => id === params.task.id && version === params.task.version,
  );

  if (dummyTask === undefined) {
    return true;
  }

  return !Object.values(params.workflow.children).some(
    (node) =>
      "task" in node && node.task.id === dummyTask.id && node.task.version === dummyTask.version,
  );
}

export function findGroupParentNode(id: string, nodes: Record<string, WorkflowNode>) {
  const node = findNode(id, nodes);

  if (node === null) {
    return null;
  }

  if (node.type !== "group") {
    return null;
  }

  return node;
}

export function findNode(id: string, nodes: Record<string, WorkflowNode>): WorkflowNode | null {
  const node = nodes[id];

  if (node !== undefined) {
    return node;
  }

  for (const childNode of Object.values(nodes)) {
    if ("children" in childNode) {
      const found = findNode(id, childNode.children);
      if (found !== null) {
        return found;
      }
    }
  }

  return null;
}

export function findGroupNode(
  id: string,
  nodes: Record<string, WorkflowNode>,
): WorkflowChildGroup | null {
  const groupNode = findNode(id, nodes);

  if (groupNode?.type !== "group") {
    return null;
  }

  return groupNode;
}

export function getLeaves(nodes: WorkflowNode[] | WorkflowNodeTreeGroup[]): WorkflowNode[] {
  return nodes.flatMap((node) => {
    if ("children" in node) {
      return getLeaves(Object.values(node.children));
    }

    return [node];
  });
}

export function findNodeSiblings(
  id: string,
  nodes: Record<string, WorkflowNode>,
): Record<string, WorkflowNode> | null {
  if (nodes[id] !== undefined) {
    return nodes;
  }

  for (const childNode of Object.values(nodes)) {
    if ("children" in childNode) {
      const found = findNodeSiblings(id, childNode.children);
      if (found !== null) {
        return found;
      }
    }
  }

  return null;
}

export function bindCanHavePlaceholders(
  bind: WorkflowChildReferenceBinding,
): bind is WorkflowChildReferenceBindings["Text"] {
  return bind.type === "text" || bind.type === "textarea";
}

export function bindPlaceholdersAreNotEmpty(
  bind: WorkflowChildReferenceBinding,
): bind is WorkflowChildReferenceBindings["Text"] {
  return (
    bindCanHavePlaceholders(bind) &&
    bind.placeholders !== undefined &&
    Object.keys(bind.placeholders).length > 0
  );
}

export function getPlaceholdersFromBind(
  bind: WorkflowChildReferenceBindings["Text"],
): Record<string, WorkflowChildReferenceBinding> | undefined {
  if (bind.value === null) {
    return undefined;
  }

  const regex = new RegExp(/\{\w+\}/, "g");
  const placeholderNames =
    (Array.isArray(bind.value)
      ? bind.value.flatMap((x) => x?.match(regex)).filter(isDefined)
      : bind.value.match(regex)
    )?.map((s) => s.slice(1, -1)) ?? [];

  if (placeholderNames.length === 0) {
    return undefined;
  }

  return placeholderNames.reduce<Record<string, WorkflowChildReferenceBinding>>((acc, name) => {
    acc[name] = (bind.placeholders ?? {})[name] ?? { type: "path", path: null };
    return acc;
  }, {});
}

export function isPlaceholder(node: WorkflowNode | WorkflowNodeTree) {
  return node.type === "system-task" && node.task.id === dummyTasks.placeholder.id;
}

export function isTrigger(node: WorkflowNode | WorkflowNodeTree) {
  return node.type === "system-task" && node.task.id === dummyTasks.trigger.id;
}

export function isBindEmpty(bind: WorkflowChildReferenceBinding | null) {
  if (bind === null) {
    return true;
  }

  if (bind.type === "path") {
    return bind.path === null;
  }

  if (bind.array && bind.value.length === 0) {
    return true;
  }

  if ((bind.type === "text" || bind.type === "textarea") && bind.value === "") {
    return true;
  }

  return bind.value === null;
}

export function isFormatterAvailableForFieldType(
  fieldType: WorkflowDataField,
): fieldType is FormatterFieldType {
  return fieldType.type === "date" || fieldType.type === "datetime" || fieldType.type === "number";
}

export function mapPathFromString(str: string, availablePaths: InputPath[]) {
  const [rawType, ...rest] = str.split(".");

  const type = validateOneOf(rawType, ["input", "queries", "tasks"] as const);

  return mappings.path.fromServer(writeable([type, ...rest] as const), availablePaths);
}

export function isValidScheduleBind(
  bind: WorkflowChildReferenceBinding,
): bind is WorkflowChildReferenceBinding & {
  type: "path" | "datetime";
} {
  return bind.type === "path" || bind.type === "datetime";
}

export function isRealNode(node: WorkflowNode): boolean {
  switch (node.type) {
    case "system-task":
      return !Object.values(dummyTasks)
        .map((t) => t.id)
        .includes(node.task.id);

    case "human-task":
    case "workflow":
    case "group":
    case "choice":
    case "short-circuit-task":
    case "reset-to-task":
      return true;
  }
}

export function filterOutDummyTasks(
  children: Record<string, WorkflowNode>,
): Record<string, WorkflowNode> {
  const { connectedNodesMap } = getConnectedNodesMap(children);

  const x = Object.fromEntries(
    Object.entries(children)
      .filter(
        ([key, value]) =>
          !Object.values(dummyTaskRefIds).includes(key) &&
          isRealNode(value) &&
          (!isPlaceholder(value) || !getIsLeafNode({ nodeRefId: key, connectedNodesMap })),
      )
      .map(([key, value]) => {
        const { id: dependsOnId } =
          value.dependsOn === undefined ? { id: null } : parseDependsOn(value.dependsOn);

        const newValue =
          dependsOnId !== null &&
          dependsOnId.length > 0 &&
          Object.values(dummyTaskRefIds).includes(dependsOnId)
            ? {
                ...value,
                dependsOn: undefined,
              }
            : value;

        return [
          key,
          newValue.type === "group"
            ? { ...newValue, children: filterOutDummyTasks(newValue.children) }
            : newValue,
        ];
      }),
  );

  return x;
}

export function formatOperatorToHuman(operator: ValueOperator) {
  return operatorToHumanString[operator];
}

export function parseDependsOn(dependsOn: [string, "error" | "output", string]) {
  return {
    id: dependsOn[0],
    type: dependsOn[1],
    output: dependsOn[2],
  };
}

export function mapDependsOn(dependsOn: {
  id: string;
  type: "error" | "output";
  output: string;
}): [string, "error" | "output", string] {
  return [dependsOn.id, dependsOn.type, dependsOn.output];
}

export function mapFieldWithValues(params: {
  values: unknown[];
  input: WorkflowInput;
  selectedField: WorkflowChildReferenceBinding | null;
}): WorkflowChildReferenceBinding {
  const { values, input } = params;
  const isArray = input.array ?? false;
  const field = params.selectedField ?? { type: input.type };

  if (input.type === "union") {
    throw new Error("Cannot map union");
  }

  switch (input.type) {
    case "text":
    case "textarea": {
      const data = values.map((v) => (typeof v === "string" ? v : null));

      return isArray
        ? {
            type: input.type,
            array: true,
            value: data,
            placeholders: "placeholders" in field ? field.placeholders : undefined,
          }
        : {
            type: input.type,
            array: false,
            value: data[0] ?? null,
            placeholders: "placeholders" in field ? field.placeholders : undefined,
          };
    }

    case "file": {
      const data = values.map((v) =>
        typeof v === "object" && v !== null && "key" in v ? (v.key as string) : null,
      );

      return isArray
        ? {
            type: input.type,
            array: true,
            value: data,
          }
        : {
            type: input.type,
            array: false,
            value: data[0] ?? null,
          };
    }

    case "number": {
      const data = values.map((v) => (typeof v === "number" ? v : null));

      return isArray
        ? {
            type: input.type,
            array: true,
            value: data,
          }
        : {
            type: input.type,
            array: false,
            value: data[0] ?? null,
          };
    }

    case "boolean": {
      const data = values.map((value) =>
        value === null || value === undefined ? true : typeof value === "boolean" ? value : null,
      );

      return isArray
        ? {
            type: input.type,
            array: true,
            value: data,
          }
        : {
            type: input.type,
            array: false,
            value: data[0] ?? null,
          };
    }

    case "date": {
      const data = values.map((v) => (v instanceof LocalDate ? v : null));

      return isArray
        ? {
            type: input.type,
            array: true,
            value: data,
          }
        : {
            type: input.type,
            array: false,
            value: data[0] ?? null,
          };
    }

    case "datetime": {
      const data = values.map((v) => (v instanceof LocalDateTime ? v : null));

      return isArray
        ? {
            type: input.type,
            array: true,
            value: data,
          }
        : {
            type: input.type,
            array: false,
            value: data[0] ?? null,
          };
    }

    case "time": {
      const data = values.map((v) => (v instanceof LocalTime ? v : null));

      return isArray
        ? {
            type: input.type,
            array: true,
            value: data,
          }
        : {
            type: input.type,
            array: false,
            value: data[0] ?? null,
          };
    }

    case "entity": {
      const data = values.map((v) => (typeof v === "number" ? v : null));

      return isArray
        ? {
            type: "number",
            array: true,
            value: data,
          }
        : {
            type: "number",
            array: false,
            value: data[0] ?? null,
          };
    }

    case "option": {
      return mapFieldWithValues({
        ...params,
        input: {
          ...input.optionType,
          array: input.array,
          name: input.name,
        },
      });
    }

    case "unknown": {
      throw new Error("Unknown should never appear in mapFieldWithValues");
    }
  }
}

function isPathBindObject(obj: unknown): obj is WorkflowChildReferenceBindings["Path"] {
  return isPlainObject(obj) && "type" in obj && obj.type === "path" && "path" in obj;
}

function runOnPathBindObject(
  nodeId: string,
  obj: Record<string, unknown>,
  operation: (
    bind: WorkflowChildReferenceBindings["Path"],
    ref: {
      parent: Record<string, unknown>;
      key: string;
    },
    nodeId: string,
  ) => void,
) {
  const isGroupNode = "children" in obj && "type" in obj && obj.type === "group";

  for (const k in obj) {
    const value = obj[k];

    if (typeof value !== "object" || value === null) {
      continue;
    }

    if (isGroupNode && k === "children") {
      continue;
    }

    if (Array.isArray(value)) {
      for (const v of value) {
        if (isPlainObject(v)) {
          runOnPathBindObject(nodeId, v, operation);
        }
      }

      continue;
    }

    if (isPathBindObject(value)) {
      operation(value, { parent: obj, key: k }, nodeId);
      continue;
    }

    if (isPlainObject(value)) {
      runOnPathBindObject(
        "logicalId" in obj && typeof obj.logicalId === "string" ? obj.logicalId : nodeId,
        value,
        operation,
      );
    }

    if (value instanceof Map) {
      for (const v of value.values()) {
        if (isPlainObject(v)) {
          runOnPathBindObject(nodeId, v, operation);
        }
      }
    }
  }
}

export function traverseWorkflowNodes(
  children: Record<string, WorkflowNode>,
  iterator: (node: WorkflowNode) => void,
) {
  for (const child of Object.values(children)) {
    iterator(child);

    if ("children" in child) {
      traverseWorkflowNodes(child.children, iterator);
    }
  }
}

export function mapWorkflowNodes({
  children,
  parentGroupNode,
  iterator,
}: {
  children: Record<string, WorkflowNode>;
  parentGroupNode: WorkflowChildGroup | undefined;
  iterator: (node: WorkflowNode, parentGroupNode?: WorkflowChildGroup) => WorkflowNode;
}): Record<string, WorkflowNode> {
  return Object.fromEntries(
    Object.values(children).map((child) => {
      const node = iterator(child, parentGroupNode);

      return [
        node.logicalId,
        "children" in node
          ? {
              ...node,
              children: mapWorkflowNodes({
                children: node.children,
                iterator,
                parentGroupNode: node,
              }),
            }
          : node,
      ];
    }),
  );
}

function generateIdsForWorkflowNodes(children: Record<string, WorkflowNode>): Map<string, string> {
  const newIdsMap = new Map<string, string>();

  traverseWorkflowNodes(children, (node) => {
    newIdsMap.set(node.logicalId, generateId("node"));
  });

  return newIdsMap;
}

function copyNodeInner({
  sourceNode,
  targetNode,
  nodeIdsMap,
  positionDiff,
}: {
  sourceNode: WorkflowNode;
  targetNode: WorkflowNode;
  nodeIdsMap: Map<string, string>;
  positionDiff: {
    x: number;
    y: number;
  };
}) {
  const newNodeId = assertDefined(nodeIdsMap.get(sourceNode.logicalId), "newNodeId is undefined");

  return produce(sourceNode, (draft) => {
    const nodeDependsOn =
      newNodeId === targetNode.logicalId ? targetNode.dependsOn : draft.dependsOn;

    const parsedDependsOn = nodeDependsOn === undefined ? null : parseDependsOn(nodeDependsOn);

    if (parsedDependsOn?.id === sourceNode.logicalId) {
      // first, treat the copied node as a root,
      // then replace its dependsOn after the copy is done
      draft.dependsOn = undefined;
    } else {
      draft.dependsOn = fmap(parsedDependsOn, (dependsOn) =>
        mapDependsOn({
          ...dependsOn,
          id: nodeIdsMap.get(dependsOn.id) ?? dependsOn.id,
        }),
      );
    }

    const position = draft.meta.position ?? { x: 0, y: 0 };

    draft.meta.position = {
      x: position.x + positionDiff.x,
      y: position.y + positionDiff.y,
    };

    runOnPathBindObject(draft.logicalId, draft, (bind) => {
      if (bind.path === null) {
        return;
      }
      bind.path.path = bind.path.path.map((p) => ({
        ...p,
        id: nodeIdsMap.get(p.id) ?? p.id,
      }));
      bind.path.key = `${bind.path.type}.${bind.path.path.map((p) => p.id).join(".")}`;
    });

    draft.logicalId = newNodeId;
  });
}

function replaceCopiedNodeGroupChildrenWithNewKeys({
  children,
  groupId,
  nodeIdsMap,
}: {
  children: Record<string, WorkflowNode>;
  groupId: string | null;
  nodeIdsMap: Map<string, string>;
}): Record<string, WorkflowNode> {
  const newGroupId = fmap(groupId, (id) => nodeIdsMap.get(id)) ?? groupId;

  return Object.fromEntries(
    Object.entries(children).map(([key, node]) => {
      const newKey = nodeIdsMap.get(key) ?? key;

      const newNode = {
        ...node,
        parent: newGroupId,
      };

      return newNode.type === "group"
        ? [
            newKey,
            {
              ...newNode,
              children: replaceCopiedNodeGroupChildrenWithNewKeys({
                children: newNode.children,
                groupId: newNode.logicalId,
                nodeIdsMap,
              }),
            },
          ]
        : [newKey, newNode];
    }),
  );
}

function addNodesToWorkflowChildren({
  children,
  placement,
  groupId,
}: {
  children: Record<string, WorkflowNode>;
  placement: Record<string, WorkflowNode>;
  groupId: string;
}): Record<string, WorkflowNode> {
  return Object.fromEntries(
    Object.entries(children).map(([key, node]) => {
      if (node.type !== "group") {
        return [key, node];
      }

      if (node.logicalId !== groupId) {
        return [
          key,
          {
            ...node,
            children: addNodesToWorkflowChildren({
              children: node.children,
              placement,
              groupId,
            }),
          },
        ];
      }

      return [
        key,
        {
          ...node,
          children: {
            ...node.children,
            ...placement,
          },
        },
      ];
    }),
  );
}

function fixCopiedNodeGroups({
  workflowChildren,
  children,
  targetNode,
  nodeIdsMap,
}: {
  workflowChildren: Record<string, WorkflowNode>;
  children: Record<string, WorkflowNode>;
  targetNode: WorkflowNode;
  nodeIdsMap: Map<string, string>;
}) {
  const nodes = replaceCopiedNodeGroupChildrenWithNewKeys({
    children: children,
    groupId: targetNode.parent,
    nodeIdsMap: nodeIdsMap,
  });

  return targetNode.parent === null
    ? {
        ...workflowChildren,
        ...nodes,
      }
    : addNodesToWorkflowChildren({
        children: workflowChildren,
        placement: nodes,
        groupId: targetNode.parent,
      });
}

export function copyNode(params: {
  sourceNode: WorkflowNode;
  targetNode: WorkflowNode;
  workflowDefinition: WorkflowDefinition;
}) {
  const trees = generateWorkflowTrees(params.workflowDefinition.children);

  const sourceNodeTree = assertDefined(
    getNodeTreeById(params.sourceNode.logicalId, trees),
    "sourceNodeTree should be defined",
  );

  return copyTree(sourceNodeTree, params.sourceNode, params.targetNode, params.workflowDefinition);
}

function copyTree(
  sourceNodeTree: WorkflowNodeTree,
  sourceNode: WorkflowNode,
  targetNode: WorkflowNode,
  workflowDefinition: WorkflowDefinition,
): Record<string, WorkflowNode> {
  const children = Object.fromEntries(
    Object.entries(flattenWorkflowNodeTree(sourceNodeTree)).filter(([, node]) => isRealNode(node)),
  );

  const nodeIdsMap = generateIdsForWorkflowNodes(children);
  nodeIdsMap.set(sourceNode.logicalId, targetNode.logicalId);

  const sourcePosition = sourceNode.meta.position ?? { x: 0, y: 0 };
  const targetPosition = targetNode.meta.position ?? { x: 0, y: 0 };

  const positionDiff = {
    x: targetPosition.x - sourcePosition.x,
    y: targetPosition.y - sourcePosition.y,
  };

  const parentGroupNode =
    findGroupParentNode(targetNode.logicalId, workflowDefinition.children) ?? undefined;

  let newChildren = mapWorkflowNodes({
    children,
    parentGroupNode,
    iterator: (sourceNode, parentGroup) =>
      copyNodeInner({
        sourceNode,
        targetNode,
        nodeIdsMap,
        positionDiff: parentGroup === undefined ? positionDiff : { x: 0, y: 0 },
      }),
  });

  newChildren = produce(newChildren, (draft) => {
    const newTargetNode = assertDefined(draft[targetNode.logicalId], `newTargetNode is undefined`);

    newTargetNode.dependsOn = fmap(targetNode.dependsOn, (dependsOn) => [...dependsOn]);
  });

  const newChildrenWithSanitizedBinds = mapWorkflowNodes({
    children: newChildren,
    parentGroupNode,
    iterator: (node) =>
      produce(node, (n) => {
        mutateRemoveUnavailableBinds({
          node: n,
          workflowChildren: newChildren,
          workflowInput: workflowDefinition.input,
        });
      }),
  });

  const newChildrenWithFixedGroups = fixCopiedNodeGroups({
    children: newChildrenWithSanitizedBinds,
    workflowChildren: workflowDefinition.children,
    targetNode,
    nodeIdsMap,
  });

  const newChildrenWithAddedPlaceholders = addPlaceholdersToLooseEnds(newChildrenWithFixedGroups);

  return newChildrenWithAddedPlaceholders;
}

export function mutateRemoveUnavailableBinds({
  node,
  workflowInput,
  workflowChildren,
}: {
  node: WorkflowNode;
  workflowInput: Map<string, WorkflowInput>;
  workflowChildren: Record<string, WorkflowNode>;
}) {
  const flattenedNodes = flattenNodes(workflowChildren);

  let nodeId: string | null = null;
  let availablePaths: InputPath[] = [];

  if (node.type === "reset-to-task" && node.to !== null) {
    const availableNodesToReset = getAvailableNodesForReset({ sourceNode: node, workflowChildren });

    if (!availableNodesToReset.some((n) => n.logicalId === node.to?.node.logicalId)) {
      node.to = null;
    }
  }

  runOnPathBindObject(node.logicalId, node, (bind, ref, currentNodeId) => {
    const path = bind.path;

    if (path === null) {
      return;
    }

    if (currentNodeId !== nodeId) {
      availablePaths = getAvailablePathsForNode({
        nodeRefId: currentNodeId,
        flattenedNodes: flattenedNodes,
        workflowInput: workflowInput,
        overrideNode: node,
      });

      nodeId = currentNodeId;
    }

    if (!availablePaths.some((p) => p.key === path.key)) {
      if (ref.key === "left") {
        bind.path = null;
      } else {
        ref.parent[ref.key] = null;
      }
    }
  });
}

export function isAncestorOfNode(params: { ancestorTree: WorkflowNodeTree; nodeId: string }) {
  return params.nodeId in flattenWorkflowNodeTree(params.ancestorTree);
}

export function getNodeDecendants(
  node: WorkflowNode,
  siblings: Record<string, WorkflowNode>,
): Record<string, WorkflowNode> {
  const output = getNodeOutput(node);

  const successTrees = Object.keys(output.success).map((key) =>
    getWorkflowNodeTree(siblings, node, {
      key,
      type: "output",
    }),
  );

  const errorTrees = Object.keys(output.error ?? {}).map((key) =>
    getWorkflowNodeTree(siblings, node, {
      key,
      type: "error",
    }),
  );

  const trees = [...successTrees, ...errorTrees];

  return Object.fromEntries(
    trees
      .flatMap((tree) => Object.entries(flattenWorkflowNodeTree(tree)))
      .filter(([key]) => key !== node.logicalId),
  );
}

// Almost the same in meaning as getNodeAncestors but from the top-down.
export function getNodesInPathToNode(
  node: WorkflowNode,
  workflowChildren: Record<string, WorkflowNode>,
): Record<string, WorkflowNode> {
  const paths = recursivelyPathToNode(node, workflowChildren);

  const ancestors: Record<string, WorkflowNode> = {};

  for (const path of paths) {
    ancestors[path.logicalId] = path;
  }

  return ancestors;
}

function recursivelyPathToNode(
  searchNode: WorkflowNode,
  workflowChildren: Record<string, WorkflowNode>,
) {
  let node = workflowChildren[searchNode.logicalId];
  let path: WorkflowNode[] = [];

  if (node === undefined) {
    // Search groups.
    for (const value of Object.values(workflowChildren)) {
      if (value.type === "group") {
        const result = recursivelyPathToNode(searchNode, value.children);

        if (result.length !== 0) {
          node = value;
          path = result.slice();
          break;
        }
      }
    }
  }

  // Intentional. If node is still undefined after searching groups, return null.
  if (node === undefined) {
    return [];
  }

  // Only if an ancestor and not the search node itself.
  if (node.logicalId !== searchNode.logicalId) {
    path.push(node);
  }

  // Furl up.
  let dependsOn = fmap(node.dependsOn, parseDependsOn);
  while (dependsOn !== undefined) {
    const parentNode = workflowChildren[dependsOn.id];

    if (parentNode !== undefined) {
      path.push(parentNode);
    }

    dependsOn = fmap(parentNode?.dependsOn ?? null, parseDependsOn);
  }

  return path;
}

export function getNodeAncestors(
  node: WorkflowNode,
  workflowChildren: Record<string, WorkflowNode>,
): Record<string, WorkflowNode> {
  const ancestors: Record<string, WorkflowNode> = {};

  const dependsOn = fmap(node.dependsOn, parseDependsOn);
  const parent = fmap(dependsOn, ({ id }) => findNode(id, workflowChildren)) ?? null;

  if (parent !== null) {
    ancestors[parent.logicalId] = parent;
    Object.assign(ancestors, getNodeAncestors(parent, workflowChildren));
  }

  return ancestors;
}

export function getAvailableNodesForReset({
  sourceNode,
  workflowChildren,
}: {
  sourceNode: WorkflowNode;
  workflowChildren: Record<string, WorkflowNode>;
}) {
  const ancestorNodes = getNodesInPathToNode(sourceNode, workflowChildren);

  return Object.values(ancestorNodes).filter(
    (node) => isRealNode(node) && node.logicalId !== sourceNode.logicalId,
  );
}

export function getNodesWithNewPositionsAffectedByANodeChange(params: {
  currentWorkflowChildren: Record<string, WorkflowNode>;
  updatedNode: WorkflowNode;
  updatedGroupChildren: Record<string, WorkflowNode>;
  parentGroupNode: WorkflowChildGroup;
}) {
  const currentHeight = getNodeHeight(params.parentGroupNode);

  const newHeight = getNodeHeight({
    ...params.parentGroupNode,
    children: params.updatedGroupChildren,
  });

  const diff = newHeight - currentHeight;

  if (diff === 0) {
    return [];
  }

  const decentants = getNodeDecendants(params.parentGroupNode, params.currentWorkflowChildren);

  return Object.values(decentants).map((node) => {
    return produce(node, (draft) => {
      if (draft.meta.position !== undefined) {
        draft.meta.position.y += diff;
      }
    });
  });
}

export function transformInputFieldsToOptional(input: Record<string, WorkflowDataField>) {
  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => [
      key,
      value.optional
        ? value
        : {
            ...value,
            optional: true,
          },
    ]),
  );
}

export function capNodePosition(node: WorkflowNode, newPosition: XYPosition) {
  return node.parent === null
    ? newPosition
    : {
        x: Math.max(workflowConfig.groupPadding, newPosition.x),
        y: Math.max(workflowConfig.groupPadding, newPosition.y),
      };
}

export function removeNodeDependsOn(node: WorkflowNode) {
  return produce(node, (draft) => {
    draft.dependsOn = undefined;
  });
}

export function connectNodes({
  source,
  target,
  nodes,
  workflowInput,
}: {
  source: WorkflowNode;
  target: WorkflowNode;
  nodes: Record<string, WorkflowNode>;
  workflowInput: Map<string, WorkflowInput>;
}) {
  const availableResult = getAvailableNodeResult({
    node: source,
    workflowChildren: nodes,
  });

  if (availableResult === null) {
    return;
  }

  const updatedTargetNode = produce(target, (draft) => {
    draft.dependsOn = mapDependsOn({
      id: source.logicalId,
      type: availableResult.type,
      output: availableResult.key,
    });
  });

  const newChildren = mapWorkflowNodes({
    children: nodes,
    parentGroupNode: undefined,
    iterator: (node) => (node.logicalId === updatedTargetNode.logicalId ? updatedTargetNode : node),
  });

  const siblings = assertDefined(
    findNodeSiblings(target.logicalId, newChildren),
    "siblings is undefined",
  );

  const targetDecendants = getNodeDecendants(target, siblings);

  const newChildrenWithSanitizedBinds = mapWorkflowNodes({
    children: {
      ...targetDecendants,
      [target.logicalId]: updatedTargetNode,
    },
    parentGroupNode: undefined,
    iterator: (node) =>
      produce(node, (n) => {
        mutateRemoveUnavailableBinds({
          node: n,
          workflowChildren: newChildren,
          workflowInput: workflowInput,
        });
      }),
  });

  const groupParentNodeId = target.parent;

  return groupParentNodeId === null
    ? {
        ...newChildren,
        ...newChildrenWithSanitizedBinds,
      }
    : produce(newChildren, (draft) => {
        const groupParentNode = assertDefined(
          findGroupNode(groupParentNodeId, draft),
          "groupParentNode is undefined",
        );
        groupParentNode.children = {
          ...groupParentNode.children,
          ...newChildrenWithSanitizedBinds,
        };
      });
}
