import {
  NodeReferenceQuery,
  WorkflowDataField,
  WorkflowError,
  WorkflowErrorHandlerType,
  WorkflowInput,
  WorkflowNode,
  WorkflowTaskTrigger,
  WorkflowTrigger,
} from "../schemas/workflows";
import { generateId } from "./string.utils";

export const emptyObject = {
  input: (field: WorkflowDataField): WorkflowInput => {
    return {
      ...field,
      name: field.type === "entity" ? field.entity : "",
    };
  },
  query: (): NodeReferenceQuery => {
    return {
      queryDefinition: null,
      bind: {},
    };
  },
  triggers: {
    job: (): WorkflowTrigger => {
      return {
        id: generateId("jobTrigger"),
        type: "job",
        pattern: { type: "interval", minutes: 30 },
        bind: {},
        report: null,
      };
    },
    event: (): WorkflowTrigger => {
      return {
        id: generateId("eventTrigger"),
        type: "event",
        bind: {},
        conditions: {
          operator: "and",
          conditions: [],
        },
        event: null,
      };
    },
  },
  taskTrigger: (): WorkflowTaskTrigger => {
    return {
      id: 0,
      conditions: {
        operator: "and",
        conditions: [],
      },
      event: null,
      triggerInitOn: null,
    };
  },
  errorHandling: (params: {
    type: WorkflowErrorHandlerType;
    node: WorkflowNode;
  }): WorkflowError => {
    const { type, node } = params;

    return {
      id: generateId("errorHandling"),
      on: [],
      handler: (() => {
        switch (type) {
          case "new-task":
            return {
              type: type,
            };

          case "fallback-to-manual":
            return {
              type: type,
              meta: {
                name: `Handle error: ${node.meta.name}`,
                priority: 1,
                severity: "LOW",
                mandatory: true,
              },
              queries: new Map(),
              skills: [],
              assignmentTimeoutSeconds: 60 * 20,
              assignmentStrategy: null,
              template: {
                template: null,
                bind: {},
              },
            };

          case "fallback-to-default":
            return {
              type: type,
              status: null,
              output: {},
            };
        }
      })(),
    };
  },
};
