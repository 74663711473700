import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  IconButton,
  IconButtonProps,
  Input,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import HintCircle from "../../../shared/components/HintCircle";
import { WorkflowSkillId } from "../../../shared/schema/schema";
import {
  HumanTaskTemplateDefinition,
  NodeReferenceQuery,
  Task,
  WorkflowDataField,
  WorkflowDefinition,
  WorkflowEntity,
  WorkflowError,
  WorkflowErrorHandlerType,
  WorkflowEventTriggerOption,
  WorkflowInput,
  WorkflowJobTriggerOption,
  WorkflowNode,
  WorkflowTaskQuery,
  WorkflowTaskTrigger,
  WorkflowTrigger,
} from "../../../shared/schemas/workflows";
import { assertDefined } from "../../../shared/utils/general.utils";
import { dummyTaskRefIds, findNode } from "../utils/workflow-node.utils";
import NodeSidebar from "./Sidebars/Node/NodeSidebar";
import WorkflowSidebar from "./Sidebars/Workflow/WorkflowSidebar";

interface Props {
  workflow: WorkflowDefinition;
  flattenedNodes: Record<string, WorkflowNode>;
  triggerOptions: WorkflowEventTriggerOption[];
  triggerJobOptions: WorkflowJobTriggerOption[];
  entityOptions: WorkflowEntity[];
  selectedNodeId: string | null;
  taskOptions: Task[];
  taskTemplateOptions: HumanTaskTemplateDefinition[];
  queryOptions: WorkflowTaskQuery[];
  skillOptions: WorkflowSkillId[];
  onChangeName: (name: string) => void;
  onChangeDescription: (description: string) => void;
  onAddInput: (field: WorkflowDataField) => void;
  onUpdateInput: (id: string, input: WorkflowInput) => void;
  onRemoveInput: (id: string) => void;
  onAddTrigger: (type: "job" | "event") => void;
  onUpdateTrigger: (trigger: WorkflowTrigger) => void;
  onRemoveTrigger: (id: string) => void;
  onChangeTaskName: (id: string, name: string) => void;
  onUpdateNode: (id: string, node: WorkflowNode) => void;
  onAddNode: (id: string, node: WorkflowNode) => void;
  onRemoveNode: (id: string) => void;
  onAddQuery: (nodeRefId: string) => void;
  onUpdateQuery: (nodeRefId: string, queryRefId: string, query: NodeReferenceQuery) => void;
  onRemoveQuery: (nodeRefId: string, queryRefId: string) => void;
  onAddTaskTrigger: (nodeRefId: string) => void;
  onUpdateTaskTrigger: (nodeRefId: string, trigger: WorkflowTaskTrigger) => void;
  onRemoveTaskTrigger: (nodeRefId: string) => void;
  onAddErrorHandling: (nodeRefId: string, type: WorkflowErrorHandlerType) => void;
  onUpdateErrorHandling: (nodeRefId: string, error: WorkflowError) => void;
  onRemoveErrorHandling: (nodeRefId: string, error: WorkflowError) => void;
  onUpdateMeta: (meta: WorkflowDefinition["meta"]) => void;
  onUpdateConcurrencyRule: (rule: WorkflowDefinition["concurrencyRule"]) => void;
  onError: (error: unknown) => void;
}

function Sidebar(props: Props) {
  const selectedNode =
    props.selectedNodeId == null || Object.values(dummyTaskRefIds).includes(props.selectedNodeId)
      ? null
      : findNode(props.selectedNodeId, props.workflow.children);

  if (selectedNode === null) {
    return (
      <WorkflowSidebar
        entityOptions={props.entityOptions}
        triggerJobOptions={props.triggerJobOptions}
        triggerOptions={props.triggerOptions}
        workflow={props.workflow}
        onAddInput={props.onAddInput}
        onAddTrigger={props.onAddTrigger}
        onChangeDescription={props.onChangeDescription}
        onChangeName={props.onChangeName}
        onRemoveInput={props.onRemoveInput}
        onRemoveTrigger={props.onRemoveTrigger}
        onUpdateConcurrencyRule={props.onUpdateConcurrencyRule}
        onUpdateInput={props.onUpdateInput}
        onUpdateMeta={props.onUpdateMeta}
        onUpdateTrigger={props.onUpdateTrigger}
      />
    );
  }

  return (
    <NodeSidebar
      entityOptions={props.entityOptions}
      flattenedNodes={props.flattenedNodes}
      id={assertDefined(props.selectedNodeId, "selectedNodeId should be defined")}
      node={selectedNode}
      queryOptions={props.queryOptions}
      skillOptions={props.skillOptions}
      taskOptions={props.taskOptions}
      taskTemplateOptions={props.taskTemplateOptions}
      triggerEventOptions={props.triggerOptions}
      workflow={props.workflow}
      onAddErrorHandling={props.onAddErrorHandling}
      onAddNode={props.onAddNode}
      onAddQuery={props.onAddQuery}
      onAddTaskTrigger={props.onAddTaskTrigger}
      onChangeName={props.onChangeTaskName}
      onError={props.onError}
      onRemoveErrorHandling={props.onRemoveErrorHandling}
      onRemoveNode={props.onRemoveNode}
      onRemoveQuery={props.onRemoveQuery}
      onRemoveTaskTrigger={props.onRemoveTaskTrigger}
      onUpdateErrorHandling={props.onUpdateErrorHandling}
      onUpdateMeta={props.onUpdateMeta}
      onUpdateNode={props.onUpdateNode}
      onUpdateQuery={props.onUpdateQuery}
      onUpdateTaskTrigger={props.onUpdateTaskTrigger}
    />
  );
}

const Root = (props: React.ComponentProps<typeof Box>) => (
  <Box
    bg="white"
    borderColor="gray.200"
    borderRightWidth={4}
    data-testid="sidebar-root"
    fontSize="sm"
    overflowY="auto"
    right={0}
    top={0}
    w="600px"
    {...props}
  />
);
Sidebar.Root = Root;

const SidebarSection = (props: React.ComponentProps<typeof Box>) => <Box p={4} {...props} />;
Sidebar.Section = SidebarSection;

const SidebarText = (props: React.ComponentProps<typeof Text>) => (
  <Text as="p" color="gray.500" px={2} py={2} {...props} />
);
Sidebar.Text = SidebarText;

const SidebarRow = (props: React.ComponentProps<typeof Flex>) => (
  <Flex align="center" display="flex" gap={4} justify="space-between" my={2} {...props} />
);
Sidebar.Row = SidebarRow;

const SidebarSectionTitle = (props: React.ComponentProps<typeof Heading>) => {
  return <Heading as="h5" color="gray.500" fontSize="sm" fontWeight="light" {...props} />;
};
Sidebar.SectionTitle = SidebarSectionTitle;

const SidebarLabel = (
  props: React.ComponentProps<typeof Text> & {
    hint?: string;
  },
) => {
  const text = (
    <Text
      align="center"
      as="label"
      display="flex"
      flexDirection="row"
      fontWeight="light"
      gap={2}
      {...props}
    />
  );

  if (props.hint === undefined) {
    return text;
  }

  return (
    <Flex alignItems="center" gap="1">
      {text}
      <HintCircle hint={props.hint} />
    </Flex>
  );
};
Sidebar.Label = SidebarLabel;

const SidebarTextarea = (props: React.ComponentProps<typeof Textarea>) => (
  <Textarea rows={1} size="sm" variant="unstyled" {...props} />
);
Sidebar.Textarea = SidebarTextarea;

const SidebarInput = (props: React.ComponentProps<typeof Input>) => (
  <Input size="sm" variant="unstyled" {...props} />
);
Sidebar.Input = SidebarInput;

const SidebarCheckbox = (props: React.ComponentProps<typeof Checkbox>) => (
  <Checkbox size="lg" {...props} />
);
Sidebar.Checkbox = SidebarCheckbox;

const SmallInput = (props: React.ComponentProps<typeof Sidebar.Input>) => (
  <Sidebar.Input variant="unstyled" w={12} {...props} />
);
Sidebar.SmallInput = SmallInput;

const SidebarSelect = (props: React.ComponentProps<typeof Select>) => (
  <Select size="sm" variant="unstyled" {...props} />
);
Sidebar.Select = SidebarSelect;

const SidebarButton = (props: React.ComponentProps<typeof Button>) => (
  <Button size="sm" {...props} />
);
Sidebar.Button = SidebarButton;

Sidebar.SquareButton = React.forwardRef(function SquareButton(
  props: IconButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  return (
    <IconButton
      ref={ref}
      colorScheme={props.isActive ? "blue" : undefined}
      size={props.size ?? "sm"}
      variant="ghost"
      {...props}
    />
  );
});

Sidebar.ToggleButton = Sidebar.SquareButton;

const SidebarTitle = (props: React.ComponentProps<typeof Input>) => (
  <Input color="gray.800" fontWeight="bold" variant="unstyled" {...props} />
);
Sidebar.Title = SidebarTitle;

const SidebarDescription = (props: React.ComponentProps<typeof Input>) => (
  <Input color="gray.500" fontSize="14" px={2} py={2} variant="unstyled" {...props} />
);
Sidebar.Description = SidebarDescription;

export default Sidebar;
