import { IconProps } from "@chakra-ui/react";
import ArrowReset32RegularIcon from "../icons/ArrowReset32RegularIcon";
import ChoiceIcon from "../icons/ChoiceIcon";
import GroupIcon from "../icons/GroupIcon";
import HumanIcon from "../icons/HumanIcon";
import MinusCircleOutlineIcon from "../icons/MinusCircleOutlineIcon";
import TaskAltIcon from "../icons/TaskAltIcon";
import Workflow16Icon from "../icons/Workflow16Icon";

type Props = IconProps & {
  type:
    | "group"
    | "workflow"
    | "human-task"
    | "choice"
    | "system-task"
    | "short-circuit-task"
    | "reset-to-task";
};

export default function NodeIcon(props: Props) {
  const { type, ...rest } = props;

  switch (type) {
    case "group":
      return <GroupIcon {...rest} />;

    case "workflow":
      return <Workflow16Icon {...rest} />;

    case "human-task":
      return <HumanIcon {...rest} />;

    case "choice":
      return <ChoiceIcon {...rest} />;

    case "system-task":
      return <TaskAltIcon {...rest} />;

    case "short-circuit-task":
      return <MinusCircleOutlineIcon {...rest} />;

    case "reset-to-task":
      return <ArrowReset32RegularIcon {...rest} />;
  }
}
