import { Center, ModalContent, ModalContentProps, Spinner } from "@chakra-ui/react";
import InternalErrorBox from "./InternalErrorBox";

export function ModalContentLoading({ children, ...props }: ModalContentProps) {
  return (
    <ModalContent {...props}>
      {children ?? (
        <Center h="sm">
          <Spinner />
        </Center>
      )}
    </ModalContent>
  );
}
export function ModalContentError({
  children,
  error,
  ...props
}: ModalContentProps & { error: unknown }) {
  return <ModalContent {...props}>{children ?? <InternalErrorBox error={error} />}</ModalContent>;
}
