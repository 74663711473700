import Select from "../../../../../../shared/components/Select";
import BaselineMinusIcon from "../../../../../../shared/icons/BaselineMinusIcon";
import {
  NodeReferenceQuery,
  WorkflowChildReferenceBinding,
  WorkflowEntity,
  WorkflowTaskQuery,
} from "../../../../../../shared/schemas/workflows";
import { InputPath, emptyRef } from "../../../../utils/workflow-node.utils";
import Sidebar from "../../../Sidebar";
import InputBinding from "./InputBinding";

interface Props {
  id: string;
  queryRef: NodeReferenceQuery;
  availableQueries: Map<string, NodeReferenceQuery>;
  queryOptions: WorkflowTaskQuery[];
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  onUpdate: (id: string, query: NodeReferenceQuery) => void;
  onClickRemove: () => void;
}

function QueryComponent(props: Props) {
  const query = props.queryRef.queryDefinition;

  const handleUpdateBind = (name: string, bind: WorkflowChildReferenceBinding | null) => {
    if (bind === null) {
      props.onUpdate(props.id, {
        ...props.queryRef,
        bind: Object.fromEntries(
          Object.entries(props.queryRef.bind).filter(([key]) => key !== name),
        ),
      });
      return;
    }

    props.onUpdate(props.id, {
      ...props.queryRef,
      bind: {
        ...props.queryRef.bind,
        [name]: bind,
      },
    });
  };

  const handleUpdateQuery = (query: WorkflowTaskQuery | undefined) => {
    props.onUpdate(props.id, {
      ...props.queryRef,
      queryDefinition: query ?? null,
      bind: {},
    });
  };

  return (
    <>
      <Sidebar.Row>
        <Select
          buttonProps={{ size: "sm" }}
          label="Select query"
          options={props.queryOptions.map((queryOption) => ({
            label: queryOption.name,
            value: queryOption,
          }))}
          value={query}
          onChange={handleUpdateQuery}
        />

        <Sidebar.SquareButton
          aria-label="remove"
          data-testid="remove-query-button"
          onClick={props.onClickRemove}
        >
          <BaselineMinusIcon />
        </Sidebar.SquareButton>
      </Sidebar.Row>
      {Object.entries(query?.input ?? {}).map(([name, input]) => (
        <InputBinding
          key={name}
          availablePaths={props.availablePaths}
          availableQueries={props.availableQueries}
          bind={props.queryRef.bind[name] ?? emptyRef.bind({ input })}
          entityOptions={props.entityOptions}
          input={input}
          name={name}
          onUpdate={handleUpdateBind}
        />
      ))}
    </>
  );
}

export default QueryComponent;
