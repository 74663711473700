import React from "react";
import config from "../../config";
import { createApi } from "../api";
import { createMutations } from "../utils/mutations";
import { createQueries } from "../utils/queries";
import { UnsafeAny } from "../utils/types";
import useAuth, { AuthTokens } from "../utils/use-auth";
import { useQueryClient } from "@tanstack/react-query";

export const ApiContext = React.createContext<{
  api: ReturnType<typeof createApi>;
  mutations: ReturnType<typeof createMutations>;
  queries: ReturnType<typeof createQueries>;
}>(null as UnsafeAny);

export const ApiProvider = (props: { tokens: AuthTokens; children: React.ReactNode }) => {
  const { authData, logout, handleTokenRefresh } = useAuth();
  const queryClient = useQueryClient();

  if (authData.type === "guest") {
    throw new Error("Can't use ApiProvider with guest auth");
  }

  const api = createApi({
    baseUrl: config.API_URL,
    auth: {
      tokens: authData.tokens,
      data: authData.data.agencyMember,
    },
    onTokenRefreshed: handleTokenRefresh,
    onInvalidRefreshToken: () => {
      console.log("[withApi]: invalid refresh token. logging out");
      logout(() => window.location.reload());
    },
  });

  const queries = createQueries({ api, queryClient });
  const mutations = createMutations({ api, queries });

  return (
    <ApiContext.Provider value={{ api, queries, mutations }}>{props.children}</ApiContext.Provider>
  );
};
