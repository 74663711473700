import { z } from "zod";

export const zDataField = z
  .object({
    type: z.string(),
    array: z.boolean().optional(),
    optional: z.boolean().optional(),
    nullable: z.boolean().optional(),
  })
  // Since it will be a chore to mimic the actual interface,
  // we'll just pass through instead of stripping the unknown keys
  .passthrough();

export const zForm = z.object({
  name: z.string().min(1),
  id: z.string().min(1),
  input: z.array(z.tuple([z.string(), zDataField])),
  threshold: z.coerce
    .number()
    .optional()
    .transform((x) => (x === 0 ? undefined : x)),
});
