import { Box, Text } from "@chakra-ui/react";

export default function SelectNoResults(props: { searchTerm: string }) {
  return (
    <Box color="gray.600" fontSize="md" mx={2} p={6} textAlign="center">
      {props.searchTerm === "" ? (
        <Text>No results</Text>
      ) : (
        <Text>No results for &rdquo;{props.searchTerm}&rdquo;</Text>
      )}
    </Box>
  );
}
