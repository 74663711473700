import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

interface Props {
  value?: string;
  onChange: (value: string) => void;
}

const items = [
  { value: "LOW", label: "Low" },
  { value: "MEDIUM", label: "Medium" },
  { value: "HIGH", label: "High" },
  { value: "CRITICAL", label: "Critical" },
];

export default function SeverityMenu(props: Props) {
  const current = items.find((item) => item.value === props.value);

  return (
    <Menu isLazy>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="xs">
        {current?.label ?? "Select severity"}
      </MenuButton>
      <MenuList>
        {items.map((item) => (
          <MenuItem key={item.value} onClick={() => props.onChange(item.value)}>
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
