import { Box } from "@chakra-ui/react";
import { LocalDateTime } from "@js-joda/core";
import React from "react";
import { AxisOptions, Chart, UserSerie } from "react-charts";
import { Messages } from "../../../shared/api";
import { fmap } from "../../../shared/utils/general.utils";
import { UnsafeAny } from "../../../shared/utils/types";

type SeriesItem = {
  primary: LocalDateTime;
  secondary: number;
};

type Props = {
  data: Messages["WorkflowInstanceAggregatedExecutionsDatum"][];
  unit: "day" | "hour";
};

export default function WorkflowInvocationsSmallGraph(props: Props) {
  const data = React.useMemo((): UserSerie<SeriesItem>[] => {
    return [
      {
        label: "Cancel",
        color: "var(--chakra-colors-gray-300)",
        data: props.data.map((datum) => ({
          primary: datum.timeGroup,
          secondary: datum.totalCancel,
        })),
      },
      {
        label: "Error",
        color: "var(--chakra-colors-red-300)",
        data: props.data.map((datum) => ({
          primary: datum.timeGroup,
          secondary: datum.totalError,
        })),
      },
      {
        label: "In Progress",
        color: "var(--chakra-colors-yellow-300)",
        data: props.data.map((datum) => ({
          primary: datum.timeGroup,
          secondary: datum.totalInProgress,
        })),
      },
      {
        label: "Success",
        color: "var(--chakra-colors-green-300)",
        data: props.data.map((datum) => ({
          primary: datum.timeGroup,
          secondary: datum.totalSuccess,
        })),
      },
    ];
  }, [props.data]);

  const primaryAxis = React.useMemo(
    (): AxisOptions<SeriesItem> => ({
      getValue: (datum) => datum.primary.toString(),
      scaleType: "band",
      show: false,
      formatters: {
        cursor: (value: UnsafeAny) => {
          switch (props.unit) {
            case "day":
              return fmap(value, (x) => new Date(x).toLocaleDateString()) ?? "";
            case "hour":
              return fmap(value, (x) => new Date(x).toLocaleTimeString()) ?? "";
          }
        },
        scale: () => "",
      },
    }),
    [props.unit],
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<SeriesItem>[] => [
      {
        getValue: (datum) => datum.secondary,
        scaleType: "linear",
        elementType: "bar",
        min: 0,
        max: Math.max(...props.data.map((x) => x.totalInvocations)) + 1,
        stacked: true,
      },
    ],
    [props.data],
  );

  return (
    <Box h={16} mb={-3} ml={-7} sx={{ ".domainAndTicks": { display: "none" } }}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
          secondaryCursor: false,
          getDatumStyle: (x) => {
            return {
              rectangle: {
                fill: x.originalSeries.color,
                border: "1px solid var(--chakra-colors-gray-600)",
              },
            };
          },
        }}
      />
    </Box>
  );
}
