import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { BodyOf } from "../../../shared/api";
import AddBoxOutlineIcon from "../../../shared/icons/AddBoxOutlineIcon";
import { WorkflowTaskClusterId } from "../../../shared/schema/schema";
import { useApi } from "../../../shared/utils/use-api";
import { zForm } from "../cluster.const";
import useClusterForm from "../hooks/useClusterForm";
import CluterFormControls from "./ClusterFormControls";
import { WorkflowDataField } from "../../../shared/schemas/workflows";

type Props = {
  disclosure: ReturnType<typeof useDisclosure>;
};

export default function CreateClusterModal(props: Props) {
  const toast = useToast();
  const { api, queries } = useApi();
  const queryClient = useQueryClient();

  const form = useClusterForm({
    defaultValues: {
      id: "",
      input: [],
      name: "",
      threshold: undefined,
    },
  });

  const mutation = useMutation({
    mutationFn: (body: BodyOf<"post", "./workflow_task_clusters">) => {
      return api.post("./workflow_task_clusters", { body });
    },
    onSuccess: () => {
      toast({ position: "top", description: `Cluster created`, status: "success" });
      queryClient.invalidateQueries(queries.clusters().queryKey);
      props.disclosure.onClose();
    },
    onError: (error) => {
      toast({ position: "top", description: `${error}`, status: "error" });
    },
  });

  const handleSuccess = (data: z.infer<typeof zForm>) => {
    mutation.mutate({
      id: WorkflowTaskClusterId.parse(data.id),
      input: data.input as [string, WorkflowDataField][],
      name: data.name,
      threshold: data.threshold ?? null,
    });
  };

  return (
    <Modal {...props.disclosure} isCentered={true}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={form.handleSubmit(handleSuccess, console.log)}>
        <ModalHeader>New Cluster</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CluterFormControls form={form} mode="create" />
        </ModalBody>
        <ModalFooter gap={3}>
          <Button variant="ghost" onClick={props.disclosure.onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" type="submit">
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function CreateClusterModalButton() {
  const disclosure = useDisclosure();
  return (
    <>
      <Button
        leftIcon={<AddBoxOutlineIcon />}
        ms="auto"
        variant="outline"
        onClick={disclosure.onOpen}
      >
        New Cluster
      </Button>
      <CreateClusterModal disclosure={disclosure} />
    </>
  );
}
