import { createBrowserRouter, RouteObject } from "react-router-dom";
import ClustersRoute from "../pages/Clusters/ClustersRoute";
import DashboardRoute from "../pages/Dashboard/DashboardPage";
import EditorRoute from "../pages/Editor/EditorRoute";
import LoginRoute from "../pages/Login/LoginRoute";
import TagsRoute from "../pages/Tags/TagsRoute";
import WorkflowRoute from "../pages/Workflow/WorkflowRoute";
import WorkflowInstanceRoute from "../pages/WorkflowInstance/WorkflowInstanceRoute";
import WorkflowsRoute from "../pages/Workflows/WorkflowsRoute";
import AuthGuard from "./components/AuthGuard";

const routes = [
  { path: "/" as const, element: <AuthGuard component={() => <DashboardRoute />} /> },
  { path: "/clusters" as const, element: <AuthGuard component={() => <ClustersRoute />} /> },
  { path: "/tags" as const, element: <AuthGuard component={() => <TagsRoute />} /> },
  { path: "/workflows" as const, element: <AuthGuard component={() => <WorkflowsRoute />} /> },
  { path: "/login" as const, element: <LoginRoute /> },
  {
    path: "/editor/:workflowId?" as const,
    element: <AuthGuard component={() => <EditorRoute />} />,
  },
  {
    path: "/workflows/:workflowDefinitionId/instances/:workflowInstanceId" as const,
    element: <AuthGuard component={() => <WorkflowInstanceRoute />} />,
  },
  {
    path: "/workflows/:correlationKey" as const,
    element: <AuthGuard component={() => <WorkflowRoute />} />,
  },
] satisfies RouteObject[];

export type RoutePath = (typeof routes)[number]["path"];

export const router = createBrowserRouter(routes);
