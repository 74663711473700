import { Flex } from "@chakra-ui/react";
import {
  Formatter,
  NodeReferenceQuery,
  WorkflowChildReferenceBinding,
  WorkflowChildReferenceBindings,
  WorkflowDataField,
  WorkflowEntity,
} from "../../../../../../shared/schemas/workflows";
import { sanitizeTestId } from "../../../../../../shared/utils/string.utils";
import {
  InputPath,
  isPathAvailable as getPathAvailability,
  hasAvailablePath,
  isBindEmpty,
} from "../../../../utils/workflow-node.utils";
import BindInputButton from "./BindInputButton";

interface Props {
  name: string;
  input: WorkflowDataField | null;
  bind: WorkflowChildReferenceBinding | null;
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  availableQueries: Map<string, NodeReferenceQuery> | null;
  size?: "sm" | "md" | "lg" | "xs";
  onUpdate: (name: string, bind: WorkflowChildReferenceBinding | null) => void;
}

function BindButton(props: Props) {
  const input = props.input;

  const availablePaths =
    input === null
      ? props.availablePaths
      : props.availablePaths.map((path) => ({
          ...path,
          availability: getPathAvailability({
            input: input,
            path: path,
            availableQueries: props.availableQueries,
          }),
        }));

  const handleChangeField = (field: WorkflowChildReferenceBinding) => {
    if (field.type === "path") {
      return;
    }

    props.onUpdate(props.name, field);
  };

  const handleChoosePath = (path: InputPath | null) => {
    props.onUpdate(props.name, {
      type: "path",
      path: path,
    });
  };

  const handleChangeFormatter = (formatter: Formatter) => {
    if (props.bind?.type !== "path") {
      return;
    }

    props.onUpdate(props.name, {
      ...props.bind,
      formatter: formatter,
    });
  };

  const handleRemoveFormatter = () => {
    if (props.bind?.type !== "path") {
      return;
    }

    props.onUpdate(props.name, {
      ...props.bind,
      formatter: undefined,
    });
  };

  return (
    <Flex direction="row" gap="1">
      {getShouldHideSetButton(props.bind, props.input) ? (
        <BindInputButton
          availablePaths={availablePaths}
          data-testid={`bind-path-${sanitizeTestId(props.name)}`}
          entityOptions={props.entityOptions}
          formatter={props.bind.formatter ?? null}
          selectedPath={props.bind.path}
          size={props.size}
          type="path"
          onChangeFormatter={handleChangeFormatter}
          onChoosePath={handleChoosePath}
          onRemoveFormatter={handleRemoveFormatter}
        />
      ) : (
        <>
          {hasAvailablePath(availablePaths) && isBindEmpty(props.bind) && (
            <BindInputButton
              availablePaths={availablePaths}
              data-testid={`bind-path-${sanitizeTestId(props.name)}`}
              entityOptions={props.entityOptions}
              formatter={null}
              selectedPath={null}
              size={props.size}
              type="path"
              onChangeFormatter={handleChangeFormatter}
              onChoosePath={handleChoosePath}
              onRemoveFormatter={handleRemoveFormatter}
            />
          )}
          {input !== null && (
            <BindInputButton
              data-testid={`bind-field-${sanitizeTestId(props.name)}`}
              input={{ ...input, name: props.name }}
              name={props.name}
              selectedField={props.bind}
              size={props.size}
              type="field"
              onChangeField={handleChangeField}
            />
          )}
        </>
      )}
    </Flex>
  );
}

function getShouldHideSetButton(
  bind: WorkflowChildReferenceBinding | null,
  input: WorkflowDataField | null,
): bind is WorkflowChildReferenceBindings["Path"] {
  if (bind?.type !== "path") {
    return false;
  }

  if (input?.type === "option") {
    return false;
  }

  const whitelistEntities = ["Slack Channel"];

  if (input?.type === "entity" && whitelistEntities.includes(input.entity)) {
    return false;
  }

  return true;
}

export default BindButton;
