import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { BodyOf } from "../../../shared/api";
import AddBoxOutlineIcon from "../../../shared/icons/AddBoxOutlineIcon";
import { WorkflowTagId } from "../../../shared/schema/schema";
import { WorkflowDataField, WorkflowTag } from "../../../shared/schemas/workflows";
import { useApi } from "../../../shared/utils/use-api";
import useTagForm from "../hooks/useTagForm";
import { zForm } from "../tag.const";
import TagFormControls from "./TagFormControls";

type Props = {
  disclosure: ReturnType<typeof useDisclosure>;
  onSuccess?: (tag: WorkflowTag) => void;
};

export default function CreateTagModal(props: Props) {
  const toast = useToast();
  const { api, queries } = useApi();
  const queryClient = useQueryClient();

  const form = useTagForm({
    defaultValues: {
      id: "",
      input: [],
      name: "",
    },
  });

  const mutation = useMutation({
    mutationFn: (body: BodyOf<"post", "./workflow_tags">) => {
      return api.post("./workflow_tags", { body });
    },
    onSuccess: (data) => {
      toast({ position: "top", description: `Tag created`, status: "success" });
      queryClient.invalidateQueries(queries.tags().queryKey);
      props.disclosure.onClose();
      props.onSuccess?.(data);
    },
    onError: (error) => {
      toast({ position: "top", description: `${error}`, status: "error" });
    },
  });

  const handleSuccess = (data: z.infer<typeof zForm>) => {
    mutation.mutate({
      id: WorkflowTagId.parse(data.id),
      input: data.input as [string, WorkflowDataField][],
      name: data.name,
    });
  };

  return (
    <Modal {...props.disclosure} isCentered={true}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={form.handleSubmit(handleSuccess, console.log)}>
        <ModalHeader>New Tag</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TagFormControls form={form} mode="create" />
        </ModalBody>
        <ModalFooter gap={3}>
          <Button variant="ghost" onClick={props.disclosure.onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" type="submit">
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function CreateTagModalButton() {
  const disclosure = useDisclosure();
  return (
    <>
      <Button
        leftIcon={<AddBoxOutlineIcon />}
        ms="auto"
        variant="outline"
        onClick={disclosure.onOpen}
      >
        New Tag
      </Button>
      <CreateTagModal disclosure={disclosure} />
    </>
  );
}
