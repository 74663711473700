import * as GraphQL from "graphql";
import { revivers } from "./schema/schema-revivers";
import graphqlSchema from "./schema/schema.graphql?raw";
import { assertDefined, isDefined } from "./utils/general.utils";

const schema = GraphQL.buildSchema(graphqlSchema);

// don't learn from me. This shouldn't be necessary in your day-to-day work.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnsafeAny = any;

export function reviveGraphQLResponse<T extends object>(node: GraphQL.DocumentNode, value: T): T {
  const newValue: UnsafeAny = structuredClone(value);

  for (const definition of node.definitions) {
    if (definition.kind !== GraphQL.Kind.OPERATION_DEFINITION) {
      continue;
    }

    reviveGraphQLSelectionSet({
      schema,
      value: newValue,
      selections: definition.selectionSet.selections,
      parent: schema.getType("Query") as GraphQL.GraphQLObjectType,
    });
  }

  return newValue;
}

function reviveGraphQLSelectionSet(params: {
  schema: GraphQL.GraphQLSchema;
  value: UnsafeAny;
  parent: GraphQL.GraphQLObjectType;
  selections: readonly GraphQL.SelectionNode[];
}) {
  if (Array.isArray(params.value)) {
    for (const item of params.value) {
      reviveGraphQLSelectionSet({
        schema: params.schema,
        value: item,
        parent: params.parent,
        selections: params.selections,
      });
    }
    return;
  }

  for (const selection of params.selections) {
    if (selection.kind !== GraphQL.Kind.FIELD) {
      continue;
    }

    const fieldName = selection.name.value;

    if (params.value === null || !isDefined(params.value[fieldName])) {
      continue;
    }

    const parentFields = params.parent.getFields();
    const parentRawType = assertDefined(
      parentFields[fieldName]?.type,
      `Missing type for ${fieldName}`,
    );
    const parentTypeName = parentRawType.toString().replace(/[[\]!]/g, "");
    const parentType = schema.getType(parentTypeName) as GraphQL.GraphQLObjectType;

    if (selection.selectionSet) {
      reviveGraphQLSelectionSet({
        schema: params.schema,
        value: params.value[fieldName],
        parent: parentType,
        selections: selection.selectionSet.selections,
      });
      continue;
    }

    const field = assertDefined(parentFields[fieldName], `Missing field ${fieldName}`);
    const fieldTypeName = field.type.toString().replace(/[[\]!]/g, "");

    if (`JSJoda.${fieldTypeName}` in revivers) {
      const reviver = assertDefined(
        revivers[`JSJoda.${fieldTypeName}`],
        `Missing reviver for ${fieldTypeName}`,
      );
      params.value[fieldName] = reviver(params.value[fieldName]);
    }
  }

  return;
}
