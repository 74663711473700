import React from "react";
import logRocketUtils from "../utils/log-rocket.utils";
import useAuth from "../utils/use-auth";

export default function LogRocketAnalyics() {
  const { authData } = useAuth();

  React.useEffect(() => {
    if (authData.type === "guest") {
      return;
    }

    logRocketUtils.setAgencyMemberSession(authData.userInfo.agencyMember.id, {
      name: `${authData.userInfo.agencyMember.firstName} ${authData.userInfo.agencyMember.lastName}`,
      email: authData.userInfo.agencyMember.email ?? "",
      timezone: authData.userInfo.agencyMember.timezone ?? "",
    });
  }, [authData]);

  return <></>;
}
