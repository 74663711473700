import { useContext } from "react";
import { TemporalState } from "zundo";
import { useStore } from "zustand";
import { WorkflowContext } from "../components/WorkflowProvider";
import assert from "../utils/assert";
import { WorkflowState } from "../utils/create-workflow-store";

export default function useWorkflowTemporalStore<T>(
  selector: (state: TemporalState<WorkflowState>) => T,
): T {
  const store = useContext(WorkflowContext);

  assert(store, "Missing WorkflowContext.Provider in the tree");

  return useStore(store.temporal, selector);
}
