import { Flex } from "@chakra-ui/react";
import React from "react";
import Header from "../../shared/components/Header";
import useSelectedNode from "../../shared/hooks/useSelectedNode";
import { WorkflowSkillId } from "../../shared/schema/schema";
import {
  HumanTaskTemplateDefinition,
  Task,
  WorkflowDefinition,
  WorkflowEntity,
  WorkflowEventTriggerOption,
  WorkflowInstanceExecutionResult,
  WorkflowJobTriggerOption,
  WorkflowNode,
  WorkflowTaskQuery,
} from "../../shared/schemas/workflows";
import { noop } from "../../shared/utils/general.utils";
import FlowBoard from "../Editor/components/FlowBoard";
import ExecutedSidebar from "./components/SideBar/ExecutedSidebar";

type Props = {
  workflow: WorkflowDefinition;
  flattenedNodes: Record<string, WorkflowNode>;
  result: WorkflowInstanceExecutionResult;
  triggerOptions: WorkflowEventTriggerOption[];
  triggerJobOptions: WorkflowJobTriggerOption[];
  entityOptions: WorkflowEntity[];
  taskOptions: Task[];
  taskTemplateOptions: HumanTaskTemplateDefinition[];
  queryOptions: WorkflowTaskQuery[];
  skillOptions: WorkflowSkillId[];
};

const WorkflowInstancePage = (props: Props) => {
  const selectedNode = useSelectedNode();

  return (
    <MainSection>
      <Header
        breadcrumbs={[
          {
            name: "Workflows",
            active: false,
            to: "/workflows",
          },
          {
            name: props.workflow.name,
            to: `/workflows/${props.workflow.correlationKey}`,
          },
          {
            name: `v${props.workflow.version}`,
            to: `/workflows/${props.workflow.correlationKey}?id=${props.workflow.id}`,
          },
          {
            name: `${props.result.id}`,
            active: true,
          },
        ]}
      />
      <SectionContentWrapper>
        <ExecutedSidebar
          executionResult={props.result}
          selectedNodeId={selectedNode.selectedNodeId}
          workflow={props.workflow}
        />
        <FlowBoard
          executionResult={props.result}
          flattenedNodes={props.flattenedNodes}
          selectedNodeId={selectedNode.selectedNodeId}
          workflowChildren={props.workflow.children}
          workflowInput={props.workflow.input}
          onAddNode={noop}
          onConnectNodes={noop}
          onRemoveNode={noop}
          onSelectNode={selectedNode.select}
          onUnselectNode={selectedNode.unselect}
          onUpdateNode={noop}
        />
      </SectionContentWrapper>
    </MainSection>
  );
};

const MainSection = (props: React.ComponentProps<typeof Flex>) => {
  return <Flex direction="column" h="full" {...props} />;
};

const SectionContentWrapper = (props: React.ComponentProps<typeof Flex>) => {
  return <Flex direction="row" h="full" overflowY="auto" {...props} />;
};

export default WorkflowInstancePage;
