import { Messages } from "../../shared/api";
import { InferWorkflowFieldTypeWithEffects, WorkflowDataFieldType } from "./workflow.types";

export type GlobalWorkflowHint = {
  type: "prefill-field";
  fieldType: Messages["WorkflowDataFieldType"];
  display: string;
  value: unknown;
};

export function getHintOfGlobalWorkflowField<TField extends WorkflowDataFieldType>(
  field: TField,
  hints: GlobalWorkflowHint[],
): {
  value: InferWorkflowFieldTypeWithEffects<TField>;
  display: string;
} | null {
  const hint = hints.find((hint) => {
    if ((hint.fieldType.array ?? false) !== (field.array ?? false)) {
      return false;
    }

    switch (field.type) {
      case "entity":
        return hint.fieldType.type === "entity" && hint.fieldType.entity === field.entity;
      case "date":
      case "datetime":
      case "time":
      case "boolean":
      case "file":
      case "number":
      case "option":
      case "text":
      case "textarea":
        return hint.fieldType.type === field.type;
    }
  });

  if (hint === undefined) {
    return null;
  }

  return {
    display: hint.display,
    value: hint.value as InferWorkflowFieldTypeWithEffects<TField>,
  };
}
