import { Box, Button, Divider, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { isAxiosError } from "axios";
import { fmap } from "../utils/general.utils";
import logRocketUtils from "../utils/log-rocket.utils";

type Props = {
  title?: string;
  error: unknown;
  onRetry?: () => void;
};

export default function InternalErrorBox(props: Props) {
  const message = isAxiosError(props.error)
    ? `${props.error.message}\n\n${props.error.stack}`
    : String(props.error);

  return (
    <Stack bg="red.50" color="red.600" fontSize="sm" gap={2} p={5} rounded="xl">
      <Flex align="center" gap={4}>
        <Heading size="sm">{props.title ?? "Something went wrong"}</Heading>
        {props.onRetry && (
          <Button colorScheme="red" size="xs" onClick={props.onRetry}>
            Retry
          </Button>
        )}
      </Flex>
      <Divider borderColor="red.100" />
      <Box overflow="auto" whiteSpace="pre-wrap">
        <Text fontFamily="monospace">{message}</Text>
      </Box>

      {fmap(logRocketUtils.getCurrentSessionUrl(), (sessionURL) => (
        <>
          <Divider borderColor="red.100" />
          <Text>LogRocket: {sessionURL}</Text>
        </>
      ))}
    </Stack>
  );
}
