import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Switch,
} from "@chakra-ui/react";
import Cron from "../../../../../../shared/components/Cron";
import Select from "../../../../../../shared/components/Select";
import BaselineMinusIcon from "../../../../../../shared/icons/BaselineMinusIcon";
import CronjobIcon from "../../../../../../shared/icons/CronjobIcon";
import TriggerIcon from "../../../../../../shared/icons/TriggerIcon";
import {
  WorkflowChildReferenceBinding,
  WorkflowDefinition,
  WorkflowEntity,
  WorkflowEventTriggerConditions,
  WorkflowEventTriggerOption,
  WorkflowJobTrigger,
  WorkflowJobTriggerOption,
  WorkflowTrigger,
} from "../../../../../../shared/schemas/workflows";
import { isDefined } from "../../../../../../shared/utils/general.utils";
import { getEventTriggerInputPath } from "../../../../utils/workflow-triggers.utils";
import { InputPath } from "../../../../utils/workflow-node.utils";
import Sidebar from "../../../Sidebar";
import WorkflowComponent from "../../../WorkflowComponent";
import InputBinding from "../../Node/components/InputBinding";
import EventTriggerSelection from "../../shared/components/EventTriggerSelection";
import WorkflowTriggerConditions from "../../shared/components/WorkflowTriggerConditions";

interface Props {
  inputs: WorkflowDefinition["input"];
  trigger: WorkflowTrigger;
  triggerEventOptions: WorkflowEventTriggerOption[];
  triggerJobOptions: WorkflowJobTriggerOption[];
  entityOptions: WorkflowEntity[];
  onUpdate: (trigger: WorkflowTrigger) => void;
  onClickRemove: () => void;
}

function WorkflowTriggerComponent(props: Props) {
  switch (props.trigger.type) {
    case "job": {
      const { trigger } = props;

      const handleEventChange = (selectedEvent: WorkflowJobTriggerOption | undefined) => {
        props.onUpdate({
          ...trigger,
          report:
            selectedEvent == undefined
              ? null
              : selectedEvent.type === "advanced"
              ? {
                  id: selectedEvent.id,
                  type: selectedEvent.type,
                  uniqueBy: null,
                }
              : {
                  id: selectedEvent.id,
                  type: selectedEvent.type,
                  filterBind: {},
                  uniqueBy: null,
                },
        });
      };

      const handleBindFilter = (filterName: string, bind: WorkflowChildReferenceBinding | null) => {
        if (trigger.report?.type !== "standard") {
          return;
        }

        props.onUpdate({
          ...trigger,
          report: {
            ...trigger.report,
            filterBind:
              bind === null
                ? Object.fromEntries(
                    Object.entries(trigger.report.filterBind).filter(([key]) => key !== filterName),
                  )
                : {
                    ...trigger.report.filterBind,
                    [filterName]: bind,
                  },
          },
        });
      };

      const handleChangeCronTime = (time: string) => {
        props.onUpdate({
          ...trigger,
          pattern: { type: "cron", cron: time },
        });
      };

      const handleChangeIntervalMinutes = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onUpdate({
          ...trigger,
          pattern: { type: "interval", minutes: Number(e.target.value) },
        });
      };

      const handleChangePattern = (value: string) => {
        props.onUpdate({
          ...trigger,
          pattern:
            value === "interval"
              ? { type: "interval", minutes: 30 }
              : { type: "cron", cron: "* * * * *" },
        });
      };

      const handleBindChange = (id: string, bind: WorkflowChildReferenceBinding | null) => {
        if (bind === null) {
          props.onUpdate({
            ...trigger,
            bind: Object.fromEntries(Object.entries(trigger.bind).filter(([key]) => key !== id)),
          });
          return;
        }

        if (bind.type !== "path") {
          return;
        }

        props.onUpdate({
          ...trigger,
          bind: {
            ...trigger.bind,
            [id]: bind.path?.key ?? "",
          },
        });
      };

      const handleToggleUnique = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (trigger.report === null) {
          return;
        }

        props.onUpdate({
          ...trigger,
          report: {
            ...trigger.report,
            uniqueBy: e.target.checked ? [] : null,
          },
        });
      };

      const handleUniqueByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (trigger.report === null) {
          return;
        }

        const uniqueBy = trigger.report.uniqueBy;

        if (uniqueBy === null) {
          return;
        }

        if (e.target.checked) {
          props.onUpdate({
            ...trigger,
            report: {
              ...trigger.report,
              uniqueBy: [...uniqueBy, e.target.value],
            },
          });
        } else {
          props.onUpdate({
            ...trigger,
            report: {
              ...trigger.report,
              uniqueBy: uniqueBy.filter((column) => column !== e.target.value),
            },
          });
        }
      };

      const report = props.triggerJobOptions.find((option) => option.id === trigger.report?.id);

      const paths =
        report === undefined
          ? []
          : report.fields.map(
              (field): InputPath => ({
                availability: { isAvailable: true },
                type: "input",
                path: [
                  {
                    id: field.name,
                    label: field.name,
                  },
                ],
                output: field,
                key: field.name,
              }),
            );

      const formattedTriggerPattern = getFormattedTriggerPattern(trigger);

      const triggerReport = trigger.report;

      return (
        <>
          <Sidebar.Row>
            <WorkflowComponent.Title isTruncated flex={1}>
              <CronjobIcon fontSize="xl" />
              <Select
                buttonProps={{ size: "xs" }}
                data-testid="workflow-trigger-report-dropdown"
                label={report?.name ?? "Select report"}
                multiple={false}
                options={props.triggerJobOptions.map((option) => ({
                  label: option.name,
                  value: option,
                }))}
                value={report ?? null}
                width="xl"
                onChange={handleEventChange}
              />
            </WorkflowComponent.Title>
            <Popover placement="bottom-start">
              <PopoverTrigger>
                <Button size="sm">{formattedTriggerPattern}</Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverBody display="flex" flexDirection="column" gap={4}>
                  <FormControl>
                    <FormLabel>Choose pattern</FormLabel>
                    <RadioGroup value={trigger.pattern.type} onChange={handleChangePattern}>
                      <Stack>
                        <Radio value="interval">Interval</Radio>
                        <Radio value="cron">Cron</Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>

                  <Divider />

                  {trigger.pattern.type === "interval" && (
                    <FormControl>
                      <FormLabel>Every</FormLabel>
                      <InputGroup>
                        <Input
                          placeholder="30"
                          value={trigger.pattern.minutes}
                          onChange={handleChangeIntervalMinutes}
                        />
                        <InputRightAddon>minutes</InputRightAddon>
                      </InputGroup>
                    </FormControl>
                  )}

                  {trigger.pattern.type === "cron" && (
                    <Cron value={trigger.pattern.cron} onChange={handleChangeCronTime} />
                  )}
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Sidebar.SquareButton aria-label="remove" onClick={props.onClickRemove}>
              <BaselineMinusIcon />
            </Sidebar.SquareButton>
          </Sidebar.Row>
          <Sidebar.Section>
            <Flex align="start" justify="space-between">
              <Sidebar.Label>Unique</Sidebar.Label>
              <Switch
                data-testid="workflow-trigger-report-unique-switch"
                isChecked={isDefined(trigger.report?.uniqueBy)}
                size="md"
                onChange={handleToggleUnique}
              />
            </Flex>
          </Sidebar.Section>
          <Sidebar.Section>
            {isDefined(trigger.report?.uniqueBy) && (
              <Flex align="start" justify="space-between">
                <Sidebar.Label>Unique By</Sidebar.Label>
                <Flex direction="column" gap="1">
                  {report?.fields.map((field) => (
                    <Sidebar.Label key={field.name}>
                      <Sidebar.Checkbox
                        isChecked={trigger.report?.uniqueBy?.includes(field.name)}
                        value={field.name}
                        onChange={handleUniqueByChange}
                      />
                      {field.name}
                    </Sidebar.Label>
                  ))}
                </Flex>
              </Flex>
            )}
          </Sidebar.Section>
          {triggerReport?.type === "standard" &&
            report?.type === "standard" &&
            report.filters.map((filter, i) => (
              <InputBinding
                key={i}
                availablePaths={paths}
                availableQueries={null}
                bind={triggerReport.filterBind[filter.name] ?? null}
                entityOptions={props.entityOptions}
                input={filter}
                name={filter.name}
                onUpdate={handleBindFilter}
              />
            ))}
          <Sidebar.Section display="flex" flexDirection="column" gap={2} p={4}>
            {[...props.inputs.entries()].map(([id, input]) => {
              const currentPath = paths.find((path) => path.key === trigger.bind[id]) ?? null;

              return (
                <InputBinding
                  key={id}
                  availablePaths={paths}
                  availableQueries={null}
                  bind={{
                    type: "path",
                    path: currentPath,
                  }}
                  entityOptions={props.entityOptions}
                  input={input}
                  name={input.name}
                  onUpdate={(_, bind) => handleBindChange(id, bind)}
                />
              );
            })}
          </Sidebar.Section>
        </>
      );
    }

    case "event": {
      const { trigger } = props;
      const handleBindChange = (id: string, bind: WorkflowChildReferenceBinding | null) => {
        if (bind === null) {
          props.onUpdate({
            ...trigger,
            bind: Object.fromEntries(Object.entries(trigger.bind).filter(([key]) => key !== id)),
          });
          return;
        }

        if (bind.type !== "path") {
          return;
        }

        props.onUpdate({
          ...trigger,
          bind: {
            ...trigger.bind,
            [id]: bind.path?.key ?? "",
          },
        });
      };

      const handleUpdateConditions = (conditions: WorkflowEventTriggerConditions) => {
        props.onUpdate({
          ...trigger,
          conditions,
        });
      };

      const availablePaths = trigger.event === null ? [] : getEventTriggerInputPath(trigger.event);

      return (
        <>
          <Sidebar.Row>
            <WorkflowComponent.Title>
              <TriggerIcon fontSize="xl" />
              <EventTriggerSelection
                trigger={trigger}
                triggerEventOptions={props.triggerEventOptions}
                onUpdate={props.onUpdate}
              />
            </WorkflowComponent.Title>
            <Sidebar.SquareButton aria-label="remove" onClick={props.onClickRemove}>
              <BaselineMinusIcon />
            </Sidebar.SquareButton>
          </Sidebar.Row>
          {trigger.event !== null && (
            <Sidebar.Row>
              <WorkflowComponent.Light>
                <WorkflowTriggerConditions
                  availablePaths={availablePaths}
                  conditions={trigger.conditions}
                  entityOptions={props.entityOptions}
                  eventTriggerOption={trigger.event}
                  onUpdateConditions={handleUpdateConditions}
                />
              </WorkflowComponent.Light>
            </Sidebar.Row>
          )}
          <Sidebar.Section display="flex" flexDirection="column" gap={2} p={4}>
            {[...props.inputs.entries()].map(([id, input]) => {
              const currentPath =
                availablePaths.find((path) => path.key === trigger.bind[id]) ?? null;

              return (
                <InputBinding
                  key={id}
                  availablePaths={availablePaths}
                  availableQueries={null}
                  bind={{
                    type: "path",
                    path: currentPath,
                  }}
                  entityOptions={props.entityOptions}
                  input={input}
                  name={input.name}
                  onUpdate={(_, bind) => handleBindChange(id, bind)}
                />
              );
            })}
          </Sidebar.Section>
        </>
      );
    }
  }
}

function getFormattedTriggerPattern(trigger: WorkflowJobTrigger) {
  switch (trigger.pattern.type) {
    case "interval":
      return `Every ${trigger.pattern.minutes} minutes`;

    case "cron":
      return `Cron: ${trigger.pattern.cron}`;
  }
}

export default WorkflowTriggerComponent;
