import React from "react";
import AddIcon from "../icons/AddIcon";
import DatabaseIcon from "../icons/DatabaseIcon";
import { WorkflowDataField, WorkflowEntity } from "../schemas/workflows";
import Sidebar from "../../pages/Editor/components/Sidebar";
import FancySelection from "../../pages/Editor/components/Sidebars/Node/components/FancySelection";
import FieldIcon from "../../pages/Editor/components/Sidebars/shared/components/FieldIcon";
import { basicFieldTypes } from "../constants/basic-field-types.const";

export function AddFieldSelection(props: {
  entityOptions: WorkflowEntity[];
  "data-testid": string;
  onAdd: (field: WorkflowDataField) => void;
  children?: React.ReactNode;
}) {
  const handleAdd = (fields: WorkflowDataField[]) => {
    const [fieldA, fieldB, ...rest] = fields;

    if (!fieldA) {
      return;
    }

    if (fieldB) {
      props.onAdd({
        type: "union",
        union: [fieldA, fieldB, ...rest],
      });
      return;
    }

    props.onAdd(fieldA);
  };

  return (
    <FancySelection<WorkflowDataField>
      multiple
      options={[
        {
          title: "Basic",
          items: basicFieldTypes.map((fieldType) => {
            return {
              value: { type: fieldType.name },
              title: fieldType.label,
              icon: <FieldIcon field={fieldType} />,
            };
          }),
        },
        {
          title: "Entities",
          items: props.entityOptions.map((entity) => {
            return {
              value: { type: "entity", entity },
              title: entity,
              icon: <DatabaseIcon />,
            };
          }),
        },
      ]}
      selected={[]}
      title="Add field"
      trigger={
        props.children ?? (
          <Sidebar.SquareButton
            aria-label="add field"
            data-testid={props["data-testid"]}
            icon={<AddIcon />}
          />
        )
      }
      onSelect={handleAdd}
    />
  );
}
