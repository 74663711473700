export const operators = [
  "!=",
  "<",
  "<=",
  "=",
  ">",
  ">=",
  "is empty",
  "is not empty",
  "contains",
  "does not contain",
  "ends with",
  "starts with",
] as const;

export const operatorToHumanString: Record<(typeof operators)[number], string> = {
  "!=": "≠",
  "<": "<",
  "<=": "≤",
  "=": "=",
  ">": ">",
  ">=": "≥",
  "is empty": "is empty",
  "is not empty": "is not empty",
  contains: "contains",
  "does not contain": "does not contain",
  "ends with": "ends with",
  "starts with": "starts with",
};
