import { Divider, Flex } from "@chakra-ui/react";
import NodeIcon from "../../../../../shared/components/NodeIcon";
import { WorkflowSkillId } from "../../../../../shared/schema/schema";
import {
  HumanTaskTemplateDefinition,
  NodeReferenceQuery,
  Task,
  WorkflowDefinition,
  WorkflowEntity,
  WorkflowError,
  WorkflowErrorHandlerType,
  WorkflowEventTriggerOption,
  WorkflowNode,
  WorkflowTagReference,
  WorkflowTaskQuery,
  WorkflowTaskTrigger,
} from "../../../../../shared/schemas/workflows";
import createEntity from "../../../utils/create-entity";
import { getAvailablePathsForNode } from "../../../utils/workflow-node.utils";
import Sidebar from "../../Sidebar";
import ChoiceTaskSidebar from "../ChoiceTask/ChoiceTaskSidebar";
import GroupSidebar from "../Group/GroupSidebar";
import HumanTaskSidebar from "../HumanTask/HumanTaskSidebar";
import ResetToTaskSidebar from "../ResetToTask/ResetToTaskSidebar";
import ShortCircuitTaskSidebar from "../ShortCircuitTask/ShortCircuitTaskSidebar";
import SystemTaskSidebar from "../SystemTask/SystemTaskSidebar";
import { TaskTriggersComponentSection } from "../shared/sections";
import NodeTypeSelectionButton from "./components/NodeTypeSelectionButton";

interface Props {
  workflow: WorkflowDefinition;
  flattenedNodes: Record<string, WorkflowNode>;
  id: string;
  node: WorkflowNode;
  taskOptions: Task[];
  taskTemplateOptions: HumanTaskTemplateDefinition[];
  queryOptions: WorkflowTaskQuery[];
  entityOptions: WorkflowEntity[];
  skillOptions: WorkflowSkillId[];
  triggerEventOptions: WorkflowEventTriggerOption[];
  onUpdateMeta: (meta: WorkflowDefinition["meta"]) => void;
  onChangeName: (id: string, name: string) => void;
  onUpdateNode: (id: string, node: WorkflowNode) => void;
  onAddNode: (id: string, node: WorkflowNode) => void;
  onRemoveNode: (id: string) => void;
  onAddQuery: (nodeRefId: string) => void;
  onUpdateQuery: (nodeRefId: string, queryRefId: string, query: NodeReferenceQuery) => void;
  onRemoveQuery: (nodeRefId: string, queryRefId: string) => void;
  onAddTaskTrigger: (nodeRefId: string) => void;
  onUpdateTaskTrigger: (nodeRefId: string, trigger: WorkflowTaskTrigger) => void;
  onRemoveTaskTrigger: (nodeRefId: string) => void;
  onAddErrorHandling: (nodeRefId: string, type: WorkflowErrorHandlerType) => void;
  onUpdateErrorHandling: (nodeRefId: string, error: WorkflowError) => void;
  onRemoveErrorHandling: (nodeRefId: string, error: WorkflowError) => void;
  onError: (error: unknown) => void;
}

function NodeSidebar(props: Props) {
  const entity = createEntity({
    id: props.id,
    node: props.node,
    taskOptions: props.taskOptions,
    taskTemplateOptions: props.taskTemplateOptions,
    workflow: props.workflow,
    onAddNode: props.onAddNode,
    onUpdateNode: props.onUpdateNode,
    onError: props.onError,
  });

  const lastVersionTaskOptions = props.taskOptions.filter(
    (task) => !props.taskOptions.some((t) => t.id === task.id && t.version > task.version),
  );

  return (
    <Sidebar.Root>
      <Flex align="center" gap={3} p={4}>
        <NodeIcon fontSize="2xl" my={2} type={props.node.type} />
        <Flex direction="row" flex={1} justify="space-between">
          <Sidebar.Title
            data-testid="node-sidebar-name-input"
            flex={1}
            value={props.node.meta.name}
            onChange={(e) => props.onChangeName(props.id, e.target.value)}
          />
          <NodeTypeSelectionButton
            node={props.node}
            taskOptions={lastVersionTaskOptions}
            workflow={props.workflow}
            onChoose={entity.change}
          />
        </Flex>
      </Flex>

      <Divider />

      <SidebarBody {...props} entity={entity} />
    </Sidebar.Root>
  );
}

function SidebarBody(
  props: Props & {
    entity: ReturnType<typeof createEntity>;
  },
) {
  const { entity } = props;

  const availablePaths = getAvailablePathsForNode({
    nodeRefId: props.id,
    flattenedNodes: props.flattenedNodes,
    workflowInput: props.workflow.input,
  });

  const handleChangeTags = (tags: WorkflowTagReference[]) => {
    props.onUpdateNode(props.id, {
      ...props.node,
      tags,
    });
  };

  const taskTriggersComponentSection = (
    <TaskTriggersComponentSection
      availablePaths={availablePaths}
      entityOptions={props.entityOptions}
      node={props.node}
      triggerEventOptions={props.triggerEventOptions}
      onAdd={() => props.onAddTaskTrigger(props.id)}
      onRemove={() => props.onRemoveTaskTrigger(props.id)}
      onUpdate={(trigger) => props.onUpdateTaskTrigger(props.id, trigger)}
    />
  );

  switch (props.node.type) {
    case "system-task": {
      const taskRef = props.node;

      const task = props.taskOptions.find(
        (t) => t.id === taskRef.task.id && taskRef.task.version === t.version,
      );

      if (task === undefined) {
        throw new Error(`Task ${taskRef.task.id} not found`);
      }

      return (
        <SystemTaskSidebar
          availablePaths={availablePaths}
          entityOptions={props.entityOptions}
          id={props.id}
          queryOptions={props.queryOptions}
          skillOptions={props.skillOptions}
          tags={props.node.tags}
          task={task}
          taskRef={taskRef}
          taskTemplateOptions={props.taskTemplateOptions}
          taskTriggersComponentSection={taskTriggersComponentSection}
          onAddErrorHandling={props.onAddErrorHandling}
          onAddQuery={props.onAddQuery}
          onChangeTags={handleChangeTags}
          onRemoveErrorHandling={props.onRemoveErrorHandling}
          onRemoveQuery={props.onRemoveQuery}
          onUpdateErrorHandling={props.onUpdateErrorHandling}
          onUpdateMeta={(name, value) => entity.updateTaskMeta(taskRef, name, value)}
          onUpdateQuery={props.onUpdateQuery}
          onUpdateScheduling={(name, value) => entity.updateNodeScheduling(taskRef, name, value)}
          onUpdateTask={props.onUpdateNode}
        />
      );
    }

    case "human-task": {
      const taskRef = props.node;
      return (
        <HumanTaskSidebar
          availablePaths={availablePaths}
          entityOptions={props.entityOptions}
          id={props.id}
          node={props.node}
          queryOptions={props.queryOptions}
          skillOptions={props.skillOptions}
          tags={props.node.tags}
          taskRef={taskRef}
          taskTemplateOptions={props.taskTemplateOptions}
          taskTriggersComponentSection={taskTriggersComponentSection}
          workflow={props.workflow}
          onAddErrorHandling={props.onAddErrorHandling}
          onAddNode={props.onAddNode}
          onAddQuery={props.onAddQuery}
          onChangeTags={handleChangeTags}
          onRemoveErrorHandling={props.onRemoveErrorHandling}
          onRemoveNode={props.onRemoveNode}
          onRemoveQuery={props.onRemoveQuery}
          onUpdateErrorHandling={props.onUpdateErrorHandling}
          onUpdateMeta={(name, value) => entity.updateTaskMeta(taskRef, name, value)}
          onUpdateQuery={props.onUpdateQuery}
          onUpdateScheduling={(name, value) => entity.updateNodeScheduling(taskRef, name, value)}
          onUpdateTask={props.onUpdateNode}
        />
      );
    }

    case "choice": {
      const choice = props.node;
      return (
        <ChoiceTaskSidebar
          availablePaths={availablePaths}
          entityOptions={props.entityOptions}
          id={props.id}
          queryOptions={props.queryOptions}
          tags={props.node.tags}
          taskRef={choice}
          taskTriggersComponentSection={taskTriggersComponentSection}
          workflow={props.workflow}
          onAddNode={props.onAddNode}
          onAddQuery={props.onAddQuery}
          onChangeTags={handleChangeTags}
          onRemoveNode={props.onRemoveNode}
          onRemoveQuery={props.onRemoveQuery}
          onUpdateMeta={(name, value) => entity.updateChoiceMeta(choice, name, value)}
          onUpdateQuery={props.onUpdateQuery}
          onUpdateTask={props.onUpdateNode}
        />
      );
    }

    case "short-circuit-task": {
      const shortCircuit = props.node;
      return (
        <ShortCircuitTaskSidebar
          availablePaths={availablePaths}
          entityOptions={props.entityOptions}
          id={props.id}
          queryOptions={props.queryOptions}
          tags={props.node.tags}
          taskRef={shortCircuit}
          taskTriggersComponentSection={taskTriggersComponentSection}
          workflow={props.workflow}
          onAddNode={props.onAddNode}
          onAddQuery={props.onAddQuery}
          onChangeTags={handleChangeTags}
          onRemoveNode={props.onRemoveNode}
          onRemoveQuery={props.onRemoveQuery}
          onUpdateMeta={(name, value) => entity.updateBasicMeta(shortCircuit, name, value)}
          onUpdateQuery={props.onUpdateQuery}
          onUpdateTask={props.onUpdateNode}
        />
      );
    }

    case "reset-to-task": {
      const resetTask = props.node;
      return (
        <ResetToTaskSidebar
          availablePaths={availablePaths}
          entityOptions={props.entityOptions}
          id={props.id}
          queryOptions={props.queryOptions}
          tags={props.node.tags}
          taskRef={resetTask}
          taskTriggersComponentSection={taskTriggersComponentSection}
          workflow={props.workflow}
          onAddNode={props.onAddNode}
          onAddQuery={props.onAddQuery}
          onChangeTags={handleChangeTags}
          onRemoveNode={props.onRemoveNode}
          onRemoveQuery={props.onRemoveQuery}
          onUpdateMeta={(name, value) => entity.updateBasicMeta(resetTask, name, value)}
          onUpdateQuery={props.onUpdateQuery}
          onUpdateTask={props.onUpdateNode}
        />
      );
    }

    case "group": {
      const group = props.node;
      return (
        <GroupSidebar
          availablePaths={availablePaths}
          entityOptions={props.entityOptions}
          id={props.id}
          node={group}
          taskTriggersComponentSection={taskTriggersComponentSection}
          workflow={props.workflow}
          onAddNode={props.onAddNode}
          onRemoveNode={props.onRemoveNode}
          onUpdateGroup={props.onUpdateNode}
          onUpdateMeta={(name, value) => entity.updateGroupMeta(group, name, value)}
          onUpdateScheduling={(name, value) => entity.updateNodeScheduling(group, name, value)}
        />
      );
    }

    case "workflow":
      return null;
  }
}

export default NodeSidebar;
