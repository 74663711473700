import { Flex } from "@chakra-ui/react";
import { NamedOutput } from "../../../../../../shared/schemas/workflows";
import Sidebar from "../../../Sidebar";

interface Props {
  name: string;
  outputRow: NamedOutput;
}

function TaskOutputComponent(props: Props) {
  return (
    <Sidebar.Row>
      <Sidebar.Label>{props.name}</Sidebar.Label>
      <Flex direction="column">
        {Object.entries(props.outputRow.output).map(([resultId, output]) => (
          <Flex key={resultId} gap={8}>
            <Sidebar.Label>{output.type}</Sidebar.Label>
            {output.type === "entity" && <Sidebar.Label>{output.entity}</Sidebar.Label>}
            <Sidebar.Label>{output.name}</Sidebar.Label>
          </Flex>
        ))}
      </Flex>
    </Sidebar.Row>
  );
}

export default TaskOutputComponent;
