import React from "react";
import { Messages } from "../api";
import { UserId } from "../schema/schema";
import { AuthData } from "../schemas/auth";

export interface AuthInfo {
  userId: UserId;
  agency: Messages["Agency"];
  agencyMember: Messages["AgencyMember"];
  permissions: Messages["AgencyRolePermissions"]["permissions"][];
  authToken: string;
  refreshToken: string;
}

export const AuthDataContext = React.createContext<AuthData>({} as AuthData);

export const AuthDataProvider = (props: { value: AuthData; children: React.ReactNode }) => {
  return <AuthDataContext.Provider value={props.value}>{props.children}</AuthDataContext.Provider>;
};
