import { z } from "zod";
import usePathParams from "../../shared/hooks/usePathParams";
import useSearchParams from "../../shared/hooks/useSearchParams";
import { WorkflowDefinitionCorrelationKey, WorkflowDefinitionId } from "../../shared/schema/schema";
import { zPathParamIntId } from "../../shared/schemas/path-params";
import WorkflowPage from "./WorkflowPage";

const zPathParams = z.object({
  correlationKey: WorkflowDefinitionCorrelationKey,
});

const zSearchParams = z.object({
  id: zPathParamIntId(WorkflowDefinitionId).optional(),
});

const WorkflowRoute = () => {
  const { correlationKey } = usePathParams(zPathParams);
  const [{ id }] = useSearchParams(zSearchParams);

  return <WorkflowPage correlationKey={correlationKey} id={id} />;
};

export default WorkflowRoute;
