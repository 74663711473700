import { Container, Flex, Text } from "@chakra-ui/react";
import {
  NodeReferenceQuery,
  WorkflowChildReferenceBinding,
  WorkflowDataField,
  WorkflowEntity,
} from "../../../../../../shared/schemas/workflows";
import { InputPath } from "../../../../utils/workflow-node.utils";
import Sidebar from "../../../Sidebar";
import BindButton from "./BindButton";

interface Props {
  name: string;
  input: WorkflowDataField;
  bind: WorkflowChildReferenceBinding | null;
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  availableQueries: Map<string, NodeReferenceQuery> | null;
  boldLabel?: boolean;
  ["data-testid"]?: string;
  onUpdate: (name: string, bind: WorkflowChildReferenceBinding | null) => void;
}

function InputBinding(props: Props) {
  return (
    <Sidebar.Row data-testid={props["data-testid"]}>
      <Container border={1} flexDirection="column" flexGrow={1} padding={0}>
        <Flex align="center" gap="4" justifyContent="space-between">
          <Sidebar.Label fontWeight={props.boldLabel ? "medium" : "normal"}>
            {!props.input.optional && (
              <Text as="span" color="red.400">
                *
              </Text>
            )}
            {props.name}
          </Sidebar.Label>
          <BindButton
            availablePaths={props.availablePaths}
            availableQueries={props.availableQueries}
            bind={props.bind}
            entityOptions={props.entityOptions}
            input={props.input}
            name={props.name}
            onUpdate={props.onUpdate}
          />
        </Flex>
      </Container>
    </Sidebar.Row>
  );
}

export default InputBinding;
