import { QuestionIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Skeleton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { AddFieldSelection } from "../../../shared/components/AddFieldSelection";
import { WorkflowDataField } from "../../../shared/schemas/workflows";
import { useApi } from "../../../shared/utils/use-api";
import { zForm } from "../cluster.const";
import { getNamedWorkflowDataField } from "../cluster.utils";

type Props = {
  mode: "create" | "edit";
  form: UseFormReturn<z.infer<typeof zForm>>;
};

export default function CluterFormControls(props: Props) {
  const { form } = props;

  return (
    <Flex direction="column" gap={6}>
      <FormControl isInvalid={form.formState.errors.name !== undefined}>
        <FormLabel>Name</FormLabel>
        <Input placeholder="Cluster Name" {...form.register("name")} />
        {form.formState.errors.name === undefined && (
          <FormHelperText>Enter a readable name for the cluster</FormHelperText>
        )}
        <FormErrorMessage>{form.formState.errors.name?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={form.formState.errors.id !== undefined}>
        <FormLabel>Cluster ID</FormLabel>
        <Input
          disabled={props.mode === "edit"}
          fontFamily={"mono"}
          placeholder="cluster-name"
          {...form.register("id", {
            onChange: (e) =>
              form.setValue("id", e.target.value.toLowerCase().replace(/[^a-z0-9-{}]/g, "-")),
          })}
        />

        {props.mode === "create" && form.formState.errors.id === undefined && (
          <FormHelperText>
            For example:{" "}
            <Text as="span" color="blue.600" fontFamily="mono">{`call-patient-{id}-{team}`}</Text>
          </FormHelperText>
        )}
        <FormErrorMessage>{form.formState.errors.id?.message}</FormErrorMessage>
      </FormControl>

      <Flex direction="column" gap={2}>
        {form.watch("input").map(([name, field], i) => (
          <Box key={name}>
            <HStack justify="space-between">
              <HStack>
                <Text color="gray.400" fontWeight="normal">
                  input:
                </Text>
                <Text fontWeight="medium">{name}</Text>
              </HStack>
              <CluterFormField
                fieldTypeName={
                  (field as WorkflowDataField | null) === null
                    ? "Choose type"
                    : getNamedWorkflowDataField(field as WorkflowDataField)
                }
                hasError={form.formState.errors.input?.[i] !== undefined}
                isEditMode={props.mode === "edit"}
                name={name}
                onAddField={(field) => form.setValue(`input.${i}`, [name, field])}
              />
            </HStack>
          </Box>
        ))}
      </Flex>

      <FormControl flex={1} isInvalid={form.formState.errors.threshold !== undefined}>
        <Tooltip label="The maximum number of tasks that can be clustered together">
          <FormLabel alignItems="center" display="flex" gap={2}>
            <Text>Threshold</Text>
            <QuestionIcon color="gray" h={4} w={4} />
          </FormLabel>
        </Tooltip>
        <Input {...form.register("threshold")} type="number" />
        <FormErrorMessage>{form.formState.errors.threshold?.message}</FormErrorMessage>
      </FormControl>
    </Flex>
  );
}

function CluterFormField(props: {
  name: string;
  fieldTypeName: string;
  hasError: boolean;
  isEditMode: boolean;
  onAddField: (field: WorkflowDataField) => void;
}) {
  const { queries } = useApi();
  const entityOptionsQuery = useQuery(queries.entityOptions());

  switch (entityOptionsQuery.status) {
    case "loading":
      return <Skeleton height="20px" />;

    case "error":
      return <FormHelperText>Failed to load entity options</FormHelperText>;

    case "success":
      return (
        <AddFieldSelection
          data-testid="add-field"
          entityOptions={entityOptionsQuery.data.data}
          onAdd={props.onAddField}
        >
          <Button
            colorScheme={props.hasError ? "red" : "gray"}
            data-input-key={props.name}
            isDisabled={props.isEditMode}
            maxW="64"
            size="sm"
            variant="outline"
          >
            <Text isTruncated>{props.fieldTypeName}</Text>
          </Button>
        </AddFieldSelection>
      );
  }
}
