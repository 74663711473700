import { Box, Button, Flex, FormControl, Heading, Input } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Header from "../../shared/components/Header";
import Page from "../../shared/components/Page";
import Select from "../../shared/components/Select";
import AddBoxOutlineIcon from "../../shared/icons/AddBoxOutlineIcon";
import { ListedWorkflow, ListedWorkflowFilters, Owner } from "../../shared/schemas/workflows";
import WorkflowsTable from "./components/WorkflowsTable";
import { Messages } from "../../shared/api";

type Props = {
  workflows: Messages["DesignerListedWorkflowDefinition"][];
  owners: Owner[];
  filters: ListedWorkflowFilters;
  onChangeFilters: (filters: Partial<ListedWorkflowFilters>) => void;
};

const WorkflowsPage = (props: Props) => {
  const workflows = props.workflows.filter((workflow) => applyFilters(workflow, props.filters));

  return (
    <Page>
      <Header breadcrumbs={[{ name: "Workflows", active: true }]} />
      <Box p={16}>
        <Heading mb={8}>Workflows</Heading>
        <Flex mb={4}>
          <Flex flex={1} gap={2}>
            <FormControl maxW="sm">
              <Input
                placeholder="Search workflow..."
                value={props.filters.name}
                onChange={(e) => props.onChangeFilters({ name: e.target.value })}
              />
            </FormControl>
            <Select
              multiple
              data-testid="owners-select"
              label="Owners"
              options={props.owners.map((owner) => ({
                label: owner.name,
                value: owner.id,
              }))}
              value={props.filters.owners.length === 0 ? null : props.filters.owners}
              onChange={(selected) => props.onChangeFilters({ owners: selected ?? [] })}
            />
            <Select
              allowUnselect={false}
              data-testid="publication-status-select"
              label="Publication status"
              multiple={false}
              options={
                [
                  { label: "All", value: null },
                  { label: "Published", value: "PUBLISHED" },
                  { label: "Unpublished", value: "UNPUBLISHED" },
                ] as const
              }
              value={props.filters.publicationStatus}
              onChange={(publicationStatus) =>
                props.onChangeFilters({ publicationStatus: publicationStatus ?? null })
              }
            />
          </Flex>
          <Button
            as={Link}
            leftIcon={<AddBoxOutlineIcon />}
            ms="auto"
            to={{ pathname: "/editor" }}
            variant="outline"
          >
            New Workflow
          </Button>
        </Flex>
        <WorkflowsTable workflows={workflows} />
      </Box>
    </Page>
  );
};

function applyFilters(workflow: ListedWorkflow, filters: ListedWorkflowFilters) {
  if (filters.name !== "" && !workflow.name.toLowerCase().includes(filters.name.toLowerCase())) {
    return false;
  }

  if (filters.owners.length > 0 && !filters.owners.includes(workflow.meta.owner.id)) {
    return false;
  }

  if (filters.publicationStatus !== null) {
    return (
      (filters.publicationStatus === "PUBLISHED" && workflow.lastPublishedVersion !== null) ||
      (filters.publicationStatus === "UNPUBLISHED" && workflow.lastPublishedVersion === null)
    );
  }

  return true;
}

export default WorkflowsPage;
