export interface JsonObject {
  [key: string]: string | number | boolean | JsonArray | JsonObject | null | undefined;
}

type JsonArray = Array<string | number | boolean | JsonObject | JsonArray | null | undefined>;

export function isPlainObject(obj: unknown): obj is Record<string, unknown> {
  return (
    typeof obj === "object" &&
    obj !== null &&
    obj.constructor === Object &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
}

export function cleanObject(object: JsonObject | JsonArray): JsonObject | JsonArray {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === "object") {
      cleanObject(v);
    }
    if ((v && typeof v === "object" && !Object.keys(v).length) || v === undefined) {
      if (Array.isArray(object)) {
        object.splice(+k, 1);
      } else {
        delete object[k];
      }
    }
  });
  return object;
}
