import { chakra, Flex } from "@chakra-ui/react";

const Page = chakra(Flex, {
  baseStyle: {
    flexDirection: "column",
    height: "full",
  },
});

Page.displayName = "Page";

export default Page;
