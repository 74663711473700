import { StyleProps, TableRowProps } from "@chakra-ui/react";
import {
  AccessorKeyColumnDef,
  Column,
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
} from "@tanstack/react-table";
import { UnsafeAny } from "../../utils/types";

export const DATA_TABLE_PAGE_SIZES = [10, 25, 50, 100, 250, 500] as const;
export const DATA_TABLE_DEFAULT_PAGE_SIZE = DATA_TABLE_PAGE_SIZES[1];

export function getClickableRowProps<TData>(params: {
  row: Row<TData>;
  onClick?: (e: React.MouseEvent<HTMLTableRowElement>, row: Row<TData>) => void;
}): TableRowProps {
  if (!params.onClick) {
    return {};
  }

  return {
    cursor: "pointer",
    _hover: {
      bg: "blue.50",
    },
    onClick: (e: React.MouseEvent<HTMLTableRowElement>) => params.onClick?.(e, params.row),
  };
}

export function getStickyProps<TData, TValue>(
  column: Column<TData, TValue>,
): StyleProps | undefined {
  if (column.columnDef.meta?.sticky === undefined) {
    return undefined;
  }

  return {
    position: "sticky",
    [column.columnDef.meta.sticky]: 0,
    zIndex: 1,
    bg: "inherit",
  };
}

export function mapColumnFiltersState<TData>(
  columnFilters: ColumnFiltersState,
  columns: ColumnDef<TData, UnsafeAny>[],
): Record<string, unknown> {
  const mapped: Record<string, unknown> = {};

  for (const filter of columnFilters) {
    const column = getColumnByPrimary(filter.id, columns);

    if (column?.meta?.gqlFilterKey === undefined) {
      continue;
    }

    mapped[column.meta.gqlFilterKey] = (() => {
      const parsed = (() => {
        switch (column.meta.gqlFilterType) {
          case "number":
            return Number(filter.value);
          case undefined:
            return filter.value === "" ? null : filter.value;
        }
      })();

      switch (column.meta.gqlFilterMap) {
        case "eq":
          return { eq: parsed };
        case "in":
          return { in: [parsed] };
        case undefined:
          return parsed;
      }
    })();
  }

  return mapped;
}

export function mapSortingState<TData>(
  sorting: SortingState,
  columns: ColumnDef<TData, UnsafeAny>[],
): { field: string; sort: "asc" | "desc" }[] {
  return sorting.map((x) => ({
    field: getColumnByPrimary(x.id, columns)?.meta?.gqlSortKey ?? x.id,
    sort: x.desc ? "desc" : "asc",
  }));
}

export function getColumnByPrimary<TData>(
  primaryKey: string,
  columns: ColumnDef<TData, UnsafeAny>[],
): ColumnDef<TData, UnsafeAny> | undefined {
  return columns.find((column) => getColumnPrimaryKey(column) === primaryKey);
}

function isAccessorKeyColumnDef<TData, TValue>(
  columnDef: ColumnDef<TData, TValue>,
): columnDef is AccessorKeyColumnDef<TData, TValue> {
  return "accessorKey" in columnDef;
}

export function getColumnPrimaryKey<TData, TValue>(
  columnDef: ColumnDef<TData, TValue>,
): string | undefined {
  if (isAccessorKeyColumnDef(columnDef)) {
    return String(columnDef.accessorKey);
  }

  return columnDef.id;
}
