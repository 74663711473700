import { CheckIcon, ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Progress,
  Skeleton,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  forwardRef,
  useDisclosure,
} from "@chakra-ui/react";
import { LocalDate, LocalDateTime, LocalTime } from "@js-joda/core";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Messages } from "../../../shared/api";
import RangeDatePicker from "../../../shared/components/DatePicker/RangeDatePicker";
import QueryResolver from "../../../shared/components/QueryResolver";
import { RequiredAst } from "../../../shared/components/RequiredAst";
import Select from "../../../shared/components/Select";
import assert from "../../../shared/utils/assert";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { capitalize, isKeyOf, toArray, toHumanCase } from "../../../shared/utils/general.utils";
import { useApi } from "../../../shared/utils/use-api";
import { BaseWorkflowFormControl, WorkflowFormControlHint } from "./WorkflowForm";

type Props = {
  layouts: Messages["WorkflowEntityLayouts"];
  hint: { value: unknown; display: string } | null;
  input: Messages["WorkflowEntityField"] & { name: string };
  value: (unknown | null)[];
  errors: string[];
  isRequired: boolean;
  defaultFilters?: Record<PropertyKey, unknown>;
  hardFilters?: Record<PropertyKey, unknown>;
  showLabel?: boolean;
  renderUnselected?: string;
  renderHint?: boolean;
  size?: ButtonProps["size"];
  allowUnselect?: boolean;
  ["aria-invalid"]?: boolean;
  onChangeField: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  onChange: (value: (unknown | null)[]) => void;
};

function WorkflowEntityFormControl(props: Props) {
  const isInvalid = props["aria-invalid"] === true && props.errors.length > 0;

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel>
        {props.input.name}
        {props.isRequired && <RequiredAst />}:
      </FormLabel>
      <WorkflowEntitySelectWithTemplates aria-invalid={isInvalid} {...props} />
    </FormControl>
  );
}

function WorkflowEntitySelectWithTemplates(props: Props & { ["aria-invalid"]?: boolean }) {
  const layout:
    | Messages["WorkflowEntityListLayout"]
    | Messages["WorkflowEntityTableLayout"]
    | null = isKeyOf(props.input.entity, props.layouts) ? props.layouts[props.input.entity] : null;

  switch (layout?.type) {
    case "list":
      return <WorkflowEntityListPopover {...props} layout={layout} />;
    case "table":
      return <WorkflowEntityTablePopover {...props} layout={layout} />;
    case undefined:
      return (
        <BaseWorkflowFormControl
          errors={props.errors}
          hint={props.hint}
          input={props.input}
          label={
            <>
              {props.input.name} <Tag>{capitalize(props.input.entity)}</Tag>
            </>
          }
          renderField={(value, index) => (
            <Input
              type="number"
              value={value === null ? "" : String(value)}
              onChange={(e) => props.onChangeField(e, index)}
            />
          )}
          value={props.value}
          onChange={props.onChange}
        />
      );
  }
}

function useNonExistentFilterWarning(
  props: Props & { layout: Messages["WorkflowEntityTableLayout"] },
) {
  React.useEffect(() => {
    for (const defaultFilterKey of Object.keys(props.defaultFilters ?? {})) {
      if (props.layout.filters.every((x) => x.name !== defaultFilterKey)) {
        console.error(
          `WorkflowEntityInputTable: default filter "${defaultFilterKey}" is not defined in layout entity "${props.input.entity}"`,
        );

        if (import.meta.env.DEV) {
          console.warn(
            `Available filters are: ${props.layout.filters.map((x) => x.name).join(", ")}`,
          );
        }
      }
    }
  }, [props.defaultFilters, props.input.entity, props.layout.filters]);
}

function WorkflowEntityTablePopover(
  props: Props & { layout: Messages["WorkflowEntityTableLayout"]; renderHint?: boolean },
) {
  useNonExistentFilterWarning(props);
  const disclosure = useDisclosure();
  const { queries } = useApi();

  const renderHint = props.renderHint ?? true;

  const [filters, setFilters] = React.useState<Record<string, unknown>>(() => {
    return props.layout.filters.reduce(
      (acc, filter) => {
        acc[filter.name] =
          props.hardFilters?.[filter.name] ??
          props.defaultFilters?.[filter.name] ??
          getDefaultFilterValue(filter);
        return acc;
      },
      {} as Record<string, unknown>,
    );
  });

  const query = useQuery({
    ...queries.entitySearch({
      entity: props.input.entity,
      filters: JSON.stringify({ ...filters, ...props.hardFilters }),
    }),
    select: (response) => {
      assert(response.type === "table", "Expected table response");
      return response;
    },
    enabled: disclosure.isOpen,
  });

  const { isSelected, isChecked, onSelect, selectedLabel, toggleSelectAll } = useSelection({
    disclosure: disclosure,
    hint: props.hint,
    input: props.input,
    onChange: props.onChange,
    value: props.value,
    rows: query.data?.rows.map((x) => ({ id: x.id, label: x.$display })) ?? [],
  });

  return (
    <Popover {...disclosure} isLazy={true}>
      <ButtonGroup isAttached>
        <PopoverTrigger>
          <SelectButton
            aria-invalid={props["aria-invalid"]}
            aria-selected={isSelected}
            renderSelected={selectedLabel}
            renderUnselected={props.renderUnselected ?? `Select ${capitalize(props.input.entity)}`}
            size={props.size}
          />
        </PopoverTrigger>
        {isSelected && props.allowUnselect !== false && (
          <ResetSelectionButton
            aria-selected={isSelected}
            size={props.size}
            onClick={() => props.onChange([null])}
          />
        )}
      </ButtonGroup>
      {renderHint && (
        <WorkflowFormControlHint
          hint={props.hint}
          isSelected={isSelected}
          onChange={(v) => props.onChange([v])}
        />
      )}
      {props.errors.length > 0 && <FormErrorMessage>{props.errors[0]}</FormErrorMessage>}
      <PopoverContent minW="2xl">
        <Flex gap={1} p={2}>
          {props.layout.filters.map((filter) => (
            <WorkflowLayoutFilter
              key={filter.name}
              filter={filter}
              filters={filters}
              hardFilters={props.hardFilters}
              onChange={setFilters}
            />
          ))}
        </Flex>

        <TableContainer
          border="1px"
          borderColor="gray.200"
          h="80"
          mb={2}
          mx={2}
          overflowY="auto"
          rounded="md"
        >
          <Table fontSize="sm" variant="simple">
            <Thead position="sticky" top={0}>
              <Tr bg="gray.50">
                {props.input.array === true && (
                  <Th key="checkbox" w={4}>
                    <Checkbox
                      aria-label="Select all"
                      isChecked={query.isSuccess && query.data.rows.every((x) => isChecked(x.id))}
                      isIndeterminate={
                        query.isSuccess &&
                        query.data.rows.some((x) => isChecked(x.id)) &&
                        !query.data.rows.every((x) => isChecked(x.id))
                      }
                      margin={0}
                      onChange={toggleSelectAll}
                    />
                  </Th>
                )}
                {props.layout.columns.map(([column]) => (
                  <Th key={column} py={3}>
                    {toHumanCase(column)}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Thead>
              <Tr>
                <Th colSpan={props.layout.columns.length} opacity={query.isFetching ? 1 : 0} p={0}>
                  <Progress h="1px" isIndeterminate={true} />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {query.isLoading &&
                [0, 1, 2, 3, 4, 5].map((i) => (
                  <Tr key={`skeleton-${i}`}>
                    {props.input.array === true && (
                      <Td py={2.5}>
                        <Skeleton h={5} rounded="full" w={5} />
                      </Td>
                    )}
                    {props.layout.columns.map(([column]) => (
                      <Td key={column} py={2.5}>
                        <Skeleton h={5} minW={5} rounded="full" w="full" />
                      </Td>
                    ))}
                  </Tr>
                ))}
              {query.isError && (
                <Tr>
                  <Td colSpan={props.layout.columns.length}>Error loading data</Td>
                </Tr>
              )}
              {query.isSuccess && (
                <>
                  {query.data.rows.map((row) => (
                    <Tr
                      key={`row-${row.id}`}
                      _hover={{ bg: "gray.50" }}
                      _selected={{ bg: "gray.100" }}
                      aria-selected={isChecked(row.id)}
                      cursor="pointer"
                      onClick={() => onSelect(row.id)}
                    >
                      <>
                        {props.input.array === true && (
                          <Td key="checkbox" py={2.5} textAlign="center" w={4}>
                            <CheckIcon h={3} opacity={isChecked(row.id) ? 1 : 0} />
                          </Td>
                        )}
                        {props.layout.columns.map(([column, type]) => (
                          <Td key={column} py={2.5}>
                            <FormattedCell type={type} value={row[column]} />
                          </Td>
                        ))}
                      </>
                    </Tr>
                  ))}
                </>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </PopoverContent>
    </Popover>
  );
}

const SelectButton = forwardRef(
  (
    props: ButtonProps & {
      renderSelected: React.ReactNode;
      renderUnselected: React.ReactNode;
    },
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const { renderSelected, renderUnselected, ...rest } = props;

    return (
      <Button
        ref={ref}
        _invalid={{
          borderColor: "red.500",
          boxShadow: "0 0 0 1px var(--chakra-colors-red-500)",
        }}
        aria-selected={props["aria-selected"]}
        bg={props["aria-selected"] ? "blue.50" : undefined}
        colorScheme={props["aria-selected"] ? "blue" : undefined}
        justifyContent="space-between"
        rightIcon={<ChevronDownIcon />}
        size={props.size}
        type="button"
        variant="outline"
        w="full"
        {...rest}
      >
        <Text isTruncated>{props["aria-selected"] ? renderSelected : renderUnselected}</Text>
      </Button>
    );
  },
);

function ResetSelectionButton(props: ButtonProps) {
  return (
    <IconButton
      aria-label="Clear"
      aria-selected={props["aria-selected"]}
      bg={props["aria-selected"] ? "blue.50" : undefined}
      colorScheme={props["aria-selected"] ? "blue" : undefined}
      icon={<CloseIcon h={2.5} />}
      variant="outline"
      onClick={props.onClick}
      {...props}
    />
  );
}

function WorkflowEntityListPopover(
  props: Props & { layout: Messages["WorkflowEntityListLayout"]; renderHint?: boolean },
) {
  const { queries } = useApi();
  const [searchTerm, setSearchTerm] = React.useState("");
  const disclosure = useDisclosure();
  const renderHint = props.renderHint ?? true;

  const query = useQuery({
    ...queries.entitySearch({
      entity: props.input.entity,
      filters: JSON.stringify({ searchTerm }),
    }),
    keepPreviousData: true,
    enabled: disclosure.isOpen,
    select: (response) => {
      assert(response.type === "list", "Expected list response");
      return response;
    },
  });

  const { isSelected, isChecked, onSelect, selectedLabel } = useSelection({
    disclosure: disclosure,
    hint: props.hint,
    input: props.input,
    onChange: props.onChange,
    value: props.value,
    rows: query.data?.rows ?? [],
  });

  return (
    <FormControl isInvalid={props.errors.length > 0}>
      <FormLabel>
        {props.input.name}
        {props.isRequired && <RequiredAst />}:
      </FormLabel>

      <Popover {...disclosure} isLazy={true}>
        <ButtonGroup isAttached w="full">
          <PopoverTrigger>
            <SelectButton
              aria-invalid={props["aria-invalid"]}
              aria-selected={isSelected}
              renderSelected={selectedLabel}
              renderUnselected={`Select ${props.input.name}`}
              size={props.size}
            />
          </PopoverTrigger>
          {isSelected && props.allowUnselect !== false && (
            <ResetSelectionButton
              aria-selected={isSelected}
              size={props.size}
              onClick={() => props.onChange([null])}
            />
          )}
        </ButtonGroup>
        {renderHint && (
          <WorkflowFormControlHint
            hint={props.hint}
            isSelected={isSelected}
            onChange={(v) => props.onChange([v])}
          />
        )}
        {props.errors.length > 0 && <FormErrorMessage>{props.errors[0]}</FormErrorMessage>}
        <PopoverContent width={props.layout.width}>
          <Input
            p={4}
            placeholder="Search..."
            value={searchTerm}
            variant="unstyled"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {query.isFetching ? <Progress h="1px" isIndeterminate={true} /> : <Divider />}
          {query.isLoading &&
            [0, 1, 2, 3, 4, 5].map((i) => (
              <Flex
                key={i}
                borderBottom="1px"
                borderColor="gray.100"
                direction="column"
                gap={1.5}
                px={4}
                py={2}
              >
                <Skeleton h={3} w={16} />
                <Skeleton h={6} w={48} />
                <Flex gap={2}>
                  <Skeleton h={4} w={32} />
                  <Skeleton h={4} w={24} />
                </Flex>
              </Flex>
            ))}
          {query.isError && (
            <Flex borderBottom="1px" borderColor="gray.100" direction="column" px={4} py={2}>
              <Text>Error loading data</Text>
            </Flex>
          )}
          {query.isSuccess && query.data.rows.length === 0 && (
            <Flex
              color="gray.500"
              direction="column"
              fontSize="sm"
              px={4}
              py={6}
              textAlign="center"
            >
              <Text>No results</Text>
            </Flex>
          )}
          {query.isSuccess && query.data.rows.length > 0 && (
            <Box maxH="40vh" overflow="auto" pb={4}>
              {query.data.rows.map((row) => (
                <Flex
                  key={`row-${row.id}`}
                  _hover={{ bg: "gray.50" }}
                  _selected={{ bg: "gray.50" }}
                  aria-selected={isChecked(row.id)}
                  borderBottom="1px"
                  borderColor="gray.100"
                  cursor="pointer"
                  gap={2}
                  px={4}
                  py={2}
                  onClick={() => onSelect(row.id)}
                >
                  <CheckIcon h={3} opacity={isChecked(row.id) ? 1 : 0} />

                  <Flex direction="column">
                    <Text color="gray.500" fontSize="xs">
                      {String(row.id)}
                    </Text>
                    <Text>{row.label}</Text>
                    <Flex color="gray.500" fontSize="sm" gap={2}>
                      <Text>{row.tags.join(" • ")}</Text>
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </Box>
          )}
        </PopoverContent>
      </Popover>
    </FormControl>
  );
}

function FormattedCell(props: {
  value: unknown;
  type: Messages["WorkflowResolvedDataFieldType"];
}): JSX.Element {
  if (props.type.array === true) {
    return (
      <>
        {(props.value as unknown[])
          .map<React.ReactNode>((x, i) => (
            <FormattedCell key={i} type={{ ...props.type, array: false }} value={x} />
          ))
          .reduce((prev, curr) => [prev, ", ", curr])}
      </>
    );
  }

  if (props.value === null || props.value === undefined) {
    return <></>;
  }

  const type = props.type.type === "option" ? props.type.optionType.type : props.type.type;

  switch (type) {
    case "boolean":
      return props.value === true ? (
        <Tag colorScheme="green">Yes</Tag>
      ) : (
        <Tag colorScheme="red">No</Tag>
      );
    case "date":
      return <>{dateFormatter.toDate(LocalDate.parse(props.value as string))}</>;
    case "time":
      return <>{dateFormatter.toTime(LocalTime.parse(props.value as string))}</>;
    case "datetime":
      return <>{dateFormatter.toDateOrDateTime(LocalDateTime.parse(props.value as string))}</>;
    case "file":
    case "entity":
    case "number":
    case "text":
    case "textarea":
    case "unknown":
      return <>{String(props.value)}</>;
  }
}

function useSelection<TRow extends { id: unknown; label: unknown }>(props: {
  input: Messages["WorkflowEntityField"] & { name: string };
  hint: { value: unknown; display: string } | null;
  value: (unknown | null)[];
  disclosure: ReturnType<typeof useDisclosure>;
  rows: TRow[];
  onChange: (value: (unknown | null)[]) => void;
}) {
  const { queries } = useApi();

  const isSelected = props.value.filter(Boolean).length > 0;

  const handleSelect = (id: unknown) => {
    if (props.input.array !== true) {
      props.onChange([id]);
      props.disclosure.onClose();
      return;
    }

    return props.value.includes(id)
      ? props.onChange(props.value.filter((x) => x !== id && x !== null))
      : props.onChange([...props.value.filter(Boolean), id]);
  };

  const toggleSelectAll = () => {
    const isSomeChecked = props.value.find((x) => x !== null) !== undefined;

    return isSomeChecked ? props.onChange([]) : props.onChange(props.rows.map((x) => x.id));
  };

  const isChecked = (id: unknown) => {
    return props.value.includes(id);
  };

  const getSelectedLabel = () => {
    if (props.input.array === true) {
      return `${props.input.name} (${props.value.length})`;
    }

    const fromQuery = props.rows.find((x) => isChecked(x.id));

    if (fromQuery !== undefined) {
      return `${fromQuery.label}`;
    }

    return (
      <QueryResolver
        options={queries.entityName({ entity: props.input.entity, id: props.value[0] })}
        onError="Error"
        onLoading="Loading..."
        onSuccess={(x) => String(x.data)}
      />
    );
  };

  return {
    isSelected,
    isChecked,
    selectedLabel: getSelectedLabel(),
    onSelect: handleSelect,
    toggleSelectAll,
  };
}

function withEntityLayouts<P extends { layouts: Messages["WorkflowEntityLayouts"] }>(
  Component: React.ComponentType<P>,
) {
  const displayName = Component.displayName || Component.name || "Component";

  const ComponentWithEntityLayouts = (props: Omit<P, "layouts">) => {
    const { queries } = useApi();
    const entityLayouts = useQuery({
      ...queries.entityLayouts(),
      select: ({ layouts }) => layouts,
    });

    if (entityLayouts.isLoading) {
      return <Skeleton h={10} rounded="md" w={32} />;
    }

    if (entityLayouts.isError) {
      return (
        <Button isDisabled={true} justifyContent="flex-start" variant="outline">
          Failed to render input
        </Button>
      );
    }

    return <Component {...({ ...props, layouts: entityLayouts.data } as P)} />;
  };

  ComponentWithEntityLayouts.displayName = `withEntityLayouts(${displayName})`;

  return ComponentWithEntityLayouts;
}

function getDefaultFilterValue(filter: Messages["WorkflowEntityFilter"]) {
  return "defaultValue" in filter ? filter.defaultValue : undefined;
}

function parseDateRangeFilterValue(value: unknown): [LocalDate | null, LocalDate | null] {
  const now = LocalDate.now();
  const $value = value as Messages["WorkflowEntityDateRangeFilter"]["defaultValue"];

  switch ($value) {
    case "24h":
      return [now.minusDays(1), now];
    case "30d":
      return [now.minusDays(30), now];
    case "7d":
      return [now.minusDays(7), now];
    case "90d":
      return [now.minusDays(90), now];
    case "365d":
      return [now.minusDays(365), now];
    default:
      return Array.isArray($value) ? $value : [null, null];
  }
}

function WorkflowLayoutFilter(props: {
  filter: Messages["WorkflowEntityFilter"];
  filters: Record<string, unknown>;
  hardFilters?: Record<PropertyKey, unknown>;
  onChange: (filters: Record<string, unknown>) => void;
}) {
  const { filter, filters } = props;

  switch (filter.type) {
    case "text":
      return (
        <Input
          aria-invalid={false}
          flex={1}
          isDisabled={props.hardFilters?.[filter.name] !== undefined}
          placeholder={filter.label}
          rounded="md"
          size="sm"
          value={String(filters[filter.name] ?? "")}
          width={filter.width ?? "xs"}
          onChange={(e) => props.onChange({ ...filters, [filter.name]: e.target.value })}
        />
      );

    case "select":
      return filter.multiple ? (
        <Select
          aria-invalid={false}
          popoverProps={{ isLazy: true }}
          {...filter}
          isDisabled={props.hardFilters?.[filter.name] !== undefined}
          multiple={true}
          size="sm"
          value={filters[filter.name] !== undefined ? toArray(filters[filter.name]) : null}
          width={filter.width ?? "fit-content"}
          onChange={(x) => props.onChange({ ...filters, [filter.name]: x })}
        />
      ) : (
        <Select
          aria-invalid={false}
          popoverProps={{ isLazy: true }}
          {...filter}
          isDisabled={props.hardFilters?.[filter.name] !== undefined}
          multiple={false}
          size="sm"
          value={filters[filter.name] ?? null}
          width={filter.width ?? "fit-content"}
          onChange={(x) => props.onChange({ ...filters, [filter.name]: x })}
        />
      );

    case "date-range": {
      const [start, end] = parseDateRangeFilterValue(filters[filter.name]);
      return (
        <RangeDatePicker
          disabled={props.hardFilters?.[filter.name] !== undefined}
          endDate={end}
          inputProps={{ size: "sm", rounded: "md" }}
          placeholderText={filter.label}
          startDate={start}
          onChange={(x) => props.onChange({ ...filters, [filter.name]: x })}
        />
      );
    }

    case "hidden":
      return <></>;
  }
}

export const WorkflowEntitySelect = withEntityLayouts(WorkflowEntitySelectWithTemplates);
export default withEntityLayouts(WorkflowEntityFormControl);
