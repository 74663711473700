import { createIcon } from "@chakra-ui/react";

const CalendarTodayRoundedIcon = createIcon({
  displayName: "CalendarTodayRoundedIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M5 22q-.825 0-1.413-.588T3 20V6q0-.825.588-1.413T5 4h1V3q0-.425.288-.713T7 2q.425 0 .713.288T8 3v1h8V3q0-.425.288-.713T17 2q.425 0 .713.288T18 3v1h1q.825 0 1.413.588T21 6v14q0 .825-.588 1.413T19 22H5Zm0-2h14V10H5v10Z"
      fill="currentColor"
    />
  ),
});

export default CalendarTodayRoundedIcon;
