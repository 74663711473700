import { basicFieldTypes } from "../../../../../../shared/constants/basic-field-types.const";
import AttachFileIcon from "../../../../../../shared/icons/AttachFileIcon";
import CalendarTodayRoundedIcon from "../../../../../../shared/icons/CalendarTodayRoundedIcon";
import ClockTimeFourOutlineIcon from "../../../../../../shared/icons/ClockTimeFourOutlineIcon";
import NumbersRoundedIcon from "../../../../../../shared/icons/NumbersRoundedIcon";
import TextFieldsIcon from "../../../../../../shared/icons/TextFieldsIcon";
import ToggleOffOutlineIcon from "../../../../../../shared/icons/ToggleOffOutlineIcon";

interface Props {
  field: (typeof basicFieldTypes)[number];
}

export default function FieldIcon(props: Props) {
  switch (props.field.name) {
    case "boolean":
      return <ToggleOffOutlineIcon />;

    case "date":
    case "datetime":
      return <CalendarTodayRoundedIcon />;

    case "time":
      return <ClockTimeFourOutlineIcon />;

    case "number":
      return <NumbersRoundedIcon />;

    case "text":
    case "textarea":
      return <TextFieldsIcon />;

    case "file":
      return <AttachFileIcon />;
  }
}
