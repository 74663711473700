import { Divider, Flex, Text, Tooltip, chakra } from "@chakra-ui/react";
import { Duration } from "@js-joda/core";
import DurationText from "../../../../../shared/components/DurationText";
import NodeIcon from "../../../../../shared/components/NodeIcon";
import UserBoldIcon from "../../../../../shared/icons/UserBoldIcon";
import {
  NodeResult,
  WorkflowDefinition,
  WorkflowNode,
} from "../../../../../shared/schemas/workflows";
import { dateFormatter } from "../../../../../shared/utils/date-formatter";
import { noop } from "../../../../../shared/utils/general.utils";
import { WorkflowInstanceOriginUI } from "../../../../Workflows/components/WorkflowInstanceOrigin";
import Sidebar from "../../Sidebar";
import DisplayResolvedValue from "./components/DisplayResolvedValue";

export default function ExecutedNodeSidebar(props: {
  node: WorkflowNode;
  workflow: WorkflowDefinition;
  executionResult: NodeResult | undefined;
}) {
  return (
    <Sidebar.Root>
      <Flex align="start" gap={3} p={4}>
        <NodeIcon fontSize="2xl" type={props.node.type} />
        <Flex direction="column" flex={1}>
          <TaskExecutionResult result={props.executionResult} />
          <Sidebar.Title
            data-testid="node-sidebar-name-input"
            flex={1}
            value={props.node.meta.name}
            onChange={noop}
          />
          <TaskDescription node={props.node} />
        </Flex>
      </Flex>

      <Divider />

      {props.executionResult != null && (
        <ExecutedNodeSidebarWithResult executionResult={props.executionResult} />
      )}
    </Sidebar.Root>
  );
}

function ExecutedNodeSidebarWithResult(props: { executionResult: NodeResult }) {
  return (
    <>
      {props.executionResult.input.length > 0 && (
        <Sidebar.Section>
          <Sidebar.Row>
            <Sidebar.SectionTitle>Input</Sidebar.SectionTitle>
          </Sidebar.Row>
          {props.executionResult.input.map((input) => (
            <Sidebar.Row key={input.name}>
              <Sidebar.Label>{input.name}</Sidebar.Label>
              <DisplayResolvedValue value={input.displayValue} />
            </Sidebar.Row>
          ))}
        </Sidebar.Section>
      )}

      <Divider />

      {props.executionResult.output && (
        <Sidebar.Section>
          <Sidebar.Row>
            <Sidebar.SectionTitle>Output: {props.executionResult.output.key}</Sidebar.SectionTitle>
          </Sidebar.Row>
          {props.executionResult.output.fields.map((field) => (
            <Sidebar.Row key={field.name}>
              <Sidebar.Label>{field.name}</Sidebar.Label>
              <DisplayResolvedValue value={field.displayValue} />
            </Sidebar.Row>
          ))}
          {props.executionResult.output.fields.length === 0 && (
            <Sidebar.Row>
              <Sidebar.Text>No fields</Sidebar.Text>
            </Sidebar.Row>
          )}
        </Sidebar.Section>
      )}

      <Divider />

      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Trigger</Sidebar.SectionTitle>
        </Sidebar.Row>
        {props.executionResult.executedBy?.type === "human" && (
          <Sidebar.Row>
            <Sidebar.Label>Origin</Sidebar.Label>
            <WorkflowInstanceOriginUI
              heading={props.executionResult.executedBy.name}
              icon={UserBoldIcon}
              text={`Agency Member #${props.executionResult.executedBy.agencyMemberId}`}
            />
          </Sidebar.Row>
        )}
        {props.executionResult.startedAt && (
          <Sidebar.Row>
            <Sidebar.Label>Date</Sidebar.Label>
            <Sidebar.Text>
              {dateFormatter.toDateOrDateTime(props.executionResult.startedAt)}
            </Sidebar.Text>
          </Sidebar.Row>
        )}
      </Sidebar.Section>

      <Divider />

      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Finish</Sidebar.SectionTitle>
        </Sidebar.Row>
        <Sidebar.Row>
          <Sidebar.Label>Type</Sidebar.Label>
          <Sidebar.Text>{props.executionResult.status.name}</Sidebar.Text>
        </Sidebar.Row>
        {props.executionResult.status.name === "ERROR" && (
          <Sidebar.Row>
            <Sidebar.Label>Error</Sidebar.Label>
            <Sidebar.Text>{props.executionResult.status.errorMessage}</Sidebar.Text>
          </Sidebar.Row>
        )}
        {props.executionResult.finishedAt !== null && props.executionResult.startedAt !== null && (
          <>
            <Sidebar.Row>
              <Sidebar.Label>Date</Sidebar.Label>
              <Sidebar.Text>
                {dateFormatter.toDateOrDateTime(props.executionResult.finishedAt)}
              </Sidebar.Text>
            </Sidebar.Row>
            <Sidebar.Row>
              <Sidebar.Label>Duration</Sidebar.Label>
              <Sidebar.Text>
                <DurationText
                  milliseconds={Duration.between(
                    props.executionResult.startedAt,
                    props.executionResult.finishedAt,
                  ).toMillis()}
                ></DurationText>
              </Sidebar.Text>
            </Sidebar.Row>
          </>
        )}
      </Sidebar.Section>
    </>
  );
}

function TaskDescription(props: { node: WorkflowNode }) {
  switch (props.node.type) {
    case "choice":
      return <TaskDescriptionText>Choice</TaskDescriptionText>;
    case "group":
      return <TaskDescriptionText>Group</TaskDescriptionText>;
    case "human-task":
      return <TaskDescriptionText>Human Task</TaskDescriptionText>;
    case "reset-to-task":
      return <TaskDescriptionText>Reset To Task</TaskDescriptionText>;
    case "short-circuit-task":
      return <TaskDescriptionText>Short Circuit Task</TaskDescriptionText>;
    case "system-task":
      return (
        <Tooltip label={`${props.node.task.id}@v${props.node.task.version}`}>
          <TaskDescriptionText>System Task ({props.node.task.meta.name})</TaskDescriptionText>
        </Tooltip>
      );
    case "workflow":
      return <TaskDescriptionText>Workflow</TaskDescriptionText>;
  }
}
function TaskExecutionResult(props: { result: NodeResult | undefined }) {
  return props.result === undefined ? (
    <TaskDescriptionText>No executed</TaskDescriptionText>
  ) : (
    <TaskDescriptionText>Executed #{props.result.id}</TaskDescriptionText>
  );
}

const TaskDescriptionText = chakra(Text, {
  baseStyle: {
    color: "gray.500",
    fontSize: "sm",
  },
});
