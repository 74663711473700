import {
  HumanTaskTemplateDefinition,
  Task,
  WorkflowChildChoiceTask,
  WorkflowChildGroup,
  WorkflowChildHumanTaskReference,
  WorkflowChildResetToTask,
  WorkflowChildScheduling,
  WorkflowChildShortCircuitTask,
  WorkflowChildSystemTaskReference,
  WorkflowDefinition,
  WorkflowNode,
} from "../../../shared/schemas/workflows";
import { fmap } from "../../../shared/utils/general.utils";
import { BasicMeta, ChoiceMeta, GroupMeta, TaskMeta } from "../components/Sidebars/shared/sections";
import {
  emptyRef,
  findGroupNode,
  findNode,
  generatePlaceholdersForNode,
  getConnectedNodeIds,
  getNodesWithNewPositionsAffectedByANodeChange,
  removeNodeDependsOn,
} from "./workflow-node.utils";

interface Props {
  workflow: WorkflowDefinition;
  id: string;
  node: WorkflowNode;
  taskOptions: Task[];
  taskTemplateOptions: HumanTaskTemplateDefinition[];
  onUpdateNode: (id: string, node: WorkflowNode) => void;
  onAddNode: (id: string, node: WorkflowNode) => void;
  onError: (error: unknown) => void;
}

function createEntity(props: Props) {
  const change = (entity: { id: string; version?: number }) => {
    const result = getChangeResult({ ...props, entity });

    if (result === null) {
      props.onError("Not available");
      return;
    }

    const { node, newNodes } = result;

    const updatedConnectedNodes = getConnectedNodeIds(props.workflow.children, props.id).flatMap(
      (id) => {
        const node = findNode(id, props.workflow.children);

        if (node === null) {
          return [];
        }

        return [removeNodeDependsOn(node)];
      },
    );

    const parentGroupNode =
      fmap(node.parent, (parentId) => findGroupNode(parentId, props.workflow.children)) ?? null;

    const updatedNodePositions =
      fmap(parentGroupNode, (groupNode) =>
        getNodesWithNewPositionsAffectedByANodeChange({
          parentGroupNode: groupNode,
          currentWorkflowChildren: props.workflow.children,
          updatedNode: node,
          updatedGroupChildren: {
            ...groupNode.children,
            ...Object.fromEntries(newNodes.map((node) => [node.logicalId, node])),
            [node.logicalId]: node,
          },
        }),
      ) ?? [];

    [...updatedNodePositions, ...updatedConnectedNodes].forEach((updatedNode) => {
      props.onUpdateNode(updatedNode.logicalId, updatedNode);
    });

    newNodes.forEach((newNode) => {
      props.onAddNode(newNode.logicalId, newNode);
    });

    props.onUpdateNode(props.id, node);
  };

  const handleUpdateTaskMeta = <T extends keyof TaskMeta>(
    node: WorkflowChildHumanTaskReference | WorkflowChildSystemTaskReference,
    name: T,
    value: TaskMeta[T],
  ) => {
    props.onUpdateNode(props.id, {
      ...node,
      meta: {
        ...node.meta,
        [name]: value,
      },
    });
  };

  const handleUpdateGroupMeta = <T extends keyof GroupMeta>(
    node: WorkflowChildGroup,
    name: T,
    value: GroupMeta[T],
  ) => {
    props.onUpdateNode(props.id, {
      ...node,
      meta: {
        ...node.meta,
        [name]: value,
      },
    });
  };

  const handleUpdateChoiceMeta = <T extends keyof ChoiceMeta>(
    node: WorkflowChildChoiceTask,
    name: T,
    value: ChoiceMeta[T],
  ) => {
    props.onUpdateNode(props.id, {
      ...node,
      meta: {
        ...node.meta,
        [name]: value,
      },
    });
  };

  const handleUpdateBasicMeta = <T extends keyof BasicMeta>(
    node: WorkflowChildShortCircuitTask | WorkflowChildResetToTask,
    name: T,
    value: BasicMeta[T],
  ) => {
    props.onUpdateNode(props.id, {
      ...node,
      meta: {
        ...node.meta,
        [name]: value,
      },
    });
  };

  const handleUpdateNodeScheduling = <T extends keyof WorkflowChildScheduling>(
    node: WorkflowNode,
    name: T,
    value: WorkflowChildScheduling[T],
  ) => {
    props.onUpdateNode(props.id, {
      ...node,
      scheduling: {
        ...node.scheduling,
        [name]: value,
      },
    });
  };

  return {
    change: change,
    updateTaskMeta: handleUpdateTaskMeta,
    updateGroupMeta: handleUpdateGroupMeta,
    updateChoiceMeta: handleUpdateChoiceMeta,
    updateBasicMeta: handleUpdateBasicMeta,
    updateNodeScheduling: handleUpdateNodeScheduling,
  };
}

function getChangeResult(
  props: Props & {
    entity: { id: string; version?: number };
  },
): {
  node: WorkflowNode;
  newNodes: WorkflowNode[];
} | null {
  const entity = props.entity;

  switch (entity.id) {
    case "human-task":
      return {
        node: emptyRef.humanTask({
          parent: props.node.parent,
          logicalId: props.id,
          dependsOn: props.node.dependsOn,
          position: props.node.meta.position,
          taskTemplateOptions: props.taskTemplateOptions,
        }),
        newNodes: [],
      };

    case "group": {
      const group = emptyRef.group({
        parent: props.node.parent,
        logicalId: props.id,
        dependsOn: props.node.dependsOn,
        position: props.node.meta.position,
      });

      return {
        node: group,
        newNodes: generatePlaceholdersForNode({
          node: group,
          children: props.workflow.children,
        }),
      };
    }

    case "choice":
      return {
        node: emptyRef.choiceNode({
          parent: props.node.parent,
          logicalId: props.id,
          dependsOn: props.node.dependsOn,
          position: props.node.meta.position,
        }),
        newNodes: [],
      };

    case "short-circuit-task":
      return {
        node: emptyRef.shortCircuit({
          parent: props.node.parent,
          logicalId: props.id,
          dependsOn: props.node.dependsOn,
          position: props.node.meta.position,
        }),
        newNodes: [],
      };

    case "reset-to-task":
      return {
        node: emptyRef.resetToTask({
          parent: props.node.parent,
          logicalId: props.id,
          dependsOn: props.node.dependsOn,
          position: props.node.meta.position,
        }),
        newNodes: [],
      };

    case "workflow":
      return {
        node: emptyRef.workflow({
          parent: props.node.parent,
          logicalId: props.id,
          id: entity.id,
          dependsOn: props.node.dependsOn,
          position: props.node.meta.position,
        }),
        newNodes: [],
      };

    default: {
      const task = props.taskOptions.find(
        (t) => t.id === entity.id && t.version === entity.version,
      );

      if (task === undefined) {
        console.error(`Entity ${JSON.stringify(entity)} was not found`);
        return null;
      }

      const node = emptyRef.task({
        parent: props.node.parent,
        logicalId: props.id,
        task: task,
        dependsOn: props.node.dependsOn,
        position: props.node.meta.position,
      });

      return {
        node,
        newNodes: generatePlaceholdersForNode({
          node: node,
          children: props.workflow.children,
        }),
      };
    }
  }
}

export default createEntity;
