import { CalendarIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  InputGroup,
  InputRightElement,
  Progress,
  Skeleton,
  Text,
  TextProps,
} from "@chakra-ui/react";
import { Row } from "@tanstack/react-table";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import RangeDatePicker from "../../shared/components/DatePicker/RangeDatePicker";
import Header from "../../shared/components/Header";
import Select from "../../shared/components/Select";
import {
  GraphQLSearchParamFilters,
  useGraphQLSearchParamFilters,
} from "../../shared/hooks/useFilters";
import useNavigateLink from "../../shared/hooks/useNavigateLink";
import LayoutIcon from "../../shared/icons/LayoutIcon";
import { WorkflowDefinitionCorrelationKey, WorkflowDefinitionId } from "../../shared/schema/schema";
import { dateFormatter } from "../../shared/utils/date-formatter";
import { isDefined, toArrayOrNull } from "../../shared/utils/general.utils";
import { fmapEq, fmapIn } from "../../shared/utils/graphql.utils";
import { EntitySelect } from "../Workflows/components/EntityFormControl";
import { RunWorkflowPopover } from "../Workflows/components/RunWorkflowPopover";
import WorkflowInstancesDataTable, {
  WorkflowInstanceRow,
} from "./components/WorkflowInstancesDataTable";
import WorkflowInvocationsGraph from "./components/WorkflowInvocationsGraph";
import useWorkflowDashboardQuery, {
  WorkflowDashboardQuery,
} from "./hooks/useWorkflowDashboardQuery";
import { WORKFLOW_DASHBOARD_INSTANCES_QUERY } from "./workflow.graphql";

type Props = {
  id?: WorkflowDefinitionId;
  correlationKey: WorkflowDefinitionCorrelationKey;
};

export default function WorkflowPage(props: Props) {
  const navigate = useNavigateLink();

  const query = useWorkflowDashboardQuery({ id: props.id, correlationKey: props.correlationKey });

  const filters = useGraphQLSearchParamFilters({
    document: WORKFLOW_DASHBOARD_INSTANCES_QUERY,
    key: "filter",
    initialState: {
      workflowDefinitionCorrelationKey: { eq: props.correlationKey },
      workflowDefinitionId: fmapEq(props.id),
    },
  });

  const handleClickRow = (event: React.MouseEvent, row: Row<WorkflowInstanceRow>) => {
    return navigate(
      `/workflows/${row.original.workflowDefinitionId}/instances/${row.original.id}`,
      { event },
    );
  };

  return (
    <WorkflowPageTemplate
      actions={
        <WorkflowPageActions correlationKey={props.correlationKey} id={props.id} query={query} />
      }
      filters={<WorkflowPageFilters filters={filters} query={query} />}
      graph={
        <WorkflowInvocationsGraph
          correlationKey={props.correlationKey}
          range={[filters.getFilterOrNull("startedAtFrom"), filters.getFilterOrNull("startedAtTo")]}
          workflowDefinitionId={props.id}
        />
      }
      header={<WorkflowPageHeader query={query} />}
      isFetching={query.isFetching}
      meta={<WorkflowPageMeta query={query} />}
      table={<WorkflowInstancesDataTable filters={filters.state} onClickRow={handleClickRow} />}
      title={<WorkflowPageTitle query={query} />}
    />
  );
}

function WorkflowPageHeader(props: { query: WorkflowDashboardQuery }) {
  switch (props.query.status) {
    case "loading":
    case "error":
      return <Header breadcrumbs={[{ name: "Workflows", to: "/workflows" }]} />;
    case "success":
      return (
        <Header
          breadcrumbs={[
            { name: "Workflows", to: "/workflows" },
            { name: props.query.data.activeVersion.name, active: true },
          ]}
        />
      );
  }
}

function WorkflowPageActions(props: {
  id: WorkflowDefinitionId | undefined;
  correlationKey: WorkflowDefinitionCorrelationKey;
  query: WorkflowDashboardQuery;
}) {
  const navigate = useNavigate();

  const handleChangeVersion = (id: WorkflowDefinitionId | null | undefined) => {
    return navigate(
      isDefined(id)
        ? `/workflows/${props.correlationKey}?id=${id}`
        : `/workflows/${props.correlationKey}`,
    );
  };

  switch (props.query.status) {
    case "loading":
      return (
        <>
          <Skeleton h={10} rounded="md" w={36} />
          <Skeleton h={10} rounded="md" w={36} />
          <Skeleton h={10} rounded="md" w={20} />
        </>
      );
    case "error":
      return <></>;
    case "success":
      return (
        <>
          <Select
            allowUnselect={false}
            buttonProps={{ bg: props.id == undefined ? "white" : "blue.50" }}
            label="All versions"
            multiple={false}
            options={[
              { value: null, label: "All versions" },
              ...props.query.data.versions.map((version) => ({
                label: `v${version.number}${version.published ? " (published)" : ""}`,
                value: version.id,
              })),
            ]}
            value={props.id ?? null}
            onChange={handleChangeVersion}
          />
          <Button
            as={Link}
            bg="white"
            leftIcon={<LayoutIcon />}
            to={{ pathname: `/editor/${props.query.data.activeVersion.id}` }}
            variant="outline"
          >
            {props.id !== undefined
              ? "Editor"
              : `Editor (v${props.query.data.activeVersion.number})`}
          </Button>
          <RunWorkflowPopover
            id={props.query.data.activeVersion.id}
            input={props.query.data.activeVersion.input}
          />
        </>
      );
  }
}

function WorkflowPageFilters(props: {
  query: WorkflowDashboardQuery;
  filters: GraphQLSearchParamFilters<typeof WORKFLOW_DASHBOARD_INSTANCES_QUERY>;
}) {
  //   if (props.query.status === "loading") {
  //     return (
  //       <>
  //         <Skeleton h={10} rounded="md" w={80} />
  //         <Skeleton h={10} rounded="md" w={32} />
  //         <Skeleton h={10} rounded="md" w={32} />
  //         <Skeleton h={10} rounded="md" w={32} />
  //       </>
  //     );
  //   }

  return (
    <>
      <InputGroup width="xs">
        <RangeDatePicker
          endDate={props.filters.getFilterOrNull("startedAtTo")}
          placeholderText="Date range"
          startDate={props.filters.getFilterOrNull("startedAtFrom")}
          onChange={(params) => {
            const [from, to] = params ?? [];
            props.filters.setFilters({ startedAtFrom: from, startedAtTo: to });
          }}
        />
        <InputRightElement pointerEvents="none">
          <CalendarIcon _groupFocusWithin={{ color: "blue.600" }} color="gray.400" />
        </InputRightElement>
      </InputGroup>
      <Select
        label="Statuses"
        multiple={true}
        options={[
          { value: "IN_PROGRESS", label: "In progress" },
          { value: "COMPLETED", label: "Completed" },
          { value: "ERROR", label: "Error" },
          { value: "CANCELED", label: "Canceled" },
        ]}
        value={props.filters.getFilter("status")?.in ?? null}
        onChange={(x) => props.filters.setFilter("status", fmapIn(x))}
      />

      <EntitySelect
        input={{ type: "entity", entity: "Patient" }}
        label="Patients"
        multiple={true}
        renderUnselected="Patients"
        value={toArrayOrNull(props.filters.getFilter("patientIds"))}
        onChange={(x) => props.filters.setFilter("patientIds", x)}
      />

      <EntitySelect
        input={{ type: "entity", entity: "Caregiver" }}
        label="Caregivers"
        multiple={true}
        renderUnselected="Caregivers"
        value={toArrayOrNull(props.filters.getFilter("caregiverIds"))}
        onChange={(x) => props.filters.setFilter("caregiverIds", x)}
      />
    </>
  );
}

function WorkflowPageTitle(props: { query: WorkflowDashboardQuery }) {
  switch (props.query.status) {
    case "loading":
      return <Skeleton h={9} rounded="full" w={36} />;
    case "error":
      return (
        <Heading color="red" size="lg">
          Something went wrong
        </Heading>
      );
    case "success":
      return <Heading size="lg">{props.query.data.activeVersion.name}</Heading>;
  }
}

function WorkflowPageMeta(props: { query: WorkflowDashboardQuery }) {
  switch (props.query.status) {
    case "loading":
      return <Skeleton h={5} rounded="full" w={80} />;
    case "error":
      return (
        <Text color="gray.600" fontSize="sm">
          {String(props.query.error)}
        </Text>
      );
    case "success":
      return (
        <Text color="gray.600" fontSize="sm">
          Created by <B>{props.query.data.activeVersion.owner.name}</B>
          {" at "}
          <B>{dateFormatter.toDateTime(props.query.data.activeVersion.createdAt)}</B>
        </Text>
      );
  }
}

function WorkflowPageTemplate(props: {
  isFetching: boolean;
  header: React.ReactNode;
  title: React.ReactNode;
  meta: React.ReactNode;
  actions: React.ReactNode;
  filters: React.ReactNode;
  graph: React.ReactNode;
  table: React.ReactNode;
}) {
  return (
    <Page>
      {props.header}
      <Box>
        <Progress bg="gray.50" h="1px" isIndeterminate={props.isFetching} />
      </Box>
      <Flex
        align="center"
        bg="gray.50"
        borderBottomColor="gray.200"
        borderBottomWidth={1}
        gap={6}
        justify="space-between"
        p={10}
        wrap="wrap"
      >
        <Flex direction="column" gap={1}>
          {props.title}
          <Flex color="gray.600" fontSize="sm" gap={1}>
            {props.meta}
          </Flex>
        </Flex>
        <Flex gap={2}>{props.actions}</Flex>
      </Flex>
      <Box>
        <Progress bg="gray.50" h="1px" isIndeterminate={props.isFetching} />
      </Box>
      <Flex direction="column" gap={8} p={8}>
        <Flex gap={2} position="relative" wrap="wrap" zIndex={1}>
          {props.filters}
        </Flex>
        <Box>{props.graph}</Box>
        <Box>{props.table}</Box>
      </Flex>
    </Page>
  );
}

function B(props: TextProps) {
  return <Text as="b" fontWeight="semibold" {...props} />;
}

const Page = (props: React.ComponentProps<typeof Flex>) => {
  return <Flex direction="column" h="full" {...props} />;
};
