import { Button } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { WorkflowTaskClusterId } from "../schema/schema";
import { useApi } from "../utils/use-api";
import LoadingButton from "./LoadingButton";
import Select, { CustomSelectProps } from "./Select";
import SelectNoResults from "./SelectNoResults";

export default function WorkflowTaskClusterSelect(props: CustomSelectProps<WorkflowTaskClusterId>) {
  const { queries } = useApi();

  const query = useQuery(queries.clusters());

  switch (query.status) {
    case "loading":
      return <LoadingButton />;
    case "error":
      return (
        <Button
          isDisabled={true}
          justifyContent="flex-start"
          variant="outline"
          {...props.buttonProps}
        >
          Failed to render input
        </Button>
      );
    case "success":
      return (
        <Select
          label={props.multiple ? "Clusters" : "Cluster"}
          options={query.data.clusters.map((x) => ({
            value: x.id,
            label: x.name,
            description: x.id,
          }))}
          renderAfter={({ searchTerm, filteredOptions }) =>
            filteredOptions.length === 0 && <SelectNoResults searchTerm={searchTerm} />
          }
          width="fit-content"
          {...props}
        />
      );
  }
}
