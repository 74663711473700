import { Divider, Flex } from "@chakra-ui/react";
import { Duration } from "@js-joda/core";
import DurationText from "../../../../shared/components/DurationText";
import Workflow16Icon from "../../../../shared/icons/Workflow16Icon";
import {
  WorkflowDefinition,
  WorkflowInstanceExecutionResult,
} from "../../../../shared/schemas/workflows";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { noop } from "../../../../shared/utils/general.utils";
import { WorkflowInstanceOrigin } from "../../../Workflows/components/WorkflowInstanceOrigin";
import Sidebar from "../../../Editor/components/Sidebar";
import DisplayResolvedValue from "../../../Editor/components/Sidebars/Node/components/DisplayResolvedValue";

export default function ExecutedWorkflowSidebar(props: {
  workflow: WorkflowDefinition;
  executionResult: WorkflowInstanceExecutionResult;
}) {
  return (
    <Sidebar.Root>
      <Flex align="start" gap={3} p={4}>
        <Workflow16Icon fontSize="xl" />
        <Flex direction="column" flex={1}>
          <Sidebar.Title
            data-testid="workflow-name-input"
            value={props.workflow.name}
            onChange={noop}
          />
          <Sidebar.Description p={0} value={`# ${props.executionResult.id}`} onChange={noop} />
        </Flex>
      </Flex>

      <Divider />

      {props.executionResult.input.length > 0 && (
        <Sidebar.Section>
          <Sidebar.Row>
            <Sidebar.SectionTitle>Input</Sidebar.SectionTitle>
          </Sidebar.Row>
          {props.executionResult.input.map((input) => (
            <Sidebar.Row key={input.name}>
              <Sidebar.Label>{input.name}</Sidebar.Label>
              <DisplayResolvedValue value={input.displayValue} />
            </Sidebar.Row>
          ))}
        </Sidebar.Section>
      )}

      <Divider />

      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Trigger</Sidebar.SectionTitle>
        </Sidebar.Row>
        <Sidebar.Row>
          <Sidebar.Label>Origin</Sidebar.Label>
          <WorkflowInstanceOrigin {...props.executionResult.origin} />
        </Sidebar.Row>
        <Sidebar.Row>
          <Sidebar.Label>Date</Sidebar.Label>
          <Sidebar.Text>
            {dateFormatter.toDateOrDateTime(props.executionResult.startedAt)}
          </Sidebar.Text>
        </Sidebar.Row>
      </Sidebar.Section>

      <Divider />

      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Finish</Sidebar.SectionTitle>
        </Sidebar.Row>
        <Sidebar.Row>
          <Sidebar.Label>Type</Sidebar.Label>
          <Sidebar.Text>{props.executionResult.status.name}</Sidebar.Text>
        </Sidebar.Row>
        {props.executionResult.finishedAt != null && (
          <>
            <Sidebar.Row>
              <Sidebar.Label>Date</Sidebar.Label>
              <Sidebar.Text>
                {dateFormatter.toDateOrDateTime(props.executionResult.finishedAt)}
              </Sidebar.Text>
            </Sidebar.Row>
            <Sidebar.Row>
              <Sidebar.Label>Duration</Sidebar.Label>
              <Sidebar.Text>
                <DurationText
                  milliseconds={Duration.between(
                    props.executionResult.startedAt,
                    props.executionResult.finishedAt,
                  ).toMillis()}
                ></DurationText>
              </Sidebar.Text>
            </Sidebar.Row>
          </>
        )}
      </Sidebar.Section>
    </Sidebar.Root>
  );
}
