import Select from "../../../../../../shared/components/Select";
import BaselineMinusIcon from "../../../../../../shared/icons/BaselineMinusIcon";
import TriggerIcon from "../../../../../../shared/icons/TriggerIcon";
import {
  TaskTriggerTriggerOn,
  WorkflowEntity,
  WorkflowEventTriggerOption,
  WorkflowTaskTrigger,
  taskTriggerInitOns,
} from "../../../../../../shared/schemas/workflows";
import { InputPath } from "../../../../utils/workflow-node.utils";
import Sidebar from "../../../Sidebar";
import WorkflowComponent from "../../../WorkflowComponent";
import EventTriggerSelection from "./EventTriggerSelection";
import TaskTriggerConditions from "./TaskTriggerConditions";

interface Props {
  trigger: WorkflowTaskTrigger;
  triggerEventOptions: WorkflowEventTriggerOption[];
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  onUpdate: (trigger: WorkflowTaskTrigger) => void;
  onRemove: () => void;
}

function TaskTriggerComponent(props: Props) {
  const triggerInitOnDescription = {
    "on-task-evaluation": "Trigger starts listening when the task is ready to be executed.",
    "on-workflow-creation": "Trigger starts listening after the workflow is created.",
  } satisfies Record<TaskTriggerTriggerOn, string>;
  return (
    <>
      <Sidebar.Row>
        <WorkflowComponent.Title>
          <TriggerIcon fontSize="xl" />
          <EventTriggerSelection
            trigger={props.trigger}
            triggerEventOptions={props.triggerEventOptions}
            onUpdate={props.onUpdate}
          />
        </WorkflowComponent.Title>
        <Sidebar.SquareButton aria-label="remove" onClick={props.onRemove}>
          <BaselineMinusIcon />
        </Sidebar.SquareButton>
      </Sidebar.Row>
      <Sidebar.Row>
        <Sidebar.Label>Evaluation time</Sidebar.Label>
        <Select
          buttonProps={{ size: "xs" }}
          label="Select"
          options={taskTriggerInitOns.map((value) => ({
            value,
            label: value,
            description: triggerInitOnDescription[value],
          }))}
          value={props.trigger.triggerInitOn}
          onChange={(triggerInitOn) =>
            props.onUpdate({ ...props.trigger, triggerInitOn: triggerInitOn ?? null })
          }
        />
      </Sidebar.Row>
      {props.trigger.event !== null && props.trigger.triggerInitOn !== null && (
        <Sidebar.Row>
          <WorkflowComponent.Light>
            <TaskTriggerConditions
              availablePaths={
                props.trigger.triggerInitOn === "on-task-evaluation"
                  ? props.availablePaths
                  : props.availablePaths.filter((path) => path.type !== "tasks")
              }
              conditions={props.trigger.conditions}
              entityOptions={props.entityOptions}
              eventTriggerOption={props.trigger.event}
              onUpdateConditions={(conditions) => props.onUpdate({ ...props.trigger, conditions })}
            />
          </WorkflowComponent.Light>
        </Sidebar.Row>
      )}
    </>
  );
}

export default TaskTriggerComponent;
