import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { Fragment } from "react";
import { Link, LinkProps } from "react-router-dom";
import ChevronRightIcon from "../icons/ChevronRightIcon";
import HomeIcon from "../icons/HomeIcon";
import UserAvatarFilledIcon from "../icons/UserAvatarFilledIcon";
import useAuth from "../utils/use-auth";

interface Props {
  breadcrumbs: BreadCrumbItemParams[];
  actions?: React.ReactNode;
}

interface BreadCrumbItemParams {
  name: string;
  to?: string;
  active?: boolean;
}

function Header(props: Props) {
  const { authData, logout } = useAuth();

  return (
    <Root>
      <HeaderWrapper>
        <HeaderBreadcrumbs>
          <HeaderBreadcrumbItem to={{ pathname: "/" }}>
            <HomeIcon fontSize="2xl" />
          </HeaderBreadcrumbItem>
          {props.breadcrumbs.map((breadcrumb, index) => (
            <Fragment key={index}>
              <ChevronRightIcon fontSize="3xl" />
              <HeaderBreadcrumbItem active={breadcrumb.active} as={Link} to={breadcrumb.to ?? {}}>
                {breadcrumb.name}
              </HeaderBreadcrumbItem>
            </Fragment>
          ))}
        </HeaderBreadcrumbs>
        <Flex align="center" gap={8}>
          {props.actions}
          <HeaderAvatarWrapper>
            <Menu isLazy>
              <MenuButton>
                <HeaderAvatar src={authData.userInfo?.agencyMember.photoUrl ?? null} />
              </MenuButton>
              <MenuList>
                <MenuGroup
                  title={`${authData.userInfo?.agencyMember.firstName} ${authData.userInfo?.agencyMember.lastName}`}
                >
                  <MenuItem onClick={() => logout()}>Logout</MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </HeaderAvatarWrapper>
        </Flex>
      </HeaderWrapper>
    </Root>
  );
}

const Root = (props: React.ComponentProps<typeof Flex>) => (
  <Flex
    as="header"
    borderBottomColor="gray.200"
    borderBottomWidth={1}
    direction="column"
    position="relative"
    px={6}
    py={2}
    shadow="sm"
    w="full"
    zIndex={1}
    {...props}
  />
);

const HeaderWrapper = (props: React.ComponentProps<typeof Flex>) => (
  <Flex align="center" justify="space-between" {...props} />
);

const HeaderBreadcrumbs = (props: React.ComponentProps<typeof Flex>) => (
  <Flex align="center" gap={2} justify="space-between" {...props} />
);

const HeaderBreadcrumbItem = (
  props: React.ComponentProps<typeof Button> & Pick<LinkProps, "to"> & { active?: boolean },
) => {
  const { active, ...rest } = props;

  return (
    <Button
      as={Link}
      colorScheme={active === true ? "blue" : "gray.600"}
      type="button"
      variant="link"
      {...rest}
    />
  );
};

const HeaderAvatarWrapper = (props: React.ComponentProps<typeof Flex>) => (
  <Flex align="center" {...props} />
);

const HeaderAvatar = (props: { src: string | null }) => {
  const size = 12;

  if (props.src == null) {
    return <UserAvatarFilledIcon color="gray.400" h={size} w={size} />;
  }

  return <Image h={size} rounded="full" src={props.src} w={size} />;
};

export default Header;
