import { Flex } from "@chakra-ui/react";
import React from "react";
import Sidebar from "../../../Sidebar";

interface Props {
  value: number;
  onChange: (value: number) => void;
}

const radioOptions = ["Seconds", "Minutes", "Hours"] as const;
type Unit = (typeof radioOptions)[number];

function IntervalInput(props: Props) {
  const value = props.value;
  const selectedRadio = findUnit(value);
  const displayValue = getDisplayValue(value, selectedRadio);

  const handleChange = (number: number, unit: Unit) => {
    const newTimeout = displayValueToSeconds(number, unit);

    props.onChange(newTimeout);
  };

  const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const number = parseInt(event.target.value);
    handleChange(isNaN(number) || number < 0 ? 0 : number, selectedRadio);
  };

  const handleChangeUnit = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const unit = event.target.value as Unit;
    handleChange(displayValue, unit);
  };

  return (
    <Flex direction="row" gap={4}>
      <Sidebar.Input
        w={12}
        {...props}
        data-testid="interval-input"
        value={displayValue}
        onChange={handleChangeNumber}
      />
      <Sidebar.Select
        data-testid="interval-unit-select"
        value={selectedRadio}
        onChange={handleChangeUnit}
      >
        {radioOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Sidebar.Select>
    </Flex>
  );
}

function findUnit(value: number): (typeof radioOptions)[number] {
  if (value % 3600 === 0) {
    return "Hours";
  }

  if (value % 60 === 0) {
    return "Minutes";
  }

  return "Seconds";
}

function displayValueToSeconds(value: number, unit: Unit): number {
  switch (unit) {
    case "Seconds":
      return value;

    case "Minutes":
      return value * 60;

    case "Hours":
      return value * 3600;
  }
}

function getDisplayValue(value: number, unit: Unit): number {
  switch (unit) {
    case "Seconds":
      return value;

    case "Minutes":
      return value / 60;

    case "Hours":
      return value / 3600;
  }
}

export default IntervalInput;
