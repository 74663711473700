import { LocalDate, LocalDateTime, LocalTime } from "@js-joda/core";
import { XYPosition } from "reactflow";
import { InputPath } from "../../pages/Editor/utils/workflow-node.utils";
import { ResponseOf } from "../api";
import {
  AgencyMemberId,
  WorkflowDefinitionId,
  WorkflowInstanceId,
  WorkflowTagId,
  WorkflowTaskClusterId,
  WorkflowTaskInstanceId,
  components,
} from "../schema/schema";
import { Flatten } from "../utils/types";

export type Messages = components["schemas"];

export type EventTriggerType = "INSERT" | "UPDATE" | "DELETE";

export type WorkflowEventTriggerOption = {
  name: Messages["WorkflowEventTriggerOption"]["name"];
  table: Messages["WorkflowEventTriggerOption"]["table"];
  type: EventTriggerType;
  fields: Messages["WorkflowEventTriggerOption"]["columns"];
};

export type WorkflowJobTriggerOption = Messages["WorkflowJobTriggerOption"];

export type WorkflowEntity = Messages["WorkflowEntity"];

export type WorkflowInput = Messages["WorkflowInput"];

// export type WorkflowChildReferenceBinding = Messages["WorkflowChildReferenceBinding"];

export const fieldTypeToFormatterActions = {
  number: ["add", "subtract", "multiply", "divide", "round", "floor", "ceil"],
  date: ["add", "subtract"],
  datetime: ["add", "subtract"],
} as const;

export type Formatters = {
  Number: {
    valueType: "number";
    action: (typeof fieldTypeToFormatterActions)["number"][number];
    value: WorkflowChildReferenceBinding | null;
  };
  Date: {
    valueType: "date";
    action: (typeof fieldTypeToFormatterActions)["date"][number];
    value: {
      unit: DateFormatterUnit;
      interval: WorkflowChildReferenceBinding;
    } | null;
  };
  DateTime: {
    valueType: "datetime";
    action: (typeof fieldTypeToFormatterActions)["date"][number];
    value: {
      unit: DateTimeFormatterUnit;
      interval: WorkflowChildReferenceBinding;
    } | null;
  };
};

export type Formatter = Formatters["Number"] | Formatters["Date"] | Formatters["DateTime"];

export type DateFormatterUnit = (typeof dateFormatterUnits)[number];
export type DateTimeFormatterUnit = (typeof dateTimeFormatterUnits)[number];

export const dateFormatterUnits = ["days", "weeks", "months", "years"] as const;
export const dateTimeFormatterUnits = [
  "seconds",
  "minutes",
  "hours",
  "days",
  "weeks",
  "months",
  "years",
] as const;

export type FormatterFieldType = WorkflowDataField & {
  type: Formatter["valueType"];
};

export type WorkflowChildReferenceBindings = {
  Path: {
    type: "path";
    path: InputPath | null;
    formatter?: Formatter;
  };
  Text: ArrayableBinding<{
    type: "text" | "textarea";
    value: string | null;
    placeholders?: Record<string, WorkflowChildReferenceBinding>;
  }>;
  Number: ArrayableBinding<{
    type: "number";
    value: number | null;
  }>;
  Boolean: ArrayableBinding<{
    type: "boolean";
    value: boolean | null;
  }>;
  Date: ArrayableBinding<{
    type: "date";
    value: LocalDate | null;
  }>;
  Time: ArrayableBinding<{
    type: "time";
    value: LocalTime | null;
  }>;
  DateTime: ArrayableBinding<{
    type: "datetime";
    value: LocalDateTime | null;
  }>;
  File: ArrayableBinding<{
    type: "file";
    value: string | null;
  }>;
};

export type NonNullableBinding<T extends WorkflowChildReferenceBinding> = Flatten<
  T extends {
    path: infer U | null;
  }
    ? Omit<T, "path"> & { path: U }
    : T extends { value: infer U | null }
    ? Omit<T, "value"> & { value: U }
    : never
>;

type ArrayableBinding<T extends { type: unknown; value: unknown }> = Omit<T, "value"> &
  ({ array: true; value: T["value"][] } | { array: false; value: T["value"] });

export type WorkflowChildReferenceBinding =
  | WorkflowChildReferenceBindings["Path"]
  | WorkflowChildReferenceBindings["Text"]
  | WorkflowChildReferenceBindings["Number"]
  | WorkflowChildReferenceBindings["Boolean"]
  | WorkflowChildReferenceBindings["Date"]
  | WorkflowChildReferenceBindings["Time"]
  | WorkflowChildReferenceBindings["DateTime"]
  | WorkflowChildReferenceBindings["File"];

export type WorkflowHumanTaskTemplateReferenceBinding =
  Messages["WorkflowHumanTaskTemplateReferenceBinding"];

export type WorkflowChildDependsOn = [string, "output" | "error", string];

export type WorkflowChildSystemTaskReference = {
  parent: string | null;
  dependsOn?: Messages["WorkflowChildSystemTask"]["dependsOn"];
  trigger?: WorkflowTaskTrigger;
  logicalId: Messages["WorkflowChildSystemTask"]["logicalId"];
  type: Messages["WorkflowChildSystemTask"]["type"];
  task: Task;
  queries: Map<string, NodeReferenceQuery>;
  bind: Record<string, WorkflowChildReferenceBinding>;
  meta: Messages["WorkflowChildSystemTask"]["meta"];
  scheduling?: WorkflowChildScheduling;
  errors?: WorkflowError[];
  retry?: WorkflowRetry;
  tags: WorkflowTagReference[];
};

export type NamedOutput = {
  name: string;
  output: Record<string, NamedField>;
};

export type WorkflowChildHumanTaskReference = {
  parent: string | null;
  logicalId: string;
  dependsOn?: Messages["WorkflowChildHumanTask"]["dependsOn"];
  trigger?: WorkflowTaskTrigger;
  type: Messages["WorkflowChildHumanTask"]["type"];
  queries: Map<string, NodeReferenceQuery>;
  output: Record<string, NamedOutput>;
  skills: Messages["WorkflowChildHumanTask"]["skills"];
  assignmentTimeoutSeconds: number | null;
  assignmentStrategy: HumanTaskAssignmentStrategy | null;
  template: HumanTaskTemplateReference;
  meta: Messages["WorkflowChildHumanTask"]["meta"];
  scheduling?: WorkflowChildScheduling;
  errors?: WorkflowError[];
  retry?: WorkflowRetry;
  cluster?: WorkflowTaskClusterReference;
  tags: WorkflowTagReference[];
};

export interface WorkflowTriggerRetry {
  type: "trigger";
  maxRetries: number;
  retryPaths: string[];
}

export interface WorkflowIntervalRetry {
  type: "interval";
  maxRetries: number;
  interval: number;
  retryPaths: string[];
}

export type WorkflowRetry = WorkflowTriggerRetry | WorkflowIntervalRetry;

export type ValueOperator = Messages["WorkflowValueConditionOperator"];

export type ChoiceTaskCondition = {
  operator: ValueOperator;
  left: WorkflowChildReferenceBindings["Path"];
  right: WorkflowChildReferenceBinding | null;
};

export type ChoiceTaskConditions = {
  operator: "and" | "or";
  conditions: (ChoiceTaskCondition | ChoiceTaskConditions)[];
};

export type WorkflowChoice = {
  conditions: ChoiceTaskConditions;
  output: string;
};

export interface WorkflowTaskTriggerCondition {
  property: string | null;
  operator: ValueOperator;
  bind: WorkflowChildReferenceBinding | null;
}

export interface WorkflowTaskTriggerConditions {
  operator: "and" | "or";
  conditions: (WorkflowTaskTriggerCondition | WorkflowTaskTriggerConditions)[];
}

export const taskTriggerInitOns = ["on-workflow-creation", "on-task-evaluation"] as const;
export type TaskTriggerTriggerOn = (typeof taskTriggerInitOns)[number];
export interface WorkflowTaskTrigger {
  id: number;
  event: WorkflowEventTriggerOption | null;
  conditions: WorkflowTaskTriggerConditions;
  triggerInitOn: TaskTriggerTriggerOn | null;
}

export const schedulingTypes = ["createAfter", "createBefore", "finishBefore"] as const;
export type SchedulingType = (typeof schedulingTypes)[number];

export type WorkflowChildScheduling = {
  timeout?: number;

  createAfter?:
    | WorkflowChildReferenceBindings["Path"]
    | WorkflowChildReferenceBindings["DateTime"]
    | null;

  createBefore?:
    | WorkflowChildReferenceBindings["Path"]
    | WorkflowChildReferenceBindings["DateTime"]
    | null;

  finishBefore?:
    | WorkflowChildReferenceBindings["Path"]
    | WorkflowChildReferenceBindings["DateTime"]
    | null;
};

export type WorkflowChildChoiceTask = {
  parent: string | null;
  dependsOn?: Messages["WorkflowChildChoiceTask"]["dependsOn"];
  trigger?: WorkflowTaskTrigger;
  logicalId: Messages["WorkflowChildChoiceTask"]["logicalId"];
  type: "choice";
  choices: WorkflowChoice[];
  default?: {
    output: string;
  };
  queries: Map<string, NodeReferenceQuery>;
  meta: {
    name: string;
    position?: XYPosition;
    description?: string;
  };
  scheduling?: WorkflowChildScheduling;
  errors?: WorkflowError[];
  retry?: WorkflowRetry;
  tags: WorkflowTagReference[];
};

export type WorkflowChildShortCircuitTask = {
  parent: string | null;
  dependsOn?: WorkflowChildDependsOn;
  trigger?: WorkflowTaskTrigger;
  logicalId: string;
  type: "short-circuit-task";
  queries: Map<string, NodeReferenceQuery>;
  errors?: WorkflowError[];
  scheduling?: WorkflowChildScheduling;
  retry?: WorkflowRetry;
  tags: WorkflowTagReference[];
  meta: {
    name: string;
    position?: XYPosition;
    description?: string;
    mandatory: boolean;
    priority: number;
    severity: string;
  };
};

export type WorkflowChildResetToTask = {
  parent: string | null;
  dependsOn?: WorkflowChildDependsOn;
  trigger?: WorkflowTaskTrigger;
  logicalId: string;
  type: "reset-to-task";
  queries: Map<string, NodeReferenceQuery>;
  errors?: WorkflowError[];
  to: {
    bind: Record<string, WorkflowChildReferenceBinding>;
    node: WorkflowNode;
  } | null;
  scheduling?: WorkflowChildScheduling;
  tags: WorkflowTagReference[];
  meta: {
    name: string;
    position?: XYPosition;
    description?: string;
    mandatory: boolean;
    priority: number;
    severity: string;
  };
};

export type NodeReferenceQuery = {
  queryDefinition: WorkflowTaskQuery | null;
  bind: Record<string, WorkflowChildReferenceBinding>;
};

export type WorkflowChildWorkflowReference = {
  parent: string | null;
  dependsOn?: Messages["WorkflowChildWorkflow"]["dependsOn"];
  trigger?: WorkflowTaskTrigger;
  logicalId: Messages["WorkflowChildWorkflow"]["logicalId"];
  type: Messages["WorkflowChildWorkflow"]["type"];
  workflow: Messages["WorkflowChildWorkflow"]["workflow"];
  bind: Record<string, WorkflowChildReferenceBinding>;
  fallbackToManual?: Messages["WorkflowChildWorkflow"]["fallbackToManual"];
  meta: Messages["WorkflowChildWorkflow"]["meta"];
  scheduling?: WorkflowChildScheduling;
  errors?: WorkflowError[];
  tags: WorkflowTagReference[];
};

export type WorkflowNode =
  | WorkflowChildSystemTaskReference
  | WorkflowChildHumanTaskReference
  | WorkflowChildChoiceTask
  | WorkflowChildShortCircuitTask
  | WorkflowChildResetToTask
  | WorkflowChildWorkflowReference
  | WorkflowChildGroup;

export type WorkflowChildGroup = {
  parent: string | null;
  dependsOn?: Messages["WorkflowChildGroup"]["dependsOn"];
  trigger?: WorkflowTaskTrigger;
  logicalId: Messages["WorkflowChildGroup"]["logicalId"];
  type: Messages["WorkflowChildGroup"]["type"];
  children: Record<string, WorkflowNode>;
  meta: Messages["WorkflowChildGroup"]["meta"];
  scheduling?: WorkflowChildScheduling;
  errors?: WorkflowError[];
  tags: WorkflowTagReference[];
};

export type WorkflowJobTriggerReport =
  | {
      type: "advanced";
      id: Messages["SavedReportId"];
      uniqueBy: string[] | null;
    }
  | {
      type: "standard";
      id: Messages["StandardReportId"];
      uniqueBy: string[] | null;
      filterBind: Record<string, WorkflowChildReferenceBinding>;
    };

export type WorkflowJobTrigger = {
  id: string;
  type: Messages["WorkflowJobTrigger"]["type"];
  pattern: Messages["WorkflowJobTrigger"]["pattern"];
  report: WorkflowJobTriggerReport | null;
  bind: Messages["WorkflowJobTrigger"]["bind"];
};

export type WorkflowEventTriggerCondition = {
  property: WorkflowChildReferenceBindings["Path"] | null;
  operator: ValueOperator;
  value: WorkflowChildReferenceBinding | null;
};

export type WorkflowEventTriggerConditions = {
  operator: "and" | "or";
  conditions: (WorkflowEventTriggerCondition | WorkflowEventTriggerConditions)[];
};

export type WorkflowEventTrigger = {
  id: string;
  type: Messages["WorkflowEventTrigger"]["type"];
  event: WorkflowEventTriggerOption | null;
  conditions: WorkflowEventTriggerConditions;
  bind: Messages["WorkflowEventTrigger"]["bind"];
};

export type WorkflowTrigger = WorkflowEventTrigger | WorkflowJobTrigger;

export type WorkflowDefinition = {
  id: Messages["WorkflowDefinition"]["id"];
  name: Messages["WorkflowDefinition"]["name"];
  description: Messages["WorkflowDefinition"]["description"];
  correlationKey: Messages["WorkflowDefinition"]["correlationKey"];
  version: Messages["WorkflowDefinition"]["version"];
  meta: {
    owner: AgencyMemberId;
    priority: number;
    severity: string;
    timeout: null | number;
    published: boolean;
    createHumanTaskOnFailure: boolean;
    tags?: WorkflowTagReference[];
  };
  triggers: WorkflowTrigger[];
  input: Map<string, Messages["WorkflowInput"]>;
  output: Messages["WorkflowDefinition"]["output"];
  children: Record<string, WorkflowNode>;
  concurrencyRule: ConcurrencyRuleOption;
};

export type WorkflowDataField = Messages["WorkflowDataFieldType"];
export type ResolvedWorkflowDataField =
  | Messages["WorkflowEntityField"]
  | Messages["WorkflowTextField"]
  | Messages["WorkflowNumberField"]
  | Messages["WorkflowBooleanField"]
  | Messages["WorkflowDateField"]
  | Messages["WorkflowTimeField"]
  | Messages["WorkflowDateTimeField"]
  | Messages["WorkflowFileField"];

export type NamedField = WorkflowDataField & { name: string };

export type NamedResolvedField = ResolvedWorkflowDataField & { name: string };

export interface WorkflowTaskQuery {
  version: number;
  name: string;
  description: string;
  input: Record<string, WorkflowDataField>;
  output: Record<string, WorkflowDataField>;
}

export type Task = {
  id: Messages["WorkflowTaskDefinition"]["id"];
  version: Messages["WorkflowTaskDefinition"]["version"];
  meta: Messages["WorkflowTaskDefinition"]["meta"];
  input: { [key: string]: WorkflowDataField };
  output: Record<string, NamedOutput>;
};

export type ListedWorkflow = {
  correlationKey: string;
  meta: {
    owner: {
      id: components["schemas"]["AgencyMemberId"];
      name: string;
    };
    priority: number;
    severity: string;
  };
  name: Messages["ListedWorkflow"]["name"];
  lastVersion: {
    id: WorkflowDefinitionId;
    number: number;
  };
  lastPublishedVersion: number | null;
};

export type Owner = {
  id: AgencyMemberId;
  name: string;
};

export interface ListedWorkflowDefinitions {
  workflows: ListedWorkflow[];
}

export type ListedWorkflowFilters = {
  name: string;
  owners: AgencyMemberId[];
  publicationStatus: "PUBLISHED" | "UNPUBLISHED" | null;
};

export type WorkflowVersion = {
  id: WorkflowDefinitionId;
  name: string;
  input: Map<string, WorkflowInput>;
  number: number;
  published: boolean;
  owner: {
    id: components["schemas"]["AgencyMemberId"];
    name: string;
  };
  createdAt: Messages["Instant"];
};

export type HumanTaskTemplateReference = {
  template: HumanTaskTemplateDefinition | null;
  bind: Record<string, WorkflowChildReferenceBinding>;
};
export type HumanTaskTemplateDefinition = {
  layout: Messages["WorkflowHumanTaskTemplateDefinition"]["layout"];
  input: Messages["WorkflowHumanTaskTemplateDefinition"]["input"];
};

export type HumanTaskAssignmentStrategyOption = {
  type: string;
  input?: Record<string, WorkflowDataField>;
};

export type HumanTaskAssignmentStrategy = {
  option: HumanTaskAssignmentStrategyOption;
  bind?: Record<string, WorkflowChildReferenceBinding>;
};

export const WORKFLOW_CONCURRENCY_DOMAINS = {
  GLOBAL: "GLOBAL",
  CORRELATION: "CORRELATION",
  DEFINITION: "DEFINITION",
} as const;

export const WORKFLOW_CONCURRENCY_RULES = {
  RUN_ALWAYS: "RUN_ALWAYS",
  RUN_FOR_UNIQUE_INPUT: "RUN_FOR_UNIQUE_INPUT",
  RUN_FOR_ACTIVE_UNIQUE_INPUT: "RUN_FOR_ACTIVE_UNIQUE_INPUT",
} as const;

export type ConcurrencyRule = keyof typeof WORKFLOW_CONCURRENCY_RULES;
export type ConcurrencyDomain = keyof typeof WORKFLOW_CONCURRENCY_DOMAINS;
export type ConcurrencyRuleOption = {
  domain: ConcurrencyDomain;
  rule: ConcurrencyRule;
};

export interface WorkflowError {
  id: string;
  on: WorkflowErrorEvent[];
  handler: WorkflowErrorHandler;
}

export const workflowErrorEvents = [
  "unexpected",
  "timeout",
  "createBefore",
  "finishBefore",
  "retryLimit",
] as const;

export type WorkflowErrorEvent = (typeof workflowErrorEvents)[number];

export type WorkflowErrorHandler =
  | WorkflowNewTaskErrorHandler
  | WorkflowFallbackToManualErrorHandler
  | WorkflowFallbackToDefaultErrorHandler;

export interface WorkflowNewTaskErrorHandler {
  type: "new-task";
}

export interface WorkflowTaskClusterReference {
  id: WorkflowTaskClusterId;
  bind: Record<string, WorkflowChildReferenceBinding>;
}

export interface WorkflowFallbackToManualErrorHandler {
  type: "fallback-to-manual";
  queries: Map<string, NodeReferenceQuery>;
  template: HumanTaskTemplateReference;
  skills: Messages["WorkflowChildHumanTask"]["skills"];
  assignmentTimeoutSeconds: WorkflowChildHumanTaskReference["assignmentTimeoutSeconds"];
  assignmentStrategy: HumanTaskAssignmentStrategy | null;
  meta: Messages["WorkflowChildHumanTask"]["meta"];
  scheduling?: Pick<WorkflowChildScheduling, "timeout" | "finishBefore">;
  cluster?: WorkflowTaskClusterReference;
}

export interface WorkflowFallbackToDefaultErrorHandler {
  type: "fallback-to-default";
  status: string | null;
  output: Record<string, WorkflowChildReferenceBinding>;
}

export type WorkflowErrorHandlerType = WorkflowErrorHandler["type"];

export type NodeOutput = {
  success: Record<string, NamedOutput>;
  error?: Record<string, NamedOutput>;
};

export type NodeOutputKey = {
  type: "output" | "error";
  key: string;
};

type ArrayableInput<T extends { type: unknown; value: unknown }> = Omit<T, "value"> &
  (
    | { name: string; array: true; value: T["value"][]; displayValue: string[] }
    | { name: string; array: false; value: T["value"] | null; displayValue: string | null }
  );

export type ResolvedInputOption = {
  Entity: ArrayableInput<{
    type: "entity";
    entity: Messages["WorkflowEntity"];
    value: WorkflowEntity;
  }>;
  Text: ArrayableInput<{
    type: "text" | "textarea";
    value: string;
  }>;
  Number: ArrayableInput<{
    type: "number";
    value: number;
  }>;
  Boolean: ArrayableInput<{
    type: "boolean";
    value: boolean;
  }>;
  Date: ArrayableInput<{
    type: "date";
    value: LocalDate;
  }>;
  Time: ArrayableInput<{
    type: "time";
    value: LocalTime;
  }>;
  DateTime: ArrayableInput<{
    type: "datetime";
    value: LocalDateTime;
  }>;
  File: ArrayableInput<{
    type: "file";
    value: string;
  }>;
  Unknown: ArrayableInput<{
    type: "unknown";
    value: unknown;
  }>;
};

export type ResolvedInput =
  | ResolvedInputOption["Entity"]
  | ResolvedInputOption["Text"]
  | ResolvedInputOption["Number"]
  | ResolvedInputOption["Boolean"]
  | ResolvedInputOption["Date"]
  | ResolvedInputOption["Time"]
  | ResolvedInputOption["DateTime"]
  | ResolvedInputOption["File"]
  | ResolvedInputOption["Unknown"];

export type NodeResult = {
  id: WorkflowTaskInstanceId | null;
  input: ResolvedInput[];
  output: {
    key: string;
    fields: ResolvedInput[];
  } | null;
  startedAt: Messages["Instant"] | null;
  finishedAt: Messages["Instant"] | null;
  executedBy:
    | {
        type: "system";
      }
    | {
        type: "human";
        agencyMemberId: AgencyMemberId;
        name: string;
      }
    | null;
  status:
    | {
        name: "CREATED" | "WAITING" | "SUCCESS";
      }
    | {
        name: "ERROR";
        errorMessage: string;
      };
};

export type WorkflowInstanceExecutionResult = {
  id: WorkflowInstanceId;
  origin: Messages["WorkflowInstanceOrigin"];
  startedAt: Messages["Instant"];
  finishedAt: Messages["Instant"] | null;
  input: ResolvedInput[];
  nodeResults: Map<string, NodeResult>;
  status:
    | {
        name: "IN_PROGRESS" | "SUCCESS" | "CANCELED";
      }
    | {
        name: "ERROR";
        errorMessage: string;
      };
};

export type WorkflowExecutionValueObject = Messages["WorkflowExecutionValueObjectWithDisplay"];

export type ListedWorkflowAPI = ResponseOf<"get", "./workflow_definitions">["workflows"][number];

export type WorkflowTag = {
  id: WorkflowTagId;
  name: string;
  input: [string, WorkflowDataField][];
};

export type WorkflowTagReference = {
  id: WorkflowTagId;
  name: string;
  input: [string, WorkflowDataField][];
  bind: Record<string, WorkflowChildReferenceBinding>;
};
