import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Skeleton,
  Spinner,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { WorkflowDefinitionCorrelationKey } from "../../../shared/schema/schema";
import { useApi } from "../../../shared/utils/use-api";
import { WorkflowDefinition } from "../../../shared/schemas/workflows";

interface Props {
  workflow: WorkflowDefinition;
  action: "Publish" | "Unpublish";
  isPublishing: boolean;
  onChangePublishState: (params: { published: boolean; deleteScheduledInstances: boolean }) => void;
}

export default function PublishSwitch(props: Props) {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { queries } = useApi();

  const [deleteScheduledInstances, setDeleteScheduledInstances] = React.useState(false);

  const handleAction = () => {
    props.onChangePublishState({
      published: props.action === "Publish",
      deleteScheduledInstances,
    });
    onClose();
  };

  const handleOpenPublishConfirmationAlertDialog = () => {
    onOpen();
    queryClient.invalidateQueries(
      queries.scheduledTasksCount(props.workflow.correlationKey).queryKey,
    );
  };

  return (
    <>
      <PublishSwitchUI
        isPublishing={props.isPublishing}
        workflow={props.workflow}
        onChange={handleOpenPublishConfirmationAlertDialog}
      />
      <Dialog
        action={props.action}
        correlationKey={props.workflow.correlationKey}
        deleteScheduledInstances={deleteScheduledInstances}
        isOpen={isOpen}
        onAction={handleAction}
        onChangeDeleteScheduledInstances={setDeleteScheduledInstances}
        onClose={onClose}
      />
    </>
  );
}

function PublishSwitchUI(props: {
  isPublishing: boolean;
  workflow: WorkflowDefinition;
  onChange: () => void;
}) {
  return (
    <FormControl
      alignItems="center"
      display="flex"
      gap={2}
      hidden={props.workflow.id === 0}
      justifyContent="end"
      w="48"
    >
      {props.isPublishing ? (
        <Spinner m={2} size="sm" textColor="gray.400" />
      ) : (
        <FormLabel
          htmlFor="workflow-publish-control"
          m={0}
          textColor={props.workflow.meta.published ? "gray.800" : "gray.500"}
        >
          {props.workflow.meta.published ? "Published" : "Unpublished"}
        </FormLabel>
      )}
      <Switch
        disabled={props.isPublishing}
        id="workflow-publish-control"
        isChecked={props.workflow.meta.published}
        size="lg"
        onChange={props.onChange}
      />
    </FormControl>
  );
}

function Dialog(props: {
  correlationKey: WorkflowDefinitionCorrelationKey;
  action: "Publish" | "Unpublish";
  isOpen: boolean;
  deleteScheduledInstances: boolean;
  onChangeDeleteScheduledInstances: (deleteScheduledInstances: boolean) => void;
  onAction: () => void;
  onClose: () => void;
}) {
  const toast = useToast();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const { queries } = useApi();
  const scheduledTasksCountQuery = useQuery({
    ...queries.scheduledTasksCount(props.correlationKey),
    onError: (error) => {
      toast({ position: "top", description: `${error}`, status: "error" });
      props.onClose();
    },
  });
  const scheduledWorkflowRunCount = scheduledTasksCountQuery.data?.workflowRunCount ?? 0;
  const inProgressTaskCount = scheduledTasksCountQuery.data?.inProgressTasksCount ?? 0;

  return (
    <AlertDialog isOpen={props.isOpen} leastDestructiveRef={cancelRef} onClose={props.onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {props.action} Workflow
          </AlertDialogHeader>

          <AlertDialogBody>
            {scheduledTasksCountQuery.isLoading && (
              <Stack>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            )}

            {scheduledTasksCountQuery.isSuccess && <Message action={props.action} />}

            {scheduledTasksCountQuery.isSuccess &&
              (scheduledWorkflowRunCount > 0 || inProgressTaskCount > 0) && (
                <Checkbox
                  isChecked={props.deleteScheduledInstances}
                  mt="4"
                  onChange={(e) => props.onChangeDeleteScheduledInstances(e.target.checked)}
                >
                  {[
                    scheduledWorkflowRunCount > 0
                      ? `Delete ${scheduledWorkflowRunCount} scheduled instances`
                      : null,
                    inProgressTaskCount > 0
                      ? `Cancel ${inProgressTaskCount} in progress tasks`
                      : null,
                  ]
                    .filter((x) => x !== null)
                    .join(" & ")}
                </Checkbox>
              )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Actions
              action={props.action}
              cancelRef={cancelRef}
              onAction={props.onAction}
              onClose={props.onClose}
            />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

function Message(props: { action: "Publish" | "Unpublish" }) {
  const message =
    props.action === "Publish"
      ? "Publishing a workflow will activate its triggers on the production environment."
      : "This will deactivate the workflow triggers";

  return <Text>{message}</Text>;
}

function Actions(props: {
  action: "Publish" | "Unpublish";
  cancelRef: React.RefObject<HTMLButtonElement>;
  onClose: () => void;
  onAction: () => void;
}) {
  return (
    <>
      <Button ref={props.cancelRef} onClick={props.onClose}>
        Cancel
      </Button>
      <Button colorScheme="red" data-testid="publication-button" ml={3} onClick={props.onAction}>
        {props.action}
      </Button>
    </>
  );
}
