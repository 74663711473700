import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import React from "react";
import { InputFileUploadDestination } from "../components/FileInput";
import { useApi } from "../utils/use-api";

interface Props {
  uploads: FileEntry[];
  fileUploadDestination: InputFileUploadDestination;
  multiple?: boolean;
  maxFiles?: number;
}

export type FileUpload = {
  type: "file";
  file: File;
  url: string | null;
  percent: number;
  id: string;
};

export interface S3Object {
  type: "s3-object";
  key: string;
  file: File;
}

export type FileEntry = FileUpload | S3Object;

export default function useUploadFiles(props: Props) {
  const [uploads, setUploads] = React.useState<FileEntry[]>(props.uploads);

  const { mutations } = useApi();
  const toast = useToast();

  const uploadFile = useMutation({
    ...mutations.uploadFile(),
    onSuccess: (_, request) => {
      setUploads((prev) =>
        prev.map((upload) => {
          if (upload.type !== "file" || upload.url !== request.url) {
            return upload;
          }

          return {
            type: "s3-object",
            key: extractS3KeyFromUrl(upload.url),
            file: upload.file,
          };
        }),
      );
    },
  });

  const beginUpload = (uploads: FileUpload[]) => {
    for (const upload of uploads) {
      if (upload.url === null) {
        throw new Error("URL not defined");
      }

      uploadFile.mutate({
        file: upload.file,
        url: upload.url,
        onUploadProgress(percentage) {
          setUploads((prev) => updateUploadInArray(prev, upload, percentage));
        },
      });
    }
  };

  const generatePresignedUrls = useMutation({
    ...mutations.generatePresignedUrls(props.fileUploadDestination),
    onSuccess: ({ urls }, uploads) => {
      const newUploads = uploads.map((upload, i) => ({
        ...upload,
        url: urls[i] ?? null,
      }));

      setUploads((prev) => mapFileUploadsWithUrl(prev, newUploads));

      beginUpload(newUploads);
    },
    onError: (error) => toast({ position: "top", description: `${error}`, status: "error" }),
  });

  const attachFiles = (acceptedFiles: File[]) => {
    if (props.maxFiles !== undefined && acceptedFiles.length + uploads.length > props.maxFiles) {
      toast({
        position: "top",
        description:
          props.maxFiles === 1
            ? "Only one file can be uploaded"
            : `You can only upload up to ${props.maxFiles} files`,
        status: "error",
      });
      return;
    }

    const newUploads = acceptedFiles.map(
      (file): FileUpload => ({
        type: "file",
        file,
        url: null,
        percent: 0,
        id: nanoid(),
      }),
    );

    setUploads((prev) => [...prev, ...newUploads]);

    return newUploads;
  };

  const unAttach = (upload: S3Object) => {
    setUploads((prev) => prev.filter((u) => u.type !== "s3-object" || u.key !== upload.key));
  };

  const upload = (uploads: FileUpload[]) => {
    generatePresignedUrls.mutate(uploads);
  };

  const isUploading = uploads.some((upload) => upload.type === "file");

  return {
    isUploading: isUploading,
    uploads: uploads,
    attachFiles: attachFiles,
    unAttach: unAttach,
    upload: upload,
    setUploads: setUploads,
  };
}

function extractS3KeyFromUrl(url: string) {
  const urlObj = new URL(url);
  const bucket = urlObj.hostname.split(".")[0];
  return `s3://${bucket}${urlObj.pathname}`;
}

function mapFileUploadsWithUrl(prev: FileEntry[], newUploads: FileUpload[]) {
  return prev.map((prevUpload) => {
    const newUpload = newUploads.find((u) => prevUpload.type === "file" && u.id === prevUpload.id);

    if (newUpload === undefined) {
      return prevUpload;
    }

    if (newUpload.url === null) {
      throw new Error("URL not defined");
    }

    return {
      ...prevUpload,
      url: newUpload.url,
    };
  });
}

function updateUploadInArray(prev: FileEntry[], upload: FileUpload, percentage: number) {
  return prev.map((prevUpload) => {
    if (prevUpload.type === "file" && prevUpload.url === upload.url) {
      return {
        ...upload,
        percent: percentage,
      };
    }
    return prevUpload;
  });
}
