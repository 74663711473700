import { createIcon } from "@chakra-ui/react";

const LayoutIcon = createIcon({
  displayName: "LayoutIcon",
  viewBox: "0 0 256 256",
  path: (
    <path
      d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16ZM40 56h176v40H40Zm0 56h56v88H40Zm176 88H112v-88h104v88Z"
      fill="currentColor"
    />
  ),
});

export default LayoutIcon;
