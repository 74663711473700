import {
  WorkflowDefinition,
  WorkflowInstanceExecutionResult,
} from "../../../../shared/schemas/workflows";
import {
  dummyTaskRefIds,
  findNode,
  isPlaceholder,
} from "../../../Editor/utils/workflow-node.utils";
import ExecutedNodeSidebar from "../../../Editor/components/Sidebars/Node/ExecutedNodeSidebar";
import ExecutedWorkflowSidebar from "./ExecutedWorkflowSidebar";

interface Props {
  workflow: WorkflowDefinition;
  selectedNodeId: string | null;
  executionResult: WorkflowInstanceExecutionResult;
}

export default function ExecutedSidebar(props: Props) {
  const selectedNode =
    props.selectedNodeId == null || Object.values(dummyTaskRefIds).includes(props.selectedNodeId)
      ? null
      : findNode(props.selectedNodeId, props.workflow.children);

  return selectedNode == null || isPlaceholder(selectedNode) ? (
    <ExecutedWorkflowSidebar executionResult={props.executionResult} workflow={props.workflow} />
  ) : (
    <ExecutedNodeSidebar
      executionResult={props.executionResult.nodeResults.get(selectedNode.logicalId)}
      node={selectedNode}
      workflow={props.workflow}
    />
  );
}
