import {
  WorkflowChildGroup,
  WorkflowChildScheduling,
  WorkflowDefinition,
  WorkflowEntity,
  WorkflowNode,
} from "../../../../../shared/schemas/workflows";
import { noop } from "../../../../../shared/utils/general.utils";
import { generateId } from "../../../../../shared/utils/string.utils";
import {
  InputPath,
  emptyRef,
  getNodeEdgePositions,
  getNodeWidth,
  getRootNodes,
} from "../../../utils/workflow-node.utils";
import { workflowConfig } from "../../../workflow-config.const";
import {
  DescriptionComponentSection,
  GroupMeta,
  RootNodesComponentSection,
  SchedulingComponentSection,
} from "../shared/sections";

interface Props {
  id: string;
  workflow: WorkflowDefinition;
  node: WorkflowChildGroup;
  availablePaths: InputPath[];
  entityOptions: WorkflowEntity[];
  taskTriggersComponentSection: JSX.Element;
  onUpdateScheduling: <T extends keyof WorkflowChildScheduling>(
    name: T,
    value: WorkflowChildScheduling[T],
  ) => void;
  onUpdateMeta: <T extends keyof GroupMeta>(name: T, value: GroupMeta[T]) => void;
  onUpdateGroup: (id: string, task: WorkflowChildGroup) => void;
  onAddNode: (id: string, node: WorkflowNode) => void;
  onRemoveNode: (id: string) => void;
}

function GroupSidebar(props: Props) {
  const handleAddRootNode = () => {
    const id = generateId("node");

    const { maxX } = getNodeEdgePositions({
      node: props.node,
      children: props.workflow.children,
    });

    const nodeWidth = getNodeWidth(props.node);

    props.onAddNode(
      id,
      emptyRef.placeholder({
        logicalId: id,
        parent: props.node.logicalId,
        position: {
          x: workflowConfig.groupPadding + maxX + nodeWidth,
          y: workflowConfig.groupPadding,
        },
      }),
    );
  };

  return (
    <>
      <DescriptionComponentSection
        description="Group lets you run multiple tasks in parallel."
        onChange={noop}
      />

      {props.taskTriggersComponentSection}

      <RootNodesComponentSection
        id={props.id}
        nodes={getRootNodes(props.node.children)}
        onAddNode={handleAddRootNode}
        onRemoveNode={props.onRemoveNode}
      />

      <SchedulingComponentSection
        availablePaths={props.availablePaths}
        createAfter={props.node.scheduling?.createAfter}
        createBefore={props.node.scheduling?.createBefore}
        entityOptions={props.entityOptions}
        finishBefore={props.node.scheduling?.finishBefore}
        timeout={props.node.scheduling?.timeout}
        onUpdateScheduling={props.onUpdateScheduling}
      />
    </>
  );
}

export default GroupSidebar;
