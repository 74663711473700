import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zForm } from "../tag.const";

type Params = {
  defaultValues: Partial<z.infer<typeof zForm>>;
};

export default function useTagForm(params: Params) {
  const form = useForm<z.infer<typeof zForm>>({
    resolver: zodResolver(zForm),
    mode: "onChange",
    defaultValues: params.defaultValues,
  });

  const idValue = form.watch("id");

  React.useEffect(() => {
    if (idValue === "") {
      return;
    }

    const fields = idValue.match(/\{(.*?)\}/g)?.map((x) => x.replace(/\{(.*?)\}/, "$1")) ?? [];
    const newInput: [string, Record<string, unknown> | null][] = fields.map(
      (field) => form.getValues().input.find(([name, _]) => name === field) ?? [field, null],
    );

    // @ts-expect-error - While field type cannot be nullable according to the schema,
    // it's possible to have a null value until the user selects a field type.
    form.setValue("input", newInput);
  }, [form, idValue]);

  return form;
}
