import { deepEqual } from "./general.utils";
import { JsonObject, cleanObject } from "./object.utils";

export function parseFilters(params: {
  queryFilters: string;
  default: Record<string, unknown> | undefined;
}) {
  return {
    ...params.default,
    ...JSON.parse(params.queryFilters),
  };
}

export function stringifyFilters(params: {
  filters: Record<string, unknown>;
  default: Record<string, unknown> | undefined;
}) {
  const filtered = Object.entries(params.filters).filter(([key, value]) => {
    return !deepEqual(value, params.default?.[key]);
  });

  const result = cleanObject(Object.fromEntries(filtered) as JsonObject);

  if (Object.keys(result).length === 0) {
    return undefined;
  }

  return JSON.stringify(result);
}
