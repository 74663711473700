import { NavigateOptions, useNavigate as useReactRouterNavigate } from "react-router-dom";

interface NavigateFunction {
  (
    to: string,
    options: NavigateOptions & {
      event: React.MouseEvent | undefined;
    },
  ): void;
}

export default function useNavigateLink(): NavigateFunction {
  const navigate = useReactRouterNavigate();

  return (
    to: string,
    options: NavigateOptions & {
      event: React.MouseEvent | undefined;
    },
  ) => {
    if (options.event?.ctrlKey || options.event?.metaKey) {
      window.open(to, "_blank");
    } else {
      navigate(to, options);
    }
  };
}
