import { QueryClient, QueryKey, Updater, UseQueryOptions } from "@tanstack/react-query";
import * as Batcher from "@yornaath/batshit";
import { WORKFLOW_DASHBOARD_DEFINITIONS_QUERY } from "../../pages/Workflow/workflow.graphql";
import { API, Messages, QueryParamsOf } from "../api";
import { WorkflowDashboardDefinitionsQueryVariables } from "../schema/gql/graphql";
import {
  AgencyMemberId,
  WorkflowDefinitionCorrelationKey,
  WorkflowDefinitionId,
  WorkflowInstanceId,
  WorkflowTagId,
  WorkflowTaskClusterId,
} from "../schema/schema";
import { mappings } from "../schemas/mapping";
import { ListedWorkflowFilters } from "../schemas/workflows";
import { memoize } from "./memoize";
import { generateId } from "./string.utils";

type QueryOptions<TQueryFnData, TError, TData, TQueryKey extends QueryKey> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  "initialData" | "queryKey"
> & {
  initialData?: () => undefined;
  queryKey: TQueryKey;
};

function getQueryKeyParent(queryKey: QueryKey): QueryKey {
  return queryKey.slice(0, -1);
}

export function createQueryWithClient<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(options: QueryOptions<TQueryFnData, TError, TData, TQueryKey>, queryClient: QueryClient) {
  return {
    ...options,
    setQueryData: (updater: Updater<TData | undefined, TData | undefined>) =>
      setQueryData(queryClient, options, updater),
    invalidate: () => queryClient.invalidateQueries(options.queryKey),
    remove: () => queryClient.removeQueries(options.queryKey),
    parent: {
      invalidate: () => queryClient.invalidateQueries(getQueryKeyParent(options.queryKey)),
    },
  };
}

function setQueryData<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryClient: QueryClient,
  options: QueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  updater: Updater<TData | undefined, TData | undefined>,
) {
  return queryClient.setQueryData(options.queryKey, updater);
}

type PartialQueryOptions<TQueryFnData, TError, TData, TQueryKey extends QueryKey> = Pick<
  QueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  "queryKey" | "queryFn" | "staleTime" | "keepPreviousData"
>;

export type CustomQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = PartialQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
  setQueryData: (updater: Updater<TData | undefined, TData | undefined>) => void;
  invalidate: () => void;
  remove: () => void;
  parent: {
    invalidate: () => void;
  };
};

export function createQueryGroup<GroupRecord extends Record<PropertyKey, unknown>>(
  factory: (p: {
    api: API;
    createQuery: <
      TQueryFnData = unknown,
      TError = unknown,
      TData = TQueryFnData,
      TQueryKey extends QueryKey = QueryKey,
    >(
      options: PartialQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    ) => CustomQueryOptions<TQueryFnData, TError, TData, TQueryKey>;
  }) => GroupRecord,
) {
  return factory;
}

export function createQueries(params: { api: API; queryClient: QueryClient }) {
  const { api } = params;

  function createQuery<
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
  >(
    options: PartialQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  ): CustomQueryOptions<TQueryFnData, TError, TData, TQueryKey> {
    return createQueryWithClient(options, params.queryClient);
  }

  return {
    workflow: (id: WorkflowDefinitionId) => {
      return createQuery({
        queryKey: ["workflow", id],
        queryFn: () =>
          api.get("./workflow_definitions/:workflowDefinition", {
            path: { workflowDefinition: id },
          }),
      });
    },
    emptyWorkflow: (agencyMemberId: AgencyMemberId) => {
      return createQuery({
        queryKey: ["workflow", "empty"],
        queryFn: () => {
          const emptyWorkflow: Messages["WorkflowDefinition"] = {
            id: WorkflowDefinitionId.parse(0),
            triggers: [],
            name: "Untitled Workflow",
            description: "",
            concurrencyRules: {
              domain: "DEFINITION",
              rule: "RUN_ALWAYS",
            },
            meta: {
              createHumanTaskOnFailure: false,
              owner: agencyMemberId,
              priority: 1,
              severity: "LOW",
              published: false,
              timeout: null,
            },
            correlationKey: WorkflowDefinitionCorrelationKey.parse(generateId("correlationkey")),
            version: 1,
            input: { __type: "Map", value: [] },
            output: {},
            children: {},
          };

          return { workflow: emptyWorkflow };
        },
      });
    },
    entityOptions: () => {
      return createQuery({
        queryKey: ["workflow_entities"],
        queryFn: () => api.get("./workflow_entities", {}),
      });
    },
    entityLayouts: () => {
      return createQuery({
        queryKey: ["workflow_entity_selections"],
        queryFn: () => api.get("./entity_layouts", {}),
        staleTime: Infinity,
      });
    },
    entitySearch: (params: QueryParamsOf<"get", "./entity_search">) => {
      return createQuery({
        queryKey: ["workflow_entity_search", params],
        queryFn: () =>
          api.get("./entity_search", {
            query: params,
          }),
        keepPreviousData: true,
      });
    },
    triggerOptions: () => {
      return createQuery({
        queryKey: ["workflow_triggers"],
        queryFn: () => api.get("./workflow_triggers", {}),
      });
    },
    taskOptions: () => {
      return createQuery({
        queryKey: ["workflow_task_definitions"],
        queryFn: () => api.get("./workflow_task_definitions", {}),
      });
    },
    humanTaskTemplateOptions: () => {
      return createQuery({
        queryKey: ["workflow_human_task_template_definitions"],
        queryFn: () => api.get("./workflow_human_task_template_definitions", {}),
      });
    },
    queryOptions: () => {
      return createQuery({
        queryKey: ["workflow_task_queries"],
        queryFn: () => api.get("/workflow_task_queries", {}),
      });
    },
    workflowDefinitions: (params: { filters: ListedWorkflowFilters }) => {
      return createQuery({
        queryKey: ["workflow_defintions", "list", params],
        queryFn: async () => {
          const { workflows } = await api.get("./workflow_definitions", {});

          return {
            workflows: workflows,
            owners: [
              ...new Map(
                workflows.map((workflow) => [workflow.meta.owner.id, workflow.meta.owner]),
              ).values(),
            ],
          };
        },
        keepPreviousData: true,
      });
    },
    workflowInstance: ({ id }: { id: WorkflowInstanceId }) => {
      return createQuery({
        queryKey: ["workflow_instance", id],
        queryFn: async () => {
          const result = await api.get("./workflow_instances/:workflowInstanceId", {
            path: { workflowInstanceId: id },
          });

          return mappings.instances.workflowExecution.fromServer(result);
        },
        keepPreviousData: true,
      });
    },
    skills: () => {
      return createQuery({
        queryKey: ["skills"],
        queryFn: () => api.get("./workflow_tasks/skills", {}),
      });
    },
    clusters: () => {
      return createQuery({
        queryKey: ["clusters", "list"],
        queryFn: () => api.get("./workflow_task_clusters", {}),
      });
    },
    cluster: (id: WorkflowTaskClusterId) => {
      return createQuery({
        queryKey: ["cluster", "get", id],
        queryFn: () =>
          api.get("./workflow_task_clusters/:workflowTaskClusterId", {
            path: { workflowTaskClusterId: id },
          }),
      });
    },
    scheduledTasksCount: (correlationKey: WorkflowDefinitionCorrelationKey) => {
      return createQuery({
        queryKey: ["scheduled_tasks_count", correlationKey],
        queryFn: () =>
          api.get(
            "./workflow_definitions/:workflowDefinitionCorrelationKey/scheduled_tasks_count",
            {
              path: {
                workflowDefinitionCorrelationKey: correlationKey,
              },
            },
          ),
      });
    },
    entityName: (params: { entity: string; id: unknown }) => {
      return createQuery({
        queryKey: ["workflow", "entity", "name", params],
        queryFn: () => entityBatcher(api).fetch({ entity: params.entity, id: params.id }),
      });
    },
    aggregatedExecutions: (
      query: QueryParamsOf<"get", "./workflow_instances/aggregated_executions">,
    ) => {
      return createQuery({
        queryKey: ["workflow", "aggregated_executions", query],
        queryFn: () => api.get("./workflow_instances/aggregated_executions", { query }),
      });
    },
    workflowDashboardDefinitions: (variables: WorkflowDashboardDefinitionsQueryVariables) => {
      return createQuery({
        queryKey: [WORKFLOW_DASHBOARD_DEFINITIONS_QUERY, variables],
        queryFn: () => api.graphql(WORKFLOW_DASHBOARD_DEFINITIONS_QUERY, { variables }),
      });
    },
    tags: () => {
      return createQuery({
        queryKey: ["tags", "list"],
        queryFn: () => api.get("./workflow_tags", {}),
      });
    },
    tag: (id: WorkflowTagId) => {
      return createQuery({
        queryKey: ["tags", "get", id],
        queryFn: () =>
          api.get("./workflow_tags/:workflowTagId", {
            path: { workflowTagId: id },
          }),
      });
    },
    assignmentStrategies: () => {
      return createQuery({
        queryKey: ["assignment_strategies"],
        queryFn: () => api.get("./workflow_tasks/assignment_strategies", {}),
      });
    },
  };
}

const entityBatcher = memoize((api: API) => {
  return Batcher.create({
    fetcher: (entities: { entity: string; id: unknown }[]) => {
      return api.post("./workflow_entities/display_values", {
        body: { entities: entities },
      });
    },
    resolver: (data, { entity, id }) => data.entities[entity]?.[String(id)] ?? null,
    scheduler: Batcher.windowScheduler(10),
  });
});
