import { ChevronRightIcon, MinusIcon, PlusSquareIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import EditRoundedIcon from "../../../../../../shared/icons/EditRoundedIcon";
import {
  EventTriggerType,
  WorkflowEventTrigger,
  WorkflowEventTriggerOption,
  WorkflowTaskTrigger,
} from "../../../../../../shared/schemas/workflows";
import { groupByKey } from "../../../../../../shared/utils/general.utils";
import FancySelection from "../../Node/components/FancySelection";

interface Props<T extends WorkflowEventTrigger | WorkflowTaskTrigger> {
  trigger: T;
  triggerEventOptions: WorkflowEventTriggerOption[];
  onUpdate: (trigger: T) => void;
}

function EventTriggerSelection<T extends WorkflowEventTrigger | WorkflowTaskTrigger>(
  props: Props<T>,
) {
  const handleChangeEvent = (event: WorkflowEventTriggerOption) => {
    props.onUpdate({
      ...props.trigger,
      event,
    });
  };

  const events = props.triggerEventOptions.map((option) => ({
    label: option.name.substring(0, option.name.lastIndexOf(" ")),
    option: option,
  }));

  const evetGroups = groupByKey(events, "label");

  const options = [...evetGroups].map(([label, events]) => ({
    title: label,
    items: events.map((event) => ({
      value: event.option,
      title: event.option.type,
      description: `Will get triggered when ${event.label.toLowerCase()} is ${
        event.option.type === "INSERT" ? "created" : event.option.type.toLowerCase() + "d"
      }`,
      icon: <EventTypeIcon type={event.option.type} />,
    })),
  }));

  return (
    <FancySelection
      options={options}
      selected={props.trigger.event}
      title="Select event"
      trigger={
        <Button data-testid="select-event-button" rightIcon={<ChevronRightIcon />} size="xs">
          {props.trigger.event?.name ?? "Select event"}
        </Button>
      }
      onSelect={handleChangeEvent}
    />
  );
}

function EventTypeIcon(props: { type: EventTriggerType }) {
  switch (props.type) {
    case "INSERT":
      return <PlusSquareIcon />;

    case "UPDATE":
      return <EditRoundedIcon />;

    case "DELETE":
      return <MinusIcon />;
  }
}

export default EventTriggerSelection;
