import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";

interface Props {
  hint: string;
}

function HintCircle(props: Props) {
  return (
    <Tooltip aria-label={props.hint} label={props.hint}>
      <QuestionOutlineIcon />
    </Tooltip>
  );
}

export default HintCircle;
