import { ChakraProvider } from "@chakra-ui/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { router } from "./shared/router";
import { theme } from "./shared/theme";
import logRocketUtils from "./shared/utils/log-rocket.utils";
import { queryClient } from "./shared/utils/query-client";

logRocketUtils.init();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
