import { Flex } from "@chakra-ui/react";
import BaselineMinusIcon from "../../../../../../shared/icons/BaselineMinusIcon";
import {
  WorkflowChoice,
  ChoiceTaskConditions,
  WorkflowEntity,
  WorkflowTaskQuery,
} from "../../../../../../shared/schemas/workflows";
import { InputPath } from "../../../../utils/workflow-node.utils";
import Sidebar from "../../../Sidebar";
import ChoiceTaskConditionsBuilder from "./ChoiceTaskConditionsBuilder";

interface Props {
  choice: WorkflowChoice;
  queryOptions: WorkflowTaskQuery[];
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  isFirst: boolean;
  onUpdate: (choice: WorkflowChoice) => void;
  onRemove: () => void;
}

function ChoiceComponent(props: Props) {
  const handleUpdateConditions = (conditions: ChoiceTaskConditions) => {
    props.onUpdate({
      ...props.choice,
      conditions: conditions,
    });
  };

  const handleUpdateOutput = (output: string) => {
    props.onUpdate({
      ...props.choice,
      output: output,
    });
  };

  return (
    <Sidebar.Row alignItems="start">
      <Flex direction="column" flex="1">
        <Sidebar.Label>{props.isFirst ? "If" : "Else if"}</Sidebar.Label>
        <ChoiceTaskConditionsBuilder
          availablePaths={props.availablePaths}
          conditions={props.choice.conditions}
          entityOptions={props.entityOptions}
          onUpdateConditions={handleUpdateConditions}
        />
        <Sidebar.Row>
          <Sidebar.Label>Go to</Sidebar.Label>
          <Sidebar.Input
            flex="1"
            placeholder="Output option"
            value={props.choice.output}
            onChange={(e) => handleUpdateOutput(e.target.value)}
          />
        </Sidebar.Row>
      </Flex>
      <Sidebar.SquareButton aria-label="remove" onClick={props.onRemove}>
        <BaselineMinusIcon />
      </Sidebar.SquareButton>
    </Sidebar.Row>
  );
}

export default ChoiceComponent;
