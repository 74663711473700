import { EditIcon } from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  Skeleton,
  SkeletonProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Messages } from "../../../shared/api";
import InternalErrorBox from "../../../shared/components/InternalErrorBox";
import { range } from "../../../shared/utils/general.utils";
import { useApi } from "../../../shared/utils/use-api";
import { EditClusterModalTrigger } from "./EditClusterModal";

function TdSkeleton(props: SkeletonProps) {
  return (
    <Td>
      <Skeleton rounded="full" {...props} />
    </Td>
  );
}

// TODO - this should be probably converted to DataTable once it will be available
export default function ClustersTable() {
  const { queries } = useApi();
  const query = useQuery(queries.clusters());

  if (query.isError) {
    return (
      <InternalErrorBox
        error={query.error}
        title="Failed to render clusters table"
        onRetry={query.refetch}
      />
    );
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Name</Th>
            <Th width={40}>Threshold</Th>
            <Th width={24}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {query.status === "loading" ? (
            <ClustersTableBody status="loading" />
          ) : (
            <ClustersTableBody clusters={query.data.clusters} status="success" />
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function ClustersTableBody(
  props:
    | { status: "loading" }
    | {
        status: "success";
        clusters: Messages["ListedWorkflowTaskCluster"][];
      },
) {
  switch (props.status) {
    case "loading":
      return (
        <>
          {range(10).map((i) => (
            <Tr key={i}>
              <TdSkeleton height="20px" />
              <TdSkeleton height="20px" />
              <TdSkeleton height="20px" />
              <TdSkeleton height="20px" marginInlineStart="auto" />
            </Tr>
          ))}
        </>
      );

    case "success":
      return (
        <>
          {props.clusters.map((cluster) => (
            <Tr key={cluster.id} data-cluster-id={cluster.id}>
              <Td>
                <Text color="blue.600" fontFamily="mono">
                  {cluster.id}
                </Text>
              </Td>
              <Td>{cluster.name}</Td>
              <Td>{cluster.threshold}</Td>
              <Td py={0}>
                <Flex justify="flex-end">
                  <EditClusterModalTrigger id={cluster.id}>
                    <IconButton
                      aria-label="Edit cluster"
                      icon={<EditIcon />}
                      size="sm"
                      variant="ghost"
                    />
                  </EditClusterModalTrigger>
                </Flex>
              </Td>
            </Tr>
          ))}
        </>
      );
  }
}
