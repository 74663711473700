import { createIcon } from "@chakra-ui/react";

const AddBoxOutlineIcon = createIcon({
  displayName: "AddBoxOutlineIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4Zm-6 4q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h14q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Zm0-2h14V5H5v14ZM5 5v14V5Z"
      fill="currentColor"
    />
  ),
});

export default AddBoxOutlineIcon;
