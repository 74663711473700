import { Card, CardBody, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { sanitizeTestId } from "../../../../../../shared/utils/string.utils";

interface Props {
  title: string;
  description?: string;
  icon: React.ReactNode;
  selected: boolean;
  onClick: () => void;
}

function FancySelectionCard(props: Props) {
  return (
    <Card
      className="fancy-selection-card"
      cursor="pointer"
      data-testid={`fancy-selection-card-${sanitizeTestId(props.title)}`}
      role="button"
      tabIndex={0}
      variant={props.selected ? "filled" : "elevated"}
      onClick={props.onClick}
    >
      <CardBody>
        <Flex direction="column">
          <Flex alignItems="center" direction="row" gap={2}>
            {props.icon}
            <Heading size="xs" textTransform="uppercase">
              {props.title}
            </Heading>
          </Flex>
          {props.description && (
            <Text fontSize="sm" pt="2">
              {props.description}
            </Text>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
}

export default FancySelectionCard;
