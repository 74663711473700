import { AgencyMemberId } from "../schema/schema";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

async function init() {
  if (!import.meta.env.PROD) {
    console.log(`[log-rocket] skipped init()`);
    return;
  }

  LogRocket.init("qlu1lh/workflows-designer");
  setupLogRocketReact(LogRocket);
}

async function setAgencyMemberSession(
  agencyMemberId: AgencyMemberId,
  traits: Record<string, string | number | boolean>,
) {
  if (!import.meta.env.PROD) {
    return;
  }

  LogRocket.identify(`${agencyMemberId}`, traits);
}

type TrackEventProperties = {
  revenue?: number;
  [key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined;
};

async function track(eventName: string, eventProperties?: TrackEventProperties) {
  if (!import.meta.env.PROD) {
    return;
  }

  LogRocket.track(eventName, eventProperties);
}

function getCurrentSessionUrl() {
  if (!import.meta.env.PROD) {
    return null;
  }

  return LogRocket.sessionURL;
}

export default {
  init: init,
  setAgencyMemberSession: setAgencyMemberSession,
  getCurrentSessionUrl: getCurrentSessionUrl,
  track: track,
};
