import {
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderProps,
  SliderThumb,
  SliderTrack,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";

function PrioritySlider(props: SliderProps) {
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <Slider
      colorScheme="blue"
      max={10}
      min={1}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      {...props}
    >
      <SliderMark color="gray.500" fontSize="sm" ml="-2.5" mt="1" value={1}>
        Low
      </SliderMark>
      <SliderMark color="gray.500" fontSize="sm" ml="-2.5" mt="1" value={5}>
        Medium
      </SliderMark>
      <SliderMark color="gray.500" fontSize="sm" ml="-2.5" mt="1" value={10}>
        High
      </SliderMark>
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <Tooltip
        hasArrow
        bg="blue.500"
        color="white"
        isOpen={showTooltip}
        label={props.value}
        placement="top"
      >
        <SliderThumb />
      </Tooltip>
    </Slider>
  );
}

export default PrioritySlider;
