import {
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Switch,
} from "@chakra-ui/react";
import AddIcon from "../../../../../shared/icons/AddIcon";
import Workflow16Icon from "../../../../../shared/icons/Workflow16Icon";
import {
  WorkflowDataField,
  WorkflowDefinition,
  WorkflowEntity,
  WorkflowEventTriggerOption,
  WorkflowInput,
  WorkflowJobTriggerOption,
  WorkflowTagReference,
  WorkflowTrigger,
} from "../../../../../shared/schemas/workflows";
import { getAvailablePathsForWorkflow } from "../../../utils/workflow-node.utils";
import Sidebar from "../../Sidebar";
import {
  ConcurrencyComponentSection,
  DefineFieldTypesSection,
  DescriptionComponentSection,
  OwnerSection,
  PriorityComponentSection,
  SeverityComponentSection,
  TagsComponentSection,
  TimeoutComponentSection,
} from "../shared/sections";
import WorkflowTriggerComponent from "./components/WorkflowTriggerComponent";

interface Props {
  workflow: WorkflowDefinition;
  triggerOptions: WorkflowEventTriggerOption[];
  triggerJobOptions: WorkflowJobTriggerOption[];
  entityOptions: WorkflowEntity[];
  onChangeName: (name: string) => void;
  onChangeDescription: (description: string) => void;
  onAddInput: (field: WorkflowDataField) => void;
  onUpdateInput: (id: string, input: WorkflowInput) => void;
  onRemoveInput: (id: string) => void;
  onAddTrigger: (type: "job" | "event") => void;
  onUpdateTrigger: (trigger: WorkflowTrigger) => void;
  onRemoveTrigger: (id: string) => void;
  onUpdateMeta: (meta: WorkflowDefinition["meta"]) => void;
  onUpdateConcurrencyRule: (rule: WorkflowDefinition["concurrencyRule"]) => void;
}

const emptyMap = new Map();

function WorkflowSidebar(props: Props) {
  const handleUpdateTimeout = (timeout: number | null) => {
    props.onUpdateMeta({ ...props.workflow.meta, timeout });
  };

  const handleToggleReportErrors = () => {
    props.onUpdateMeta({
      ...props.workflow.meta,
      createHumanTaskOnFailure: !props.workflow.meta.createHumanTaskOnFailure,
    });
  };

  const handleUpdateTags = (tags: WorkflowTagReference[]) => {
    props.onUpdateMeta({ ...props.workflow.meta, tags });
  };

  const timeout = props.workflow.meta.timeout;

  const availablePaths = getAvailablePathsForWorkflow({
    workflowInput: props.workflow.input,
  });

  return (
    <Sidebar.Root>
      <Flex align="center" gap={3} p={4}>
        <Workflow16Icon fontSize="xl" />
        <Sidebar.Title
          data-testid="workflow-name-input"
          value={props.workflow.name}
          onChange={(e) => props.onChangeName(e.target.value)}
        />
      </Flex>

      <Divider />

      <DescriptionComponentSection
        description={props.workflow.description}
        placeholder="Describe your workflow..."
        onChange={props.onChangeDescription}
      />

      <Divider />

      <DefineFieldTypesSection
        entityOptions={props.entityOptions}
        fieldTypes={props.workflow.input}
        title="Input"
        onAdd={props.onAddInput}
        onRemove={props.onRemoveInput}
        onUpdate={props.onUpdateInput}
      />

      <Sidebar.Section data-test-id="workflow-triggers-section">
        <Sidebar.Row>
          <Sidebar.SectionTitle>Triggers</Sidebar.SectionTitle>
          <Menu isLazy placement="bottom-end">
            <MenuButton
              as={Sidebar.SquareButton}
              data-testid="add-workflow-trigger"
              icon={<AddIcon />}
            />
            <Portal>
              <MenuList minW={24}>
                <MenuItem
                  data-testid="add-workflow-job-trigger"
                  onClick={() => props.onAddTrigger("job")}
                >
                  Job
                </MenuItem>
                <MenuItem
                  data-testid="add-workflow-event-trigger"
                  onClick={() => props.onAddTrigger("event")}
                >
                  Event
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </Sidebar.Row>
        {props.workflow.triggers.map((trigger) => (
          <WorkflowTriggerComponent
            key={trigger.id.toString()}
            entityOptions={props.entityOptions}
            inputs={props.workflow.input}
            trigger={trigger}
            triggerEventOptions={props.triggerOptions}
            triggerJobOptions={props.triggerJobOptions}
            onClickRemove={() => props.onRemoveTrigger(trigger.id)}
            onUpdate={props.onUpdateTrigger}
          />
        ))}
      </Sidebar.Section>

      <Divider />

      <TimeoutComponentSection
        data-testid="workflow-timeout"
        timeout={timeout}
        title="Timeout"
        onChange={handleUpdateTimeout}
      />

      <Divider />

      <ConcurrencyComponentSection
        rule={props.workflow.concurrencyRule}
        onUpdate={props.onUpdateConcurrencyRule}
      />

      <Divider />

      <PriorityComponentSection
        priority={props.workflow.meta.priority}
        onUpdate={(v) => props.onUpdateMeta({ ...props.workflow.meta, priority: v })}
      />

      <SeverityComponentSection
        severity={props.workflow.meta.severity}
        onUpdate={(v) => props.onUpdateMeta({ ...props.workflow.meta, severity: v })}
      />

      <Sidebar.Section>
        <Sidebar.Row>
          <Sidebar.SectionTitle>Report Errors</Sidebar.SectionTitle>
          <Switch
            data-testid="workflow-report-errors-switch"
            isChecked={props.workflow.meta.createHumanTaskOnFailure}
            size="md"
            onChange={handleToggleReportErrors}
          />
        </Sidebar.Row>
      </Sidebar.Section>

      <Divider />

      <TagsComponentSection
        availablePaths={availablePaths}
        availableQueries={emptyMap}
        entityOptions={props.entityOptions}
        tags={props.workflow.meta.tags ?? []}
        onChange={handleUpdateTags}
      />

      <Divider />

      <OwnerSection
        owner={props.workflow.meta.owner}
        onChange={(owner) => props.onUpdateMeta({ ...props.workflow.meta, owner })}
      />
    </Sidebar.Root>
  );
}

export default WorkflowSidebar;
