export function parseEndpointPath(params: {
  path: string;
  params: {
    path?: Record<string, unknown>;
    query?: Record<string, unknown>;
  };
}) {
  const {
    path,
    params: { path: pathParams, query },
  } = params;

  let url = path.replace(/:([^/]+)/g, (_match, key) => {
    if (pathParams === undefined) {
      throw new Error(`Missing path param ${key} in ${path}`);
    }

    const value: unknown = pathParams[key];

    if (value === undefined) {
      throw new Error(`Missing path param: ${key}`);
    }

    if (value === null) {
      return "null";
    }

    return `${value}`;
  });

  if (query !== undefined && Object.keys(query).length > 0) {
    url = `${url}?${serializeQuery(query)}`;
  }

  return url;
}

function serializeQuery<T>(params: T) {
  const parsed = JSON.parse(JSON.stringify(params));

  const queries: string[] = [];

  for (const [key, value] of Object.entries(parsed)) {
    if (value === undefined) {
      continue;
    }

    if (Array.isArray(value)) {
      for (const item of value) {
        queries.push(`${key}[]=${encodeURIComponent(item)}`);
      }
      continue;
    }

    queries.push(`${key}=${encodeURIComponent(value as string | number)}`);
  }

  return queries.join("&");
}
