import React from "react";
import { Navigate } from "react-router-dom";
import { AuthData } from "../schemas/auth";
import useAuth from "../utils/use-auth";
import { ApiProvider } from "./ApiProvider";
import { AuthDataProvider } from "./AuthDataProvider";
import LogRocketAnalyics from "./LogRocketAnalyics";

type Props = {
  component: (props: { authData: AuthData }) => React.ReactElement;
};

const AuthGuard = (props: Props) => {
  const auth = useAuth();

  if (auth.authData.type === "guest") {
    return <Navigate to="/login" />;
  }

  return (
    <AuthDataProvider value={auth.authData.data}>
      <ApiProvider tokens={auth.authData.tokens}>
        {props.component({ authData: auth.authData.data })}
        <LogRocketAnalyics />
      </ApiProvider>
    </AuthDataProvider>
  );
};

export default AuthGuard;
