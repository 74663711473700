import { QuestionIcon } from "@chakra-ui/icons";
import { Button, Flex, Heading, List, ListItem, Tag, Text, VStack } from "@chakra-ui/react";
import { Row, createColumnHelper } from "@tanstack/react-table";
import DataTable from "../../../shared/components/DataTable/DataTable";
import { DataTableEndActionFlex } from "../../../shared/components/DataTable/DataTableUI";
import useGraphQLDataTable from "../../../shared/components/DataTable/useGraphQLDataTable";
import WorkflowInstanceStatusTag from "../../../shared/components/WorkflowInstanceStatusTag";
import StopCircleOutlineRoundedIcon from "../../../shared/icons/StopCircleOutlineRoundedIcon";
import {
  WorkflowDashboardInstancesQuery,
  WorkflowDashboardInstancesQueryVariables,
} from "../../../shared/schema/gql/graphql";
import { mappings } from "../../../shared/schemas/mapping";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { fmap } from "../../../shared/utils/general.utils";
import { GraphQLDocumentFilters } from "../../../shared/utils/graphql.utils";
import { WorkflowInstanceOrigin } from "../../Workflows/components/WorkflowInstanceOrigin";
import { WORKFLOW_DASHBOARD_INSTANCES_QUERY } from "../workflow.graphql";
import { CancelWorkflowInstanceAlertDialogTrigger } from "./CancelWorkflowInstanceAlertDialog";
import ResolvedInputFormatter from "./ResolvedInputFormatter";

export default function WorkflowInstancesDataTable(props: {
  filters: GraphQLDocumentFilters<WorkflowDashboardInstancesQueryVariables>;
  onClickRow: (event: React.MouseEvent<HTMLTableRowElement>, row: Row<WorkflowInstanceRow>) => void;
}) {
  const { tableProps, query } = useGraphQLDataTable({
    document: WORKFLOW_DASHBOARD_INSTANCES_QUERY,
    connection: "workflowInstances",
    columns: columns,
    globalFilters: props.filters,
    initialSorting: [{ id: "startedAt", desc: true }],
    enableColumnFilters: false,
    initialPageSize: 250,
  });

  const filterNode = (
    <Flex align="center">
      <Heading size="md">Instances</Heading>
    </Flex>
  );

  if (query.data?.workflowInstances.totalCount === 0) {
    return (
      <VStack p={16}>
        <QuestionIcon color="gray" fontSize="2xl" mb={4} />
        <Heading size="md">No instances found for this workflow</Heading>
        <Text>Try changing the filters or run a new instance</Text>
      </VStack>
    );
  }

  return <DataTable {...tableProps} filterNode={filterNode} onClickRow={props.onClickRow} />;
}

export type WorkflowInstanceRow =
  WorkflowDashboardInstancesQuery["workflowInstances"]["nodes"][number];

const { accessor, display } = createColumnHelper<WorkflowInstanceRow>();

export const columns = [
  accessor("id", { header: "#" }),
  accessor("origin", {
    header: "Origin",
    cell: ({ row }) => <WorkflowInstanceOrigin {...row.original.origin} />,
  }),
  accessor("input", {
    header: "Input",
    cell: ({ row }) => {
      return (
        <List fontSize="sm">
          {mappings.instances.resolvedInput.fromServer(row.original.input).map((input) => (
            <ListItem key={input.name} display="flex" gap={1}>
              <strong>{input.name}:</strong> <ResolvedInputFormatter {...input} />
            </ListItem>
          ))}
        </List>
      );
    },
  }),
  accessor("startedAt", {
    header: "Started",
    cell: ({ row }) => dateFormatter.toDateTime(row.original.startedAt),
  }),
  accessor("completedAt", {
    header: "Ended",
    cell: ({ row }) => fmap(row.original.completedAt, dateFormatter.toDateTime),
  }),
  accessor("workflowDefinition.version", {
    header: "Version",
    cell: ({ getValue }) => <Tag colorScheme="gray">v{getValue()}</Tag>,
  }),
  accessor("status", {
    header: "Status",
    cell: ({ row }) => (
      <WorkflowInstanceStatusTag
        status={mappings.instances.status.fromServer(row.original.status)}
      />
    ),
  }),
  display({
    id: "_actions",
    header: "",
    cell: ({ row }) => (
      <DataTableEndActionFlex>
        {row.original.status === "IN_PROGRESS" && (
          <CancelWorkflowInstanceAlertDialogTrigger id={row.original.id}>
            <Button
              aria-label="View"
              leftIcon={<StopCircleOutlineRoundedIcon fontSize="lg" />}
              variant="outline"
            >
              Stop
            </Button>
          </CancelWorkflowInstanceAlertDialogTrigger>
        )}
      </DataTableEndActionFlex>
    ),
  }),
];
