import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

interface Props {
  isCloning: boolean;
  onClone: () => void;
}

export default function CloneButton(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClone = () => {
    props.onClone();
    onClose();
  };

  return (
    <>
      <CloneButtonUI isCloning={props.isCloning} onClick={onOpen} />
      <Dialog isOpen={isOpen} onClone={handleClone} onClose={onClose} />
    </>
  );
}

function CloneButtonUI(props: { isCloning: boolean; onClick: () => void }) {
  return (
    <Button
      data-testid="clone-workflow-button"
      isLoading={props.isCloning}
      variant="ghost"
      onClick={props.onClick}
    >
      Clone
    </Button>
  );
}

function Dialog(props: { isOpen: boolean; onClone: () => void; onClose: () => void }) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog isOpen={props.isOpen} leastDestructiveRef={cancelRef} onClose={props.onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Clone Workflow
          </AlertDialogHeader>

          <AlertDialogBody>Are you sure you want to clone this workflow?</AlertDialogBody>

          <AlertDialogFooter>
            <Actions cancelRef={cancelRef} onClone={props.onClone} onClose={props.onClose} />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

function Actions(props: {
  cancelRef: React.RefObject<HTMLButtonElement>;
  onClone: () => void;
  onClose: () => void;
}) {
  return (
    <>
      <Button ref={props.cancelRef} onClick={props.onClose}>
        Cancel
      </Button>
      <Button colorScheme="red" data-testid="clone-button" ml={3} onClick={props.onClone}>
        Clone
      </Button>
    </>
  );
}
