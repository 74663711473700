import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { BodyOf, Messages } from "../../../shared/api";
import { ModalContentError, ModalContentLoading } from "../../../shared/components/Modal";
import { WorkflowTaskClusterId } from "../../../shared/schema/schema";
import { createDisclosureTriggerComponent } from "../../../shared/utils/create-disclosure-trigger-component";
import { useApi } from "../../../shared/utils/use-api";
import { zForm } from "../cluster.const";
import useClusterForm from "../hooks/useClusterForm";
import CluterFormControls from "./ClusterFormControls";

type Props = {
  id: WorkflowTaskClusterId;
  disclosure: ReturnType<typeof useDisclosure>;
};

export default function EditClusterModal(props: Props) {
  return (
    <Modal {...props.disclosure} isCentered={true}>
      <ModalOverlay />
      <EditClusterModalContent disclosure={props.disclosure} id={props.id} />
    </Modal>
  );
}

export const EditClusterModalTrigger = createDisclosureTriggerComponent<{
  id: WorkflowTaskClusterId;
}>({
  displayName: "EditClusterModalTrigger",
  render: EditClusterModal,
});

function EditClusterModalContent(props: Props) {
  const query = useQuery(useApi().queries.cluster(props.id));

  switch (query.status) {
    case "loading":
      return <ModalContentLoading />;
    case "error":
      return <ModalContentError error={query.error} />;
    case "success":
      return <ModalContentSuccess cluster={query.data} disclosure={props.disclosure} />;
  }
}

function ModalContentSuccess(props: {
  cluster: Messages["ListedWorkflowTaskCluster"];
  disclosure: ReturnType<typeof useDisclosure>;
}) {
  const toast = useToast();
  const { api, queries } = useApi();
  const queryClient = useQueryClient();

  const form = useClusterForm({
    defaultValues: {
      id: props.cluster.id,
      input: props.cluster.input,
      name: props.cluster.name,
      threshold: props.cluster.threshold ?? undefined,
    },
  });

  const mutation = useMutation({
    mutationFn: (body: BodyOf<"put", "./workflow_task_clusters/:workflowTaskClusterId">) => {
      return api.put("./workflow_task_clusters/:workflowTaskClusterId", {
        body,
        path: { workflowTaskClusterId: props.cluster.id },
      });
    },
    onSuccess: () => {
      toast({ position: "top", description: "Cluster updated", status: "success" });
      queryClient.invalidateQueries(queries.clusters().queryKey);
      queryClient.invalidateQueries(queries.cluster(props.cluster.id).queryKey);
      props.disclosure.onClose();
    },
    onError: (error) => {
      toast({ position: "top", description: `${error}`, status: "error" });
    },
  });

  const handleSuccess = (data: z.infer<typeof zForm>) => {
    mutation.mutate({
      name: data.name,
      threshold: data.threshold ?? null,
    });
  };
  return (
    <ModalContent as="form" onSubmit={form.handleSubmit(handleSuccess, console.log)}>
      <ModalHeader>Edit Cluster</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <CluterFormControls form={form} mode="edit" />
      </ModalBody>
      <ModalFooter gap={3}>
        <Button variant="ghost" onClick={props.disclosure.onClose}>
          Cancel
        </Button>
        <Button colorScheme="blue" type="submit">
          Submit
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}
