import {
  DayOfWeek,
  Duration,
  Instant,
  LocalDate,
  LocalDateTime,
  LocalTime,
  Month,
  MonthDay,
  Period,
  Year,
  YearMonth,
} from "@js-joda/core";
import { UnsafeAny } from "../utils/types";

export type Reviver = (val: string) => UnsafeAny;

export function parseDayOfWeek(val: string): DayOfWeek {
  switch (val) {
    case "MONDAY":
      return DayOfWeek.MONDAY;
    case "TUESDAY":
      return DayOfWeek.TUESDAY;
    case "WEDNESDAY":
      return DayOfWeek.WEDNESDAY;
    case "THURSDAY":
      return DayOfWeek.THURSDAY;
    case "FRIDAY":
      return DayOfWeek.FRIDAY;
    case "SATURDAY":
      return DayOfWeek.SATURDAY;
    case "SUNDAY":
      return DayOfWeek.SUNDAY;
    default:
      throw new Error(`Text '${val}' is not a valid DayOfWeek`);
  }
}

function parseMonth(val: string): Month {
  switch (val) {
    case "JANUARY":
      return Month.JANUARY;
    case "FEBRUARY":
      return Month.FEBRUARY;
    case "MARCH":
      return Month.MARCH;
    case "APRIL":
      return Month.APRIL;
    case "MAY":
      return Month.MAY;
    case "JUNE":
      return Month.JUNE;
    case "JULY":
      return Month.JULY;
    case "AUGUST":
      return Month.AUGUST;
    case "SEPTEMBER":
      return Month.SEPTEMBER;
    case "OCTOBER":
      return Month.OCTOBER;
    case "NOVEMBER":
      return Month.NOVEMBER;
    case "DECEMBER":
      return Month.DECEMBER;
    default:
      throw new Error(`Text '${val}' is not a valid Month`);
  }
}

export const revivers: { [key: string]: Reviver } = {
  "JSJoda.DayOfWeek": parseDayOfWeek,
  "JSJoda.Duration": Duration.parse,
  "JSJoda.Instant": Instant.parse,
  "JSJoda.LocalDate": LocalDate.parse,
  "JSJoda.LocalDateTime": LocalDateTime.parse,
  "JSJoda.LocalTime": LocalTime.parse,
  "JSJoda.Month": parseMonth,
  "JSJoda.MonthDay": MonthDay.parse,
  "JSJoda.Period": Period.parse,
  "JSJoda.Year": Year.parse,
  "JSJoda.YearMonth": YearMonth.parse,
};
