import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { groupBy } from "fp-ts/lib/ReadonlyNonEmptyArray";
import React from "react";
import { z } from "zod";
import HintCircle from "../../../../../../shared/components/HintCircle";
import usePathParams from "../../../../../../shared/hooks/usePathParams";
import CloseIcon from "../../../../../../shared/icons/CloseIcon";
import CogIcon from "../../../../../../shared/icons/CogIcon";
import MagnetIcon from "../../../../../../shared/icons/MagnetIcon";
import { WorkflowDefinitionId } from "../../../../../../shared/schema/schema";
import { zPathParamIntId } from "../../../../../../shared/schemas/path-params";
import {
  Formatter,
  FormatterFieldType,
  Formatters,
  WorkflowChildReferenceBinding,
  WorkflowEntity,
  WorkflowInput,
  dateFormatterUnits,
  dateTimeFormatterUnits,
  fieldTypeToFormatterActions,
} from "../../../../../../shared/schemas/workflows";
import { sanitizeTestId } from "../../../../../../shared/utils/string.utils";
import {
  NullableResolvedField,
  WorkflowFormControl,
} from "../../../../../Workflows/components/WorkflowForm";
import {
  InputPath,
  hasAvailablePath,
  isBindEmpty,
  isFormatterAvailableForFieldType,
  mapFieldWithValues,
} from "../../../../utils/workflow-node.utils";
import InputBinding from "./InputBinding";

type Props = (
  | {
      type: "path";
      selectedPath: InputPath | null;
      availablePaths: InputPath[];
      formatter: Formatter | null;
      entityOptions: WorkflowEntity[];
      size?: "sm" | "md" | "lg" | "xs";
      onChoosePath: (path: InputPath | null) => void;
      onChangeFormatter: (formatter: Formatter) => void;
      onRemoveFormatter: () => void;
    }
  | {
      type: "field";
      name: string;
      input: WorkflowInput;
      selectedField: WorkflowChildReferenceBinding | null;
      size?: "sm" | "md" | "lg" | "xs";
      onChangeField: (field: WorkflowChildReferenceBinding) => void;
    }
) & {
  "data-testid": string;
};

function BindInputButton(props: Props) {
  switch (props.type) {
    case "path":
      return (
        <BindPathInputButton
          availablePaths={props.availablePaths}
          data-testid={props["data-testid"]}
          entityOptions={props.entityOptions}
          formatter={props.formatter}
          selectedPath={props.selectedPath}
          size={props.size}
          onChangeFormatter={props.onChangeFormatter}
          onChoose={props.onChoosePath}
          onRemoveFormatter={props.onRemoveFormatter}
        />
      );

    case "field":
      return (
        <BindFieldInputButton
          data-testid={props["data-testid"]}
          input={props.input}
          name={props.name}
          selectedField={props.selectedField}
          size={props.size}
          onChangeField={props.onChangeField}
        />
      );
  }
}

function BindFieldInputButton(props: {
  name: string;
  input: WorkflowInput;
  selectedField: WorkflowChildReferenceBinding | null;
  size?: "sm" | "md" | "lg" | "xs";
  "data-testid": string;
  onChangeField: (field: WorkflowChildReferenceBinding) => void;
}) {
  const { workflowId } = usePathParams(
    z.object({ workflowId: zPathParamIntId(WorkflowDefinitionId).optional() }),
  );
  const workflowDefinitionId = workflowId ?? WorkflowDefinitionId.parse(0);

  const field = props.selectedField ?? { type: props.input.type };

  const handleChangeField = (field: NullableResolvedField) =>
    props.onChangeField(
      mapFieldWithValues({
        input: props.input,
        selectedField: props.selectedField,
        values: field?.value ?? [],
      }),
    );

  const bindPreviewText = getBindText(props.selectedField, props.input);

  const value =
    "value" in field
      ? Array.isArray(field.value)
        ? field.value.length === 0
          ? [null]
          : field.value
        : [field.value]
      : [null];

  const handleRemoveField = () => handleChangeField(null);

  return (
    <Popover placement="bottom-end">
      <ButtonGroup isAttached>
        <PopoverTrigger>
          {isBindEmpty(props.selectedField) ? (
            <Button data-testid={props["data-testid"]} size={props.size ?? "sm"} variant="outline">
              Set
            </Button>
          ) : (
            <Button data-testid={props["data-testid"]} size={props.size ?? "sm"}>
              <BindTextPreview size={props.size ?? "sm"} text={bindPreviewText} />
            </Button>
          )}
        </PopoverTrigger>
        {props.selectedField !== null && !isBindEmpty(props.selectedField) && (
          <IconButton
            aria-label="Remove Path"
            data-testid={`${props["data-testid"]}-remove`}
            icon={<CloseIcon />}
            size={props.size ?? "sm"}
            onClick={handleRemoveField}
          />
        )}
      </ButtonGroup>

      <PopoverContent>
        <PopoverBody data-testid={`${props["data-testid"]}-form`}>
          <WorkflowFormControl
            errors={[]}
            fileUploadDestination={{ by: "workflow-definition", id: workflowDefinitionId }}
            hints={[]}
            input={props.input}
            value={field.type === "file" ? [] : value}
            onChange={handleChangeField}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function BindPathInputButton(props: {
  selectedPath: InputPath | null;
  availablePaths: InputPath[];
  formatter: Formatter | null;
  entityOptions: WorkflowEntity[];
  size?: "sm" | "md" | "lg" | "xs";
  "data-testid": string;
  onChoose: (path: InputPath | null) => void;
  onChangeFormatter: (formatter: Formatter) => void;
  onRemoveFormatter: () => void;
}) {
  const unavailableTitle = "Unavailable";

  const groupedPaths = groupBy<InputPath>((path) =>
    !path.availability.isAvailable ? unavailableTitle : path.type,
  )(
    props.availablePaths
      .filter((p) => p.availability.isAvailable || p.availability.reason !== null)
      .sort((a, b) => a.path[0]?.label.localeCompare(b.path[0]?.label ?? "") ?? 0),
  );

  return (
    <ButtonGroup isAttached size={props.size ?? "sm"}>
      <Menu isLazy>
        {props.selectedPath === null ? (
          <BindPathInputButtonEmpty
            availablePaths={props.availablePaths}
            data-testid={`${props["data-testid"]}-empty`}
            size={props.size ?? "sm"}
          />
        ) : (
          <BindPathInputButtonWithValue
            data-testid={`${props["data-testid"]}-value`}
            selectedPath={props.selectedPath}
            size={props.size ?? "sm"}
          />
        )}
        <MenuList data-testid="paths-bind-menu" maxH="50vh" overflow="auto">
          {!hasAvailablePath(props.availablePaths) && (
            <MenuItem>You have no paths available</MenuItem>
          )}
          {Object.entries(groupedPaths)
            .sort(([a], [b]) => {
              if (a === unavailableTitle) {
                return 1;
              }
              if (b === unavailableTitle) {
                return -1;
              }
              return 0;
            })
            .flatMap(([type, items], i) => {
              return (
                <React.Fragment key={type}>
                  <MenuGroup data-testid={`paths-bind-menu-${type}`} title={type}>
                    {items.map((item) => {
                      const isSelected = props.selectedPath?.key === item.key;
                      return (
                        <MenuItem
                          key={item.key}
                          color={isSelected ? "blue.500" : "inherit"}
                          data-testid={`${props["data-testid"]}-value-${sanitizeTestId(item.key)}`}
                          fontWeight={isSelected ? "medium" : "normal"}
                          gap={2}
                          isDisabled={!item.availability.isAvailable}
                          onClick={() => props.onChoose(item)}
                        >
                          {!item.availability.isAvailable && item.availability.reason !== null && (
                            <HintCircle hint={item.availability.reason} />
                          )}
                          {item.path.map((item) => item.label).join(" / ")}
                        </MenuItem>
                      );
                    })}
                  </MenuGroup>
                  {i < Object.keys(groupedPaths).length - 1 && <MenuDivider />}
                </React.Fragment>
              );
            })}
        </MenuList>
      </Menu>

      {props.selectedPath && isFormatterAvailableForFieldType(props.selectedPath.output) && (
        <FormatterButton
          availablePaths={props.availablePaths}
          data-testid={`${props["data-testid"]}-formatter`}
          entityOptions={props.entityOptions}
          fieldType={props.selectedPath.output}
          formatter={props.formatter}
          size={props.size}
          valueType={props.selectedPath.output.type}
          onChangeFormatter={props.onChangeFormatter}
          onRemoveFormatter={props.onRemoveFormatter}
        />
      )}

      {props.selectedPath !== null && (
        <IconButton
          aria-label="Remove Path"
          icon={<CloseIcon />}
          size={props.size ?? "sm"}
          onClick={() => props.onChoose(null)}
        />
      )}
    </ButtonGroup>
  );
}

function FormatterButton(props: {
  valueType: Formatter["valueType"];
  formatter: Formatter | null;
  fieldType: FormatterFieldType;
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  size?: "sm" | "md" | "lg" | "xs";
  "data-testid": string;
  onChangeFormatter: (formatter: Formatter) => void;
  onRemoveFormatter: () => void;
}) {
  const handleChangeFormatterAction = (action: Formatter["action"]) => {
    const formatter = {
      valueType: props.valueType,
      action: action,
      value: null,
    } as Formatter;

    props.onChangeFormatter(formatter);
  };

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton aria-label="Value Effect" icon={<CogIcon />} size={props.size ?? "sm"} />
      </PopoverTrigger>

      <PopoverContent>
        <PopoverBody>
          <Box marginBottom="4">
            <Heading as="h4" size={props.size ?? "sm"}>
              Formatter
            </Heading>
            <Text marginY="1">You can modify this value with an action.</Text>
          </Box>
          <Flex>
            <Text flex="1" fontWeight="medium">
              Action
            </Text>
            <Menu isLazy>
              <MenuButton
                as={Button}
                data-testid={`${props["data-testid"]}-action`}
                rightIcon={<ChevronDownIcon />}
              >
                {props.formatter?.action ?? "none"}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={props.onRemoveFormatter}>none</MenuItem>
                {fieldTypeToFormatterActions[props.fieldType.type].map((action) => (
                  <MenuItem
                    key={action}
                    data-testid={`${props["data-testid"]}-action-${action}`}
                    onClick={() => handleChangeFormatterAction(action)}
                  >
                    {action}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
          {props.formatter && (
            <FormatterInput
              availablePaths={props.availablePaths.map((p) => ({ ...p, isAvailable: true }))}
              entityOptions={props.entityOptions}
              formatter={props.formatter}
              onChangeFormatter={props.onChangeFormatter}
            />
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function FormatterInput(props: {
  formatter: Formatter;
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  onChangeFormatter: (formatter: Formatter) => void;
}) {
  const formatter = props.formatter;

  switch (formatter.valueType) {
    case "number":
      return <FormatterNumberInput {...props} formatter={formatter} />;

    case "date":
    case "datetime":
      return <FormatterDateInput {...props} formatter={formatter} />;
  }
}

function FormatterDateInput(props: {
  formatter: Formatters["Date"] | Formatters["DateTime"];
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  onChangeFormatter: (formatter: Formatter) => void;
}) {
  const formatter = props.formatter;

  switch (props.formatter.action) {
    case "add":
    case "subtract":
      return (
        <IntervalFormatter
          availablePaths={props.availablePaths}
          entityOptions={props.entityOptions}
          formatter={formatter}
          onChangeFormatter={props.onChangeFormatter}
        />
      );
  }
}

function FormatterNumberInput(props: {
  formatter: Formatters["Number"];
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  onChangeFormatter: (formatter: Formatter) => void;
}) {
  const formatter = props.formatter;

  switch (props.formatter.action) {
    case "add":
    case "subtract":
    case "multiply":
    case "divide":
    case "round":
    case "ceil":
    case "floor":
      return (
        <InputBinding
          availablePaths={props.availablePaths}
          availableQueries={null}
          bind={formatter.value ?? { type: "number", value: null, array: false }}
          entityOptions={props.entityOptions}
          input={{ type: "number" }}
          name={formatter.action}
          onUpdate={(_, bind) => props.onChangeFormatter({ ...formatter, value: bind })}
        />
      );
  }
}

function IntervalFormatter<T extends Formatters["Date"] | Formatters["DateTime"]>(props: {
  formatter: T;
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  onChangeFormatter: (formatter: T) => void;
}) {
  const formatter = props.formatter;

  const units = formatter.valueType === "date" ? dateFormatterUnits : dateTimeFormatterUnits;

  const handleChangeUnit = (
    interval: WorkflowChildReferenceBinding,
    unit: (typeof units)[number],
  ) => {
    props.onChangeFormatter({ ...formatter, value: { interval, unit } });
  };

  const handleChangeInterval = (interval: WorkflowChildReferenceBinding | null) => {
    props.onChangeFormatter({
      ...formatter,
      value: { interval, unit: formatter.value?.unit ?? units[0] },
    });
  };

  const value = formatter.value;

  return (
    <Flex direction="column">
      <InputBinding
        availablePaths={props.availablePaths}
        availableQueries={null}
        bind={value?.interval ?? { type: "number", value: null, array: false }}
        boldLabel={true}
        entityOptions={props.entityOptions}
        input={{ type: "number" }}
        name="Interval"
        onUpdate={(_, bind) => handleChangeInterval(bind)}
      />
      {value && (
        <Flex>
          <Text flex="1" fontWeight="medium">
            Unit
          </Text>
          <Menu isLazy>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              {value.unit}
            </MenuButton>
            <MenuList>
              {units.map((unit) => (
                <MenuItem key={unit} onClick={() => handleChangeUnit(value.interval, unit)}>
                  {unit}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
      )}
    </Flex>
  );
}

function BindPathInputButtonEmpty(props: {
  availablePaths: InputPath[];
  size?: "sm" | "md" | "lg" | "xs";
  "data-testid": string;
}) {
  return (
    <MenuButton
      as={Button}
      data-testid={props["data-testid"]}
      disabled={!hasAvailablePath(props.availablePaths)}
      size={props.size ?? "sm"}
      variant="outline"
    >
      Bind
    </MenuButton>
  );
}

function BindPathInputButtonWithValue(props: {
  selectedPath: InputPath;
  size?: "sm" | "md" | "lg" | "xs";
  "data-testid": string;
}) {
  return (
    <MenuButton
      as={Button}
      data-testid={props["data-testid"]}
      leftIcon={<MagnetIcon />}
      size={props.size ?? "sm"}
    >
      {props.selectedPath.path.at(-1)?.label ?? ""}
    </MenuButton>
  );
}

function BindTextPreview(props: { text: string | null; size?: "sm" | "md" | "lg" | "xs" }) {
  if (props.text === null) {
    return null;
  }

  return (
    <Text
      as="span"
      fontSize={props.size ?? "md"}
      maxW={48}
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {props.text}
    </Text>
  );
}

function getBindText(bind: WorkflowChildReferenceBinding | null, input: WorkflowInput) {
  if (bind === null || bind.type === "path") {
    return null;
  }

  if (bind.array) {
    if (bind.value.length === 0) {
      return null;
    }

    const strings = getBindTextValues(bind, input);

    return strings.filter((t) => t !== null).join(", ");
  }

  return getBindTextSingle(bind, input);
}

function getBindTextValues(
  bind: Exclude<WorkflowChildReferenceBinding, { type: "path" }> & { array: true },
  input: WorkflowInput,
) {
  switch (bind.type) {
    case "file":
      return [`${bind.value.length} file${bind.value.length === 1 ? "" : "s"}`];

    case "text":
    case "textarea":
      return bind.value.map((v) =>
        getBindTextSingle(
          {
            ...bind,
            array: false,
            value: v,
          },
          input,
        ),
      );

    case "number":
      return bind.value.map((v) =>
        getBindTextSingle(
          {
            ...bind,
            array: false,
            value: v,
          },
          input,
        ),
      );

    case "datetime":
      return bind.value.map((v) =>
        getBindTextSingle(
          {
            ...bind,
            array: false,
            value: v,
          },
          input,
        ),
      );

    case "time":
      return bind.value.map((v) =>
        getBindTextSingle(
          {
            ...bind,
            array: false,
            value: v,
          },
          input,
        ),
      );

    case "date":
      return bind.value.map((v) =>
        getBindTextSingle(
          {
            ...bind,
            array: false,
            value: v,
          },
          input,
        ),
      );

    case "boolean":
      return bind.value.map((v) =>
        getBindTextSingle(
          {
            ...bind,
            array: false,
            value: v,
          },
          input,
        ),
      );
  }
}

function getBindTextSingle(
  bind: WorkflowChildReferenceBinding | null,
  input: WorkflowInput,
): string | null {
  if (bind === null || bind.type === "path" || bind.array) {
    return null;
  }

  const optionValue =
    input.type === "option"
      ? input.options.find((o) => o.key === bind.value)?.value?.toString() ?? null
      : null;

  if (optionValue !== null) {
    return optionValue;
  }

  switch (bind.type) {
    case "text":
    case "textarea":
      return bind.value;

    case "number":
    case "datetime":
    case "date":
    case "time":
      return bind.value?.toString() ?? null;

    case "boolean":
      return bind.value ? "Yes" : "No";

    case "file":
      return bind.value;
  }
}

export default BindInputButton;
