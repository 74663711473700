import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import InternalErrorBox from "../../shared/components/InternalErrorBox";
import LoadingPage from "../../shared/components/LoadingPage";
import usePathParams from "../../shared/hooks/usePathParams";
import useWorkflowDefinitionQueries from "../../shared/hooks/useWorkflowDefinitionQueries";
import { WorkflowDefinitionId, WorkflowInstanceId } from "../../shared/schema/schema";
import { zPathParamIntId } from "../../shared/schemas/path-params";
import { useApi } from "../../shared/utils/use-api";
import { flattenNodes } from "../Editor/utils/workflow-node.utils";
import WorkflowInstancePage from "./WorkflowInstancePage";
import useAgencyMember from "../../shared/hooks/useAuthData";

const WorkflowInstanceRoute = () => {
  const { queries } = useApi();
  const { workflowDefinitionId, workflowInstanceId } = usePathParams(
    z.object({
      workflowDefinitionId: zPathParamIntId(WorkflowDefinitionId),
      workflowInstanceId: zPathParamIntId(WorkflowInstanceId),
    }),
  );
  const query = useQuery(queries.workflowInstance({ id: workflowInstanceId }));
  const agencyMember = useAgencyMember();
  const definitionQueries = useWorkflowDefinitionQueries({
    id: workflowDefinitionId,
    agencyMemberId: agencyMember.id,
  });

  if (query.isLoading || definitionQueries.isLoading) {
    return <LoadingPage />;
  }

  if (query.isError || definitionQueries.isError) {
    return <InternalErrorBox error={query.error} />;
  }

  return (
    <WorkflowInstancePage
      entityOptions={definitionQueries.entityOptions}
      flattenedNodes={flattenNodes(definitionQueries.workflow.children)}
      queryOptions={definitionQueries.queryOptions}
      result={query.data}
      skillOptions={definitionQueries.skillOptions}
      taskOptions={definitionQueries.taskOptions}
      taskTemplateOptions={definitionQueries.taskTemplateOptions}
      triggerJobOptions={definitionQueries.triggerJobOptions}
      triggerOptions={definitionQueries.triggerOptions}
      workflow={definitionQueries.workflow}
    />
  );
};

export default WorkflowInstanceRoute;
