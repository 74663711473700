import { createIcon } from "@chakra-ui/react";

const StopCircleOutlineRoundedIcon = createIcon({
  displayName: "StopCircleOutlineRoundedIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M9 16h6q.425 0 .713-.288T16 15V9q0-.425-.288-.713T15 8H9q-.425 0-.713.288T8 9v6q0 .425.288.713T9 16Zm3 6q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"
      fill="currentColor"
    />
  ),
});

export default StopCircleOutlineRoundedIcon;
