import { createIcon } from "@chakra-ui/react";

const UserBoldIcon = createIcon({
  displayName: "UserBoldIcon",
  viewBox: "0 0 256 256",
  path: (
    <path
      d="M235.4 210a124.2 124.2 0 0 0-61.8-53.2a76 76 0 1 0-91.2 0A124.2 124.2 0 0 0 20.6 210a12 12 0 0 0 20.8 12a100 100 0 0 1 173.2 0a12.1 12.1 0 0 0 10.4 6a11.7 11.7 0 0 0 6-1.6a12 12 0 0 0 4.4-16.4ZM76 96a52 52 0 1 1 52 52a52 52 0 0 1-52-52Z"
      fill="currentColor"
    />
  ),
});

export default UserBoldIcon;
