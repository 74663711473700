import { createIcon } from "@chakra-ui/react";

const MinusCircleOutlineIcon = createIcon({
  displayName: "MinusCircleOutlineIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2M7 13h10v-2H7"
      fill="currentColor"
    />
  ),
});

export default MinusCircleOutlineIcon;
