import { Updater, isFunction } from "@tanstack/react-table";
import React, { Dispatch, SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";
import { parseFilters, stringifyFilters } from "../utils/filter.utils";
import { fmap } from "../utils/general.utils";

export function useSearchParamState<S = undefined>(
  key: string,
): [S | undefined, Dispatch<SetStateAction<S | undefined>>];

export default function useSearchParamState<S>(
  key: string,
  initialState: S | (() => S),
): [S, Dispatch<SetStateAction<S>>];

export default function useSearchParamState<S>(
  key: string,
  initialState: S | (() => S),
): [S, Dispatch<SetStateAction<S>>];

export default function useSearchParamState<S extends Record<string, unknown> | undefined>(
  key: string,
  initialState?: S | (() => S),
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const resolvedInitialState = isFunction(initialState) ? initialState() : initialState;

  const state =
    fmap(searchParams.get(key), (queryFilters) =>
      parseFilters({ queryFilters, default: resolvedInitialState }),
    ) ?? resolvedInitialState;

  const setState = React.useCallback(
    (updater: Updater<S>) => {
      setSearchParams((searchParams) => {
        const old =
          fmap(searchParams.get(key), (queryFilters) =>
            parseFilters({ queryFilters, default: resolvedInitialState }),
          ) ?? resolvedInitialState;

        const newValue = isFunction(updater) ? updater(old) : state;

        const searchParamString = stringifyFilters({
          filters: newValue,
          default: resolvedInitialState,
        });

        searchParamString == undefined
          ? searchParams.delete(key)
          : searchParams.set(key, searchParamString);

        return searchParams;
      });
    },
    [key, resolvedInitialState, setSearchParams, state],
  );

  return [state, setState] as const;
}
