import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { Instant, LocalDate, LocalDateTime, LocalTime } from "@js-joda/core";
import React from "react";
import {
  GraphQLDocumentFilters,
  GraphQLDocumentVariables,
  getGraphQLFilterVariableEntries,
} from "../utils/graphql.utils";
import useSearchParamState from "./useSearchParamState";

export type GraphQLSearchParamFilters<TDocumentNode> = TDocumentNode extends TypedDocumentNode<
  infer TDocumentData extends Record<string, unknown>,
  infer TDocumentVariables extends GraphQLDocumentVariables
>
  ? ReturnType<
      typeof useGraphQLSearchParamFilters<
        TDocumentData,
        TDocumentVariables,
        GraphQLDocumentFilters<TDocumentVariables>
      >
    >
  : never;

export function useGraphQLSearchParamFilters<
  TDocumentData extends Record<string, unknown>,
  TDocumentVariables extends GraphQLDocumentVariables,
  TFilters extends GraphQLDocumentFilters<TDocumentVariables>,
>(params: {
  document: TypedDocumentNode<TDocumentData, TDocumentVariables>;
  key: string;
  initialState?: Partial<GraphQLDocumentFilters<TDocumentVariables>>;
}) {
  const documentRef = React.useRef(params.document);
  const [state, setState] = useSearchParamState<Partial<TFilters>>(
    params.key,
    (params.initialState ?? {}) as TFilters,
  );

  const derivedState = React.useMemo(() => {
    const filterEntries = getGraphQLFilterVariableEntries(documentRef.current);

    const parsedEntries = filterEntries.map(([name, type]) => {
      const value = state[name];

      if (value === null || value === undefined) {
        return [name, null];
      }

      switch (type) {
        case "Instant":
          return [name, Instant.parse(value as string)];
        case "LocalDate":
          return [name, LocalDate.parse(value as string)];
        case "LocalDateTime":
          return [name, LocalDateTime.parse(value as string)];
        case "LocalTime":
          return [name, LocalTime.parse(value as string)];
        case "String":
          return [name, value as string];
        case "Int":
          return [name, Number(value)];
        case "Boolean":
          return [name, Boolean(value)];
      }

      if (type.endsWith("_Filter") || type.endsWith("Id")) {
        return [name, value];
      }

      console.error(`Unhandled type "${type}". Please add a case for it.`);

      return [name, null];
    });

    return Object.fromEntries(parsedEntries) as Partial<TFilters>;
  }, [state]);

  return useBaseFilters({
    state: derivedState,
    setState,
    initialState: params.initialState as Partial<TFilters>,
  });
}

export function useSearchParamFilters<TFilters extends Record<string, unknown>>(params: {
  key: string;
  initialState?: Partial<TFilters>;
}) {
  const [state, setState] = useSearchParamState<Partial<TFilters>>(
    params.key,
    params.initialState ?? {},
  );

  return useBaseFilters({ state, setState, initialState: params.initialState });
}

export function useFilters<TFilters extends Record<string, unknown>>(params?: {
  initialState?: Partial<TFilters>;
}) {
  const [state, setState] = React.useState<Partial<TFilters>>(params?.initialState ?? {});

  return useBaseFilters({ state, setState, initialState: params?.initialState });
}

function useBaseFilters<TFilters extends Record<string, unknown>>(params: {
  initialState?: Partial<TFilters>;
  state: Partial<TFilters>;
  setState: React.Dispatch<React.SetStateAction<Partial<TFilters>>>;
}) {
  const { initialState, state, setState } = params;

  const setFilter = <T extends keyof TFilters>(key: T, value: TFilters[T] | undefined) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const setFilters = (values: Partial<TFilters>) => {
    setState((prev) => ({ ...prev, ...values }));
  };

  const getFilter = <T extends keyof TFilters>(key: T) => {
    return state[key];
  };

  const getFilterOrNull = <T extends keyof TFilters>(key: T) => {
    return state[key] ?? null;
  };

  const resetToInitial = () => {
    setState(initialState ?? {});
  };

  return {
    state: state,
    setFilter: setFilter,
    setFilters: setFilters,
    getFilter: getFilter,
    getFilterOrNull: getFilterOrNull,
    resetToInitial: resetToInitial,
  };
}
