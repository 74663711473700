import React from "react";
import { useSearchParams as useReactRouterSearchParams } from "react-router-dom";
import { z } from "zod";

export default function useSearchParams<$PathParamSchema extends z.AnyZodObject>(
  schema: $PathParamSchema,
) {
  const [searchParams, setSearchParams] = useReactRouterSearchParams();

  const record = React.useMemo(() => {
    return schema.parse(Object.fromEntries(searchParams.entries())) as z.infer<$PathParamSchema>;
  }, [schema, searchParams]);

  return [record, setSearchParams] as const;
}
