import { chakra } from "@chakra-ui/react";
import ReactFlow, { Background, Controls, ReactFlowProvider } from "reactflow";
import {
  WorkflowDefinition,
  WorkflowInstanceExecutionResult,
  WorkflowNode,
} from "../../../shared/schemas/workflows";
import useFlowBoard from "../hooks/useFlowBoard";

interface Props {
  workflowChildren: Record<string, WorkflowNode>;
  workflowInput: WorkflowDefinition["input"];
  flattenedNodes: Record<string, WorkflowNode>;
  selectedNodeId: string | null;
  executionResult?: WorkflowInstanceExecutionResult;
  onSelectNode: (id: string) => void;
  onUnselectNode: (id: string) => void;
  onAddNode: (id: string, node: WorkflowNode) => void;
  onUpdateNode: (id: string, node: WorkflowNode) => void;
  onRemoveNode: (id: string) => void;
  onConnectNodes: (sourceId: string, targetId: string) => void;
}

function FlowBoard(props: Props) {
  return (
    <ReactFlowProvider>
      <FlowBoardInner {...props} />
    </ReactFlowProvider>
  );
}

function FlowBoardInner(props: Props) {
  const {
    nodes,
    edges,
    reactFlowWrapper,
    onChangeNodes,
    onEdgesChange,
    onConnect,
    onConnectStart,
    onConnectEnd,
  } = useFlowBoard(props);

  return (
    <FlowBoard.Root ref={reactFlowWrapper}>
      <ReactFlow
        fitView
        edges={edges}
        nodes={nodes}
        onConnect={onConnect}
        onConnectEnd={onConnectEnd}
        onConnectStart={onConnectStart}
        onEdgesChange={onEdgesChange}
        onNodesChange={onChangeNodes}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </FlowBoard.Root>
  );
}

FlowBoard.Root = chakra("section", {
  baseStyle: {
    h: "full",
    w: "full",
  },
});

export default FlowBoard;
