import { Container, Flex, Heading } from "@chakra-ui/react";
import Header from "../../shared/components/Header";
import Page from "../../shared/components/Page";
import { CreateClusterModalButton } from "./components/CreateClusterModal";
import ClustersTable from "./components/ClustersTable";

export default function ClustersPage() {
  return (
    <Page>
      <Header breadcrumbs={[{ name: "Clusters", active: true, to: "/clusters" }]} />
      <Container maxW="container.xl" p={16}>
        <Flex justify="space-between" mb={8}>
          <Heading>Clusters</Heading>
          <CreateClusterModalButton />
        </Flex>
        <ClustersTable />
      </Container>
    </Page>
  );
}
