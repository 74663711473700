import { createIcon } from "@chakra-ui/react";

const CronjobIcon = createIcon({
  displayName: "CronjobIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M10.021 1.021h6v2h-6zM20.04 7.41l1.434-1.434l-1.414-1.414l-1.433 1.433A8.989 8.989 0 0 0 7.53 5.881l1.42 1.44a7.038 7.038 0 0 1 4.06-1.3l.01.001v6.98l4.953 4.958A7.001 7.001 0 0 1 6.01 13.021h3l-4-4l-4 4h3A9 9 0 1 0 20.04 7.41Z"
      fill="currentColor"
    />
  ),
});

export default CronjobIcon;
