import { EditIcon } from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  Skeleton,
  SkeletonProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Messages } from "../../../shared/api";
import InternalErrorBox from "../../../shared/components/InternalErrorBox";
import { range } from "../../../shared/utils/general.utils";
import { useApi } from "../../../shared/utils/use-api";
import { EditTagModalTrigger } from "./EditTagModal";

function TdSkeleton(props: SkeletonProps) {
  return (
    <Td>
      <Skeleton rounded="full" {...props} />
    </Td>
  );
}

// TODO - this should be probably converted to DataTable once it will be available
export default function TagsTable() {
  const { queries } = useApi();
  const query = useQuery(queries.tags());

  if (query.isError) {
    return (
      <InternalErrorBox
        error={query.error}
        title="Failed to render tags table"
        onRetry={query.refetch}
      />
    );
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Name</Th>
            <Th width={24}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {query.status === "loading" ? (
            <TagsTableBody status="loading" />
          ) : (
            <TagsTableBody status="success" tags={query.data.tags} />
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function TagsTableBody(
  props:
    | { status: "loading" }
    | {
        status: "success";
        tags: Messages["ListedWorkflowTag"][];
      },
) {
  switch (props.status) {
    case "loading":
      return (
        <>
          {range(10).map((i) => (
            <Tr key={i}>
              <TdSkeleton height="20px" />
              <TdSkeleton height="20px" />
              <TdSkeleton height="20px" marginInlineStart="auto" />
            </Tr>
          ))}
        </>
      );

    case "success":
      return (
        <>
          {props.tags.map((tag) => (
            <Tr key={tag.id} data-tag-id={tag.id}>
              <Td>
                <Text color="blue.600" fontFamily="mono">
                  {tag.id}
                </Text>
              </Td>
              <Td>{tag.name}</Td>
              <Td py={0}>
                <Flex justify="flex-end">
                  <EditTagModalTrigger id={tag.id}>
                    <IconButton
                      aria-label="Edit tag"
                      icon={<EditIcon />}
                      size="sm"
                      variant="ghost"
                    />
                  </EditTagModalTrigger>
                </Flex>
              </Td>
            </Tr>
          ))}
        </>
      );
  }
}
