import { CheckIcon, ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Flex,
  FlexProps,
  HStack,
  Input,
  Popover,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
  useFormControlProps,
} from "@chakra-ui/react";
import React from "react";

type SelectOptions<TValue> =
  | ReadonlyArray<{ value: TValue; label: string; description?: string }>
  | { value: TValue; label: string; description?: string }[];

type BaseProps<TValue> = {
  searchable?: boolean;
  buttonProps?: ButtonProps;
  width?: PopoverContentProps["width"];
  size?: ButtonProps["size"];
  allowUnselect?: boolean;
  isDisabled?: boolean;
  "data-testid"?: string;
  "aria-invalid"?: boolean;
  renderAfter?: (p: {
    searchTerm: string;
    filteredOptions: SelectOptions<TValue>;
  }) => React.ReactNode;
  children?: React.ReactNode | ((p: { isOpen: boolean }) => React.ReactNode);
  popoverProps?: PopoverProps;
  portal?: boolean;
} & (
  | {
      multiple: true;
      value: TValue[] | null;
      hideCheckAll?: boolean;
      hideClearSelection?: boolean;
      selectedLabel?: (value: TValue[]) => string;
      onChange: (value: TValue[] | undefined) => void;
    }
  | {
      multiple?: false;
      value: TValue | null;
      defaultLabel?: string;
      selectedLabel?: (value: TValue) => string;
      onChange: (value: TValue | undefined) => void;
    }
);

export type CustomSelectProps<TValue> = BaseProps<TValue> & {
  label?: string;
};

type Props<TValue> = BaseProps<TValue> & {
  label: string;
  options: SelectOptions<TValue>;
};

function ConditionalPortal(props: { children: React.ReactNode; enable: boolean }) {
  return props.enable ? <Portal>{props.children}</Portal> : <>{props.children}</>;
}

export default function Select<TValue>(props: Props<TValue>) {
  const formControlProps = useFormControlProps(props);

  const [searchTerm, setSearchTerm] = React.useState("");
  const disclosure = useDisclosure({
    onClose: () => setTimeout(() => setSearchTerm(""), 200),
  });

  const isSearchable = props.searchable ?? props.options.length > 5;
  const canUnselect = props.allowUnselect ?? true;
  const showCheckAll = props.multiple === true && props.hideCheckAll !== true;
  const showClearSelection =
    canUnselect && props.multiple === true && props.hideClearSelection !== true;

  const isChecked = (value: TValue) => {
    if (props.multiple === true) {
      return (props.value ?? []).some((v) => isEqual(v, value));
    }

    return isEqual(props.value, value);
  };

  const filteredOptions = props.options.filter((option) => {
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleSelect = (value: TValue) => {
    if (props.multiple === true) {
      const selected = props.value ?? [];
      const newValue = selected.includes(value)
        ? selected.filter((v) => v !== value)
        : [...selected, value];

      return props.onChange(newValue.length > 0 ? newValue : undefined);
    }

    isEqual(props.value, value) ? props.onChange(undefined) : props.onChange(value);
    disclosure.onClose();
  };

  const handleSelectAll = () => {
    if (props.multiple === true) {
      return props.onChange(props.options.map((option) => option.value));
    }
  };

  const handleUnselectAll = () => {
    return props.onChange(undefined);
  };

  const getButtonLabel = () => {
    if (hasSelectedValue(props.value)) {
      const selectedValue = JSON.stringify(props.value);

      return props.multiple === true
        ? props.selectedLabel?.(props.value) ?? `${props.label} (${props.value.length})`
        : props.selectedLabel?.(props.value) ??
            props.options.find((option) => JSON.stringify(option.value) === selectedValue)?.label ??
            props.defaultLabel ??
            "";
    }

    return String(props.label);
  };

  const testId = `${props["data-testid"] ?? `${props.label}-select`}`;

  return (
    <Popover placement="bottom-start" {...disclosure} {...props.popoverProps}>
      <PopoverTrigger>
        {props.children !== undefined ? (
          typeof props.children === "function" ? (
            props.children({ isOpen: disclosure.isOpen })
          ) : (
            props.children
          )
        ) : (
          <Button
            _invalid={
              disclosure.isOpen
                ? undefined
                : {
                    borderColor: "red.500",
                    boxShadow: "0 0 0 1px var(--chakra-colors-red-500)",
                  }
            }
            aria-invalid={props["aria-invalid"] ?? formControlProps.isInvalid}
            bg={hasSelectedValue(props.value) ? "blue.50" : undefined}
            colorScheme={hasSelectedValue(props.value) ? "blue" : undefined}
            data-testid={`${testId}-button`}
            isDisabled={props.isDisabled}
            rightIcon={<ChevronDownIcon />}
            size={props.size}
            textOverflow="ellipsis"
            type="button"
            variant="outline"
            whiteSpace="nowrap"
            {...props.buttonProps}
          >
            <Text isTruncated textAlign="start" w="full">
              {getButtonLabel()}
            </Text>
          </Button>
        )}
      </PopoverTrigger>
      <ConditionalPortal enable={props.portal === true}>
        <PopoverContent width={props.width}>
          {showCheckAll ? (
            <>
              <MenuGroup>
                <MenuItem data-testid={`${testId}-check-all`} onClick={handleSelectAll}>
                  <CheckIcon h={3} />
                  <Text>Check all</Text>
                </MenuItem>
                <MenuItem data-testid={`${testId}-uncheck-all`} onClick={handleUnselectAll}>
                  <CloseIcon h={2.5} />
                  <Text>Uncheck all</Text>
                </MenuItem>
              </MenuGroup>
              <Divider />
            </>
          ) : (
            hasSelectedValue(props.value) &&
            showClearSelection && (
              <>
                <MenuGroup>
                  <MenuItem data-testid={`${testId}-clear-selection`} onClick={handleUnselectAll}>
                    <CloseIcon h={2.5} />
                    <Text>Clear selection</Text>
                  </MenuItem>
                </MenuGroup>
                <Divider />
              </>
            )
          )}
          {isSearchable && (
            <>
              <Input
                p={4}
                placeholder="Search..."
                value={searchTerm}
                variant="unstyled"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Divider />
            </>
          )}
          <MenuGroup data-testid={`${testId}-items`}>
            {filteredOptions.map((option) => {
              const key = JSON.stringify(option.value);
              return (
                <MenuItem key={key} onClick={() => handleSelect(option.value)}>
                  <HStack align="start">
                    {(props.multiple === true || props.allowUnselect) && (
                      <Box>
                        <CheckIcon h={3} opacity={isChecked(option.value) ? 1 : 0} />
                      </Box>
                    )}
                    <Flex alignItems="start" direction="column">
                      <Text>{option.label}</Text>
                      {option.description !== undefined && (
                        <Text color="gray.400" fontSize="sm">
                          {option.description}
                        </Text>
                      )}
                    </Flex>
                  </HStack>
                </MenuItem>
              );
            })}
          </MenuGroup>
          {props.renderAfter?.({ searchTerm, filteredOptions })}
        </PopoverContent>
      </ConditionalPortal>
    </Popover>
  );
}

function hasSelectedValue<TValue>(
  value: TValue | TValue[] | null | undefined,
): value is TValue | TValue[] {
  return value !== null && value !== undefined && (!Array.isArray(value) || value.length !== 0);
}

function MenuGroup(props: FlexProps) {
  return <Flex direction="column" maxH="40vh" overflow="auto" py={2} {...props} />;
}

function MenuItem(props: FlexProps) {
  return (
    <Flex
      _hover={{ bg: "gray.100" }}
      alignItems="center"
      as="button"
      cursor="pointer"
      gap={2}
      px={4}
      py={2}
      textAlign="start"
      type="button"
      {...props}
    />
  );
}

function isEqual<TValue>(
  a: TValue | TValue[] | null | undefined,
  b: TValue | TValue[] | null | undefined,
) {
  return JSON.stringify(a) === JSON.stringify(b);
}

Select.MenuGroup = MenuGroup;
Select.MenuItem = MenuItem;
