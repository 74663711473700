import { SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Wrap,
  useDisclosure,
} from "@chakra-ui/react";
import React, { Fragment } from "react";
import FancySelectionCard from "./NodeTypeCard";

interface Option<TValue> {
  value: TValue;
  title: string;
  description?: string;
  icon: React.ReactNode;
}

interface OptionsGroup<TValue> {
  title: string;
  items: Option<TValue>[];
}

type Props<TValue> = {
  title: string;
  options: OptionsGroup<TValue>[];
  trigger: React.ReactNode;
} & (
  | {
      multiple?: false;
      selected: TValue | null;
      onSelect: (option: TValue) => void;
    }
  | {
      multiple: true;
      selected: TValue[];
      onSelect: (options: TValue[]) => void;
    }
);

export default function FancySelection<TValue>(props: Props<TValue>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [term, setTerm] = React.useState("");
  const [selectedMany, setSelectedMany] = React.useState<TValue[] | null>(null);

  const reset = () => {
    setTerm("");
    setSelectedMany(null);
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  };

  const options = props.options.flatMap((optionsGroup) => {
    const items = optionsGroup.items.filter(
      (option) =>
        option.title.toLowerCase().includes(term.toLowerCase()) ||
        optionsGroup.title.toLowerCase().includes(term.toLowerCase()),
    );

    if (items.length === 0) {
      return [];
    }

    return {
      ...optionsGroup,
      items: items,
    };
  });

  const handleChoose = (option: Option<TValue>) => {
    if (props.multiple && selectedMany !== null) {
      const isSelected = selectedMany.some((s) => areEqual({ a: s, b: option.value }));

      setSelectedMany((prev) =>
        isSelected
          ? (prev ?? []).filter((s) => !areEqual({ a: s, b: option.value }))
          : [...(prev ?? []), option.value],
      );

      return;
    }

    if (props.multiple) {
      props.onSelect([option.value]);
    } else {
      props.onSelect(option.value);
    }

    handleClose();
  };

  const handleClickSelectMany = () => {
    setSelectedMany(selectedMany === null ? [] : null);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const option = options.at(0)?.items.at(0);

      if (option === undefined) {
        return;
      }

      handleChoose(option);
    }
  };

  return (
    <>
      <div onClick={onOpen}>{props.trigger}</div>

      <Modal isOpen={isOpen} size="xl" onClose={handleClose}>
        <ModalOverlay />
        <ModalContent data-testid="fancy-selection-modal">
          <ModalHeader backgroundColor="white" position="sticky" top="0" zIndex="1">
            <Flex alignItems="center" direction="row" gap={2}>
              <Text>{props.title}</Text>
            </Flex>
            <Flex direction="column" gap={4} mt="4">
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  autoFocus={true}
                  data-testid={`fancy-selection-search-input`}
                  placeholder="Search..."
                  type="search"
                  onChange={handleSearch}
                  onKeyDown={handleKeyDown}
                />
              </InputGroup>
              {props.multiple && (
                <Wrap>
                  <Checkbox isChecked={selectedMany !== null} onChange={handleClickSelectMany}>
                    Select many
                  </Checkbox>
                  {selectedMany !== null && (
                    <Button
                      colorScheme="blue"
                      data-testid={`fancy-selection-select-button`}
                      isDisabled={selectedMany.length === 0}
                      size="xs"
                      onClick={() => {
                        props.onSelect(selectedMany);
                        handleClose();
                      }}
                    >
                      Select
                    </Button>
                  )}
                </Wrap>
              )}
            </Flex>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            {options.length === 0 && (
              <Center>
                <Heading fontWeight="li" my={4} size="sm">
                  Whoops! No results
                </Heading>
              </Center>
            )}

            {options.flatMap((optionsGroup, i) => {
              const group = (
                <Fragment key={optionsGroup.title}>
                  <Heading key={i} mb={4} size="sm">
                    {optionsGroup.title}
                  </Heading>
                  <Grid key={i + 1} gap="4" templateColumns="repeat(2, 1fr)">
                    {optionsGroup.items.map((option) => {
                      return (
                        <GridItem key={JSON.stringify(option.value)}>
                          <FancySelectionCard
                            description={option.description}
                            icon={option.icon}
                            selected={getIsSelected({
                              option: option,
                              selected: props.selected,
                              selectedMany: selectedMany,
                              multiple: props.multiple ?? false,
                            })}
                            title={option.title}
                            onClick={() => handleChoose(option)}
                          />
                        </GridItem>
                      );
                    })}
                  </Grid>
                </Fragment>
              );

              return [
                group,
                ...[
                  i < options.length - 1 ? (
                    <Divider key={i + 2} my={4} orientation="horizontal" />
                  ) : (
                    []
                  ),
                ],
              ];
            })}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function areEqual<TValue>(
  params:
    | {
        a: TValue;
        b: TValue;
      }
    | {
        a: TValue[];
        b: TValue[];
      },
) {
  const { a, b } = params;

  if (Array.isArray(a) && Array.isArray(b)) {
    return a.every((aItem) => b.some((bItem) => aItem === bItem));
  }

  return a === b;
}

function getIsSelected<TValue>(params: {
  option: Option<TValue>;
  selected: TValue | TValue[] | null;
  selectedMany: TValue[] | null;
  multiple: boolean;
}) {
  const { option, selected, selectedMany, multiple } = params;

  if (multiple && selectedMany !== null) {
    return selectedMany.some((s) => areEqual({ a: s, b: option.value }));
  }

  if (Array.isArray(selected)) {
    return selected.some((s) => areEqual({ a: s, b: option.value }));
  }

  return areEqual({ a: selected, b: option.value });
}
