import { createIcon } from "@chakra-ui/react";

const FlowsheetOutlineIcon = createIcon({
  displayName: "FlowsheetOutlineIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M4 12v6V6v6Zm10 9q.425 0 .713-.288T15 20q0-.425-.288-.713T14 19q-.425 0-.713.288T13 20q0 .425.288.713T14 21Zm6-10q.425 0 .713-.288T21 10q0-.425-.288-.713T20 9q-.425 0-.713.288T19 10q0 .425.288.713T20 11ZM6 11h5V9H6v2Zm0 4h5v-2H6v2Zm-2 5q-.825 0-1.413-.588T2 18V6q0-.825.588-1.413T4 4h16q.825 0 1.413.588T22 6H4v12h5v2H4Zm10 3q-1.25 0-2.125-.875T11 20q0-.975.563-1.75T13 17.175V14h6v-1.175q-.875-.3-1.438-1.075T17 10q0-1.25.875-2.125T20 7q1.25 0 2.125.875T23 10q0 .975-.563 1.75T21 12.825V16h-6v1.175q.875.3 1.438 1.075T17 20q0 1.25-.875 2.125T14 23Z"
      fill="currentColor"
    />
  ),
});

export default FlowsheetOutlineIcon;
