import { Container, Flex, Heading } from "@chakra-ui/react";
import Header from "../../shared/components/Header";
import Page from "../../shared/components/Page";
import { CreateTagModalButton } from "./components/CreateTagModal";
import TagsTable from "./components/TagsTable";

export default function TagsPage() {
  return (
    <Page>
      <Header breadcrumbs={[{ name: "Tags", active: true, to: "/tags" }]} />
      <Container maxW="container.xl" p={16}>
        <Flex justify="space-between" mb={8}>
          <Heading>Tags</Heading>
          <CreateTagModalButton />
        </Flex>
        <TagsTable />
      </Container>
    </Page>
  );
}
