import React from "react";
import { WorkflowDefinition } from "../schemas/workflows";
import { WorkflowStore, createWorkflowStore } from "../utils/create-workflow-store";

export const WorkflowContext = React.createContext<WorkflowStore>({} as WorkflowStore);

export function WorkflowProvider(props: {
  value: WorkflowDefinition;
  children: React.ReactNode;
  onError: (error: unknown) => void;
}) {
  const storeRef = React.useRef<WorkflowStore>();

  if (!storeRef.current) {
    storeRef.current = createWorkflowStore({ workflow: props.value, onError: props.onError });
  }

  return (
    <WorkflowContext.Provider value={storeRef.current}>{props.children}</WorkflowContext.Provider>
  );
}
