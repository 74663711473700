import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { parseFilters, stringifyFilters } from "../utils/filter.utils";
import { fmap } from "../utils/general.utils";

interface Params<$FiltersSchema extends z.AnyZodObject> {
  schema: $FiltersSchema;
  key: string;
  default: z.infer<$FiltersSchema>;
}

export default function useZodSearchParamFilters<$FiltersSchema extends z.AnyZodObject>(
  params: Params<$FiltersSchema>,
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters =
    fmap(searchParams.get(params.key), (queryFilters) =>
      params.schema.parse(parseFilters({ queryFilters, default: params.default })),
    ) ?? params.default;

  const setFilters = (filters: z.infer<$FiltersSchema>) => {
    const queryFilters = stringifyFilters({ filters, default: params.default });

    if (queryFilters == undefined) {
      searchParams.delete(params.key);
      setSearchParams(searchParams, { replace: true });
      return;
    }

    setSearchParams({ [params.key]: queryFilters }, { replace: true });
  };

  return [filters as z.infer<$FiltersSchema>, setFilters] as const;
}
