import { createIcon } from "@chakra-ui/react";

const ClockTimeFourOutlineIcon = createIcon({
  displayName: "ClockTimeFourOutlineIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M12 20c4.4 0 8-3.6 8-8s-3.6-8-8-8s-8 3.6-8 8s3.6 8 8 8m0-18c5.5 0 10 4.5 10 10s-4.5 10-10 10S2 17.5 2 12S6.5 2 12 2m5 11.9l-.7 1.3l-5.3-2.9V7h1.5v4.4l4.5 2.5Z"
      fill="currentColor"
    />
  ),
});

export default ClockTimeFourOutlineIcon;
