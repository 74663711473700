import { Writeable } from "./types";

export function isDefined<T>(value: T | undefined | null): value is NonNullable<T> {
  return value !== undefined && value !== null;
}

export function validateOneOf<T>(value: unknown, possibilies: readonly T[]): T {
  if (!possibilies.includes(value as T)) {
    throw new Error(
      `validation of validateOneOf failed. Expected "${value}" to be one of "${possibilies.join(
        ", ",
      )}"`,
    );
  }

  return value as T;
}

export function deepEqual<T>(obj1: T, obj2: T): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }

    for (const prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false;
      }
    }

    return true;
  }

  return false;

  function isObject(obj: unknown): obj is Record<string, unknown> {
    return typeof obj === "object" && obj != null;
  }
}

export function capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function assertNever(value: never, noThrow?: boolean): never {
  if (noThrow) {
    return value;
  }

  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
}

export function assertDefined<T>(value: T | null | undefined, message: string): T {
  if (isDefined(value)) {
    return value;
  }

  throw new Error(message);
}

export function fmap<T, U>(value: T | null | undefined, cb: (value: T) => U): U | undefined {
  if (value !== undefined && value !== null) {
    return cb(value);
  }

  return undefined;
}

export function noop(): void {
  // do nothing
}

export function writeable<T>(value: T): Writeable<T> {
  return value as Writeable<T>;
}

export function groupByKey<T extends object, K extends keyof T>(collection: T[], iteratee: K) {
  const map: Map<T[K], T[]> = new Map();

  for (const item of collection) {
    const accumalated = map.get(item[iteratee]);
    if (accumalated === undefined) {
      map.set(item[iteratee], [item]);
    } else {
      map.set(item[iteratee], [...accumalated, item]);
    }
  }

  return map;
}

export function groupByFn<T, K>(collection: T[], iteratee: (item: T) => K) {
  const map: Map<K, T[]> = new Map();

  for (const item of collection) {
    const accumalated = map.get(iteratee(item));
    if (accumalated === undefined) {
      map.set(iteratee(item), [item]);
    } else {
      map.set(iteratee(item), [...accumalated, item]);
    }
  }

  return map;
}

export function averageByFn<T, K extends number>(data: T[], fn: (x: T) => K) {
  return data.length > 0
    ? Math.round(data.reduce((acc, datum) => acc + fn(datum), 0) / data.length)
    : 0;
}

export function isKeyOf<T extends Record<PropertyKey, unknown>>(
  key: PropertyKey,
  obj: T,
): key is keyof T {
  return key in obj;
}

export function toHumanCase(str: string) {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
}

export function toArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}

export function toArrayOrNull<T>(value: T | T[] | null | undefined): T[] | null {
  if (value === null || value === undefined) {
    return null;
  }

  return toArray(value);
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export function objectEntries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>;
}

export function range(num: number): number[] {
  if (num < 0) {
    throw new Error(`${num} should be bigger than or equal to 0`);
  }

  return Array(num)
    .fill(0)
    .map((_, i) => i);
}
