import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { atomWithStorage, RESET } from "jotai/utils";
import jwtDecode from "jwt-decode";
import React from "react";
import { z } from "zod";
import config from "../../config";
import { createApi, InferResponseOf } from "../api";
import { AuthData, UserInfoFromAuthToken } from "../schemas/auth";
import { createMutations } from "./mutations";
import { createQueries } from "./queries";

const zAuthTokens = z.object({ accessToken: z.string(), refreshToken: z.string() });

export type AuthTokens = z.infer<typeof zAuthTokens>;

const authTokensAtom = atomWithStorage<AuthTokens | null>("auth", null);

const useAuth = () => {
  const [tokens, setTokens] = useAtom(authTokensAtom);
  const queryClient = useQueryClient();

  const authData = React.useMemo(() => parseTokens(tokens), [tokens]);

  const isLoggedIn = tokens != null;

  const { mutations } = React.useMemo(() => {
    const api = createApi({
      baseUrl: config.API_URL,
      auth: null,
      onTokenRefreshed: () => null,
      onInvalidRefreshToken: () => null,
    });

    const queries = createQueries({ api, queryClient });
    const mutations = createMutations({ api, queries });

    return { api, queries, mutations };
  }, [queryClient]);

  const login = useMutation({
    ...mutations.login(),
    onSuccess: (data) => {
      setTokens({
        accessToken: data.accessJWT,
        refreshToken: data.refreshJWT,
      });
    },
  });

  function logout(onLoggedOut?: () => void) {
    setTokens(RESET);
    onLoggedOut?.();
  }

  function handleTokenRefresh(data: InferResponseOf<"post", "/auth/token">) {
    setTokens({
      accessToken: data.accessJWT,
      refreshToken: data.refreshJWT,
    });
  }

  return { tokens, isLoggedIn, login, logout, authData, handleTokenRefresh };
};

function parseTokens(tokens: AuthTokens | null) {
  if (tokens === null || !zAuthTokens.safeParse(tokens).success) {
    return { type: "guest" as const };
  }

  const { authData, userLoginDetails } = jwtDecode<{
    authData: AuthData;
    userLoginDetails: UserInfoFromAuthToken;
  }>(tokens.accessToken);

  return {
    type: "authenticated" as const,
    data: authData,
    userInfo: userLoginDetails,
    tokens: tokens,
  };
}

export default useAuth;
