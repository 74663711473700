import { UnsafeAny } from "./types";

type Memoize = <T extends (...args: UnsafeAny[]) => unknown>(
  fn: T,
) => (...args: Parameters<T>) => ReturnType<T>;

export const memoize: Memoize = (fn) => {
  const cache = new Map();

  return (...args) => {
    const key = JSON.stringify(args);

    if (cache.has(key)) {
      return cache.get(key);
    }

    const result = fn(...args);

    cache.set(key, result);

    return result;
  };
};
