import React from "react";

export function useOverflow() {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const [hasScrolledToEnd, setHasScrolledToEnd] = React.useState(false);

  React.useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const handleScroll = () => {
      const { scrollWidth, clientWidth, scrollLeft } = element;
      const isOverflowing = scrollWidth > clientWidth;
      const hasScrolledToEnd = scrollLeft + clientWidth === scrollWidth;
      setIsOverflowing(isOverflowing);
      setHasScrolledToEnd(hasScrolledToEnd);
    };

    handleScroll();

    element.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      element.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  const canScroll = isOverflowing && !hasScrolledToEnd;

  return { ref, isOverflowing, hasScrolledToEnd, canScroll };
}
