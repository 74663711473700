import { Tag } from "@chakra-ui/react";
import { Messages } from "../api";

export default function WorkflowInstanceStatusTag(props: {
  status: Messages["WorkflowCompletionStatus"];
}) {
  switch (props.status.name) {
    case "SUCCESS":
      return (
        <Tag colorScheme="green" variant="subtle">
          Success
        </Tag>
      );
    case "IN_PROGRESS":
      return (
        <Tag colorScheme="blue" variant="subtle">
          In progress
        </Tag>
      );
    case "ERROR":
      return (
        <Tag colorScheme="red" variant="subtle">
          Failed
        </Tag>
      );
    case "CANCELED":
      return (
        <Tag colorScheme="gray" variant="subtle">
          Canceled
        </Tag>
      );
  }
}
