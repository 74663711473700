import { ChevronRightIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import React from "react";
import NodeIcon from "../../../../../../shared/components/NodeIcon";
import TaskAltIcon from "../../../../../../shared/icons/TaskAltIcon";
import { Task, WorkflowDefinition, WorkflowNode } from "../../../../../../shared/schemas/workflows";
import { canAddTask } from "../../../../utils/workflow-node.utils";
import FancySelection from "./FancySelection";

interface Props {
  workflow: WorkflowDefinition;
  node: WorkflowNode;
  taskOptions: Task[];
  onChoose: (params: { id: string; version?: number }) => void;
}

export default function NodeTypeSelectionButton(props: Props) {
  const options = getOptions({
    workflow: props.workflow,
    taskOptions: props.taskOptions,
  });

  const selected = getSelectionId(props.node);

  const items = [
    {
      title: "Flow Items",
      items: options
        .filter((option) => canAddTask({ workflow: props.workflow, task: option.value }))
        .filter((option) => option.isFlowItem),
    },
    {
      title: "Tasks",
      items: options
        .filter((option) => canAddTask({ workflow: props.workflow, task: option.value }))
        .filter((option) => !option.isFlowItem),
    },
  ].filter((item) => item.items.length > 0);

  return (
    <FancySelection
      options={items}
      selected={selected}
      title="Select node"
      trigger={
        <IconButton
          aria-label={"Change"}
          data-testid="select-node-button"
          icon={<ChevronRightIcon />}
        />
      }
      onSelect={props.onChoose}
    />
  );
}

function getSelectionId(node: WorkflowNode) {
  switch (node.type) {
    case "system-task":
      return { id: node.task.id, version: node.task.version };
    case "human-task":
    case "group":
    case "workflow":
    case "choice":
    case "short-circuit-task":
    case "reset-to-task":
      return { id: node.type };
  }
}

function getOptions(params: { workflow: WorkflowDefinition; taskOptions: Task[] }): {
  value: { id: string; version?: number };
  title: string;
  description: string;
  icon: React.ReactNode;
  isFlowItem: boolean;
}[] {
  return [
    {
      value: { id: "group" },
      title: "Group",
      description: "Run multiple tasks in parallel",
      icon: <NodeIcon fontSize="2xl" type="group" />,
      isFlowItem: true,
    },
    {
      value: { id: "workflow" },
      title: "Trigger Workflow",
      description: "Trigger a workflow",
      icon: <NodeIcon fontSize="2xl" type="workflow" />,
      isFlowItem: true,
    },
    {
      value: { id: "human-task" },
      title: "Human Task",
      description: "This task will be assigned to a human",
      icon: <NodeIcon fontSize="2xl" type="human-task" />,
      isFlowItem: true,
    },
    {
      value: { id: "choice" },
      title: "Choice",
      description: "Run different tasks based on conditions",
      icon: <NodeIcon fontSize="2xl" type="choice" />,
      isFlowItem: true,
    },
    {
      value: { id: "short-circuit-task" },
      title: "Short Circuit",
      description: "Stop the workflow",
      icon: <NodeIcon fontSize="2xl" type="short-circuit-task" />,
      isFlowItem: true,
    },
    {
      value: { id: "reset-to-task" },
      title: "Reset to Task",
      description: "Go back to a previous task",
      icon: <NodeIcon fontSize="2xl" type="reset-to-task" />,
      isFlowItem: true,
    },
    ...params.taskOptions.map((task) => ({
      value: { id: task.id, version: task.version },
      version: task.version,
      title: task.meta.name,
      description: task.meta.description,
      icon: <TaskAltIcon fontSize="2xl" />,
      isFlowItem: false,
    })),
  ];
}
