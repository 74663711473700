import { createIcon } from "@chakra-ui/react";

const HumanIcon = createIcon({
  displayName: "HumanIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M21 9h-6v13h-2v-6h-2v6H9V9H3V7h18m-9-5a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2c0-1.11.89-2 2-2Z"
      fill="currentColor"
    />
  ),
});

export default HumanIcon;
