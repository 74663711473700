import { Button, Flex, IconButton, useToast } from "@chakra-ui/react";
import Header from "../../shared/components/Header";
import { WorkflowProvider } from "../../shared/components/WorkflowProvider";
import useSelectedNode from "../../shared/hooks/useSelectedNode";
import useWorkflowStore from "../../shared/hooks/useWorkflowStore";
import useWorkflowTemporalStore from "../../shared/hooks/useWorkflowTemporalStore";
import RedoIcon from "../../shared/icons/RedoIcon";
import UndoIcon from "../../shared/icons/UndoIcon";
import { WorkflowSkillId } from "../../shared/schema/schema";
import {
  HumanTaskTemplateDefinition,
  Task,
  WorkflowDefinition,
  WorkflowEntity,
  WorkflowEventTriggerOption,
  WorkflowJobTriggerOption,
  WorkflowTaskQuery,
} from "../../shared/schemas/workflows";
import { isDefined } from "../../shared/utils/general.utils";
import CloneButton from "./components/CloneButton";
import FlowBoard from "./components/FlowBoard";
import PublishSwitch from "./components/PublishSwitch";
import Sidebar from "./components/Sidebar";
import useEditor from "./hooks/useEditor";
import useEditorHistory from "./hooks/useEditorHistory";
import useNodeClipboard from "./hooks/useNodeClipboard";
import { dummyTasks, flattenNodes } from "./utils/workflow-node.utils";

interface Props {
  workflow: WorkflowDefinition;
  triggerOptions: WorkflowEventTriggerOption[];
  triggerJobOptions: WorkflowJobTriggerOption[];
  entityOptions: WorkflowEntity[];
  taskOptions: Task[];
  taskTemplateOptions: HumanTaskTemplateDefinition[];
  queryOptions: WorkflowTaskQuery[];
  skillOptions: WorkflowSkillId[];
}

export default function EditorPage(props: Props) {
  const toast = useToast();

  const handleError = (error: unknown) => {
    toast({ position: "top", description: `${error}`, status: "error" });
  };

  return (
    <WorkflowProvider value={props.workflow} onError={handleError}>
      <EditorPageInner {...props} onError={handleError} />
    </WorkflowProvider>
  );
}

function EditorPageInner(props: Props & { onError: (error: unknown) => void }) {
  const {
    isSaving,
    isCloning,
    isPublishing,
    saveWorkflow,
    cloneWorkflow,
    changePublicationState,
    showError,
  } = useEditor({ workflow: props.workflow, onError: props.onError });

  const workflowStore = useWorkflowStore((s) => s);
  const { undo, redo, pastStates, futureStates } = useWorkflowTemporalStore((s) => s);
  const selectedNode = useSelectedNode();
  const flattenedNodes = flattenNodes(workflowStore.children);
  const taskOptions: Task[] = [...Object.values(dummyTasks), ...props.taskOptions];
  const isEditing = workflowStore.id > 0;

  useNodeClipboard({
    selectedNodeId: selectedNode.selectedNodeId,
    onCopyNode: workflowStore.copyNode,
  });

  useEditorHistory({
    onUndo: undo,
    onRedo: redo,
    onSave: () => saveWorkflow(workflowStore),
  });

  return (
    <MainSection>
      <Header
        actions={
          <>
            <Flex alignItems="center" gap={4}>
              <PublishSwitch
                action={props.workflow.meta.published ? "Unpublish" : "Publish"}
                isPublishing={isPublishing}
                workflow={props.workflow}
                onChangePublishState={changePublicationState}
              />
              <IconButton
                aria-label="Undo"
                data-testid="undo-workflow-button"
                icon={<UndoIcon />}
                isDisabled={pastStates.length === 0}
                variant="ghost"
                onClick={() => undo()}
              />
              <IconButton
                aria-label="Redo"
                data-testid="redo-workflow-button"
                icon={<RedoIcon />}
                isDisabled={futureStates.length === 0}
                variant="ghost"
                onClick={() => redo()}
              />
              <CloneButton isCloning={isCloning} onClone={cloneWorkflow} />
              <Button
                data-testid="save-workflow-button"
                isLoading={isSaving}
                onClick={() => saveWorkflow(workflowStore)}
              >
                Save
              </Button>
            </Flex>
          </>
        }
        breadcrumbs={[
          {
            name: "Workflows",
            active: false,
            to: "/workflows",
          },
          isEditing
            ? {
                name: workflowStore.name,
                to: `/workflows/${workflowStore.correlationKey}`,
              }
            : null,
          isEditing
            ? {
                name: `v${props.workflow.version}`,
                to: `/workflows/${props.workflow.correlationKey}?id=${props.workflow.id}`,
              }
            : null,
          {
            name: "Editor",
            active: true,
          },
        ].filter(isDefined)}
      />
      <SectionContentWrapper>
        <Sidebar
          entityOptions={props.entityOptions}
          flattenedNodes={flattenedNodes}
          queryOptions={props.queryOptions}
          selectedNodeId={selectedNode.selectedNodeId}
          skillOptions={props.skillOptions}
          taskOptions={taskOptions}
          taskTemplateOptions={props.taskTemplateOptions}
          triggerJobOptions={props.triggerJobOptions}
          triggerOptions={props.triggerOptions}
          workflow={workflowStore}
          onAddErrorHandling={workflowStore.addErrorHandling}
          onAddInput={workflowStore.addInput}
          onAddNode={workflowStore.addNode}
          onAddQuery={workflowStore.addQuery}
          onAddTaskTrigger={workflowStore.addTaskTrigger}
          onAddTrigger={workflowStore.addTrigger}
          onChangeDescription={workflowStore.setDescription}
          onChangeName={workflowStore.setName}
          onChangeTaskName={workflowStore.setNodeName}
          onError={showError}
          onRemoveErrorHandling={workflowStore.removeErrorHandling}
          onRemoveInput={workflowStore.removeInput}
          onRemoveNode={workflowStore.removeNode}
          onRemoveQuery={workflowStore.removeQuery}
          onRemoveTaskTrigger={workflowStore.removeTaskTrigger}
          onRemoveTrigger={workflowStore.removeTrigger}
          onUpdateConcurrencyRule={workflowStore.setConcurrencyRule}
          onUpdateErrorHandling={workflowStore.updateErrorHandling}
          onUpdateInput={workflowStore.updateInput}
          onUpdateMeta={workflowStore.updateWorkflowMeta}
          onUpdateNode={workflowStore.updateNode}
          onUpdateQuery={workflowStore.updateQuery}
          onUpdateTaskTrigger={workflowStore.updateTaskTrigger}
          onUpdateTrigger={workflowStore.updateTrigger}
        />
        <FlowBoard
          flattenedNodes={flattenedNodes}
          selectedNodeId={selectedNode.selectedNodeId}
          workflowChildren={workflowStore.children}
          workflowInput={workflowStore.input}
          onAddNode={workflowStore.addNode}
          onConnectNodes={workflowStore.connectNodes}
          onRemoveNode={workflowStore.removeNode}
          onSelectNode={selectedNode.select}
          onUnselectNode={selectedNode.unselect}
          onUpdateNode={workflowStore.updateNode}
        />
      </SectionContentWrapper>
    </MainSection>
  );
}

const MainSection = (props: React.ComponentProps<typeof Flex>) => {
  return <Flex direction="column" h="full" {...props} />;
};

const SectionContentWrapper = (props: React.ComponentProps<typeof Flex>) => {
  return <Flex direction="row" h="full" overflowY="auto" {...props} />;
};
