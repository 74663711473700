import { convert, Instant, LocalDate, LocalDateTime, LocalTime, ZoneId } from "@js-joda/core";

function toLocalDateTime(date: LocalDateTime | Instant | LocalDate) {
  return date instanceof LocalDateTime
    ? date
    : date instanceof LocalDate
    ? date.atStartOfDay()
    : LocalDateTime.ofInstant(date, ZoneId.systemDefault());
}

function createFormatter() {
  const dateTimeFormatter = Intl.DateTimeFormat("en-US", {
    dateStyle: "short",
    timeStyle: "short",
  });
  const dateOnlyFormatter = Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const monthDayFormatter = Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
  });
  const timeOnlyForatter = Intl.DateTimeFormat("en-US", { timeStyle: "short" });

  function toDateTime(date: LocalDateTime | Instant) {
    const localDate = toLocalDateTime(date);
    return dateTimeFormatter.format(convert(localDate).toDate());
  }

  function toMonthDay(date: LocalDateTime | LocalDate | Instant) {
    const localDate = toLocalDateTime(date);
    return monthDayFormatter.format(convert(localDate).toDate());
  }

  function toDate(date: LocalDateTime | Instant | LocalDate) {
    const localDate = toLocalDateTime(date);
    return dateOnlyFormatter.format(convert(localDate).toDate());
  }

  function toTime(date: LocalDateTime | Instant | LocalTime) {
    const localDate = toLocalDateTime(
      date instanceof LocalTime ? LocalDateTime.of(LocalDate.now(), date) : date,
    );
    return timeOnlyForatter.format(convert(localDate).toDate());
  }

  function toDateOrDateTime(date: LocalDateTime | Instant) {
    const localDate = toLocalDateTime(date);
    const now = LocalDateTime.now();
    if (
      localDate.year() === now.year() &&
      localDate.month() === now.month() &&
      localDate.dayOfMonth() === now.dayOfMonth()
    ) {
      return toTime(localDate);
    }

    return toDateTime(localDate);
  }

  return {
    toDateTime,
    toDate,
    toTime,
    toDateOrDateTime,
    toMonthDay,
  };
}

export const dateFormatter = createFormatter();
