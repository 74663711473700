import { LocalDate, LocalDateTime } from "@js-joda/core";

export function localDateRange(from: LocalDate, to: LocalDate) {
  if (from.isAfter(to)) {
    throw new Error("from is after to");
  }

  const days = [];

  for (let date = from; date.isBefore(to); date = date.plusDays(1)) {
    days.push(date);
  }

  return days;
}

export function localDateTimeRange(from: LocalDateTime, to: LocalDateTime, by: "day" | "hour") {
  if (from.isAfter(to)) {
    throw new Error("from is after to");
  }

  const days = [];

  for (
    let date = from;
    date.isBefore(to) || date.isEqual(to);
    date = by === "day" ? date.plusDays(1) : date.plusHours(1)
  ) {
    days.push(date);
  }

  return days;
}

export function atEndOfDay(date: LocalDate) {
  return date.atTime(23, 59, 59, 999999999);
}
