import { useQueries } from "@tanstack/react-query";
import { AgencyMemberId, WorkflowDefinitionId } from "../schema/schema";
import { mappings } from "../schemas/mapping";
import { useApi } from "../utils/use-api";

interface Params {
  id: WorkflowDefinitionId | undefined;
  agencyMemberId: AgencyMemberId;
}

export default function useWorkflowDefinitionQueries({ id, agencyMemberId }: Params) {
  const { queries } = useApi();

  const [
    workflowQuery,
    entityOptionsQuery,
    triggerOptionsQuery,
    taskOptionsQuery,
    humanTaskTemplateOptionsQuery,
    queryOptionsQuery,
    skillsQuery,
    assignmentStrategiesQuery,
  ] = useQueries({
    queries: [
      id ? queries.workflow(id) : queries.emptyWorkflow(agencyMemberId),
      queries.entityOptions(),
      queries.triggerOptions(),
      queries.taskOptions(),
      queries.humanTaskTemplateOptions(),
      queries.queryOptions(),
      queries.skills(),
      queries.assignmentStrategies(),
    ],
  });

  if (
    workflowQuery.isLoading ||
    entityOptionsQuery.isLoading ||
    triggerOptionsQuery.isLoading ||
    taskOptionsQuery.isLoading ||
    humanTaskTemplateOptionsQuery.isLoading ||
    queryOptionsQuery.isLoading ||
    skillsQuery.isLoading ||
    assignmentStrategiesQuery.isLoading
  ) {
    return { isLoading: true as const, isError: false };
  }

  if (
    workflowQuery.isError ||
    entityOptionsQuery.isError ||
    triggerOptionsQuery.isError ||
    taskOptionsQuery.isError ||
    humanTaskTemplateOptionsQuery.isError ||
    queryOptionsQuery.isError ||
    skillsQuery.isError ||
    assignmentStrategiesQuery.isError
  ) {
    const error =
      workflowQuery.error ??
      entityOptionsQuery.error ??
      triggerOptionsQuery.error ??
      taskOptionsQuery.error ??
      humanTaskTemplateOptionsQuery.error ??
      queryOptionsQuery.error;

    return { isError: true as const, isLoading: false, error };
  }

  return {
    isLoading: false as const,
    isError: false as const,
    ...mappings.definitionQueryResponses({
      entityOptions: entityOptionsQuery.data,
      queryOptions: queryOptionsQuery.data,
      skillOptions: skillsQuery.data,
      taskOptions: taskOptionsQuery.data,
      taskTemplateOptions: humanTaskTemplateOptionsQuery.data,
      triggerOptions: triggerOptionsQuery.data,
      assignmentStrategyOptions: assignmentStrategiesQuery.data,
      workflow: workflowQuery.data.workflow,
    }),
  };
}
