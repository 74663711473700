import { Box, Container, Flex, Heading } from "@chakra-ui/react";
import Header from "../../shared/components/Header";
import Page from "../../shared/components/Page";
import useAgencyMember from "../../shared/hooks/useAuthData";
import FlowsheetOutlineIcon from "../../shared/icons/FlowsheetOutlineIcon";
import Workflow16Icon from "../../shared/icons/Workflow16Icon";
import FeatureCard from "./components/FeatureCard";
import TagsOIcon from "../../shared/icons/TagsOIcon";

export default function DashboardPage() {
  const agencyMember = useAgencyMember();

  return (
    <Page>
      <Header breadcrumbs={[]} />
      <Box bg="gray.100" h="xs" mb="-64" w="full" />
      <Container maxW="container.xl" px={16}>
        <Heading mb={8}>Hi {agencyMember.firstName}</Heading>
        <Flex gap={4} wrap={{ base: "wrap", md: "nowrap" }}>
          <FeatureCard
            button="Manage workflows"
            color="blue"
            description="Design and manage workflows of automated and manual tasks to improve efficiency and streamline agency processes"
            icon={<Workflow16Icon h={6} w={6} />}
            title="Workflows"
            to="/workflows"
          />

          <FeatureCard
            button="Manage clusters"
            color="pink"
            description="Use Clusters to join related tasks together for efficient execution. Ideal for managing similar tasks across different flows."
            icon={<FlowsheetOutlineIcon h={7} w={7} />}
            title="Clusters"
            to="/clusters"
          />

          <FeatureCard
            button="Manage tags"
            color="purple"
            description="Use Tags to categorize and filter tasks and workflows. Ideal for monitoring similar tasks across different flows."
            icon={<TagsOIcon h={7} w={7} />}
            title="Tags"
            to="/tags"
          />
        </Flex>
      </Container>
    </Page>
  );
}
