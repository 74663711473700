import { dummyTaskRefIds, isBindEmpty } from "../../pages/Editor/utils/workflow-node.utils";
import {
  WorkflowChildChoiceTask,
  WorkflowChildHumanTaskReference,
  WorkflowChildReferenceBinding,
  WorkflowChildSystemTaskReference,
  WorkflowDataField,
  WorkflowDefinition,
  WorkflowInput,
  WorkflowNode,
  WorkflowTrigger,
} from "./workflows";

export function validateBind(params: {
  bind: Record<string, WorkflowChildReferenceBinding>;
  input: Record<string, WorkflowDataField>;
  location: string;
}) {
  for (const [key, value] of Object.entries(params.input)) {
    const isRequired = !(value.optional ?? false);
    const bind = params.bind[key];

    if (isRequired && (bind === undefined || isBindEmpty(bind))) {
      throw new Error(`Input field ${key} is required but not bound at ${params.location}`);
    }
  }
}

export function validateHumanTaskOutput(humanTask: WorkflowChildHumanTaskReference) {
  if (Object.keys(humanTask.output).length === 0) {
    throw new Error(`Human task "${humanTask.meta.name}" must have at least one output`);
  }
}

export function validateHumanTaskSkills(humanTask: WorkflowChildHumanTaskReference) {
  if (humanTask.skills.length === 0) {
    throw new Error(`Human task "${humanTask.meta.name}" must have at least one skill`);
  }
}

export function validateTaskRetry(params: {
  task:
    | WorkflowChildHumanTaskReference
    | WorkflowChildSystemTaskReference
    | WorkflowChildChoiceTask;
  location: string;
}) {
  if (params.task.retry === undefined) {
    return;
  }

  if (params.task.retry.retryPaths.length === 0) {
    throw new Error(`${params.location} must have at least one output selected`);
  }

  if (params.task.retry.type === "trigger" && params.task.trigger === undefined) {
    throw new Error(`${params.location} cannot have a retry of type "trigger" without a trigger`);
  }
}

export function validateWorkflowDefinition(workflowDefinition: WorkflowDefinition) {
  validateWorkflowTriggers({
    triggers: workflowDefinition.triggers,
    workflowInput: workflowDefinition.input,
  });

  validateOneRootChild(workflowDefinition.children);
}

function validateWorkflowTriggers(params: {
  workflowInput: Map<string, WorkflowInput>;
  triggers: WorkflowTrigger[];
}) {
  for (const [key, input] of params.workflowInput) {
    const isRequired = !(input.optional ?? false);

    if (isRequired && params.triggers.some((trigger) => trigger.bind[key] === undefined)) {
      throw new Error(`Input field ${input.name} is required but not bound to a trigger`);
    }
  }
}

function validateOneRootChild(children: Record<string, WorkflowNode>) {
  const detachedNodes = Object.values(children).filter(
    (child) => child.dependsOn === undefined && child.logicalId !== dummyTaskRefIds.trigger,
  );

  if (detachedNodes.length !== 0) {
    const errorMessagePrefix =
      detachedNodes.length === 1
        ? "Please connect the following node:"
        : "Please connect the following nodes:";

    throw new Error(
      `${errorMessagePrefix} ${detachedNodes.map((node) => node.meta.name).join(", ")}`,
    );
  }
}
