import BaselineMinusIcon from "../../../../../../shared/icons/BaselineMinusIcon";
import DataArrayIcon from "../../../../../../shared/icons/DataArrayIcon";
import QuestionMarkIcon from "../../../../../../shared/icons/QuestionMarkIcon";
import { NamedField, WorkflowEntity } from "../../../../../../shared/schemas/workflows";
import Sidebar from "../../../Sidebar";

interface Props {
  id: string;
  fieldType: NamedField;
  entityOptions: WorkflowEntity[];
  "data-testid": string;
  onUpdate: (fieldType: NamedField) => void;
  onClickRemove: () => void;
}

function DefineFieldTypeComponent(props: Props) {
  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onUpdate({ ...props.fieldType, name: e.target.value });
  };

  const handleToggleArray = () => {
    props.onUpdate({ ...props.fieldType, array: !props.fieldType.array });
  };

  const handleToggleOptional = () => {
    props.onUpdate({ ...props.fieldType, optional: !props.fieldType.optional });
  };

  return (
    <Sidebar.Row>
      {props.fieldType.type !== "entity" && <Sidebar.Label>{props.fieldType.type}</Sidebar.Label>}
      {props.fieldType.type === "entity" && <Sidebar.Label>{props.fieldType.entity}</Sidebar.Label>}
      <Sidebar.Input
        data-testid={`${props["data-testid"]}-name-input`}
        placeholder="Name"
        value={props.fieldType.name}
        onChange={handleChangeName}
      />
      <Sidebar.ToggleButton
        aria-label="array"
        isActive={props.fieldType.array === true}
        onClick={handleToggleArray}
      >
        <DataArrayIcon />
      </Sidebar.ToggleButton>
      <Sidebar.ToggleButton
        aria-label="optional"
        isActive={props.fieldType.optional === true}
        onClick={handleToggleOptional}
      >
        <QuestionMarkIcon />
      </Sidebar.ToggleButton>
      <Sidebar.SquareButton aria-label="remove" onClick={props.onClickRemove}>
        <BaselineMinusIcon />
      </Sidebar.SquareButton>
    </Sidebar.Row>
  );
}

export default DefineFieldTypeComponent;
