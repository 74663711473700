import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { LocalDateTime, nativeJs } from "@js-joda/core";
import cronParser from "cron-parser";
import cronstrue from "cronstrue";
import { E, pipe } from "../fp-ts";
import { dateFormatter } from "../utils/date-formatter";

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export default function Cron(props: Props) {
  const result = pipe(
    E.Do,
    E.bind("expression", () => {
      return E.tryCatch(() => cronParser.parseExpression(props.value), E.toError);
    }),
    E.bind("formatted", () => {
      return E.tryCatch(() => cronstrue.toString(props.value), E.toError);
    }),
  );

  return (
    <FormControl isInvalid={pipe(result, E.isLeft)}>
      <FormLabel>Every</FormLabel>
      <Flex gap={2}>
        <Input
          placeholder="30"
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
        <Button variant="outline" onClick={() => props.onChange("* * * * *")}>
          Reset
        </Button>
      </Flex>
      {pipe(
        result,
        E.fold(
          (error) => <FormErrorMessage>{error.message}</FormErrorMessage>,
          ({ expression, formatted }) => (
            <Flex bg="gray.50" direction="column" gap={4} mt={2} p={4} rounded="md">
              <Heading size="xs">{formatted}</Heading>
              <Flex direction="column" gap={2}>
                {Array.from({ length: 5 }, () => expression.next()).map((value) => (
                  <Text key={value.toDate().toISOString()}>
                    {dateFormatter.toDateTime(LocalDateTime.from(nativeJs(value.toDate())))}
                  </Text>
                ))}
                <Text>...</Text>
              </Flex>
            </Flex>
          ),
        ),
      )}
    </FormControl>
  );
}
