import { produce } from "immer";
import {
  addPlaceholdersToLooseEnds,
  dummyTaskRefIds,
  dummyTasks,
  emptyRef,
  filterOutDummyTasks,
  findNode,
  flattenNodes,
  getAvailablePathsForNode,
  getAvailablePathsForWorkflow,
  getNodeOutput,
  InputPath,
  isBindEmpty,
  isValidScheduleBind,
  mapDependsOn,
  parseDependsOn,
} from "../../pages/Editor/utils/workflow-node.utils";
import { getEventTriggerInputPath } from "../../pages/Editor/utils/workflow-triggers.utils";
import { workflowConfig } from "../../pages/Editor/workflow-config.const";
import { ResponseOf } from "../api";
import { nowPath, todayPath } from "../constants/date-paths.const";
import { WorkflowDefinitionTriggerId } from "../schema/schema";
import assert from "../utils/assert";
import { assertDefined, fmap, isDefined, objectEntries } from "../utils/general.utils";
import { generateId } from "../utils/string.utils";
import { UnsafeAny } from "../utils/types";
import { WorkflowDefinitionSerializer } from "../utils/worklow-definition-serializer";
import {
  validateBind,
  validateHumanTaskOutput,
  validateHumanTaskSkills,
  validateTaskRetry,
  validateWorkflowDefinition,
} from "./validators";
import {
  ChoiceTaskCondition,
  ChoiceTaskConditions,
  Formatter,
  HumanTaskAssignmentStrategy,
  HumanTaskTemplateDefinition,
  Messages,
  NamedOutput,
  NodeReferenceQuery,
  NonNullableBinding,
  ResolvedInput,
  Task,
  WorkflowChildChoiceTask,
  WorkflowChildGroup,
  WorkflowChildHumanTaskReference,
  WorkflowChildReferenceBinding,
  WorkflowChildReferenceBindings,
  WorkflowChildScheduling,
  WorkflowDefinition,
  WorkflowError,
  WorkflowErrorHandler,
  WorkflowEventTrigger,
  WorkflowEventTriggerCondition,
  WorkflowEventTriggerConditions,
  WorkflowEventTriggerOption,
  WorkflowExecutionValueObject,
  WorkflowJobTrigger,
  WorkflowJobTriggerOption,
  WorkflowNode,
  WorkflowTagReference,
  WorkflowTaskClusterReference,
  WorkflowTaskQuery,
  WorkflowTaskTrigger,
  WorkflowTaskTriggerCondition,
  WorkflowTaskTriggerConditions,
  WorkflowTrigger,
} from "./workflows";

function mapWorkflowTriggerToServer(trigger: WorkflowTrigger): Messages["WorkflowTrigger"] {
  switch (trigger.type) {
    case "job": {
      if (trigger.report === null) {
        throw new Error("Please select a report");
      }

      const location = "Workflow trigger";

      return {
        id: WorkflowDefinitionTriggerId.parse(0),
        type: "job",
        pattern: trigger.pattern,
        report:
          trigger.report.type === "standard"
            ? {
                ...trigger.report,
                filterBind: mapObjectValues(trigger.report.filterBind, (bind) =>
                  mapChildBindToServer({ bind, location }),
                ),
              }
            : trigger.report,
        bind: trigger.bind,
      };
    }

    case "event": {
      if (trigger.event === null) {
        throw new Error("Please select an event trigger");
      }

      const location = `Workflow trigger ${trigger.event.name}`;

      return {
        id: WorkflowDefinitionTriggerId.parse(0),
        type: "event",
        event: {
          table: trigger.event.table,
          type: trigger.event.type,
        },
        conditions: mapWorkflowEventConditionsToServer({
          conditions: trigger.conditions,
          location,
        }),
        bind: trigger.bind,
      };
    }
  }
}

function mapWorkflowTriggerFromServer({
  trigger,
  triggerEventOptions,
}: {
  trigger: Messages["WorkflowTrigger"];
  triggerEventOptions: WorkflowEventTriggerOption[];
}): WorkflowTrigger {
  switch (trigger.type) {
    case "job": {
      const parsed: WorkflowJobTrigger = {
        id: trigger.id.toString(),
        type: "job",
        pattern: trigger.pattern,
        report:
          trigger.report.type === "standard"
            ? {
                ...trigger.report,
                filterBind: mapObjectValues(trigger.report.filterBind, (bind) =>
                  mapChildBindFromServer({
                    bind,
                    availablePaths: [],
                  }),
                ),
              }
            : trigger.report,
        bind: trigger.bind,
      };

      return parsed;
    }

    case "event": {
      const event = triggerEventOptions.find(
        (t) => t.table === trigger.event.table && t.type === trigger.event.type,
      );

      assert(event !== undefined, `Event ${trigger.event.table}.${trigger.event.type} not found`);

      const parsed: WorkflowEventTrigger = {
        id: trigger.id.toString(),
        type: "event",
        event: event,
        conditions: mapWorkflowEventConditionsFromServer(
          trigger.conditions,
          getEventTriggerInputPath(event),
        ),
        bind: trigger.bind,
      };

      return parsed;
    }
  }
}

function addTriggerTaskConnector(children: { [key: string]: WorkflowNode }): {
  [key: string]: WorkflowNode;
} {
  return Object.fromEntries(
    Object.entries(children).map(([key, value]) => [
      key,
      key !== dummyTaskRefIds.trigger && (value.dependsOn?.length ?? 0) === 0
        ? {
            ...value,
            dependsOn: mapDependsOn({
              id: dummyTaskRefIds.trigger,
              type: "output",
              output: "Triggered",
            }),
          }
        : value,
    ]),
  );
}

function mapWorkflowDefinitionToCreate(
  workflowDefinition: WorkflowDefinition,
): Messages["CreateWorkflowDefinition"] {
  validateWorkflowDefinition(workflowDefinition);

  const triggers = workflowDefinition.triggers.map(mapWorkflowTriggerToServer);

  return {
    name: workflowDefinition.name,
    description: workflowDefinition.description,
    correlationKey: workflowDefinition.correlationKey,
    version: workflowDefinition.version,
    meta: {
      owner: workflowDefinition.meta.owner,
      createHumanTaskOnFailure: workflowDefinition.meta.createHumanTaskOnFailure,
      priority: workflowDefinition.meta.priority,
      published: workflowDefinition.meta.published,
      severity: workflowDefinition.meta.severity,
      timeout: workflowDefinition.meta.timeout,
      tags: fmap(workflowDefinition.meta.tags, (tags) => tags.map(mapWorkflowTagReferenceToServer)),
      childrenTags: fmap(aggregateChildrenTags(workflowDefinition.children), (childrenTags) =>
        mapObjectValues(childrenTags, (tags) => tags.map(mapWorkflowTagReferenceToServer)),
      ),
    },
    triggers: triggers,
    input: WorkflowDefinitionSerializer.toJSON(workflowDefinition.input),
    output: workflowDefinition.output,
    children: mapNodesToServer(filterOutDummyTasks(workflowDefinition.children)),
    concurrencyRules: workflowDefinition.concurrencyRule,
  };
}

function mapWorkflowTagReferenceToServer(
  tag: WorkflowTagReference,
): Messages["WorkflowTagReference"] {
  return {
    id: tag.id,
    name: tag.name,
    input: tag.input,
    bind: mapObjectValues(tag.bind, (bind) => mapChildBindToServer({ bind, location: "Tag" })),
  };
}

function mapWorkflowTagReferenceFromServer(params: {
  tag: Messages["WorkflowTagReference"];
  availablePaths: InputPath[];
}): WorkflowTagReference {
  return {
    id: params.tag.id,
    name: params.tag.name,
    input: params.tag.input,
    bind: mapObjectValues(params.tag.bind, (bind) =>
      mapChildBindFromServer({ bind, availablePaths: params.availablePaths }),
    ),
  };
}

function mapWorkflowDefinitionToUpdate(
  workflowDefinition: WorkflowDefinition,
): Messages["UpdateWorkflowDefinition"] {
  validateWorkflowDefinition(workflowDefinition);

  const triggers = workflowDefinition.triggers.map(mapWorkflowTriggerToServer);

  return {
    id: workflowDefinition.id,
    name: workflowDefinition.name,
    description: workflowDefinition.description,
    correlationKey: workflowDefinition.correlationKey,
    version: workflowDefinition.version,
    meta: {
      owner: workflowDefinition.meta.owner,
      createHumanTaskOnFailure: workflowDefinition.meta.createHumanTaskOnFailure,
      priority: workflowDefinition.meta.priority,
      published: workflowDefinition.meta.published,
      severity: workflowDefinition.meta.severity,
      timeout: workflowDefinition.meta.timeout,
      tags:
        fmap(workflowDefinition.meta.tags, (tags) => tags.map(mapWorkflowTagReferenceToServer)) ??
        [],
      childrenTags:
        fmap(aggregateChildrenTags(workflowDefinition.children), (childrenTags) =>
          mapObjectValues(childrenTags, (tags) => tags.map(mapWorkflowTagReferenceToServer)),
        ) ?? {},
    },
    triggers: triggers,
    input: WorkflowDefinitionSerializer.toJSON(workflowDefinition.input),
    output: workflowDefinition.output,
    children: mapNodesToServer(filterOutDummyTasks(workflowDefinition.children)),
    concurrencyRules: workflowDefinition.concurrencyRule,
  };
}

function mapOutputFromServer(output: {
  [key: string]: {
    [key: string]: Messages["WorkflowDataFieldType"];
  };
}): Record<string, NamedOutput> {
  return Object.fromEntries(
    Object.entries(output).map(([key, value]): [string, NamedOutput] => [
      key,
      {
        name: key,
        output: Object.fromEntries(
          Object.entries(value).map(([key, value]) => [
            key,
            {
              ...value,
              name: key,
            },
          ]),
        ),
      },
    ]),
  );
}

function mapTaskTriggerFromServer(params: {
  trigger: Messages["WorkflowTaskTrigger"];
  availablePaths: InputPath[];
  eventTriggerOptions: WorkflowEventTriggerOption[];
}): WorkflowTaskTrigger {
  return {
    id: params.trigger.id,
    event: assertDefined(
      params.eventTriggerOptions.find(
        (t) => t.table === params.trigger.event.table && t.type === params.trigger.event.type,
      ),
      "Event trigger not found",
    ),
    conditions: mapTaskEventConditionsFromServer(params.trigger.conditions, params.availablePaths),
    triggerInitOn: params.trigger.triggerInitOn ?? "on-task-evaluation",
  };
}

function mapTaskEventConditionsFromServer(
  conditions: Messages["WorkflowTaskTriggerConditions"],
  availablePaths: InputPath[],
): WorkflowTaskTriggerConditions {
  return {
    ...conditions,
    conditions: conditions.conditions.map((condition) =>
      "conditions" in condition
        ? mapTaskEventConditionsFromServer(condition, availablePaths)
        : mapTaskEventConditionFromServer(condition, availablePaths),
    ),
  };
}

function mapTaskEventConditionFromServer(
  condition: Messages["WorkflowTaskTriggerCondition"],
  availablePaths: InputPath[],
): WorkflowTaskTriggerCondition {
  return {
    ...condition,
    bind:
      condition.bind === null
        ? null
        : mapChildBindFromServer({ bind: condition.bind, availablePaths }),
  };
}

function mapWorkflowEventConditionsFromServer(
  conditions: Messages["WorkflowEventTriggerConditions"],
  availablePaths: InputPath[],
): WorkflowEventTriggerConditions {
  return {
    ...conditions,
    conditions: conditions.conditions.map((condition) =>
      "conditions" in condition
        ? mapWorkflowEventConditionsFromServer(condition, availablePaths)
        : mapWorkflowEventConditionFromServer(condition, availablePaths),
    ),
  };
}

function mapWorkflowEventConditionFromServer(
  condition: Messages["WorkflowEventTriggerCondition"],
  availablePaths: InputPath[],
): WorkflowEventTriggerCondition {
  const property = mapChildBindFromServer({ bind: condition.property, availablePaths });

  if (property.type !== "path") {
    throw new Error("Property must be a path");
  }

  return {
    property,
    operator: condition.operator,
    value:
      condition.bind === null
        ? null
        : mapChildBindFromServer({ bind: condition.bind, availablePaths }),
  };
}

function mapHumanTaskReferenceFromServer(params: {
  parent: string | null;
  taskRef: Messages["WorkflowChildHumanTask"];
  availablePaths: InputPath[];
  eventTriggerOptions: WorkflowEventTriggerOption[];
  humanTaskTemplateOptions: HumanTaskTemplateDefinition[];
  queryDefinitionOptions: WorkflowTaskQuery[];
  tags: WorkflowTagReference[];
  assignmentStrategyOptions: Messages["HumanTaskAssignmentStrategyOption"][];
}): WorkflowChildHumanTaskReference {
  return {
    ...params.taskRef,
    trigger:
      params.taskRef.trigger === undefined
        ? undefined
        : mapTaskTriggerFromServer({
            trigger: params.taskRef.trigger,
            availablePaths: params.availablePaths,
            eventTriggerOptions: params.eventTriggerOptions,
          }),
    parent: params.parent,
    template: {
      template: assertDefined(
        params.humanTaskTemplateOptions.find((t) => t.layout === params.taskRef.template.layout),
        "Human task template not found" + params.taskRef.template.layout,
      ),
      bind: mapObjectValues(params.taskRef.template.bind, (bind) =>
        mapHumanTaskBindFromServer({ bind, availablePaths: params.availablePaths }),
      ),
    },
    output: mapOutputFromServer(params.taskRef.output),
    queries: mapMapValues(new Map(params.taskRef.queries.value), (query) =>
      mappings.query.fromServer({
        query,
        availablePaths: params.availablePaths,
        queryDefinitionOptions: params.queryDefinitionOptions,
      }),
    ),
    cluster: fmap(params.taskRef.cluster, (cluster) =>
      mapClusterReferenceFromServer({ cluster, availablePaths: params.availablePaths }),
    ),
    scheduling:
      params.taskRef.scheduling === undefined
        ? undefined
        : mapSchedulingFromServer({
            scheduling: params.taskRef.scheduling,
            availablePaths: params.availablePaths,
          }),
    assignmentTimeoutSeconds: params.taskRef.assignmentTimeoutSeconds ?? null,
    assignmentStrategy: mapAssignmentStrategyFromServer({
      strategy: params.taskRef.assignmentStrategy,
      availablePaths: params.availablePaths,
      assignmentStrategyOptions: params.assignmentStrategyOptions,
    }),
    errors: mapErrorsFromServer({
      errors: params.taskRef.error,
      availablePaths: params.availablePaths,
      humanTaskTemplateOptions: params.humanTaskTemplateOptions,
      queryDefinitionOptions: params.queryDefinitionOptions,
      assignmentStrategyOptions: params.assignmentStrategyOptions,
    }),
    retry: params.taskRef.retry,
    tags: params.tags,
  };
}

function mapAssignmentStrategyFromServer(params: {
  assignmentStrategyOptions: Messages["HumanTaskAssignmentStrategyOption"][];
  strategy: Messages["WorkflowChildHumanTask"]["assignmentStrategy"];
  availablePaths: InputPath[];
}): HumanTaskAssignmentStrategy | null {
  if (params.strategy === undefined) {
    return null;
  }

  const strategy = params.assignmentStrategyOptions.find(
    (s) => s.type === params.strategy?.optionType,
  );

  assert(strategy !== undefined, "Assignment strategy not found");

  return {
    option: {
      type: params.strategy.optionType,
      input: strategy.input,
    },
    bind:
      params.strategy.bind === undefined
        ? undefined
        : mapObjectValues(params.strategy.bind, (bind) =>
            mapChildBindFromServer({ bind, availablePaths: params.availablePaths }),
          ),
  };
}

function mapAssignmentStrategyToServer(params: {
  strategy: HumanTaskAssignmentStrategy | null;
  location: string;
}): Messages["WorkflowChildHumanTask"]["assignmentStrategy"] | undefined {
  if (params.strategy === null) {
    return undefined;
  }

  if (params.strategy.bind === undefined && params.strategy.option.input !== undefined) {
    throw new Error(`Assignment strategy input must be bound for ${params.location}`);
  }

  return {
    optionType: params.strategy.option.type,
    bind:
      params.strategy.bind === undefined
        ? undefined
        : mapObjectValues(params.strategy.bind, (bind) =>
            mapChildBindToServer({ bind, location: params.location }),
          ),
  };
}

function namedOutputToServer(value: NamedOutput): {
  [key: string]: Messages["WorkflowDataFieldType"];
} {
  return Object.fromEntries(Object.values(value.output).map((value) => [value.name, value]));
}

function mapTaskTriggerToServer({
  trigger,
  location,
}: {
  trigger: WorkflowTaskTrigger;
  location: string;
}): Messages["WorkflowTaskTrigger"] {
  assert(trigger.event !== null, `Event trigger must be set for ${location}`);
  assert(trigger.triggerInitOn !== null, `Trigger initialization time must be set for ${location}`);

  return {
    id: trigger.id,
    event: {
      table: trigger.event.table,
      type: trigger.event.type,
    },
    conditions: mapTaskEventConditionsToServer({
      conditions: trigger.conditions,
      location,
    }),
    triggerInitOn: trigger.triggerInitOn,
  };
}

function mapTaskEventConditionsToServer({
  conditions,
  location,
}: {
  conditions: WorkflowTaskTriggerConditions;
  location: string;
}): Messages["WorkflowTaskTriggerConditions"] {
  return {
    ...conditions,
    conditions: conditions.conditions.map((condition) =>
      "conditions" in condition
        ? mapTaskEventConditionsToServer({ conditions: condition, location })
        : mapTaskEventConditionToServer({ condition, location }),
    ),
  };
}

function assertTaskEventConditionProperty(
  condition: WorkflowTaskTriggerCondition,
): asserts condition is WorkflowTaskTriggerCondition & { property: string } {
  assert(condition.property !== null, "condition.property should not be null");
}

function mapTaskEventConditionToServer({
  condition,
  location,
}: {
  condition: WorkflowTaskTriggerCondition;
  location: string;
}): Messages["WorkflowTaskTriggerCondition"] {
  assertTaskEventConditionProperty(condition);

  return {
    ...condition,
    bind: condition.bind === null ? null : mapChildBindToServer({ bind: condition.bind, location }),
  };
}

function mapWorkflowTaskEventTriggerConditionsToServer({
  conditions,
  location,
}: {
  conditions: ChoiceTaskConditions;
  location: string;
}): Messages["WorkflowTaskEventTriggerConditions"] {
  return {
    operator: conditions.operator,
    conditions: conditions.conditions.map((condition) =>
      "conditions" in condition
        ? mapWorkflowTaskEventTriggerConditionsToServer({ conditions: condition, location })
        : mapWorkflowTaskEventTriggerConditionToServer({ condition, location }),
    ),
  };
}

function mapWorkflowEventConditionToServer({
  condition,
  location,
}: {
  condition: WorkflowEventTriggerCondition;
  location: string;
}): Messages["WorkflowEventTriggerCondition"] {
  if (condition.property === null) {
    throw new Error("Property must be defined");
  }

  const property = mapChildBindToServer({ bind: condition.property, location });

  if (property.type !== "path") {
    throw new Error("Property must be a path");
  }

  return {
    property: property,
    operator: condition.operator,
    bind:
      condition.value === null ? null : mapChildBindToServer({ bind: condition.value, location }),
  };
}

function mapWorkflowEventConditionsToServer({
  conditions,
  location,
}: {
  conditions: WorkflowEventTriggerConditions;
  location: string;
}): Messages["WorkflowEventTriggerConditions"] {
  return {
    operator: conditions.operator,
    conditions: conditions.conditions.map((condition) =>
      "conditions" in condition
        ? mapWorkflowEventConditionsToServer({ conditions: condition, location })
        : mapWorkflowEventConditionToServer({ condition, location }),
    ),
  };
}

function mapWorkflowTaskEventTriggerConditionToServer({
  condition,
  location,
}: {
  condition: ChoiceTaskCondition;
  location: string;
}): Messages["WorkflowTaskEventTriggerCondition"] {
  return {
    operator: condition.operator,
    left: mapChildBindToServer({ bind: condition.left, location }),
    right:
      condition.right === null ? null : mapChildBindToServer({ bind: condition.right, location }),
  };
}

function mapGroupReferenceFromServer(params: {
  parent: string | null;
  group: Messages["WorkflowChildGroup"];
  availablePaths: InputPath[];
  eventTriggerOptions: WorkflowEventTriggerOption[];
  taskDefinitionOptions: Task[];
  humanTaskTemplateOptions: HumanTaskTemplateDefinition[];
  queryDefinitionOptions: WorkflowTaskQuery[];
  workflowInput: Map<string, Messages["WorkflowInput"]>;
  workflowChildren: Messages["WorkflowChildren"];
  childrenTags: Messages["WorkflowDefinition"]["meta"]["childrenTags"];
  tags: WorkflowTagReference[];
  assignmentStrategyOptions: Messages["HumanTaskAssignmentStrategyOption"][];
  mapped: Record<string, WorkflowNode> | undefined;
}): WorkflowChildGroup {
  return {
    ...params.group,
    trigger:
      params.group.trigger === undefined
        ? undefined
        : mapTaskTriggerFromServer({
            trigger: params.group.trigger,
            availablePaths: params.availablePaths,
            eventTriggerOptions: params.eventTriggerOptions,
          }),
    parent: params.parent,
    children: mapChildren({
      parent: params.group.logicalId,
      children: params.group.children,
      humanTaskTemplateOptions: params.humanTaskTemplateOptions,
      queryOptions: params.queryDefinitionOptions,
      taskOptions: params.taskDefinitionOptions,
      triggerEventOptions: params.eventTriggerOptions,
      workflowInput: params.workflowInput,
      childrenTags: params.childrenTags,
      assignmentStrategyOptions: params.assignmentStrategyOptions,
      mapped: params.mapped,
    }),
    scheduling:
      params.group.scheduling === undefined
        ? undefined
        : mapSchedulingFromServer({
            scheduling: params.group.scheduling,
            availablePaths: params.availablePaths,
          }),
    errors: mapErrorsFromServer({
      errors: params.group.error,
      availablePaths: params.availablePaths,
      humanTaskTemplateOptions: params.humanTaskTemplateOptions,
      queryDefinitionOptions: params.queryDefinitionOptions,
      assignmentStrategyOptions: params.assignmentStrategyOptions,
    }),
    tags: params.tags,
  };
}

function mapSchedulingFromServer(params: {
  scheduling: Messages["WorkflowChildScheduling"];
  availablePaths: InputPath[];
}): WorkflowChildScheduling {
  const { scheduling, availablePaths } = params;

  const createAfter =
    scheduling.createAfter === undefined
      ? undefined
      : mapChildBindFromServer({
          bind: scheduling.createAfter,
          availablePaths: availablePaths,
        });

  const createBefore =
    scheduling.createBefore === undefined
      ? undefined
      : mapChildBindFromServer({
          bind: scheduling.createBefore,
          availablePaths: availablePaths,
        });

  const finishBefore =
    scheduling.finishBefore === undefined
      ? undefined
      : mapChildBindFromServer({
          bind: scheduling.finishBefore,
          availablePaths: availablePaths,
        });

  if (createAfter !== undefined && !isValidScheduleBind(createAfter)) {
    throw new Error(`Invalid scheduling.createAfter type: ${createAfter.type}`);
  }

  if (createBefore !== undefined && !isValidScheduleBind(createBefore)) {
    throw new Error(`Invalid scheduling.createBefore type: ${createBefore.type}`);
  }

  if (finishBefore !== undefined && !isValidScheduleBind(finishBefore)) {
    throw new Error(`Invalid scheduling.finishBefore type: ${finishBefore.type}`);
  }

  return {
    timeout: scheduling.timeout,
    createAfter,
    createBefore,
    finishBefore,
  };
}

function findSystemTaskDefinition(
  taskDefinitionOptions: Task[],
  node: Messages["WorkflowChildSystemTask"],
): Task {
  if (node.task.id === dummyTasks.trigger.id) {
    return dummyTasks.trigger;
  }

  if (node.task.id === dummyTasks.placeholder.id) {
    return dummyTasks.placeholder;
  }

  return assertDefined(
    taskDefinitionOptions.find(
      (taskDefinition) =>
        taskDefinition.id === node.task.id && taskDefinition.version === node.task.version,
    ),
    `Task definition not found: ${node.task.id}@${node.task.version}`,
  );
}

function mapNodeFromServer(params: {
  parent: string | null;
  node: Messages["WorkflowChild"];
  availablePaths: InputPath[];
  eventTriggerOptions: WorkflowEventTriggerOption[];
  taskDefinitionOptions: Task[];
  humanTaskTemplateOptions: HumanTaskTemplateDefinition[];
  queryDefinitionOptions: WorkflowTaskQuery[];
  workflowInput: Map<string, Messages["WorkflowInput"]>;
  workflowChildren: Messages["WorkflowChildren"];
  childrenTags: Messages["WorkflowDefinition"]["meta"]["childrenTags"];
  assignmentStrategyOptions: Messages["HumanTaskAssignmentStrategyOption"][];
  mapped: Record<string, WorkflowNode> | undefined;
}): WorkflowNode {
  const tags = (params.childrenTags?.[params.node.logicalId] ?? []).map((tag) =>
    mapWorkflowTagReferenceFromServer({ tag, availablePaths: params.availablePaths }),
  );

  switch (params.node.type) {
    case "human-task":
      return mapHumanTaskReferenceFromServer({ ...params, tags, taskRef: params.node });

    case "system-task":
      return {
        ...params.node,
        parent: params.parent,
        task: findSystemTaskDefinition(params.taskDefinitionOptions, params.node),
        trigger:
          params.node.trigger === undefined
            ? undefined
            : mapTaskTriggerFromServer({
                trigger: params.node.trigger,
                availablePaths: params.availablePaths,
                eventTriggerOptions: params.eventTriggerOptions,
              }),
        bind: Object.fromEntries(
          Object.entries(params.node.bind).map(([key, bind]) => [
            key,
            mapChildBindFromServer({
              bind: bind,
              availablePaths: params.availablePaths,
            }),
          ]),
        ),
        queries: new Map(
          params.node.queries.value.map(([key, query]) => [
            key,
            mapQueryFromServer({
              query,
              availablePaths: params.availablePaths,
              queryDefinitionOptions: params.queryDefinitionOptions,
            }),
          ]),
        ),
        scheduling:
          params.node.scheduling === undefined
            ? undefined
            : mapSchedulingFromServer({
                scheduling: params.node.scheduling,
                availablePaths: params.availablePaths,
              }),
        errors: mapErrorsFromServer({
          errors: params.node.error,
          availablePaths: params.availablePaths,
          humanTaskTemplateOptions: params.humanTaskTemplateOptions,
          queryDefinitionOptions: params.queryDefinitionOptions,
          assignmentStrategyOptions: params.assignmentStrategyOptions,
        }),
        retry: params.node.retry,
        tags: tags,
      };

    case "workflow":
      return {
        ...params.node,
        parent: params.parent,
        trigger:
          params.node.trigger === undefined
            ? undefined
            : mapTaskTriggerFromServer({
                trigger: params.node.trigger,
                availablePaths: params.availablePaths,
                eventTriggerOptions: params.eventTriggerOptions,
              }),
        bind: Object.fromEntries(
          Object.entries(params.node.bind).map(([key, bind]) => [
            key,
            mapChildBindFromServer({
              bind: bind,
              availablePaths: params.availablePaths,
            }),
          ]),
        ),
        scheduling:
          params.node.scheduling === undefined
            ? undefined
            : mapSchedulingFromServer({
                scheduling: params.node.scheduling,
                availablePaths: params.availablePaths,
              }),
        errors: mapErrorsFromServer({
          errors: params.node.error,
          availablePaths: params.availablePaths,
          humanTaskTemplateOptions: params.humanTaskTemplateOptions,
          queryDefinitionOptions: params.queryDefinitionOptions,
          assignmentStrategyOptions: params.assignmentStrategyOptions,
        }),
        tags: tags,
      };

    case "group":
      return mapGroupReferenceFromServer({ ...params, tags, group: params.node });

    case "choice":
      return mapChoiceTaskFromServer({ ...params, tags, choiceTask: params.node });

    case "short-circuit-task":
      return {
        ...params.node,
        parent: params.parent,
        trigger:
          params.node.trigger === undefined
            ? undefined
            : mapTaskTriggerFromServer({
                trigger: params.node.trigger,
                availablePaths: params.availablePaths,
                eventTriggerOptions: params.eventTriggerOptions,
              }),
        queries: mapMapValues(
          new Map(params.node.queries.value),
          (v): NodeReferenceQuery =>
            mapQueryFromServer({
              query: v,
              availablePaths: params.availablePaths,
              queryDefinitionOptions: params.queryDefinitionOptions,
            }),
        ),
        scheduling:
          params.node.scheduling === undefined
            ? undefined
            : mapSchedulingFromServer({
                scheduling: params.node.scheduling,
                availablePaths: params.availablePaths,
              }),
        errors: mapErrorsFromServer({
          errors: params.node.error,
          availablePaths: params.availablePaths,
          humanTaskTemplateOptions: params.humanTaskTemplateOptions,
          queryDefinitionOptions: params.queryDefinitionOptions,
          assignmentStrategyOptions: params.assignmentStrategyOptions,
        }),
        retry: params.node.retry,
        tags: tags,
      };

    case "reset-to-task":
      return {
        ...params.node,
        parent: params.parent,
        trigger:
          params.node.trigger === undefined
            ? undefined
            : mapTaskTriggerFromServer({
                trigger: params.node.trigger,
                availablePaths: params.availablePaths,
                eventTriggerOptions: params.eventTriggerOptions,
              }),
        queries: mapMapValues(
          new Map(params.node.queries.value),
          (v): NodeReferenceQuery =>
            mapQueryFromServer({
              query: v,
              availablePaths: params.availablePaths,
              queryDefinitionOptions: params.queryDefinitionOptions,
            }),
        ),
        scheduling:
          params.node.scheduling === undefined
            ? undefined
            : mapSchedulingFromServer({
                scheduling: params.node.scheduling,
                availablePaths: params.availablePaths,
              }),
        errors: [],
        to:
          params.mapped === undefined
            ? null
            : {
                node: assertDefined(
                  findNode(params.node.to.logicalId, params.mapped),
                  `Node not found: ${params.node.to.logicalId}`,
                ),
                bind: mapObjectValues(params.node.to.bind, (bind) =>
                  mapChildBindFromServer({ bind, availablePaths: params.availablePaths }),
                ),
              },
        tags: tags,
      };
  }
}

function mapErrorFromServer(params: {
  id: string;
  error: Messages["WorkflowError"];
  availablePaths: InputPath[];
  queryDefinitionOptions: WorkflowTaskQuery[];
  humanTaskTemplateOptions: HumanTaskTemplateDefinition[];
  assignmentStrategyOptions: Messages["HumanTaskAssignmentStrategyOption"][];
}): WorkflowError {
  return {
    id: params.id,
    on: params.error.on,
    handler: ((): WorkflowErrorHandler => {
      switch (params.error.handler.type) {
        case "new-task":
          return params.error.handler;

        case "fallback-to-manual": {
          const template = params.error.handler.template;
          return {
            type: params.error.handler.type,
            queries: mapMapValues(
              new Map(params.error.handler.queries.value),
              (v): NodeReferenceQuery =>
                mapQueryFromServer({
                  query: v,
                  availablePaths: params.availablePaths,
                  queryDefinitionOptions: params.queryDefinitionOptions,
                }),
            ),
            template: {
              template: assertDefined(
                params.humanTaskTemplateOptions.find((t) => t.layout === template.layout),
                "Human task template not found",
              ),
              bind: mapObjectValues(template.bind, (bind) =>
                mapHumanTaskBindFromServer({ bind, availablePaths: params.availablePaths }),
              ),
            },
            skills: params.error.handler.skills,
            assignmentTimeoutSeconds: params.error.handler.assignmentTimeoutSeconds ?? null,
            assignmentStrategy: mapAssignmentStrategyFromServer({
              strategy: params.error.handler.assignmentStrategy,
              availablePaths: params.availablePaths,
              assignmentStrategyOptions: params.assignmentStrategyOptions,
            }),
            meta: params.error.handler.meta,
            cluster: fmap(params.error.handler.cluster, (cluster) =>
              mapClusterReferenceFromServer({ cluster, availablePaths: params.availablePaths }),
            ),
            scheduling:
              params.error.handler.scheduling === undefined
                ? undefined
                : mapSchedulingFromServer({
                    scheduling: params.error.handler.scheduling,
                    availablePaths: params.availablePaths,
                  }),
          };
        }

        case "fallback-to-default":
          return {
            type: params.error.handler.type,
            status: params.error.handler.status,
            output: Object.fromEntries(
              Object.entries(params.error.handler.output).map(([key, bind]) => [
                key,
                mapChildBindFromServer({
                  bind: bind,
                  availablePaths: params.availablePaths,
                }),
              ]),
            ),
          };
      }
    })(),
  };
}

function mapErrorsFromServer(params: {
  errors?: Messages["SerializedMap<string,WorkflowError>"];
  availablePaths: InputPath[];
  queryDefinitionOptions: WorkflowTaskQuery[];
  humanTaskTemplateOptions: HumanTaskTemplateDefinition[];
  assignmentStrategyOptions: Messages["HumanTaskAssignmentStrategyOption"][];
}) {
  const { errors, ...rest } = params;

  if (errors === undefined) {
    return undefined;
  }

  return errors.value.map(([id, error]) =>
    mapErrorFromServer({
      ...rest,
      id,
      error,
    }),
  );
}

function mapChoiceTaskFromServer(params: {
  parent: string | null;
  choiceTask: Messages["WorkflowChildChoiceTask"];
  availablePaths: InputPath[];
  eventTriggerOptions: WorkflowEventTriggerOption[];
  queryDefinitionOptions: WorkflowTaskQuery[];
  humanTaskTemplateOptions: HumanTaskTemplateDefinition[];
  assignmentStrategyOptions: Messages["HumanTaskAssignmentStrategyOption"][];
  tags: WorkflowTagReference[];
}): WorkflowChildChoiceTask {
  return {
    ...params.choiceTask,
    parent: params.parent,
    trigger:
      params.choiceTask.trigger === undefined
        ? undefined
        : mapTaskTriggerFromServer({
            trigger: params.choiceTask.trigger,
            availablePaths: params.availablePaths,
            eventTriggerOptions: params.eventTriggerOptions,
          }),
    choices: params.choiceTask.choices.map((choice) => ({
      ...choice,
      conditions: mapWorkflowTaskEventTriggerConditionsFromServer(
        choice.conditions,
        params.availablePaths,
      ),
    })),
    queries: mapMapValues(
      new Map(params.choiceTask.queries.value),
      (v): NodeReferenceQuery =>
        mapQueryFromServer({
          query: v,
          availablePaths: params.availablePaths,
          queryDefinitionOptions: params.queryDefinitionOptions,
        }),
    ),
    scheduling:
      params.choiceTask.scheduling === undefined
        ? undefined
        : mapSchedulingFromServer({
            scheduling: params.choiceTask.scheduling,
            availablePaths: params.availablePaths,
          }),
    errors: mapErrorsFromServer({
      errors: params.choiceTask.error,
      availablePaths: params.availablePaths,
      humanTaskTemplateOptions: params.humanTaskTemplateOptions,
      queryDefinitionOptions: params.queryDefinitionOptions,
      assignmentStrategyOptions: params.assignmentStrategyOptions,
    }),
    retry: params.choiceTask.retry,
    tags: params.tags,
  };
}

function mapWorkflowTaskEventTriggerConditionsFromServer(
  conditions: Messages["WorkflowTaskEventTriggerConditions"],
  availablePaths: InputPath[],
): ChoiceTaskConditions {
  return {
    ...conditions,
    conditions: conditions.conditions.map((condition) =>
      "conditions" in condition
        ? mapWorkflowTaskEventTriggerConditionsFromServer(condition, availablePaths)
        : mapWorkflowTaskEventTriggerConditionFromServer(condition, availablePaths),
    ),
  };
}

function mapWorkflowTaskEventTriggerConditionFromServer(
  condition: Messages["WorkflowTaskEventTriggerCondition"],
  availablePaths: InputPath[],
): ChoiceTaskCondition {
  const left = mapChildBindFromServer({ bind: condition.left, availablePaths: availablePaths });

  if (left.type !== "path") {
    throw new Error("left side of condition must be a path");
  }

  return {
    ...condition,
    left: left,
    right:
      condition.right === null
        ? null
        : mapChildBindFromServer({ bind: condition.right, availablePaths }),
  };
}

function isValidScheduleServerBind(
  bind: Messages["WorkflowChildReferenceBinding"],
): bind is Messages["WorkflowChildReferenceBinding"] & {
  type: "path" | "datetime" | "now" | "now-time" | "today";
} {
  return (
    bind.type === "path" ||
    bind.type === "datetime" ||
    bind.type === "now" ||
    bind.type === "now-time" ||
    bind.type === "today"
  );
}

function mapSchedulingToServer({
  scheduling,
  location,
}: {
  scheduling: WorkflowChildScheduling;
  location: string;
}): Messages["WorkflowChildScheduling"] {
  const createAfter = !isDefined(scheduling.createAfter)
    ? undefined
    : mapChildBindToServer({ bind: scheduling.createAfter, location });

  const createBefore = !isDefined(scheduling.createBefore)
    ? undefined
    : mapChildBindToServer({ bind: scheduling.createBefore, location });

  const finishBefore = !isDefined(scheduling.finishBefore)
    ? undefined
    : mapChildBindToServer({ bind: scheduling.finishBefore, location });

  if (createAfter !== undefined && !isValidScheduleServerBind(createAfter)) {
    throw new Error(`Invalid scheduling.createAfter type: ${createAfter.type}`);
  }

  if (createBefore !== undefined && !isValidScheduleServerBind(createBefore)) {
    throw new Error(`Invalid scheduling.createBefore type: ${createBefore.type}`);
  }

  if (finishBefore !== undefined && !isValidScheduleServerBind(finishBefore)) {
    throw new Error(`Invalid scheduling.finishBefore type: ${finishBefore.type}`);
  }

  return {
    timeout: scheduling.timeout,
    createAfter,
    createBefore,
    finishBefore,
  };
}

function mapNodesToServer(
  children: Record<string, WorkflowNode>,
): Record<string, Messages["WorkflowChild"]> {
  return Object.fromEntries(
    Object.entries(children).map(([key, value]) => [
      key,
      mapNodeToServer({
        ...value,
        dependsOn: fmap(value.dependsOn, (dependsOn) =>
          mapDependsOnToServer({ dependsOn, siblings: children }),
        ),
      }),
    ]),
  );
}

function aggregateChildrenTags(children: Record<string, WorkflowNode>) {
  const tags: Record<string, WorkflowTagReference[]> = {};

  function aggregateTags(node: WorkflowNode) {
    tags[node.logicalId] = node.tags;

    if (node.type === "group") {
      Object.values(node.children).forEach((child) => aggregateTags(child));
    }
  }

  Object.values(children).forEach((child) => aggregateTags(child));

  return tags;
}

function mapDependsOnToServer(params: {
  dependsOn: WorkflowNode["dependsOn"];
  siblings: Record<string, WorkflowNode>;
}) {
  if (params.dependsOn === undefined) {
    return undefined;
  }

  const dependsOn = parseDependsOn(params.dependsOn);
  const sibling = params.siblings[dependsOn.id];

  if (dependsOn.type === "error") {
    // errors are saved with their ids as output,
    // so we can just return them as is
    return params.dependsOn;
  }

  if (sibling === undefined) {
    throw new Error(`Node not found: ${dependsOn.id}`);
  }

  const output = getNodeOutput(sibling);

  const result = output.success[dependsOn.output];

  if (result === undefined) {
    throw new Error(`Output not found: ${dependsOn.id}.${dependsOn.output}`);
  }

  return mapDependsOn({
    id: dependsOn.id,
    type: dependsOn.type,
    output: result.name,
  });
}

function mapNodeToServer(node: WorkflowNode): Messages["WorkflowChild"] {
  switch (node.type) {
    case "human-task": {
      const location = `Human task "${node.meta.name}"`;

      if (node.template.template === null) {
        throw new Error(`${location} has no template`);
      }

      validateHumanTaskOutput(node);
      validateHumanTaskSkills(node);
      validateTaskRetry({ location, task: node });

      validateBind({
        bind: node.template.bind,
        input: node.template.template.input,
        location: location,
      });

      return {
        logicalId: node.logicalId,
        trigger:
          node.trigger === undefined
            ? undefined
            : mapTaskTriggerToServer({ trigger: node.trigger, location }),
        meta: node.meta,
        skills: node.skills,
        cluster: fmap(node.cluster, (cluster) =>
          mapClusterReferenceToServer({ cluster, location }),
        ),
        type: node.type,
        dependsOn: node.dependsOn,
        template: {
          layout: node.template.template.layout,
          bind: mapObjectValues(node.template.bind, (bind) =>
            mapHumanTaskBindToServer({ bind, location }),
          ),
        },
        output: Object.fromEntries(
          Object.values(node.output).map(
            (value): [string, Record<string, Messages["WorkflowDataFieldType"]>] => [
              value.name,
              namedOutputToServer(value),
            ],
          ),
        ),
        queries: WorkflowDefinitionSerializer.toJSON(
          mapMapValues(node.queries, (query) => mappings.query.toServer({ query, location })),
        ),
        scheduling:
          node.scheduling === undefined
            ? undefined
            : mapSchedulingToServer({ scheduling: node.scheduling, location }),
        assignmentTimeoutSeconds: node.assignmentTimeoutSeconds ?? undefined,
        assignmentStrategy: mapAssignmentStrategyToServer({
          strategy: node.assignmentStrategy,
          location,
        }),
        error: mapErrorsToServer(node.errors),
        retry: node.retry,
      };
    }
    case "system-task": {
      const location = `System task "${node.meta.name}"`;

      validateBind({ bind: node.bind, input: node.task.input, location });
      validateTaskRetry({ location, task: node });

      return {
        dependsOn: node.dependsOn,
        trigger:
          node.trigger === undefined
            ? undefined
            : mapTaskTriggerToServer({ trigger: node.trigger, location }),
        logicalId: node.logicalId,
        meta: node.meta,
        task: node.task,
        type: node.type,
        bind: Object.fromEntries(
          Object.entries(node.bind).map(([key, value]) => [
            key,
            mapChildBindToServer({ bind: value, location }),
          ]),
        ),
        queries: WorkflowDefinitionSerializer.toJSON(
          mapMapValues(node.queries, (query) => mappings.query.toServer({ query, location })),
        ),
        scheduling:
          node.scheduling === undefined
            ? undefined
            : mapSchedulingToServer({ scheduling: node.scheduling, location }),
        error: mapErrorsToServer(node.errors),
        retry: node.retry,
      };
    }
    case "workflow": {
      const location = `Workflow "${node.meta.name}"`;

      return {
        dependsOn: node.dependsOn,
        trigger:
          node.trigger === undefined
            ? undefined
            : mapTaskTriggerToServer({ trigger: node.trigger, location }),
        logicalId: node.logicalId,
        meta: node.meta,
        type: node.type,
        workflow: node.workflow,
        fallbackToManual: node.fallbackToManual,
        bind: Object.fromEntries(
          Object.entries(node.bind).map(([key, value]) => [
            key,
            mapChildBindToServer({ bind: value, location }),
          ]),
        ),
        scheduling:
          node.scheduling === undefined
            ? undefined
            : mapSchedulingToServer({
                scheduling: node.scheduling,
                location,
              }),
        error: mapErrorsToServer(node.errors),
      };
    }
    case "group": {
      const location = `Group "${node.meta.name}"`;

      return {
        dependsOn: node.dependsOn,
        trigger:
          node.trigger === undefined
            ? undefined
            : mapTaskTriggerToServer({ trigger: node.trigger, location }),
        logicalId: node.logicalId,
        meta: node.meta,
        type: node.type,
        children: mapNodesToServer(node.children),
        scheduling:
          node.scheduling === undefined
            ? undefined
            : mapSchedulingToServer({ scheduling: node.scheduling, location }),
        error: mapErrorsToServer(node.errors),
      };
    }
    case "choice": {
      const location = `Choice task "${node.meta.name}"`;

      return {
        dependsOn: node.dependsOn,
        trigger:
          node.trigger === undefined
            ? undefined
            : mapTaskTriggerToServer({ trigger: node.trigger, location }),
        logicalId: node.logicalId,
        meta: node.meta,
        type: node.type,
        default: node.default,
        choices: node.choices.map((choice) => ({
          ...choice,
          conditions: mapWorkflowTaskEventTriggerConditionsToServer({
            conditions: choice.conditions,
            location,
          }),
        })),
        queries: WorkflowDefinitionSerializer.toJSON(
          mapMapValues(node.queries, (query) =>
            mappings.query.toServer({ query, location: `Choice task "${node.meta.name}"` }),
          ),
        ),
        scheduling:
          node.scheduling === undefined
            ? undefined
            : mapSchedulingToServer({ scheduling: node.scheduling, location }),
        error: mapErrorsToServer(node.errors),
        retry: node.retry,
      };
    }
    case "short-circuit-task": {
      const location = `Short Circuit task "${node.meta.name}"`;

      return {
        dependsOn: node.dependsOn,
        trigger:
          node.trigger === undefined
            ? undefined
            : mapTaskTriggerToServer({ trigger: node.trigger, location }),
        logicalId: node.logicalId,
        type: node.type,
        queries: WorkflowDefinitionSerializer.toJSON(
          mapMapValues(node.queries, (query) =>
            mappings.query.toServer({ query, location: `Choice task "${node.meta.name}"` }),
          ),
        ),
        error: mapErrorsToServer(node.errors),
        scheduling:
          node.scheduling === undefined
            ? undefined
            : mapSchedulingToServer({ scheduling: node.scheduling, location }),
        retry: node.retry,
        meta: node.meta,
      };
    }
    case "reset-to-task": {
      const location = `Reset to task "${node.meta.name}"`;
      assert(node.to !== null, `Reset to task "${node.meta.name}" has no "to" property`);

      return {
        dependsOn: node.dependsOn,
        trigger:
          node.trigger === undefined
            ? undefined
            : mapTaskTriggerToServer({ trigger: node.trigger, location }),
        logicalId: node.logicalId,
        type: node.type,
        queries: WorkflowDefinitionSerializer.toJSON(
          mapMapValues(node.queries, (query) =>
            mappings.query.toServer({ query, location: `Choice task "${node.meta.name}"` }),
          ),
        ),
        scheduling:
          node.scheduling === undefined
            ? undefined
            : mapSchedulingToServer({ scheduling: node.scheduling, location }),
        meta: node.meta,
        to: {
          logicalId: node.to.node.logicalId,
          bind: mapObjectValues(
            Object.fromEntries(
              Object.entries(node.to.bind).filter(([_, bind]) => !isBindEmpty(bind)),
            ),
            (bind) => mapChildBindToServer({ bind, location }),
          ),
        },
      };
    }
  }
}

function mapErrorToServer(error: WorkflowError): Messages["WorkflowError"] {
  const location = `Error handler ${error.id} for "${error.on}"`;

  return {
    on: error.on,
    handler: ((): Messages["WorkflowErrorHandler"] => {
      switch (error.handler.type) {
        case "new-task":
          return error.handler;

        case "fallback-to-manual":
          if (error.handler.template.template === null) {
            throw new Error(`${location} has no template`);
          }

          return {
            type: error.handler.type,
            queries: WorkflowDefinitionSerializer.toJSON(
              mapMapValues(error.handler.queries, (query) =>
                mappings.query.toServer({
                  query,
                  location,
                }),
              ),
            ),
            template: {
              layout: error.handler.template.template.layout,
              bind: mapObjectValues(error.handler.template.bind, (bind) =>
                mapHumanTaskBindToServer({ bind, location }),
              ),
            },
            skills: error.handler.skills,
            cluster: fmap(error.handler.cluster, (cluster) =>
              mapClusterReferenceToServer({ cluster, location }),
            ),
            assignmentTimeoutSeconds: error.handler.assignmentTimeoutSeconds ?? undefined,
            assignmentStrategy: mapAssignmentStrategyToServer({
              strategy: error.handler.assignmentStrategy,
              location,
            }),
            meta: error.handler.meta,
            scheduling:
              error.handler.scheduling === undefined
                ? undefined
                : mapSchedulingToServer({ scheduling: error.handler.scheduling, location }),
          };

        case "fallback-to-default":
          if (error.handler.status === null) {
            throw new Error(`${location} has no status`);
          }

          return {
            type: error.handler.type,
            status: error.handler.status,
            output: mapObjectValues(error.handler.output, (bind) =>
              mapChildBindToServer({ bind, location }),
            ),
          };
      }
    })(),
  };
}

function mapErrorsToServer(errors?: WorkflowError[]) {
  if (errors === undefined) {
    return undefined;
  }

  const map = new Map<string, Messages["WorkflowError"]>(
    errors
      .filter((error) => error.on.length > 0)
      .map((error) => [error.id, mapErrorToServer(error)]),
  );

  return WorkflowDefinitionSerializer.toJSON(map);
}

function mapChildren(params: {
  parent: string | null;
  workflowInput: Map<string, Messages["WorkflowInput"]>;
  children: Messages["WorkflowChildren"];
  taskOptions: Task[];
  queryOptions: WorkflowTaskQuery[];
  triggerEventOptions: WorkflowEventTriggerOption[];
  humanTaskTemplateOptions: HumanTaskTemplateDefinition[];
  childrenTags: Messages["WorkflowDefinition"]["meta"]["childrenTags"];
  assignmentStrategyOptions: Messages["HumanTaskAssignmentStrategyOption"][];
  mapped: Record<string, WorkflowNode> | undefined;
}) {
  if (params.parent !== null && Object.keys(params.children).length === 0) {
    const placeholder = emptyRef.placeholder({
      parent: params.parent,
      logicalId: generateId("node"),
      position: { x: workflowConfig.groupPadding, y: workflowConfig.groupPadding },
    });

    return {
      [placeholder.logicalId]: placeholder,
    };
  }

  return Object.fromEntries(
    Object.entries(params.children).map(([key, node]) => {
      const availablePaths =
        params.mapped === undefined
          ? []
          : getAvailablePathsForNode({
              nodeRefId: key,
              flattenedNodes: params.mapped,
              workflowInput: params.workflowInput,
            });

      return [
        key,
        mapNodeFromServer({
          parent: params.parent,
          node: node,
          availablePaths: params.mapped === undefined ? [] : availablePaths,
          eventTriggerOptions: params.triggerEventOptions,
          taskDefinitionOptions: params.taskOptions,
          humanTaskTemplateOptions: params.humanTaskTemplateOptions,
          queryDefinitionOptions: params.queryOptions,
          workflowInput: params.workflowInput,
          workflowChildren: params.children,
          childrenTags: params.childrenTags,
          assignmentStrategyOptions: params.assignmentStrategyOptions,
          mapped: params.mapped,
        }),
      ];
    }),
  );
}

interface MapWorkflowDefinitionParams {
  workflowDefinition: Messages["WorkflowDefinition"];
  taskOptions: Task[];
  taskTemplateOptions: HumanTaskTemplateDefinition[];
  triggerEventOptions: WorkflowEventTriggerOption[];
  jobTriggerOptions: WorkflowJobTriggerOption[];
  queryOptions: WorkflowTaskQuery[];
  humanTaskTemplateOptions: HumanTaskTemplateDefinition[];
  assignmentStrategyOptions: Messages["HumanTaskAssignmentStrategyOption"][];
}

function mapWorkflowDefinitionFromServer(params: MapWorkflowDefinitionParams) {
  // The reason we need to map the nodes twice is because we need to know the
  // available paths for each node. since group nodes can be nested, we need to
  // map the nodes first to get the available paths for each node, then map the
  // nodes again with the available paths.
  const mapped = flattenNodes(mapWorkflowDefinitionFromServerInner(params).children);

  return mapWorkflowDefinitionFromServerInner({
    ...params,
    mapped,
  });
}

function mapWorkflowDefinitionFromServerInner(
  params: MapWorkflowDefinitionParams & {
    mapped?: Record<string, WorkflowNode>;
  },
): WorkflowDefinition {
  const triggers: WorkflowTrigger[] = params.workflowDefinition.triggers.map((trigger) =>
    mapWorkflowTriggerFromServer({ trigger, triggerEventOptions: params.triggerEventOptions }),
  );

  const workflowInput = WorkflowDefinitionSerializer.fromJSON(params.workflowDefinition.input);
  const availablePaths = getAvailablePathsForWorkflow({ workflowInput: workflowInput });

  return {
    id: params.workflowDefinition.id,
    name: params.workflowDefinition.name,
    description: params.workflowDefinition.description,
    correlationKey: params.workflowDefinition.correlationKey,
    version: params.workflowDefinition.version,
    meta: {
      owner: params.workflowDefinition.meta.owner,
      priority: params.workflowDefinition.meta.priority,
      published: params.workflowDefinition.meta.published,
      severity: params.workflowDefinition.meta.severity,
      timeout: params.workflowDefinition.meta.timeout,
      createHumanTaskOnFailure: params.workflowDefinition.meta.createHumanTaskOnFailure,
      tags: fmap(params.workflowDefinition.meta.tags, (tags) =>
        tags.map((tag) =>
          mapWorkflowTagReferenceFromServer({ tag, availablePaths: availablePaths }),
        ),
      ),
    },
    triggers: triggers,
    input: workflowInput,
    output: params.workflowDefinition.output,
    children: addPlaceholdersToLooseEnds(
      addTriggerTaskConnector(
        mapChildren({
          parent: null,
          workflowInput: workflowInput,
          children: params.workflowDefinition.children,
          humanTaskTemplateOptions: params.humanTaskTemplateOptions,
          queryOptions: params.queryOptions,
          taskOptions: params.taskOptions,
          triggerEventOptions: params.triggerEventOptions,
          assignmentStrategyOptions: params.assignmentStrategyOptions,
          childrenTags: params.workflowDefinition.meta.childrenTags,
          mapped: params.mapped,
        }),
      ),
    ),
    concurrencyRule: params.workflowDefinition.concurrencyRules,
  };
}

function mapWorkflowEventTriggerOptionFromServer(
  option: Messages["WorkflowEventTriggerOption"],
): WorkflowEventTriggerOption {
  return {
    name: option.name,
    table: option.table,
    type: option.type,
    fields: option.columns,
  };
}

function mapWorkflowTaskDefinitionFromServer(
  taskDefinition: Messages["WorkflowTaskDefinition"],
): Task {
  return {
    id: taskDefinition.id,
    version: taskDefinition.version,
    meta: taskDefinition.meta,
    input: taskDefinition.input,
    output: mapOutputFromServer(taskDefinition.output),
  };
}

function assertDefinedWorkflowChildReferenceBinding(params: {
  bind: WorkflowChildReferenceBinding | null;
  location: string;
}): asserts params is {
  bind: NonNullableBinding<WorkflowChildReferenceBinding>;
  location: string;
} {
  assert(params.bind !== null, `bind in ${params.location} is not set`);
  assert(
    !("path" in params.bind) || params.bind.path !== null,
    `path bind in ${params.location} is not set`,
  );
  assert(
    !("value" in params.bind) || params.bind.value !== null,
    `value in ${params.location} bind is not set`,
  );
}

function mapClusterReferenceToServer(params: {
  cluster: WorkflowTaskClusterReference;
  location: string;
}): Messages["WorkflowTaskClusterReference"] {
  return {
    id: params.cluster.id,
    bind: Object.fromEntries(
      objectEntries(params.cluster.bind).map(([key, value]) => [
        key,
        mapChildBindToServer({ bind: value, location: params.location }),
      ]),
    ),
  };
}

function mapClusterReferenceFromServer(params: {
  cluster: Messages["WorkflowTaskClusterReference"];
  availablePaths: InputPath[];
}): WorkflowTaskClusterReference {
  return {
    id: params.cluster.id,
    bind: Object.fromEntries(
      objectEntries(params.cluster.bind).map(([key, value]) => [
        key,
        mapChildBindFromServer({ bind: value, availablePaths: params.availablePaths }),
      ]),
    ),
  };
}

function mapChildBindToServer(params: {
  bind: WorkflowChildReferenceBinding;
  location: string;
}): Messages["WorkflowChildReferenceBinding"] {
  assertDefinedWorkflowChildReferenceBinding(params);

  const { bind, location } = params;

  switch (bind.type) {
    case "boolean":
      return bind.array
        ? {
            type: bind.type,
            array: true,
            value: bind.value.filter(isDefined),
          }
        : bind;

    case "number":
      return bind.array
        ? {
            type: bind.type,
            array: true,
            value: bind.value.filter(isDefined),
          }
        : bind;

    case "file":
      return bind.array
        ? {
            type: bind.type,
            array: true,
            value: bind.value.filter(isDefined),
          }
        : bind;

    case "date":
      return bind.array
        ? {
            type: bind.type,
            array: true,
            value: bind.value.filter(isDefined),
          }
        : bind;

    case "time":
      return bind.array
        ? {
            type: bind.type,
            array: true,
            value: bind.value.filter(isDefined),
          }
        : bind;

    case "datetime":
      return bind.array
        ? {
            type: bind.type,
            array: true,
            value: bind.value.filter(isDefined),
          }
        : bind;

    case "text":
    case "textarea":
      return bind.array
        ? {
            type: bind.type,
            array: true,
            value: bind.value.filter(isDefined),
            placeholders: fmap(bind.placeholders, (p) =>
              mapObjectValues(p, (bind) => mapChildBindToServer({ bind, location })),
            ),
          }
        : {
            ...bind,
            placeholders: fmap(bind.placeholders, (p) =>
              mapObjectValues(p, (bind) => mapChildBindToServer({ bind, location })),
            ),
          };

    case "path":
      if (bind.path.type === "dates") {
        return mapDatesPathBindToServer({ bind, location });
      }

      return {
        type: bind.type,
        path: mapPathToServer(bind.path),
        formatter: getFormatterWithValueOrUndefined({ formatter: bind.formatter, location }),
      };
  }
}

function mapDatesPathBindToServer({
  bind,
  location,
}: {
  bind: WorkflowChildReferenceBindings["Path"];
  location: string;
}): Messages["WorkflowChildReferenceBinding"] {
  switch (bind.path?.path[0]?.id) {
    case "now":
      return {
        type: "now",
        formatter: getFormatterWithValueOrUndefined({ formatter: bind.formatter, location }),
      };

    case "now-time":
      return {
        type: "now-time",
        formatter: getFormatterWithValueOrUndefined({ formatter: bind.formatter, location }),
      };

    case "today":
      return {
        type: "today",
        formatter: getFormatterWithValueOrUndefined({ formatter: bind.formatter, location }),
      };

    default:
      throw new Error("Invalid path");
  }
}

function doesFormatterHasValue(
  formatter: Formatter,
): formatter is Formatter & { value: NonNullable<Formatter["value"]> } {
  return formatter.value !== null;
}

function getFormatterWithValueOrUndefined({
  formatter,
  location,
}: {
  formatter: Formatter | undefined;
  location: string;
}): Messages["WorkflowChildReferenceBinding.Path"]["formatter"] {
  if (formatter === undefined) {
    return undefined;
  }

  if (!doesFormatterHasValue(formatter)) {
    return undefined;
  }

  switch (formatter.valueType) {
    case "number":
      return {
        action: formatter.action,
        valueType: formatter.valueType,
        value: mapChildBindToServer({ bind: formatter.value, location }),
      };

    case "date":
      return {
        action: formatter.action,
        valueType: formatter.valueType,
        value: {
          unit: formatter.value.unit,
          interval: mapChildBindToServer({
            bind: formatter.value.interval,
            location,
          }),
        },
      };

    case "datetime":
      return {
        action: formatter.action,
        valueType: formatter.valueType,
        value: {
          unit: formatter.value.unit,
          interval: mapChildBindToServer({
            bind: formatter.value.interval,
            location,
          }),
        },
      };
  }
}

function mapFormatterFromServer(params: {
  formatter: Messages["WorkflowChildReferenceBinding.Path"]["formatter"];
  availablePaths: InputPath[];
}): Formatter | undefined {
  const { formatter } = params;

  if (formatter === undefined) {
    return undefined;
  }

  switch (formatter.valueType) {
    case "number":
      return {
        action: formatter.action,
        valueType: formatter.valueType,
        value: mapChildBindFromServer({
          bind: formatter.value,
          availablePaths: params.availablePaths,
        }),
      };

    case "date":
      return {
        action: formatter.action,
        valueType: formatter.valueType,
        value: {
          unit: formatter.value.unit,
          interval: mapChildBindFromServer({
            bind: formatter.value.interval,
            availablePaths: params.availablePaths,
          }),
        },
      };

    case "datetime":
      return {
        action: formatter.action,
        valueType: formatter.valueType,
        value: {
          unit: formatter.value.unit,
          interval: mapChildBindFromServer({
            bind: formatter.value.interval,
            availablePaths: params.availablePaths,
          }),
        },
      };
  }
}

function mapHumanTaskBindToServer(params: {
  bind: WorkflowChildReferenceBinding;
  location: string;
}): Messages["WorkflowHumanTaskTemplateReferenceBinding"] {
  assertDefinedWorkflowChildReferenceBinding(params);

  return mapChildBindToServer(params);
}

function mapChildBindFromServer(params: {
  bind: Messages["WorkflowChildReferenceBinding"];
  availablePaths: InputPath[];
}): WorkflowChildReferenceBinding {
  const { bind } = params;

  switch (bind.type) {
    case "boolean":
    case "number":
    case "date":
    case "time":
    case "datetime":
    case "file":
      return bind;

    case "text":
    case "textarea":
      return {
        ...bind,
        placeholders: fmap(bind.placeholders, (p) =>
          mapObjectValues(p, (bind) =>
            mapChildBindFromServer({ bind, availablePaths: params.availablePaths }),
          ),
        ),
      };

    case "now":
      return {
        type: "path",
        path: nowPath,
        formatter: mapFormatterFromServer({
          formatter: bind.formatter,
          availablePaths: params.availablePaths,
        }),
      };

    case "now-time":
      return {
        type: "path",
        path: nowPath,
        formatter: mapFormatterFromServer({
          formatter: bind.formatter,
          availablePaths: params.availablePaths,
        }),
      };

    case "today":
      return {
        type: "path",
        path: todayPath,
        formatter: mapFormatterFromServer({
          formatter: bind.formatter,
          availablePaths: params.availablePaths,
        }),
      };

    case "path":
      return {
        type: bind.type,
        path: mapPathFromServer(bind.path, params.availablePaths),
        formatter: mapFormatterFromServer({
          formatter: bind.formatter,
          availablePaths: params.availablePaths,
        }),
      };
  }
}

function mapHumanTaskBindFromServer(params: {
  bind: Messages["WorkflowHumanTaskTemplateReferenceBinding"];
  availablePaths: InputPath[];
}): WorkflowChildReferenceBinding {
  return mapChildBindFromServer(params);
}

function mapPathToServer(
  path: InputPath,
): ["input" | "queries" | "tasks" | "triggers", ...string[]] {
  if (path.type === "dates") {
    throw new Error("this should be converted to a separate type");
  }

  return [path.type, ...path.path.map((p) => p.id)];
}

function mapPathFromServer(
  path: ["input" | "queries" | "tasks" | "triggers", ...string[]],
  availablePaths: InputPath[],
): InputPath | null {
  return availablePaths.find((p) => p.key === path.join(".")) ?? null;
}

function mapQueryFromServer(params: {
  query: Messages["WorkflowChildTaskQuery"];
  availablePaths: InputPath[];
  queryDefinitionOptions: WorkflowTaskQuery[];
}): NodeReferenceQuery {
  return {
    queryDefinition: assertDefined(
      params.queryDefinitionOptions.find(
        (queryDefinition) =>
          queryDefinition.name === params.query.name &&
          queryDefinition.version === params.query.version,
      ),
      `Query definition not found: ${params.query.name}@${params.query.version}`,
    ),
    bind: mapObjectValues(
      params.query.bind,
      (bind): WorkflowChildReferenceBinding =>
        mapChildBindFromServer({
          bind: bind,
          availablePaths: params.availablePaths,
        }),
    ),
  };
}

function mapQueryToServer({
  query,
  location,
}: {
  query: NodeReferenceQuery;
  location: string;
}): Messages["WorkflowChildTaskQuery"] {
  if (query.queryDefinition === null) {
    throw new Error(`Query is not selected for ${location}`);
  }

  validateBind({
    bind: query.bind,
    input: query.queryDefinition.input,
    location: location,
  });

  return {
    name: query.queryDefinition.name,
    version: query.queryDefinition.version,
    bind: mapObjectValues(query.bind, (bind): Messages["WorkflowChildReferenceBinding"] =>
      mapChildBindToServer({ bind, location }),
    ),
  };
}

function mapWorkflowInstanceStatusFromServer(status: string): Messages["WorkflowCompletionStatus"] {
  switch (status) {
    case "IN_PROGRESS":
    case "SUCCESS":
    case "CANCELED":
      return { name: status };
    case "ERROR":
      return { name: "ERROR", errorMessage: "Workflow execution failed" };
    default:
      throw new Error(`Unknown workflow instance status: ${status}`);
  }
}

function emptyUnknownArrayToNull(value: unknown) {
  return !Array.isArray(value) || value.length === 0 ? null : value;
}

function emptyUnknownValueToNull(value: unknown) {
  return value === undefined || value === null || value === "" ? null : value;
}

function mapExecutedResolvedInput(input: WorkflowExecutionValueObject): ResolvedInput[] {
  return Object.entries(input).map(([key, field]): ResolvedInput => {
    if (field.type.array === true) {
      const value =
        emptyUnknownArrayToNull(field.displayValue) ?? emptyUnknownArrayToNull(field.value) ?? [];

      const fieldType = field.type.type === "option" ? field.type.optionType : field.type;

      return {
        ...fieldType,
        array: true,
        name: key,
        displayValue: value.map((stringOrNumber) => stringOrNumber.toString()),
        value: field.value as UnsafeAny,
      };
    } else {
      const value =
        emptyUnknownValueToNull(field.displayValue) ?? emptyUnknownValueToNull(field.value) ?? null;

      const fieldType = field.type.type === "option" ? field.type.optionType : field.type;

      return {
        ...fieldType,
        array: false,
        name: key,
        displayValue: value?.toString() ?? null,
        value: field.value as UnsafeAny,
      };
    }
  });
}

function mapWorkflowExecutionFromServer(
  workflowInstanceExecution: ResponseOf<"get", "./workflow_instances/:workflowInstanceId">,
) {
  return {
    ...workflowInstanceExecution,
    input: mapExecutedResolvedInput(workflowInstanceExecution.input),
    nodeResults: new Map(
      workflowInstanceExecution.nodeResults.value.map(([key, value]) => {
        return [
          key,
          {
            ...value,
            input: mapExecutedResolvedInput(value.input),
            output:
              value.output === null
                ? null
                : {
                    ...value.output,
                    key: value.output.key,
                    fields: mapExecutedResolvedInput(value.output.fields),
                  },
            executedBy: null,
          },
        ];
      }),
    ),
  };
}

function wrapTaskOptions(taskOptions: Task[]): Task[] {
  return [...taskOptions, dummyTasks.placeholder];
}

function mapDefinitionQueryResponses(params: {
  taskOptions: ResponseOf<"get", "./workflow_task_definitions">;
  entityOptions: ResponseOf<"get", "./workflow_entities">;
  queryOptions: ResponseOf<"get", "/workflow_task_queries">;
  skillOptions: ResponseOf<"get", "./workflow_tasks/skills">;
  taskTemplateOptions: ResponseOf<"get", "./workflow_human_task_template_definitions">;
  triggerOptions: ResponseOf<"get", "./workflow_triggers">;
  assignmentStrategyOptions: ResponseOf<"get", "./workflow_tasks/assignment_strategies">;
  workflow: Messages["WorkflowDefinition"];
}) {
  const entityOptions = params.entityOptions.data;
  const triggerEventOptions = params.triggerOptions.eventTriggers.map(
    mappings.definitions.trigger.event.fromServer,
  );
  const taskOptions = params.taskOptions.tasks.map(mappings.definitions.task.fromServer);
  const skillOptions = params.skillOptions.skills.map((x) => x.id);
  const taskTemplateOptions = params.taskTemplateOptions.templates;
  const jobTriggerOptions = params.triggerOptions.jobTriggers;
  const queryOptions = params.queryOptions.queries;

  const workflow = mappings.definitions.workflow.fromServer({
    workflowDefinition: produce(params.workflow, (draft) => {
      draft.children[dummyTaskRefIds.trigger] =
        draft.children[dummyTaskRefIds.trigger] ??
        mappings.node.toServer(
          emptyRef.trigger({
            parent: null,
            logicalId: dummyTaskRefIds.trigger,
            position: { x: 0, y: 0 },
          }),
        );

      if (Object.keys(draft.children).length === 1) {
        const refId = generateId("node");
        draft.children[refId] = mappings.node.toServer(
          emptyRef.placeholder({
            parent: null,
            logicalId: refId,
            position: { x: 0, y: 100 },
          }),
        );
      }
    }),
    taskOptions,
    taskTemplateOptions,
    triggerEventOptions,
    jobTriggerOptions,
    queryOptions,
    humanTaskTemplateOptions: params.taskTemplateOptions.templates,
    assignmentStrategyOptions: params.assignmentStrategyOptions.strategies,
  });

  return {
    workflow,
    entityOptions,
    triggerOptions: triggerEventOptions,
    taskOptions: wrapTaskOptions(taskOptions),
    skillOptions,
    taskTemplateOptions,
    triggerJobOptions: jobTriggerOptions,
    queryOptions,
  };
}

function mapWorkflowInstanceFromServer(instance: Messages["WorkflowInstance"]) {
  return {
    ...instance,
    input: mapExecutedResolvedInput(instance.input),
  };
}

function mapObjectValues<T, R>(object: Record<string, T>, map: (value: T) => R): Record<string, R> {
  return Object.fromEntries(Object.entries(object).map(([key, value]) => [key, map(value)]));
}

function mapMapValues<T, R>(mapObj: Map<string, T>, map: (value: T) => R): Map<string, R> {
  return new Map(Array.from(mapObj.entries()).map(([key, value]) => [key, map(value)]));
}

export const mappings = {
  definitions: {
    workflow: {
      toUpdate: mapWorkflowDefinitionToUpdate,
      toCreate: mapWorkflowDefinitionToCreate,
      fromServer: mapWorkflowDefinitionFromServer,
    },
    task: {
      fromServer: mapWorkflowTaskDefinitionFromServer,
    },
    trigger: {
      event: {
        fromServer: mapWorkflowEventTriggerOptionFromServer,
      },
    },
  },
  instances: {
    status: {
      fromServer: mapWorkflowInstanceStatusFromServer,
    },
    resolvedInput: {
      fromServer: mapExecutedResolvedInput,
    },
    workflowExecution: {
      fromServer: mapWorkflowExecutionFromServer,
    },
    workflow: {
      fromServer: mapWorkflowInstanceFromServer,
    },
  },
  node: {
    fromServer: mapNodeFromServer,
    toServer: mapNodeToServer,
  },
  query: {
    fromServer: mapQueryFromServer,
    toServer: mapQueryToServer,
  },
  path: {
    fromServer: mapPathFromServer,
    toServer: mapPathToServer,
  },
  definitionQueryResponses: mapDefinitionQueryResponses,
};
