import {
  Heading,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
} from "@chakra-ui/react";
import useNavigateLink from "../../../shared/hooks/useNavigateLink";
import { useOverflow } from "../../../shared/hooks/useOverflow";
import { Messages } from "../../../shared/api";
import WorkflowInvocationsSmallGraph from "../../Workflow/components/WorkflowInvocationsSmallGraph";

type Props = {
  workflows: Messages["DesignerListedWorkflowDefinition"][];
};

const WorkflowsTable = (props: Props) => {
  const { ref } = useOverflow();
  const navigate = useNavigateLink();

  return (
    <TableContainer ref={ref} border="1px" borderColor="gray.100" rounded="md" shadow="sm">
      <Table data-testid="workflow-definitions-table">
        <Thead>
          <Tr>
            <Th>Workflow</Th>
            <Th>Last 24h</Th>
            <Th>30 Days</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.workflows.map((workflow) => (
            <Tr
              key={workflow.correlationKey}
              _hover={{ bg: "gray.50" }}
              cursor="pointer"
              onClick={(event) => navigate(`/workflows/${workflow.correlationKey}`, { event })}
            >
              <Td>
                <Heading size="sm">{workflow.name}</Heading>
                <Wrap>
                  <Text color="gray.500">
                    by{" "}
                    <Text as="b" fontWeight="medium">
                      {workflow.meta.owner.name}
                    </Text>
                  </Text>
                  <Tag
                    colorScheme={
                      workflow.lastVersion.number === workflow.lastPublishedVersion
                        ? "green"
                        : "yellow"
                    }
                    variant="subtle"
                  >
                    v{workflow.lastVersion.number}
                  </Tag>
                  {workflow.lastPublishedVersion !== null &&
                    workflow.lastVersion.number !== workflow.lastPublishedVersion && (
                      <Tag colorScheme="green" variant="subtle">
                        v{workflow.lastPublishedVersion}
                      </Tag>
                    )}
                </Wrap>
              </Td>

              <Td minW="xs">
                <WorkflowInvocationsSmallGraph
                  data={workflow.aggregations.last24Hours}
                  unit="hour"
                />
              </Td>
              <Td minW="xs">
                <WorkflowInvocationsSmallGraph data={workflow.aggregations.last30Days} unit="day" />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default WorkflowsTable;
