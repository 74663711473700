import { Box, Flex } from "@chakra-ui/react";
import React from "react";

function WorkflowComponent() {
  return null;
}

const WorkflowRoot = (props: React.ComponentProps<typeof Flex>) => (
  <Flex align="center" direction="row" justify="space-between" {...props} />
);
WorkflowComponent.Root = WorkflowRoot;

const WorkflowTitle = (props: React.ComponentProps<typeof Flex>) => (
  <Flex align="center" direction="row" gap={2} {...props} />
);
WorkflowComponent.Title = WorkflowTitle;

const WorkflowColumn = (props: React.ComponentProps<typeof Flex>) => (
  <Flex direction="column" {...props} />
);
WorkflowComponent.Column = WorkflowColumn;

const WorkflowLight = (props: React.ComponentProps<typeof Box>) => (
  <Box color="gray.600" fontSize="xs" {...props} />
);
WorkflowComponent.Light = WorkflowLight;

export default WorkflowComponent;
