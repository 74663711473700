import { ColumnDef, RowData } from "@tanstack/react-table";
import { Path } from "dot-path-value";
import React from "react";
import { isDefined } from "../../utils/general.utils";
import { UnsafeAny } from "../../utils/types";

type DotToUnderscore<T> = T extends `${infer A}.${infer B}` ? `${A}_${DotToUnderscore<B>}` : T;

export type ColumnVisibilityParams<TData, TValue> = {
  storage?: { key: string; version: number };
  columns: ColumnDef<TData, TValue>[];
  forceVisibleColumns?: string[];
  initialSelected?: (DotToUnderscore<Path<TData>> | `@${string}`)[];
};

export default function useColumnVisibility<TData, TValue>(
  params: ColumnVisibilityParams<TData, TValue>,
) {
  const actualStorageKey = React.useMemo(() => {
    return params.storage !== undefined
      ? JSON.stringify(["column-visiblity", params.storage])
      : undefined;
  }, [params.storage]);

  const defaultColumnOptions = React.useMemo(
    () => getDefaultColumnOptions(params.columns),
    [params.columns],
  );

  const actualInitialSelected = React.useMemo(() => {
    return params.initialSelected?.map((x) => {
      if (typeof x === "string" && String(x).startsWith("@")) {
        return String(x).slice(1);
      }

      return x;
    });
  }, [params.initialSelected]);

  const [visibleColumns, setVisibleColumns] = React.useState<string[] | undefined>(() => {
    if (actualStorageKey === undefined) {
      return actualInitialSelected;
    }

    return parseJsonFromLocalStorage(actualStorageKey) ?? actualInitialSelected;
  });

  const actualVisibleColumns = React.useMemo(() => {
    return visibleColumns ?? actualInitialSelected ?? defaultColumnOptions.map((x) => x.value);
  }, [defaultColumnOptions, actualInitialSelected, visibleColumns]);

  const columnOptions = React.useMemo(
    () => defaultColumnOptions.filter((x) => !(params.forceVisibleColumns ?? []).includes(x.value)),
    [defaultColumnOptions, params.forceVisibleColumns],
  );

  const columnVisibility = React.useMemo(() => {
    return {
      ...Object.fromEntries(columnOptions.map((x) => [x.value, false])),
      ...Object.fromEntries(actualVisibleColumns.map((x) => [x, true])),
      ...Object.fromEntries((params.forceVisibleColumns ?? []).map((x) => [x, true])),
    };
  }, [columnOptions, params.forceVisibleColumns, actualVisibleColumns]);

  React.useEffect(() => {
    if (actualStorageKey === undefined) {
      return;
    }

    visibleColumns === undefined
      ? localStorage.removeItem(actualStorageKey)
      : localStorage.setItem(actualStorageKey, JSON.stringify(visibleColumns));
  }, [actualStorageKey, visibleColumns]);

  return {
    columnOptions,
    columnVisibility,
    visibleColumns: actualVisibleColumns,
    setVisibleColumns,
  };
}

function getDefaultColumnOptions<TData extends RowData>(columns: ColumnDef<TData, UnsafeAny>[]) {
  return columns
    .filter((x) => isDefined(x.header))
    .map((x) => ({
      value: `${x.id ?? (x as UnsafeAny).accessorKey?.replace(".", "_")}`,
      label: `${x.header}`,
    }));
}

function parseJsonFromLocalStorage<T>(key: string): T | null {
  try {
    const value = localStorage.getItem(key);
    if (value === null) {
      return null;
    }

    return JSON.parse(value);
  } catch (e) {
    console.error(`Failed to parse ${key} from local storage`, e);
    return null;
  }
}
