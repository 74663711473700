import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  Heading,
  Text,
  ThemeTypings,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import ChevronRightIcon from "../../../shared/icons/ChevronRightIcon";

export default function FeatureCard(props: {
  title: string;
  description: string;
  button: string;
  color: ThemeTypings["colorSchemes"];
  icon: React.ReactElement;
  to: string;
}) {
  return (
    <Card w={{ base: "full", md: "sm" }}>
      <CardHeader>
        <Flex direction="column" gap={4}>
          <Center bg="blue.50" color={`${props.color}.600`} h={12} rounded="md" w={12}>
            {props.icon}
          </Center>
          <Heading size="md">{props.title}</Heading>
        </Flex>
      </CardHeader>
      <CardBody py={0}>
        <Text color="gray.600">{props.description}</Text>
      </CardBody>
      <CardFooter justify="flex-end">
        <Button as={Link} colorScheme={props.color} rightIcon={<ChevronRightIcon />} to={props.to}>
          {props.button}
        </Button>
      </CardFooter>
    </Card>
  );
}
