import useAuth from "../utils/use-auth";

export default function useAgencyMember() {
  const auth = useAuth();

  if (auth.authData.type !== "authenticated") {
    throw new Error("useAgencyMember must be used in an authenticated context");
  }

  return auth.authData.userInfo.agencyMember;
}
