import { QueryKey, QueryObserverSuccessResult, useQuery } from "@tanstack/react-query";
import { ReactNode } from "react";
import { CustomQueryOptions } from "../utils/queries";

export default function QueryResolver<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(props: {
  options: CustomQueryOptions<TQueryFnData, TError, TData, TQueryKey>;
  onLoading: ReactNode | (() => ReactNode);
  onError: ReactNode | ((error: unknown) => ReactNode);
  onSuccess: ReactNode | ((result: QueryObserverSuccessResult<TQueryFnData, unknown>) => ReactNode);
}) {
  const { options, onLoading, onError, onSuccess } = props;

  const query = useQuery(options);

  if (query.isLoading) {
    return <>{typeof onLoading === "function" ? onLoading() : onLoading}</>;
  }

  if (query.isError) {
    return <>{typeof onError === "function" ? onError(query.error) : onError}</>;
  }

  return <>{typeof onSuccess === "function" ? onSuccess(query) : onSuccess}</>;
}
