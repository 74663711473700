import { ArrowDownIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Column, Header, Table as ReactTable, Row, flexRender } from "@tanstack/react-table";
import React from "react";
import FirstPageIcon from "../../icons/FirstPageIcon";
import LastPageIcon from "../../icons/LastPageIcon";
import DebouncedInput from "../DebouncedInput";
import Select from "../Select";
import { DataTableSkeleton } from "./DataTableSkeleton";
import {
  DataTableColumnFiltersRow,
  DataTableSpacing,
  DataTableTbodyTd,
  DataTableTheadText,
} from "./DataTableUI";
import {
  DATA_TABLE_DEFAULT_PAGE_SIZE,
  DATA_TABLE_PAGE_SIZES,
  getClickableRowProps,
  getStickyProps,
} from "./data-table.utils";
import DataTableMetaButtons, { DataTableMetaButtonsProps } from "./DataTableMetaButtons";

type Props<TData> = {
  table: ReactTable<TData>;
  isLoading: boolean;
  spacing?: DataTableSpacing;
  filterNode: React.ReactNode;
  actionNode?: React.ReactNode;
  metaButtonProps: DataTableMetaButtonsProps;
  onClickRow?: (e: React.MouseEvent<HTMLTableRowElement>, row: Row<TData>) => void;
};

export default function DataTable<TData>(props: Props<TData>) {
  return (
    <Box>
      <Flex gap={2} justify="space-between" pb={8}>
        <Flex gap={2} position="relative" wrap="wrap">
          {props.filterNode}
        </Flex>
        <Flex gap={2}>
          <DataTableMetaButtons {...props.metaButtonProps} />
          {props.actionNode}
        </Flex>
      </Flex>

      {props.isLoading ? (
        <DataTableSkeleton table={props.table} />
      ) : (
        <>
          <TableContainer>
            <Table>
              <DataTableHead table={props.table} />
              <DataTableBody
                spacing={props.spacing}
                table={props.table}
                onClickRow={props.onClickRow}
              />
            </Table>
          </TableContainer>
          <DataTablePagination table={props.table} />
        </>
      )}
    </Box>
  );
}

function DataTableHead<TData>(props: { table: ReactTable<TData> }) {
  return (
    <Thead>
      {props.table.getHeaderGroups().map((headerGroup) => (
        <React.Fragment key={headerGroup.id}>
          <Tr>
            {headerGroup.headers.map((header) => (
              <DataTableHeaderTitle key={header.id} header={header} />
            ))}
          </Tr>
          {props.table.options.enableColumnFilters && (
            <DataTableColumnFiltersRow>
              {headerGroup.headers.map((header) => (
                <DataTableHeaderFilter key={header.id} header={header} table={props.table} />
              ))}
            </DataTableColumnFiltersRow>
          )}
        </React.Fragment>
      ))}
    </Thead>
  );
}

function DataTableHeaderTitle<TData, TValue>(props: { header: Header<TData, TValue> }) {
  const { column } = props.header;

  return (
    <Th key={props.header.id} p={2} {...getStickyProps(column)}>
      {props.header.isPlaceholder ? null : column.columnDef.meta?.gqlSortKey !== undefined ? (
        <Button
          data-group
          justifyContent="space-between"
          rightIcon={
            <ArrowDownIcon
              _groupHover={{
                opacity: column.getIsSorted() === false ? 0.5 : 1,
              }}
              opacity={column.getIsSorted() === false ? 0 : 1}
              transform={column.getIsSorted() === "desc" ? "rotate(180deg)" : undefined}
              transition="all 0.2s ease"
            />
          }
          size="sm"
          variant={column.getIsSorted() === false ? "ghost" : "solid"}
          width="full"
          onClick={column.getToggleSortingHandler()}
        >
          {flexRender(column.columnDef.header, props.header.getContext())}
        </Button>
      ) : (
        <DataTableTheadText>
          {flexRender(column.columnDef.header, props.header.getContext())}
        </DataTableTheadText>
      )}
    </Th>
  );
}

function DataTableHeaderFilter<TData, TValue>(props: {
  header: Header<TData, TValue>;
  table: ReactTable<TData>;
}) {
  return (
    <Td key={props.header.id} p={2} {...getStickyProps(props.header.column)}>
      {props.header.column.columnDef.meta?.gqlFilterKey !== undefined && (
        <DataTableHeaderColumnFilter column={props.header.column} table={props.table} />
      )}
    </Td>
  );
}

function DataTableHeaderColumnFilter<TData, TValue>(props: {
  table: ReactTable<TData>;
  column: Column<TData, TValue>;
}) {
  return (
    <DebouncedInput
      bg="white"
      debounce={200}
      minW={24}
      placeholder="Search..."
      type={props.column.columnDef.meta?.gqlFilterType ?? "text"}
      value={(props.column.getFilterValue() ?? "") as string}
      onChange={props.column.setFilterValue}
    />
  );
}

function DataTableBody<TData>(props: {
  table: ReactTable<TData>;
  spacing?: DataTableSpacing;
  onClickRow?: (e: React.MouseEvent<HTMLTableRowElement>, row: Row<TData>) => void;
}) {
  const onClickRow = (e: React.MouseEvent<HTMLTableRowElement>, row: Row<TData>) => {
    const target = e.target as HTMLElement;
    const isClickableTarget = ["A", "BUTTON"].includes(target.tagName);
    const isInPopover = target.closest(".chakra-popover__popper") !== null;

    if (isClickableTarget || isInPopover) {
      return;
    }

    return props.onClickRow?.(e, row);
  };

  return (
    <Tbody>
      {props.table.getRowModel().rows.map((row) => (
        <Tr
          key={row.id}
          data-group
          {...getClickableRowProps({ row, onClick: onClickRow })}
          bg="white"
        >
          {row.getVisibleCells().map((cell) => (
            <DataTableTbodyTd key={cell.id} column={cell.column} spacing={props.spacing ?? "loose"}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </DataTableTbodyTd>
          ))}
        </Tr>
      ))}
    </Tbody>
  );
}

// function DataTableFooter<TData>(props: { table: ReactTable<TData> }) {
//   return (
//     <Tfoot>
//       {props.table.getFooterGroups().map((footerGroup) => (
//         <Tr key={footerGroup.id}>
//           {footerGroup.headers.map((header) => (
//             <Th key={header.id}>
//               {header.isPlaceholder
//                 ? null
//                 : flexRender(header.column.columnDef.footer, header.getContext())}
//             </Th>
//           ))}
//         </Tr>
//       ))}
//     </Tfoot>
//   );
// }

function DataTablePagination<TData>(props: { table: ReactTable<TData> }) {
  return (
    <Flex align="center" gap={4} px={8} py={6}>
      <ButtonGroup isAttached={true} variant="outline">
        <IconButton
          aria-label="First page"
          icon={<FirstPageIcon fontSize="lg" />}
          isDisabled={!props.table.getCanPreviousPage()}
          onClick={() => props.table.setPageIndex(0)}
        />
        <IconButton
          aria-label="Previous page"
          icon={<ChevronLeftIcon fontSize="lg" />}
          isDisabled={!props.table.getCanPreviousPage()}
          onClick={() => props.table.previousPage()}
        />
        <IconButton
          aria-label="Previous page"
          icon={<ChevronRightIcon fontSize="lg" />}
          isDisabled={!props.table.getCanNextPage()}
          onClick={() => props.table.nextPage()}
        />
        <IconButton
          aria-label="Last page"
          icon={<LastPageIcon fontSize="lg" />}
          isDisabled={!props.table.getCanNextPage()}
          onClick={() => props.table.setPageIndex(props.table.getPageCount() - 1)}
        />
      </ButtonGroup>
      <Select
        allowUnselect={false}
        label="Page Size"
        multiple={false}
        options={DATA_TABLE_PAGE_SIZES.map((pageSize) => ({
          value: pageSize,
          label: String(pageSize),
        }))}
        searchable={false}
        selectedLabel={(perPage) => `Show ${perPage}`}
        value={props.table.getState().pagination.pageSize}
        width="fit-content"
        onChange={(x) => props.table.setPageSize(x ?? DATA_TABLE_DEFAULT_PAGE_SIZE)}
      />

      <Flex gap={1}>
        <Text>Page</Text>
        <Text fontWeight="semibold">
          {props.table.getState().pagination.pageIndex + 1} of {props.table.getPageCount()}
        </Text>
      </Flex>
    </Flex>
  );
}
