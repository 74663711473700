import { ComponentWithAs, Flex, Heading, IconProps, Text } from "@chakra-ui/react";
import React from "react";
import { Messages } from "../../../shared/api";
import CronjobIcon from "../../../shared/icons/CronjobIcon";
import EventOutlineIcon from "../../../shared/icons/EventOutlineIcon";
import UserBoldIcon from "../../../shared/icons/UserBoldIcon";
import Workflow16Icon from "../../../shared/icons/Workflow16Icon";

export const WorkflowInstanceOrigin = (props: Messages["WorkflowInstanceOrigin"]) => {
  switch (props.type) {
    case "EVENT":
      return <WorkflowInstanceOriginUI heading={props.name} icon={EventOutlineIcon} text="Event" />;
    case "JOB":
      return <WorkflowInstanceOriginUI heading={props.name} icon={CronjobIcon} text="Job Report" />;
    case "MANUAL":
      return (
        <WorkflowInstanceOriginUI
          heading={props.name}
          icon={UserBoldIcon}
          text={`Agency Member #${props.id}`}
        />
      );
    case "WORKFLOW":
      return (
        <WorkflowInstanceOriginUI
          heading={props.name}
          icon={Workflow16Icon}
          text={`Instance #${props.id}`}
        />
      );
    default:
      return props satisfies never;
  }
};

export const WorkflowInstanceOriginUI = (props: {
  icon: ComponentWithAs<"svg", IconProps>;
  heading: React.ReactNode;
  text: React.ReactNode;
}) => {
  return (
    <Flex align="center" gap={3}>
      <props.icon color="blue.500" fontSize="xl" />
      <Flex direction="column">
        <Heading fontSize="md" fontWeight="semibold">
          {props.heading}
        </Heading>
        <Text color="gray" fontSize="sm">
          {props.text}
        </Text>
      </Flex>
    </Flex>
  );
};
