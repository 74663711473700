import {
  NodeReferenceQuery,
  WorkflowChildChoiceTask,
  WorkflowChoice,
  WorkflowDefinition,
  WorkflowEntity,
  WorkflowNode,
  WorkflowRetry,
  WorkflowTagReference,
  WorkflowTaskQuery,
} from "../../../../../shared/schemas/workflows";
import {
  InputPath,
  emptyRef,
  generatePlaceholdersForNode,
  getNodeIdsToRemoveByConnectedNodeOutput,
  getNodeOutput,
} from "../../../utils/workflow-node.utils";
import {
  ChoiceMeta,
  ChoiceTaskConditionsComponentSection,
  DescriptionComponentSection,
  QueriesComponentSection,
  RetryComponentSection,
  TagsComponentSection,
} from "../shared/sections";

interface Props {
  id: string;
  workflow: WorkflowDefinition;
  taskRef: WorkflowChildChoiceTask;
  queryOptions: WorkflowTaskQuery[];
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  taskTriggersComponentSection: JSX.Element;
  tags: WorkflowTagReference[];
  onChangeTags: (tags: WorkflowTagReference[]) => void;
  onUpdateMeta: <T extends keyof ChoiceMeta>(name: T, value: ChoiceMeta[T]) => void;
  onUpdateTask: (id: string, task: WorkflowChildChoiceTask) => void;
  onAddQuery: (nodeRefId: string) => void;
  onUpdateQuery: (nodeRefId: string, queryRefId: string, query: NodeReferenceQuery) => void;
  onRemoveQuery: (nodeRefId: string, queryRefId: string) => void;
  onAddNode: (id: string, node: WorkflowNode) => void;
  onRemoveNode: (id: string) => void;
}

function ChoiceTaskSidebar(props: Props) {
  const handleChangeDescription = (description: string) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      meta: {
        ...props.taskRef.meta,
        description,
      },
    });
  };

  const handleUpdateChoices = (choices: WorkflowChoice[]) => {
    const newNode = {
      ...props.taskRef,
      choices,
    };

    props.onUpdateTask(props.id, newNode);

    handleOutput(newNode);
  };

  const handleUpdateNode = (node: WorkflowChildChoiceTask) => {
    props.onUpdateTask(props.id, node);

    handleOutput(node);
  };

  const handleOutput = (node: WorkflowChildChoiceTask) => {
    const nodeIdsToRemove = getNodeIdsToRemoveByConnectedNodeOutput({
      workflow: props.workflow,
      parent: props.taskRef.parent,
      nodeRefId: props.id,
      nodeOutput: getNodeOutput(node),
    });

    const children = Object.fromEntries(
      Object.entries(props.workflow.children).filter(([id]) => !nodeIdsToRemove.includes(id)),
    );

    generatePlaceholdersForNode({
      node: node,
      children: children,
    }).forEach((node) => {
      props.onAddNode(node.logicalId, node);
    });

    nodeIdsToRemove.forEach((nodeId) => {
      props.onRemoveNode(nodeId);
    });
  };

  const handleAddChoice = () => {
    handleUpdateChoices([...props.taskRef.choices, emptyRef.choice()]);
  };

  const handleRemoveChoice = (index: number) => {
    handleUpdateChoices(props.taskRef.choices.filter((_, i) => i !== index));
  };

  const handleUpdateChoice = (index: number, choice: WorkflowChoice) => {
    handleUpdateChoices(props.taskRef.choices.map((c, i) => (i === index ? choice : c)));
  };

  const handleUpdateRetry = (retry: WorkflowRetry | undefined) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      retry,
    });
  };

  const output = getNodeOutput(props.taskRef);

  return (
    <>
      <DescriptionComponentSection
        description={props.taskRef.meta.description}
        onChange={handleChangeDescription}
      />

      {props.taskTriggersComponentSection}

      <RetryComponentSection
        hasTaskTrigger={props.taskRef.trigger !== undefined}
        output={output.success}
        retry={props.taskRef.retry}
        onUpdate={handleUpdateRetry}
      />

      <QueriesComponentSection
        availablePaths={props.availablePaths}
        entityOptions={props.entityOptions}
        id={props.id}
        queries={props.taskRef.queries}
        queryOptions={props.queryOptions}
        onAddQuery={props.onAddQuery}
        onRemoveQuery={props.onRemoveQuery}
        onUpdateQuery={props.onUpdateQuery}
      />

      <ChoiceTaskConditionsComponentSection
        availablePaths={props.availablePaths}
        entityOptions={props.entityOptions}
        id={props.id}
        node={props.taskRef}
        queryOptions={props.queryOptions}
        onAddChoice={handleAddChoice}
        onRemoveChoice={handleRemoveChoice}
        onUpdateChoice={handleUpdateChoice}
        onUpdateNode={handleUpdateNode}
      />

      <TagsComponentSection
        availablePaths={props.availablePaths}
        availableQueries={props.taskRef.queries}
        entityOptions={props.entityOptions}
        tags={props.tags}
        onChange={props.onChangeTags}
      />
    </>
  );
}

export default ChoiceTaskSidebar;
