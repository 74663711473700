import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import useAuth from "../../shared/utils/use-auth";
import { ReactComponent as LogoText } from "/src/assets/logo-text.svg";

const zLogin = z.object({
  email: z.string().email("Enter a valid email address"),
  password: z.string(),
  code: z.string().optional(),
});

const LoginPage = () => {
  const { login } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const [logoClicks, setLogoClicks] = React.useState(0);

  const { register, handleSubmit, formState } = useForm<z.infer<typeof zLogin>>({
    resolver: zodResolver(zLogin),
  });

  const handleSuccess = async (form: z.infer<typeof zLogin>) => {
    return login.mutate(form, {
      onSuccess: () => {
        navigate({ pathname: "/workflows" });
      },
      onError: (error) => {
        toast({ position: "top", description: `${error}`, status: "error" });
      },
    });
  };

  return (
    <Center h="full">
      <Stack align="center" gap={4}>
        <LogoText onClick={() => setLogoClicks((x) => x + 1)} />
        <Stack as="form" w={72} onSubmit={handleSubmit(handleSuccess)}>
          <FormControl isInvalid={formState.errors.email !== undefined}>
            <Input placeholder="email@medflyt.com" {...register("email")} />
            {formState.errors.email !== undefined && (
              <FormErrorMessage>{formState.errors.email.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={formState.errors.password !== undefined}>
            <Input placeholder="password" type="password" {...register("password")} />
            {formState.errors.password !== undefined && (
              <FormErrorMessage>{formState.errors.password.message}</FormErrorMessage>
            )}
          </FormControl>

          {logoClicks >= 5 && (
            <FormControl isInvalid={formState.errors.code !== undefined}>
              <Input placeholder="placeholder" type="code" {...register("code")} />
              {formState.errors.code !== undefined && (
                <FormErrorMessage>{formState.errors.code.message}</FormErrorMessage>
              )}
            </FormControl>
          )}
          <Button colorScheme="blue" isLoading={login.isLoading} type="submit">
            Login
          </Button>
        </Stack>
      </Stack>
    </Center>
  );
};

export default LoginPage;
