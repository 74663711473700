import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, Flex, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { WorkflowChildChoiceTask } from "../../../../../../shared/schemas/workflows";
import Sidebar from "../../../Sidebar";

interface Props {
  node: WorkflowChildChoiceTask;
  onUpdate: (node: WorkflowChildChoiceTask) => void;
}

function ChoiceDefault(props: Props) {
  const handleUpdateOutput = (output: string) => {
    props.onUpdate({
      ...props.node,
      default: { output },
    });
  };

  const handleUpdateDefault = (defaultChoice: "Error" | "Go to") => {
    props.onUpdate({
      ...props.node,
      default:
        defaultChoice === "Go to"
          ? {
              output: "Default",
            }
          : undefined,
    });
  };

  const currentDefault = props.node.default === undefined ? "Error" : "Go to";

  return (
    <Sidebar.Row alignItems="start">
      <Flex alignItems="center" gap="4">
        <Sidebar.Label>Else</Sidebar.Label>
        <Sidebar.Row>
          <Menu isLazy>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="xs">
              {currentDefault}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => handleUpdateDefault("Go to")}>Go to</MenuItem>
              <MenuItem onClick={() => handleUpdateDefault("Error")}>Error</MenuItem>
            </MenuList>
          </Menu>
          {currentDefault === "Go to" && (
            <Sidebar.Input
              flex="1"
              placeholder="Output option"
              value={props.node.default?.output ?? ""}
              onChange={(e) => handleUpdateOutput(e.target.value)}
            />
          )}
        </Sidebar.Row>
      </Flex>
    </Sidebar.Row>
  );
}

export default ChoiceDefault;
