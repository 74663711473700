import { WorkflowEventTriggerOption } from "../../../shared/schemas/workflows";
import { InputPath } from "./workflow-node.utils";

export function getEventTriggerInputPath(event: WorkflowEventTriggerOption): InputPath[] {
  const inputType = "triggers";

  switch (event.type) {
    case "DELETE":
      return event.fields.map(
        (field): InputPath => ({
          availability: { isAvailable: true },
          type: inputType,
          path: [
            {
              id: "old",
              label: "old",
            },
            {
              id: field.name,
              label: field.name,
            },
          ],
          output: field,
          key: `${inputType}.old.${field.name}`,
        }),
      );

    case "INSERT":
      return event.fields.map(
        (field): InputPath => ({
          availability: { isAvailable: true },
          type: inputType,
          path: [
            {
              id: "new",
              label: "new",
            },
            {
              id: field.name,
              label: field.name,
            },
          ],
          output: field,
          key: `${inputType}.new.${field.name}`,
        }),
      );

    case "UPDATE":
      return event.fields.flatMap((field): InputPath[] =>
        ["old", "new"].map((type) => ({
          availability: { isAvailable: true },
          type: inputType,
          path: [
            {
              id: type,
              label: type,
            },
            {
              id: field.name,
              label: field.name,
            },
          ],
          output: field,
          key: `${inputType}.${type}.${field.name}`,
        })),
      );
  }
}
