import "reactflow/dist/style.css";
import { z } from "zod";
import InternalErrorBox from "../../shared/components/InternalErrorBox";
import LoadingPage from "../../shared/components/LoadingPage";
import useAgencyMember from "../../shared/hooks/useAuthData";
import usePathParams from "../../shared/hooks/usePathParams";
import useWorkflowDefinitionQueries from "../../shared/hooks/useWorkflowDefinitionQueries";
import { WorkflowDefinitionId } from "../../shared/schema/schema";
import { zPathParamIntId } from "../../shared/schemas/path-params";
import EditorPage from "./EditorPage";

const editorRouteParams = z.object({
  workflowId: zPathParamIntId(WorkflowDefinitionId).optional(),
});

function EditorRoute() {
  const { workflowId } = usePathParams(editorRouteParams);
  const agencyMember = useAgencyMember();
  const definitionQueries = useWorkflowDefinitionQueries({
    id: workflowId,
    agencyMemberId: agencyMember.id,
  });

  if (definitionQueries.isLoading) {
    return <LoadingPage />;
  }

  if (definitionQueries.isError) {
    return <InternalErrorBox error={definitionQueries.error} />;
  }

  return (
    <EditorPage
      entityOptions={definitionQueries.entityOptions}
      queryOptions={definitionQueries.queryOptions}
      skillOptions={definitionQueries.skillOptions}
      taskOptions={definitionQueries.taskOptions}
      taskTemplateOptions={definitionQueries.taskTemplateOptions}
      triggerJobOptions={definitionQueries.triggerJobOptions}
      triggerOptions={definitionQueries.triggerOptions}
      workflow={definitionQueries.workflow}
    />
  );
}

export default EditorRoute;
