import { WorkflowSkillId } from "../../../../../shared/schema/schema";
import {
  HumanTaskAssignmentStrategy,
  HumanTaskTemplateDefinition,
  HumanTaskTemplateReference,
  NamedField,
  NamedOutput,
  NodeReferenceQuery,
  WorkflowChildHumanTaskReference,
  WorkflowChildScheduling,
  WorkflowDataField,
  WorkflowDefinition,
  WorkflowEntity,
  WorkflowError,
  WorkflowErrorHandlerType,
  WorkflowNode,
  WorkflowRetry,
  WorkflowTagReference,
  WorkflowTaskClusterReference,
  WorkflowTaskQuery,
} from "../../../../../shared/schemas/workflows";
import { generateId } from "../../../../../shared/utils/string.utils";
import {
  InputPath,
  generatePlaceholdersForNode,
  getNodeIdsToRemoveByConnectedNodeOutput,
  getNodeOutput,
} from "../../../utils/workflow-node.utils";
import {
  AssignmentStrategyComponentSection,
  ClusterComponentSection,
  DefineOutputSection,
  DescriptionComponentSection,
  ErrorHandlingComponentSection,
  HumanTaskTemplateSection,
  MandatoryComponentSection,
  PriorityComponentSection,
  QueriesComponentSection,
  RetryComponentSection,
  SchedulingComponentSection,
  SeverityComponentSection,
  SkillsComponentSection,
  TagsComponentSection,
  TaskMeta,
  TimeoutComponentSection,
} from "../shared/sections";

interface Props {
  id: string;
  workflow: WorkflowDefinition;
  node: WorkflowNode;
  taskRef: WorkflowChildHumanTaskReference;
  queryOptions: WorkflowTaskQuery[];
  entityOptions: WorkflowEntity[];
  taskTemplateOptions: HumanTaskTemplateDefinition[];
  availablePaths: InputPath[];
  skillOptions: WorkflowSkillId[];
  taskTriggersComponentSection: JSX.Element;
  tags: WorkflowTagReference[];
  onChangeTags: (tags: WorkflowTagReference[]) => void;
  onUpdateScheduling: <T extends keyof WorkflowChildScheduling>(
    name: T,
    value: WorkflowChildScheduling[T],
  ) => void;
  onUpdateMeta: <T extends keyof TaskMeta>(name: T, value: TaskMeta[T]) => void;
  onUpdateTask: (id: string, task: WorkflowChildHumanTaskReference) => void;
  onAddQuery: (nodeRefId: string) => void;
  onUpdateQuery: (nodeRefId: string, queryRefId: string, query: NodeReferenceQuery) => void;
  onRemoveQuery: (nodeRefId: string, queryRefId: string) => void;
  onAddNode: (id: string, node: WorkflowNode) => void;
  onRemoveNode: (id: string) => void;
  onAddErrorHandling: (nodeRefId: string, type: WorkflowErrorHandlerType) => void;
  onUpdateErrorHandling: (nodeRefId: string, error: WorkflowError) => void;
  onRemoveErrorHandling: (nodeRefId: string, error: WorkflowError) => void;
}

function HumanTaskSidebar(props: Props) {
  const handleChangeDescription = (description: string) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      meta: {
        ...props.taskRef.meta,
        description,
      },
    });
  };

  const handleUpdateTaskTemplate = (template: HumanTaskTemplateReference) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      template: template,
    });
  };

  const handleUpdateTaskOutput = (output: Record<string, NamedOutput>) => {
    const newNode = {
      ...props.taskRef,
      output,
    };

    props.onUpdateTask(props.id, newNode);

    generatePlaceholdersForNode({
      node: newNode,
      children: props.workflow.children,
    }).forEach((node) => {
      props.onAddNode(node.logicalId, node);
    });

    getNodeIdsToRemoveByConnectedNodeOutput({
      workflow: props.workflow,
      parent: props.node.parent,
      nodeRefId: props.id,
      nodeOutput: getNodeOutput({
        ...props.taskRef,
        output,
      }),
    }).forEach((nodeId) => {
      props.onRemoveNode(nodeId);
    });
  };

  const handleUpdateTaskOutputResult = (id: string, name: string) => {
    const current = props.taskRef.output[id];

    if (current === undefined) {
      return;
    }

    handleUpdateTaskOutput({
      ...props.taskRef.output,
      [id]: {
        ...current,
        name,
      },
    });
  };

  const handleRemoveTaskOutputResult = (id: string) => {
    handleUpdateTaskOutput(
      Object.fromEntries(Object.entries(props.taskRef.output).filter(([key]) => key !== id)),
    );
  };

  const handleAddTaskOutputResult = () => {
    const id = generateId("nodeOutput");
    handleUpdateTaskOutput({
      ...props.taskRef.output,
      [id]: {
        name: "OK",
        output: {},
      },
    });
  };

  const handleAddTaskOutputField = (outputId: string, field: WorkflowDataField) => {
    const id = generateId("nodeOutputField");

    const current = props.taskRef.output[outputId];

    if (current === undefined) {
      return;
    }

    handleUpdateTaskOutput({
      ...props.taskRef.output,
      [outputId]: {
        ...current,
        output: {
          ...current.output,
          [id]: { ...field, name: "" },
        },
      },
    });
  };

  const handleUpdateTaskOutputField = (
    outputId: string,
    fieldId: string,
    fieldType: NamedField,
  ) => {
    const current = props.taskRef.output[outputId];

    if (current === undefined) {
      return;
    }

    handleUpdateTaskOutput({
      ...props.taskRef.output,
      [outputId]: {
        ...current,
        output: {
          ...current.output,
          [fieldId]: fieldType,
        },
      },
    });
  };

  const handleRemoveTaskOutputField = (outputId: string, fieldId: string) => {
    const current = props.taskRef.output[outputId];

    if (current === undefined) {
      return;
    }

    handleUpdateTaskOutput({
      ...props.taskRef.output,
      [outputId]: {
        ...current,
        output: Object.fromEntries(
          Object.entries(current.output).filter(([key]) => key !== fieldId),
        ),
      },
    });
  };

  const handleUpdateErrorHandling = (error: WorkflowError) => {
    props.onUpdateErrorHandling(props.id, error);
  };

  const handleRemoveErrorHandling = (error: WorkflowError) => {
    props.onRemoveErrorHandling(props.id, error);
  };

  const handleUpdateTimeout = (timeout: number | null) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      assignmentTimeoutSeconds: timeout,
    });
  };

  const handleUpdateSkills = (skills: WorkflowSkillId[]) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      skills,
    });
  };

  const handleUpdateStrategy = (strategy: HumanTaskAssignmentStrategy | null) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      assignmentStrategy: strategy,
    });
  };

  const handleUpdateCluster = (cluster: WorkflowTaskClusterReference | undefined) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      cluster,
    });
  };

  const handleUpdateRetry = (retry: WorkflowRetry | undefined) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      retry,
    });
  };

  return (
    <>
      <DescriptionComponentSection
        description={props.taskRef.meta.description}
        onChange={handleChangeDescription}
      />

      {props.taskTriggersComponentSection}

      <HumanTaskTemplateSection
        availablePaths={props.availablePaths}
        entityOptions={props.entityOptions}
        templateRef={props.taskRef.template}
        templates={props.taskTemplateOptions}
        onUpdate={handleUpdateTaskTemplate}
      />

      <ClusterComponentSection
        availablePaths={props.availablePaths}
        availableQueries={props.taskRef.queries}
        cluster={props.taskRef.cluster}
        entityOptions={props.entityOptions}
        onChange={handleUpdateCluster}
      />

      <SkillsComponentSection
        skillOptions={props.skillOptions}
        skills={props.taskRef.skills}
        onChange={handleUpdateSkills}
      />

      <AssignmentStrategyComponentSection
        availablePaths={props.availablePaths}
        entityOptions={props.entityOptions}
        strategies={[
          {
            type: "intake-patient-representative",
            input: {
              patient: { type: "entity", entity: "Patient" },
            },
          },
        ]}
        strategy={props.taskRef.assignmentStrategy}
        onChange={handleUpdateStrategy}
      />

      <TimeoutComponentSection
        data-testid="assigment-timeout"
        timeout={props.taskRef.assignmentTimeoutSeconds}
        title="Assignment timeout"
        onChange={handleUpdateTimeout}
      />

      <RetryComponentSection
        hasTaskTrigger={props.node.trigger !== undefined}
        output={props.taskRef.output}
        retry={props.taskRef.retry}
        onUpdate={handleUpdateRetry}
      />

      <DefineOutputSection
        entityOptions={props.entityOptions}
        output={props.taskRef.output}
        onAdd={handleAddTaskOutputResult}
        onAddField={handleAddTaskOutputField}
        onRemove={handleRemoveTaskOutputResult}
        onRemoveField={handleRemoveTaskOutputField}
        onUpdate={handleUpdateTaskOutputResult}
        onUpdateField={handleUpdateTaskOutputField}
      />

      <QueriesComponentSection
        availablePaths={props.availablePaths}
        entityOptions={props.entityOptions}
        id={props.id}
        queries={props.taskRef.queries}
        queryOptions={props.queryOptions}
        onAddQuery={props.onAddQuery}
        onRemoveQuery={props.onRemoveQuery}
        onUpdateQuery={props.onUpdateQuery}
      />

      <MandatoryComponentSection
        mandatory={props.taskRef.meta.mandatory}
        onUpdateMeta={props.onUpdateMeta}
      />

      <PriorityComponentSection
        priority={props.taskRef.meta.priority}
        onUpdate={(v) => props.onUpdateMeta("priority", v)}
      />

      <SeverityComponentSection
        severity={props.taskRef.meta.severity}
        onUpdate={(v) => props.onUpdateMeta("severity", v)}
      />

      <SchedulingComponentSection
        availablePaths={props.availablePaths}
        createAfter={props.taskRef.scheduling?.createAfter}
        createBefore={props.taskRef.scheduling?.createBefore}
        entityOptions={props.entityOptions}
        finishBefore={props.taskRef.scheduling?.finishBefore}
        timeout={props.taskRef.scheduling?.timeout}
        onUpdateScheduling={props.onUpdateScheduling}
      />

      <ErrorHandlingComponentSection
        availablePaths={props.availablePaths}
        availableQueries={props.taskRef.queries}
        entityOptions={props.entityOptions}
        errors={props.taskRef.errors}
        hasRetry={props.taskRef.retry !== undefined}
        output={props.taskRef.output}
        queryOptions={props.queryOptions}
        scheduling={props.taskRef.scheduling}
        skillOptions={props.skillOptions}
        templates={props.taskTemplateOptions}
        onAdd={(type) => props.onAddErrorHandling(props.id, type)}
        onRemove={handleRemoveErrorHandling}
        onUpdate={handleUpdateErrorHandling}
      />

      <TagsComponentSection
        availablePaths={props.availablePaths}
        availableQueries={props.taskRef.queries}
        entityOptions={props.entityOptions}
        tags={props.tags}
        onChange={props.onChangeTags}
      />
    </>
  );
}

export default HumanTaskSidebar;
