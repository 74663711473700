import React from "react";

export default function useIsHoldingShiftKey() {
  const isHoldingShift = React.useRef(false);

  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      isHoldingShift.current = e.shiftKey;
    };

    window.addEventListener("keydown", listener);
    window.addEventListener("keyup", listener);

    return () => {
      window.removeEventListener("keydown", listener);
      window.removeEventListener("keyup", listener);
    };
  });

  return isHoldingShift.current;
}
