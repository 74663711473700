import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  ColumnFiltersState,
  PaginationState,
  SortingState,
  Updater,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import useSearchParamState from "../../hooks/useSearchParamState";
import { Exact } from "../../schema/gql/graphql";
import { downloadCSVContent, mapDataTableToCSV } from "../../utils/data-table-csv.utils";
import { GraphQLDocumentFilters, getGraphQLFilterVariableEntries } from "../../utils/graphql.utils";
import { UnsafeAny } from "../../utils/types";
import { useApi } from "../../utils/use-api";
import { DataTableMetaButtonsProps } from "./DataTableMetaButtons";
import {
  DATA_TABLE_DEFAULT_PAGE_SIZE,
  DATA_TABLE_PAGE_SIZES,
  mapColumnFiltersState,
  mapSortingState,
} from "./data-table.utils";
import useColumnVisibility, { ColumnVisibilityParams } from "./useColumnVisibility";

type DocumentData<
  TConnectionName extends string,
  TConnectionType extends Record<string, unknown>,
> = {
  [key in TConnectionName]: {
    totalCount: number;
    nodes: TConnectionType[];
  };
};

type DocumentVariables = Record<"order" | "limit" | "offset", unknown> & Record<string, unknown>;

export default function useGraphQLDataTable<
  TDocumentData extends DocumentData<TConnectionName, TConnectionType>,
  TConnectionName extends string,
  TConnectionType extends Record<string, unknown>,
  TDocumentVariables extends DocumentVariables,
  TValue,
>(params: {
  connection: TConnectionName;
  document: TypedDocumentNode<TDocumentData, TDocumentVariables>;
  columnVisiblity?: Omit<ColumnVisibilityParams<TConnectionType, TValue>, "columns">;
  initialSorting?: {
    id: GraphQLOrderField<TypedDocumentNode<TDocumentData, TDocumentVariables>>;
    desc: boolean;
  }[];
  globalFilters?: GraphQLDocumentFilters<TDocumentVariables>;
  columns: ColumnDef<TConnectionType, UnsafeAny>[];
  enableColumnFilters?: boolean;
  initialPageSize?: (typeof DATA_TABLE_PAGE_SIZES)[number];
}) {
  const { api } = useApi();

  const { columnOptions, columnVisibility, visibleColumns, setVisibleColumns } =
    useColumnVisibility({ ...params.columnVisiblity, columns: params.columns });

  const [pagination, setPagination] = useSearchParamState<PaginationState>("pagination", {
    pageIndex: 0,
    pageSize: params.initialPageSize ?? DATA_TABLE_DEFAULT_PAGE_SIZE,
  });

  const [sorting, setSorting] = useSearchParamState<SortingState>(
    "sorting",
    params.initialSorting ?? [],
  );

  const $sorting = React.useMemo(
    () => mapSortingState(sorting, params.columns),
    [params.columns, sorting],
  );

  const [columnFilters, setColumnFilters] = useSearchParamState<ColumnFiltersState>("filters", []);

  const $columnFilters = React.useMemo(() => {
    return mapColumnFiltersState(columnFilters, params.columns);
  }, [columnFilters, params.columns]);

  const derivedGlobalFilters = React.useMemo(
    () =>
      Object.fromEntries(
        getGraphQLFilterVariableEntries(params.document).map(([name]) => [
          name,
          params.globalFilters?.[name] ?? null,
        ]),
      ),
    [params.globalFilters, params.document],
  );

  const variables = {
    ...derivedGlobalFilters,
    ...$columnFilters,
    order: $sorting,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
  } as TDocumentVariables;

  const query = useQuery({
    keepPreviousData: true,
    queryKey: [params.document, variables],
    queryFn: () => api.graphql(params.document, { variables }),
  });

  const nodes = query.data?.[params.connection].nodes;
  const totalCount = query.data?.[params.connection].totalCount ?? 0;

  const pageCount = React.useMemo(() => {
    return Math.ceil(totalCount / pagination.pageSize);
  }, [pagination.pageSize, totalCount]);

  const exportMutation = useMutation({
    mutationFn: () => {
      return api.graphql(params.document, {
        variables: { ...variables, limit: 100000, offset: 0 },
      });
    },
    onSuccess: (data) => {
      const rows = data[params.connection].nodes;

      const headers = table
        .getHeaderGroups()
        .flatMap((group) => group.headers)
        .filter((x) => !x.id.startsWith("_"));

      const csv = mapDataTableToCSV({ rows, headers });

      downloadCSVContent(csv);
    },
  });

  const table = useReactTable({
    data: React.useMemo(() => nodes ?? [], [nodes]),
    columns: params.columns,
    pageCount: pageCount,
    state: {
      columnVisibility: columnVisibility,
      pagination: pagination,
      globalFilter: derivedGlobalFilters,
      columnFilters: columnFilters,
      sorting: sorting,
    },
    enableColumnFilters: params.enableColumnFilters ?? true,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: React.useCallback(() => {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }, [setPagination]),
    onColumnFiltersChange: React.useCallback(
      (updater: Updater<ColumnFiltersState>) => {
        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
        setColumnFilters(updater);
      },
      [setColumnFilters, setPagination],
    ),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  const metaButtonProps: DataTableMetaButtonsProps = {
    columnOptions: columnOptions,
    onChangeVisibleColumns: setVisibleColumns,
    visibleColumns: visibleColumns,
    isRefreshing: query.isFetching,
    onClickRefresh: query.refetch,
    isExporting: exportMutation.isLoading,
    onClickExport: exportMutation.mutate,
  };

  return {
    table,
    query,
    exportMutation,
    tableProps: {
      isLoading: query.isFetching,
      metaButtonProps: metaButtonProps,
      table: table,
    },
  };
}

type GraphQLOrderField<T> = T extends TypedDocumentNode<
  infer _UData,
  Exact<{ order: ({ field: infer UField | null | undefined } | null)[] } & infer _UOther>
>
  ? Stringify<UField>
  : never;

type Stringify<T> = T extends string | number | bigint | boolean | null | undefined
  ? `${T}`
  : never;
