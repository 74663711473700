import Select from "../../../../../../shared/components/Select";
import { ConcurrencyRule } from "../../../../../../shared/schemas/workflows";
import { objectEntries } from "../../../../../../shared/utils/general.utils";

interface Props {
  value: ConcurrencyRule;
  onChange: (value: ConcurrencyRule) => void;
}

const items: Record<
  ConcurrencyRule,
  {
    label: string;
    description: string;
  }
> = {
  RUN_ALWAYS: {
    label: "Run always",
    description: "Run this step every time the workflow is triggered.",
  },
  RUN_FOR_ACTIVE_UNIQUE_INPUT: {
    label: "Run for active unique input",
    description: "Run this workflow only if input is unique in active workflows in domain.",
  },
  RUN_FOR_UNIQUE_INPUT: {
    label: "Run for unique input",
    description: "Run this workflow only if input is unique in domain.",
  },
};

export default function ConcurrencyRuleSelect(props: Props) {
  const handleChange = (value: ConcurrencyRule | undefined) => {
    if (value !== undefined) {
      props.onChange(value);
    }
  };

  return (
    <Select
      label="Concurrency rule"
      multiple={false}
      options={objectEntries(items).map(([rule, value]) => ({
        label: value.label,
        value: rule,
        description: value.description,
      }))}
      size="sm"
      value={props.value}
      onChange={handleChange}
    />
  );
}
