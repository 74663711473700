import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  HeadingProps,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ChronoUnit, LocalDate, LocalDateTime } from "@js-joda/core";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { AxisOptions, Chart, UserSerie } from "react-charts";
import { Messages } from "../../../shared/api";
import DurationText from "../../../shared/components/DurationText";
import RefreshRoundedIcon from "../../../shared/icons/RefreshRoundedIcon";
import {
  WorkflowDefinitionCorrelationKey,
  WorkflowDefinitionId,
} from "../../../shared/schema/schema";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { atEndOfDay, localDateTimeRange } from "../../../shared/utils/date.utils";
import { averageByFn, fmap, groupByFn } from "../../../shared/utils/general.utils";
import { useApi } from "../../../shared/utils/use-api";

type SeriesItem = {
  primary: LocalDateTime;
  secondary: number;
};

type Props = {
  correlationKey: WorkflowDefinitionCorrelationKey;
  workflowDefinitionId?: WorkflowDefinitionId;
  range: [LocalDate | null, LocalDate | null];
};

function useWorkflowAggregatedExecutionsQuery(props: Props) {
  const { range, unit } = (() => {
    const [from, to] = props.range;

    const range =
      from === null || to === null
        ? ([LocalDate.now().minusDays(30), LocalDate.now()] as const)
        : ([from, to] as const);

    const unit: "day" | "hour" = range[0].until(range[1], ChronoUnit.DAYS) > 1 ? "day" : "hour";

    return { range, unit };
  })();

  const { queries } = useApi();

  const query = useQuery({
    ...queries.aggregatedExecutions({
      correlationKey: [props.correlationKey],
      workflowDefinitionId: fmap(props.workflowDefinitionId, (id) => [id]),
      groupBy: unit,
      from: range[0],
      to: range[1],
    }),
    select: ({ data }) => {
      const dateRange = localDateTimeRange(range[0].atStartOfDay(), atEndOfDay(range[1]), unit);
      const grouped = groupByFn(data, (x) => x.timeGroup.toString());
      const emptyDatum = (
        timeGroup: LocalDateTime,
      ): Messages["WorkflowInstanceAggregatedExecutionsDatum"] => ({
        averageInSeconds: null,
        medianInSeconds: null,
        timeGroup: timeGroup,
        totalCancel: 0,
        totalError: 0,
        totalInProgress: 0,
        totalInvocations: 0,
        totalSuccess: 0,
      });

      return {
        unit: unit,
        data: dateRange.map((date) => grouped.get(date.toString()) ?? [emptyDatum(date)]).flat(),
        maxInvocations: data.reduce((acc, datum) => Math.max(acc, datum.totalInvocations), 0),
        totalInvocations: data.reduce((acc, datum) => acc + datum.totalInvocations, 0),
        averageInSeconds: averageByFn(data, (x) => x.averageInSeconds ?? 0),
        medianInSeconds: averageByFn(data, (x) => x.medianInSeconds ?? 0),
      };
    },
  });

  return { query, unit };
}

function WorkflowInvocationsGraph(props: Props) {
  const { query, unit } = useWorkflowAggregatedExecutionsQuery(props);

  const data = React.useMemo((): UserSerie<SeriesItem>[] => {
    if (!query.isSuccess) {
      return [];
    }

    return [
      {
        label: "Cancel",
        color: "var(--chakra-colors-gray-300)",
        data: query.data.data.map((datum) => ({
          primary: datum.timeGroup,
          secondary: datum.totalCancel,
        })),
      },
      {
        label: "Error",
        color: "var(--chakra-colors-red-300)",
        data: query.data.data.map((datum) => ({
          primary: datum.timeGroup,
          secondary: datum.totalError,
        })),
      },
      {
        label: "In Progress",
        color: "var(--chakra-colors-yellow-300)",
        data: query.data.data.map((datum) => ({
          primary: datum.timeGroup,
          secondary: datum.totalInProgress,
        })),
      },
      {
        label: "Success",
        color: "var(--chakra-colors-green-300)",
        data: query.data.data.map((datum) => ({
          primary: datum.timeGroup,
          secondary: datum.totalSuccess,
        })),
      },
    ];
  }, [query.data?.data, query.isSuccess]);

  const primaryAxis = React.useMemo(
    (): AxisOptions<SeriesItem> => ({
      getValue: (datum) => datum.primary.toString(),
      formatters: {
        scale: (value: string | null) => {
          if (value === null) {
            return "";
          }

          switch (unit) {
            case "day":
              return dateFormatter.toMonthDay(LocalDateTime.parse(value));
            case "hour":
              return dateFormatter.toTime(LocalDateTime.parse(value));
          }
        },
      },
    }),
    [unit],
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<SeriesItem>[] => [
      {
        getValue: (datum) => datum.secondary,
        elementType: "bar",
        showGrid: false,
        stacked: true,
      },
    ],
    [],
  );

  if (query.status === "loading") {
    return <WorkflowInvocationsGraphSkeleton />;
  }

  if (query.status === "error") {
    return <WorkflowInvocationsGraphError error={query.error} onRetry={query.refetch} />;
  }

  return (
    <CardTemplate
      average={
        <SecondaryValue>
          <DurationText milliseconds={query.data.averageInSeconds * 1000} />
        </SecondaryValue>
      }
      invocations={<PrimaryValue>{query.data.totalInvocations.toLocaleString()}</PrimaryValue>}
      median={
        <SecondaryValue>
          <DurationText milliseconds={query.data.medianInSeconds * 1000} />
        </SecondaryValue>
      }
    >
      <Box h={40} sx={{ "*": { fontFamily: "Inter" } }}>
        <Chart
          options={{
            data,
            primaryAxis,
            secondaryAxes,
            secondaryCursor: false,
            getDatumStyle: (x) => {
              return {
                rectangle: {
                  fill: x.originalSeries.color,
                },
              };
            },
            getSeriesStyle: () => {
              return {
                strokeWidth: 0,
                fontFamily: "Inter",
                line: {
                  strokeWidth: 2,
                  stroke: "var(--chakra-colors-blue-600)",
                },
                rectangle: {
                  fill: `url(#gradient-blue)`,
                },
              };
            },
          }}
        />
      </Box>
    </CardTemplate>
  );
}

function WorkflowInvocationsGraphSkeleton() {
  return (
    <CardTemplate
      average={<Skeleton h={6} mt={2} rounded="full" w={32} />}
      invocations={<Skeleton h={8} mt={2} rounded="full" w={20} />}
      median={<Skeleton h={6} mt={2} rounded="full" w={32} />}
    >
      <Skeleton h={40} rounded="md" w="full" />
    </CardTemplate>
  );
}

function WorkflowInvocationsGraphError(props: { error: unknown; onRetry: () => void }) {
  return (
    <CardTemplate
      average={<SecondaryValue>--</SecondaryValue>}
      invocations={<PrimaryValue color="red">Error</PrimaryValue>}
      median={<SecondaryValue>--</SecondaryValue>}
    >
      <VStack bg="red.50" h={40} justify="center" rounded="md">
        <Text color="red">{String(props.error)}</Text>
        <Button
          colorScheme="red"
          leftIcon={<RefreshRoundedIcon />}
          variant="outline"
          onClick={props.onRetry}
        >
          Retry
        </Button>
      </VStack>
    </CardTemplate>
  );
}

function CardTemplate(props: {
  invocations: React.ReactNode;
  average: React.ReactNode;
  median: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <Card flex={1}>
      <CardHeader>
        <Flex gap={12} wrap="wrap">
          <Box>
            <PrimaryHeading>Invocations</PrimaryHeading>
            {props.invocations}
          </Box>

          <Box>
            <SecondaryHeading>Average</SecondaryHeading>
            {props.average}
          </Box>

          <Box>
            <SecondaryHeading>Median</SecondaryHeading>
            {props.median}
          </Box>
        </Flex>
      </CardHeader>
      <CardBody>{props.children}</CardBody>
    </Card>
  );
}

function PrimaryHeading(props: HeadingProps) {
  return (
    <Heading mb={1} size="sm" {...props}>
      {props.children}
    </Heading>
  );
}

function PrimaryValue(props: HeadingProps) {
  return (
    <Heading size="lg" {...props}>
      {props.children}
    </Heading>
  );
}

function SecondaryHeading(props: HeadingProps) {
  return (
    <Heading color="gray.400" fontWeight="regular" mb={1} size="sm" {...props}>
      {props.children}
    </Heading>
  );
}

function SecondaryValue(props: HeadingProps) {
  return (
    <Heading color="gray.700" size="md" {...props}>
      {props.children}
    </Heading>
  );
}

export default Object.assign(WorkflowInvocationsGraph, {
  Skeleton: WorkflowInvocationsGraphSkeleton,
  Error: WorkflowInvocationsGraphError,
});
