import {
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import PlayCircleIcon from "../../../shared/icons/PlayCircleIcon";
import { WorkflowDefinitionId } from "../../../shared/schema/schema";
import { WorkflowInput } from "../../../shared/schemas/workflows";
import { useApi } from "../../../shared/utils/use-api";
import { WorkflowInputForm } from "./WorkflowForm";
import { WORKFLOW_DASHBOARD_INSTANCES_QUERY } from "../../Workflow/workflow.graphql";

type Props = {
  id: WorkflowDefinitionId;
  input: Map<string, WorkflowInput>;
};

export function RunWorkflowPopover(props: Props) {
  const { mutations } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();

  const runWorkflow = useMutation({
    ...mutations.runWorkflow(props.id),
    onSuccess: () => {
      toast({ position: "top", description: "Workflow started", status: "success" });
      queryClient.invalidateQueries([WORKFLOW_DASHBOARD_INSTANCES_QUERY]);
    },
    onError: (error) => toast({ position: "top", description: `${error}`, status: "error" }),
  });

  return (
    // https://github.com/chakra-ui/chakra-ui/issues/7789
    <Popover closeOnBlur={false} placement="bottom-end">
      <PopoverTrigger>
        <Button colorScheme="blue" leftIcon={<PlayCircleIcon />} variant="solid">
          Run
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody p={4}>
            <Flex direction="column" gap={4}>
              <WorkflowInputForm
                fields={props.input}
                fileUploadDestination={{ by: "workflow-definition", id: props.id }}
                hints={[]}
                renderButton={() => (
                  <Button
                    colorScheme="blue"
                    leftIcon={<PlayCircleIcon />}
                    ms="auto"
                    type="submit"
                    variant="solid"
                  >
                    Run
                  </Button>
                )}
                onSubmit={runWorkflow.mutate}
              />
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
