import { UseMutationOptions } from "@tanstack/react-query";
import axios from "axios";
import { API, InferBodyOf } from "../api";
import { InputFileUploadDestination } from "../components/FileInput";
import { FileUpload } from "../hooks/useUploadFiles";
import { WorkflowDefinitionCorrelationKey, WorkflowDefinitionId } from "../schema/schema";
import { mappings } from "../schemas/mapping";
import { WorkflowDefinition } from "../schemas/workflows";
import { createQueries } from "./queries";

function createMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>,
) {
  return options;
}

export function createMutations(params: { api: API; queries: ReturnType<typeof createQueries> }) {
  const { api, queries } = params;

  return {
    login: () =>
      createMutation({
        mutationKey: ["auth", "login"],
        mutationFn: (body: InferBodyOf<"post", "/auth/new_login">) => {
          return api.post("/auth/new_login", { body });
        },
      }),
    createWorkflow: () =>
      createMutation({
        mutationKey: ["workflows"],
        mutationFn: (workflow: WorkflowDefinition) => {
          return api.post("./workflow_definitions", {
            body: { workflow: mappings.definitions.workflow.toCreate(workflow) },
          });
        },
      }),
    updateWorkflow: () =>
      createMutation({
        mutationKey: ["workflows"],
        mutationFn: (workflow: WorkflowDefinition) => {
          return api.put("./workflow_definitions/:workflowDefinition", {
            body: { workflow: mappings.definitions.workflow.toUpdate(workflow) },
            path: { workflowDefinition: workflow.id },
          });
        },
      }),
    publishWorkflow: () =>
      createMutation({
        mutationKey: ["publish_worfklow"],
        mutationFn: (params: {
          id: WorkflowDefinitionId;
          published: boolean;
          deleteScheduledInstances: boolean;
        }) => {
          return api.patch("./workflow_definitions/:workflowDefinitionId/publish", {
            path: { workflowDefinitionId: params.id },
            body: {
              published: params.published,
              deleteScheduledInstances: params.deleteScheduledInstances,
            },
          });
        },
        onSuccess: (payload) => queries.workflow(payload.workflow.id).setQueryData(payload),
      }),
    cloneWorkflow: () =>
      createMutation({
        mutationKey: ["clone_worfklow"],
        mutationFn: (params: {
          id: WorkflowDefinitionId;
          correlationKey: WorkflowDefinitionCorrelationKey;
        }) => {
          return api.post("./workflow_definitions/:workflowDefinitionId/clone", {
            path: { workflowDefinitionId: params.id },
            body: { correlationKey: params.correlationKey },
          });
        },
      }),
    runWorkflow: (workflowDefinitionId: WorkflowDefinitionId) =>
      createMutation({
        mutationKey: ["workflows"],
        mutationFn: (input: Record<string, unknown>) => {
          return api.post("./workflow_definitions/:workflowDefinitionId/run", {
            body: { input },
            path: { workflowDefinitionId },
          });
        },
      }),
    generatePresignedUrls: (fileUploadDestination: InputFileUploadDestination) =>
      createMutation({
        mutationKey: ["generate_presigned_urls"],
        mutationFn: (uploads: FileUpload[]) => {
          switch (fileUploadDestination.by) {
            case "workflow-definition":
              return api.post("./workflow_definitions/:workflowDefinitionId/presign_urls", {
                path: { workflowDefinitionId: fileUploadDestination.id },
                body: { fileNames: uploads.map((upload) => upload.file.name) },
              });

            case "workflow-task":
              return api.post("./workflow_tasks/:workflowTaskInstanceId/presign_urls", {
                path: { workflowTaskInstanceId: fileUploadDestination.id },
                body: { fileNames: uploads.map((upload) => upload.file.name) },
              });
          }
        },
      }),
    uploadFile: () =>
      createMutation({
        mutationKey: ["upload_file"],
        mutationFn: async (params: {
          url: string;
          file: File;
          onUploadProgress: (percentage: number) => void;
        }) => {
          const { url, file, onUploadProgress } = params;
          const { data } = await axios.put(url, file, {
            headers: { "Content-Type": file.type },
            onUploadProgress: (e) => {
              const percentCompleted =
                e.total === undefined ? 0 : Math.floor((e.loaded * 100) / e.total);

              onUploadProgress(percentCompleted);
            },
          });
          return data;
        },
      }),
  };
}
