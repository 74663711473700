import { WorkflowDataField } from "../../shared/schemas/workflows";
import { capitalize } from "../../shared/utils/general.utils";

export function getNamedWorkflowDataField(field: WorkflowDataField): string {
  switch (field.type) {
    case "number":
    case "boolean":
    case "text":
    case "textarea":
    case "date":
    case "datetime":
    case "time":
    case "file":
    case "unknown":
      return capitalize(field.type);
    case "union":
      return `Union: ${field.union.map(getNamedWorkflowDataField).join(", ")}`;
    case "option":
      return `Option (${getNamedWorkflowDataField(field.optionType)})`;
    case "entity":
      return field.entity;
  }
}
