import { createIcon } from "@chakra-ui/react";

const HomeIcon = createIcon({
  displayName: "HomeIcon",
  viewBox: "0 0 22 24",
  path: (
    <path
      d="M2.99998 20.8333H6.99998V12.8333H15V20.8333H19V8.83334L11 2.83334L2.99998 8.83334V20.8333ZM2.99998 23.5C2.26665 23.5 1.63909 23.2391 1.11731 22.7173C0.594646 22.1947 0.333313 21.5667 0.333313 20.8333V8.83334C0.333313 8.41112 0.427979 8.01112 0.617313 7.63334C0.805757 7.25556 1.06665 6.94445 1.39998 6.7L9.39998 0.700005C9.64442 0.522227 9.89998 0.388894 10.1666 0.300005C10.4333 0.211116 10.7111 0.166672 11 0.166672C11.2889 0.166672 11.5666 0.211116 11.8333 0.300005C12.1 0.388894 12.3555 0.522227 12.6 0.700005L20.6 6.7C20.9333 6.94445 21.1946 7.25556 21.384 7.63334C21.5724 8.01112 21.6666 8.41112 21.6666 8.83334V20.8333C21.6666 21.5667 21.4058 22.1947 20.884 22.7173C20.3613 23.2391 19.7333 23.5 19 23.5H12.3333V15.5H9.66665V23.5H2.99998Z"
      fill="currentColor"
    />
  ),
});

export default HomeIcon;
