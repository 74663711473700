import { WorkflowSkillId } from "../../../../../shared/schema/schema";
import {
  HumanTaskTemplateDefinition,
  NodeReferenceQuery,
  Task,
  WorkflowChildReferenceBinding,
  WorkflowChildScheduling,
  WorkflowChildSystemTaskReference,
  WorkflowEntity,
  WorkflowError,
  WorkflowErrorHandlerType,
  WorkflowRetry,
  WorkflowTagReference,
  WorkflowTaskQuery,
} from "../../../../../shared/schemas/workflows";
import {
  InputPath,
  bindCanHavePlaceholders,
  getPlaceholdersFromBind,
} from "../../../utils/workflow-node.utils";
import {
  DescriptionComponentSection,
  ErrorHandlingComponentSection,
  InputBindComponentSection,
  OutputStaticComponentSection,
  QueriesComponentSection,
  RetryComponentSection,
  SchedulingComponentSection,
  TagsComponentSection,
  TaskMeta,
} from "../shared/sections";

interface Props {
  id: string;
  taskRef: WorkflowChildSystemTaskReference;
  task: Task;
  queryOptions: WorkflowTaskQuery[];
  entityOptions: WorkflowEntity[];
  availablePaths: InputPath[];
  taskTriggersComponentSection: JSX.Element;
  taskTemplateOptions: HumanTaskTemplateDefinition[];
  skillOptions: WorkflowSkillId[];
  tags: WorkflowTagReference[];
  onChangeTags: (tags: WorkflowTagReference[]) => void;
  onUpdateScheduling: <T extends keyof WorkflowChildScheduling>(
    name: T,
    value: WorkflowChildScheduling[T],
  ) => void;
  onUpdateMeta: <T extends keyof TaskMeta>(name: T, value: TaskMeta[T]) => void;
  onUpdateTask: (id: string, task: WorkflowChildSystemTaskReference) => void;
  onAddQuery: (nodeRefId: string) => void;
  onUpdateQuery: (nodeRefId: string, queryRefId: string, query: NodeReferenceQuery) => void;
  onRemoveQuery: (nodeRefId: string, queryRefId: string) => void;
  onAddErrorHandling: (nodeRefId: string, type: WorkflowErrorHandlerType) => void;
  onUpdateErrorHandling: (nodeRefId: string, error: WorkflowError) => void;
  onRemoveErrorHandling: (nodeRefId: string, error: WorkflowError) => void;
}

function SystemTaskSidebar(props: Props) {
  const handleUpdateBind = (name: string, bind: WorkflowChildReferenceBinding | null) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      bind:
        bind === null
          ? {}
          : {
              ...props.taskRef.bind,
              [name]: bindCanHavePlaceholders(bind)
                ? { ...bind, placeholders: getPlaceholdersFromBind(bind) }
                : bind,
            },
    });
  };

  const handleChangeDescription = (description: string) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      meta: {
        ...props.taskRef.meta,
        description,
      },
    });
  };

  const handleUpdateErrorHandling = (error: WorkflowError) => {
    props.onUpdateErrorHandling(props.id, error);
  };

  const handleRemoveErrorHandling = (error: WorkflowError) => {
    props.onRemoveErrorHandling(props.id, error);
  };

  const handleUpdateRetry = (retry: WorkflowRetry | undefined) => {
    props.onUpdateTask(props.id, {
      ...props.taskRef,
      retry,
    });
  };

  return (
    <>
      <DescriptionComponentSection
        description={props.taskRef.meta.description ?? props.task.meta.description}
        onChange={handleChangeDescription}
      />

      {props.taskTriggersComponentSection}

      <InputBindComponentSection
        availablePaths={props.availablePaths}
        bind={props.taskRef.bind}
        entityOptions={props.entityOptions}
        input={props.task.input}
        onUpdateBind={handleUpdateBind}
      />

      <OutputStaticComponentSection output={props.task.output} />

      <RetryComponentSection
        hasTaskTrigger={props.taskRef.trigger !== undefined}
        output={props.task.output}
        retry={props.taskRef.retry}
        onUpdate={handleUpdateRetry}
      />

      <QueriesComponentSection
        availablePaths={props.availablePaths}
        entityOptions={props.entityOptions}
        id={props.id}
        queries={props.taskRef.queries}
        queryOptions={props.queryOptions}
        onAddQuery={props.onAddQuery}
        onRemoveQuery={props.onRemoveQuery}
        onUpdateQuery={props.onUpdateQuery}
      />

      <SchedulingComponentSection
        availablePaths={props.availablePaths}
        createAfter={props.taskRef.scheduling?.createAfter}
        createBefore={props.taskRef.scheduling?.createBefore}
        entityOptions={props.entityOptions}
        finishBefore={props.taskRef.scheduling?.finishBefore}
        timeout={props.taskRef.scheduling?.timeout}
        onUpdateScheduling={props.onUpdateScheduling}
      />

      <ErrorHandlingComponentSection
        availablePaths={props.availablePaths}
        availableQueries={props.taskRef.queries}
        entityOptions={props.entityOptions}
        errors={props.taskRef.errors}
        hasRetry={props.taskRef.retry !== undefined}
        output={props.task.output}
        queryOptions={props.queryOptions}
        scheduling={props.taskRef.scheduling}
        skillOptions={props.skillOptions}
        templates={props.taskTemplateOptions}
        onAdd={(type) => props.onAddErrorHandling(props.id, type)}
        onRemove={handleRemoveErrorHandling}
        onUpdate={handleUpdateErrorHandling}
      />

      <TagsComponentSection
        availablePaths={props.availablePaths}
        availableQueries={props.taskRef.queries}
        entityOptions={props.entityOptions}
        tags={props.tags}
        onChange={props.onChangeTags}
      />
    </>
  );
}

export default SystemTaskSidebar;
