import { graphql } from "../../shared/schema/gql";

export const WORKFLOW_DASHBOARD_INSTANCES_QUERY = graphql(`
  query WorkflowDashboardInstances(
    $order: [AgencyMemberWorkflowInstance__Order]!
    $limit: Int!
    $offset: Int!
    $workflowDefinitionCorrelationKey: WorkflowDefinitionCorrelationKey_Filter
    $workflowDefinitionId: WorkflowDefinitionId_Filter
    $startedAtFrom: LocalDate
    $startedAtTo: LocalDate
    $status: String_Filter
    $patientIds: [PatientId!]
    $caregiverIds: [CaregiverId!]
  ) {
    workflowInstances(
      limit: $limit
      offset: $offset
      order: $order
      startedAtFrom: $startedAtFrom
      startedAtTo: $startedAtTo
      workflowDefinitionCorrelationKey: $workflowDefinitionCorrelationKey
      status: $status
      patientIds: $patientIds
      caregiverIds: $caregiverIds
      workflowDefinitionId: $workflowDefinitionId
    ) {
      totalCount
      nodes {
        id
        origin
        startedAt
        completedAt
        status
        input
        status
        workflowDefinitionId
        workflowDefinition {
          version
        }
      }
    }
  }
`);

export const WORKFLOW_DASHBOARD_DEFINITIONS_QUERY = graphql(`
  query WorkflowDashboardDefinitions(
    $workflowDefinitionId: WorkflowDefinitionId_Filter
    $corrletionKey: WorkflowDefinitionCorrelationKey_Filter!
  ) {
    workflowDefinitions(id: $workflowDefinitionId, correlationKey: $corrletionKey) {
      nodes {
        correlationKey
        versions(order: { field: version, sort: desc }) {
          nodes {
            id
            name
            input
            version
            published
            createdAt
            owner {
              id
              full_name
            }
          }
        }
      }
    }
  }
`);
