import { createIcon } from "@chakra-ui/react";

const UndoIcon = createIcon({
  displayName: "UndoIcon",
  viewBox: "0 0 24 24",
  path: (
    <svg fill="none" height="20" viewBox="0 0 21 20" width="21" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.11633 6.5H7.61633C7.88154 6.5 8.1359 6.39464 8.32343 6.20711C8.51097 6.01957 8.61633 5.76522 8.61633 5.5C8.61633 5.23478 8.51097 4.98043 8.32343 4.79289C8.1359 4.60536 7.88154 4.5 7.61633 4.5H4.11633V1C4.11633 0.734784 4.01097 0.48043 3.82343 0.292893C3.6359 0.105357 3.38154 0 3.11633 0C2.85111 0 2.59675 0.105357 2.40922 0.292893C2.22168 0.48043 2.11633 0.734784 2.11633 1V5.5C2.11633 5.76522 2.22168 6.01957 2.40922 6.20711C2.59675 6.39464 2.85111 6.5 3.11633 6.5Z"
        fill="black"
      />
      <path
        d="M10.9913 20C12.747 20.0015 14.4722 19.5408 15.9933 18.6641C17.5144 17.7875 18.778 16.5258 19.6569 15.0059C20.5357 13.486 20.999 11.7615 21 10.0059C21.001 8.25018 20.5398 6.52516 19.6627 5.00426C18.7856 3.48336 17.5236 2.22019 16.0034 1.34174C14.4833 0.463292 12.7587 0.00053764 11.003 4.27628e-06C9.24735 -0.000529087 7.52247 0.461177 6.00182 1.3387C4.48117 2.21622 3.21835 3.47863 2.34033 4.999C2.21614 5.22788 2.18612 5.49617 2.25666 5.74683C2.32721 5.99749 2.49274 6.21075 2.71807 6.34126C2.94341 6.47177 3.21076 6.50924 3.46329 6.4457C3.71581 6.38215 3.9336 6.22261 4.07033 6.001C5.04299 4.32457 6.59186 3.05885 8.42844 2.43958C10.265 1.82032 12.2641 1.88974 14.0533 2.63492C15.8425 3.3801 17.2998 4.75022 18.1538 6.49008C19.0079 8.22995 19.2003 10.2209 18.6955 12.0922C18.1906 13.9634 17.0227 15.5874 15.4094 16.6615C13.7961 17.7356 11.8474 18.1867 9.92617 17.9308C8.00498 17.6748 6.24232 16.7293 4.9665 15.2702C3.69069 13.8112 2.98871 11.9382 2.99133 10C2.99133 9.73478 2.88597 9.48043 2.69843 9.29289C2.5109 9.10536 2.25654 9 1.99133 9C1.72611 9 1.47175 9.10536 1.28422 9.29289C1.09668 9.48043 0.991325 9.73478 0.991325 10C0.994237 12.6513 2.04874 15.1931 3.92347 17.0679C5.79821 18.9426 8.34005 19.9971 10.9913 20V20Z"
        fill="black"
      />
    </svg>
  ),
});

export default UndoIcon;
