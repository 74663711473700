import { useCallbackRef } from "@chakra-ui/react";
import { produce } from "immer";
import React from "react";
import {
  Connection,
  Edge,
  EdgeChange,
  NodeChange,
  OnConnectEnd,
  OnConnectStart,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
} from "reactflow";
import useIsHoldingShiftKey from "../../../shared/hooks/useIsHoldingShiftKey";
import {
  WorkflowDefinition,
  WorkflowInstanceExecutionResult,
  WorkflowNode,
} from "../../../shared/schemas/workflows";
import { fmap, isDefined } from "../../../shared/utils/general.utils";
import { generateId } from "../../../shared/utils/string.utils";
import {
  capNodePosition,
  dummyTaskRefIds,
  emptyRef,
  findNode,
  findNodeSiblings,
  generateReactflowEdges,
  generateReactflowNodes,
  getAvailableNodeResult,
  getNodeDecendants,
  mapDependsOn,
  removeNodeDependsOn,
} from "../utils/workflow-node.utils";
import { workflowConfig } from "../workflow-config.const";

export default function useFlowBoard(props: {
  workflowChildren: Record<string, WorkflowNode>;
  workflowInput: WorkflowDefinition["input"];
  flattenedNodes: Record<string, WorkflowNode>;
  selectedNodeId: string | null;
  executionResult?: WorkflowInstanceExecutionResult;
  onSelectNode: (id: string) => void;
  onUnselectNode: (id: string) => void;
  onAddNode: (id: string, node: WorkflowNode) => void;
  onUpdateNode: (id: string, node: WorkflowNode) => void;
  onRemoveNode: (id: string) => void;
  onConnectNodes: (sourceId: string, targetId: string) => void;
}) {
  const isHoldingShift = useIsHoldingShiftKey();

  const nodes = React.useMemo(
    () =>
      generateReactflowNodes({
        children: props.workflowChildren,
        selectedNodeId: props.selectedNodeId,
        executionResult: props.executionResult,
      }),
    [props.executionResult, props.selectedNodeId, props.workflowChildren],
  );

  const edges: Edge[] = React.useMemo(
    () =>
      generateReactflowEdges({
        children: props.workflowChildren,
        selectedNodeId: props.selectedNodeId,
      }),
    [props.selectedNodeId, props.workflowChildren],
  );

  const reactFlowWrapper = React.useRef<HTMLDivElement | null>(null);
  const connectingNodeId = React.useRef<string | null>(null);
  const onUpdateNodeRef = useCallbackRef(props.onUpdateNode);
  const onUnselectNodeRef = useCallbackRef(props.onUnselectNode);
  const onRemoveNodeRef = useCallbackRef(props.onRemoveNode);
  const onAddNodeRef = useCallbackRef(props.onAddNode);
  const onConnectNodesRef = useCallbackRef(props.onConnectNodes);
  const { project } = useReactFlow();

  const applyNodeChange = React.useCallback(
    (change: NodeChange) => {
      switch (change.type) {
        case "add":
        case "dimensions":
        case "reset":
          return;

        case "select": {
          const fn = change.selected ? props.onSelectNode : props.onUnselectNode;

          fn(change.id);

          break;
        }

        case "remove": {
          if (Object.values(dummyTaskRefIds).includes(change.id)) {
            return;
          }

          onUnselectNodeRef(change.id);
          onRemoveNodeRef(change.id);

          break;
        }

        case "position": {
          if (Object.values(dummyTaskRefIds).includes(change.id)) {
            return;
          }

          const siblings = findNodeSiblings(change.id, props.workflowChildren);
          const node = siblings?.[change.id];

          if (siblings === null || node === undefined || change.position === undefined) {
            return;
          }

          const updates = [{ node, position: change.position }];

          if (isHoldingShift) {
            const diff = {
              x: change.position.x - (node.meta.position?.x ?? 0),
              y: change.position.y - (node.meta.position?.y ?? 0),
            };

            const decendants = getNodeDecendants(node, siblings);

            for (const decendant of Object.values(decendants)) {
              const currentPosition =
                decendant.meta.position === undefined ? { x: 0, y: 0 } : decendant.meta.position;

              updates.push({
                node: decendant,
                position: {
                  x: currentPosition.x + diff.x,
                  y: currentPosition.y + diff.y,
                },
              });
            }
          }

          for (const update of updates) {
            onUpdateNodeRef(
              update.node.logicalId,
              produce(update.node, (draft) => {
                draft.meta.position = capNodePosition(draft, update.position);
              }),
            );
          }
        }
      }
    },
    [
      isHoldingShift,
      onRemoveNodeRef,
      onUnselectNodeRef,
      onUpdateNodeRef,
      props.onSelectNode,
      props.onUnselectNode,
      props.workflowChildren,
    ],
  );

  const applyEdgeChange = React.useCallback(
    (change: EdgeChange) => {
      switch (change.type) {
        case "add":
        case "reset":
          return;

        case "select": {
          const fn = change.selected ? props.onSelectNode : props.onUnselectNode;

          fn(change.id);

          break;
        }

        case "remove": {
          const [, id] = change.id.split(" -> ");

          if (id === undefined) {
            return;
          }

          const node = findNode(id, props.workflowChildren);

          if (node === null) {
            return;
          }

          props.onUpdateNode(id, removeNodeDependsOn(node));

          break;
        }
      }
    },
    [props],
  );

  const onChangeNodes = React.useCallback(
    (changes: NodeChange[]) => {
      const hasSelectTrueEvent = changes.some(
        (change) => change.type === "select" && change.selected === true,
      );

      changes
        .filter(
          (change) => !hasSelectTrueEvent || change.type !== "select" || change.selected === true,
        )
        .forEach(applyNodeChange);

      applyNodeChanges(changes, nodes);
    },
    [applyNodeChange, nodes],
  );

  const onEdgesChange = React.useCallback(
    (changes: EdgeChange[]) => {
      changes.forEach(applyEdgeChange);
      applyEdgeChanges(changes, edges);
    },
    [applyEdgeChange, edges],
  );

  const onConnect = React.useCallback(
    (connection: Connection) => {
      connectingNodeId.current = null;

      const sourceId = connection.source;

      if (sourceId === null || connection.target === null) {
        return;
      }

      onConnectNodesRef(sourceId, connection.target);
    },
    [onConnectNodesRef],
  );

  const onConnectStart: OnConnectStart = React.useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd: OnConnectEnd = React.useCallback(
    (event) => {
      const targetIsDroppable =
        event.target !== null &&
        event.target instanceof Element &&
        (event.target.classList.contains("react-flow__pane") ||
          (event.target.classList.contains("react-flow__node") &&
            event.target.classList.contains("parent")));

      if (!targetIsDroppable) {
        return;
      }

      const source = fmap(connectingNodeId.current, (id) => findNode(id, props.workflowChildren));

      if (!isDefined(source)) {
        return;
      }

      const availableResult = getAvailableNodeResult({
        node: source,
        workflowChildren: props.workflowChildren,
      });

      if (availableResult === null) {
        return;
      }

      const { top, left } = reactFlowWrapper.current?.getBoundingClientRect() ?? {
        top: 0,
        left: 0,
      };

      const newNode = emptyRef.placeholder({
        logicalId: generateId("node"),
        parent: source.parent ?? null,
        position: project({
          x:
            ("clientX" in event ? event.clientX : event.touches[0]?.clientX ?? 0) -
            left -
            workflowConfig.nodeWidth / 2,
          y: ("clientY" in event ? event.clientY : event.touches[0]?.clientY ?? 0) - top,
        }),
        dependsOn: mapDependsOn({
          id: source.logicalId,
          type: availableResult.type,
          output: availableResult.key,
        }),
      });

      onAddNodeRef(newNode.logicalId, newNode);
    },
    [onAddNodeRef, project, props.workflowChildren],
  );

  return {
    nodes,
    edges,
    reactFlowWrapper,
    onChangeNodes,
    onEdgesChange,
    onConnect,
    onConnectStart,
    onConnectEnd,
  };
}
