import { createIcon } from "@chakra-ui/react";

const TriggerIcon = createIcon({
  displayName: "TriggerIcon",
  viewBox: "0 0 24 24",
  path: (
    <path d="M4 14h6l-3 9h2L20 9h-6l4-8H7z" fill="none" stroke="currentColor" strokeWidth="2" />
  ),
});

export default TriggerIcon;
